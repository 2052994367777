import { EllipsisOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import { Language } from '../../../config/Language'

interface IProps {
    task: ITask
}
interface ITask {
    taskColor: string
    indicatorColor: string
    title: string
    subtitle: string
    estado: string
}

const Task = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const { task } = props

    const menuDetails = (
        <Menu>
            <div id='title-menu-detail'>{getTranslation('briefs')}</div>
            <Menu.Divider />
            <Menu.Item key='0'>{getTranslation('completar')}</Menu.Item>
            <Menu.Item key='1'>{getTranslation('eliminar')}</Menu.Item>
        </Menu>
    )
    let { estado, indicatorColor, taskColor, subtitle, title } = task
    let borderColor = indicatorColor
    if (indicatorColor === '#FFFFFF') {
        borderColor = '#66CCCC'
    }
    return (
        <div className='task-item' style={{ backgroundColor: taskColor }}>
            <div
                className='indicator'
                style={{
                    backgroundColor: indicatorColor,
                    borderColor: borderColor,
                }}></div>
            <div className='details'>
                <div className='title'>{title}</div>
                <div className='subtitle'>
                    <a>{subtitle}</a>
                </div>
                <div className='footer'>{estado}</div>
            </div>
            <div className='button'>
                <Dropdown
                    overlay={menuDetails}
                    trigger={['click']}
                    placement='bottomRight'>
                    <EllipsisOutlined />
                </Dropdown>
            </div>
        </div>
    )
}

export default Task
