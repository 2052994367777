import { Line } from '@ant-design/charts'
import { Empty } from 'antd'
import { Language } from '../../../config/Language'

interface IProps {
    data: any[]
}

export const CustomLineChart = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const { data } = props

    var config = {
        data: data,
        xField: 'name',
        yField: 'value',
        label: {},
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        },
        tooltip: { showMarkers: false },
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red',
                },
            },
        },
        interactions: [{ type: 'marker-active' }],
    }
    return (
        <>
            {data.length > 0 ? (
                <div style={{ height: '400px' }}>
                    <Line {...config} />
                </div>
            ) : (
                <div className='data-ant-empty'>
                    <Empty description={getTranslation('sin_datos')} />
                </div>
            )}
        </>
    )
}
