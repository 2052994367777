import { Badge, Tooltip, Dropdown, Menu } from 'antd'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    BellOutlined,
    QuestionCircleOutlined,
    UserOutlined,
    GlobalOutlined,
} from '@ant-design/icons'
import logo from '../../asset/img/logo-switch.svg'
import { NotificationPanel } from './NotificationPanel'
import useUserContext from '../../hooks/useUserContext'
import { Link, useHistory } from 'react-router-dom'
import { LOGOUT } from '../../config/Path'
import { Language } from '../../config/Language'
import { useAxios } from '../../hooks/useAxios'
import { useCallback } from 'react'
import { ENDPOINTS } from '../../config/EndPoints'
import { Notifications } from '../common/notifications/Notifications'

interface IProps {
    setToggle: (bool: boolean) => void
    toggle: boolean
    setLocaleSpin: (bool: boolean) => void
}

export const NavBar = ({ ...props }: IProps) => {
    const { user } = useUserContext()
    const { getLanguage, getTranslation } = Language()
    const { errorObjectWithData } = Notifications()
    const { put } = useAxios()
    const history = useHistory()
    const { setToggle, toggle, setLocaleSpin } = props

    const textTooltipHelp = 'Ayuda'

    const menuUser = (
        <Menu>
            <Menu.Item key='0'>
                {getTranslation('ajustes_personales')}
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key='1'>
                <Link to={LOGOUT}>{getTranslation('cerrar_sesion')}</Link>
            </Menu.Item>
        </Menu>
    )

    const handleMenuClick = (e: any) => {
        let param: any = {
            locale: 'es',
            user_id: user.sub,
        }
        if (e.key === '0') {
            param.locale = 'en'
        } else if (e.key === '1') {
            param.locale = 'es'
        } else {
            param.locale = 'pt'
        }

        setLocaleSpin(true)
        put(ENDPOINTS.PUT_LOCALE, param, success, error, always)
    }

    const menuTraslate = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item
                className={getLanguage() === 'en' ? 'active' : 'inactive'}
                key='0'>
                {getTranslation('en')}
            </Menu.Item>
            <Menu.Item
                className={getLanguage() === 'es' ? 'active' : 'inactive'}
                key='1'>
                {getTranslation('es')}
            </Menu.Item>
            {/* <Menu.Item
                className={getLanguage() === 'pt' ? 'active' : 'inactive'}
                key='2'>
                {getTranslation('pt')}
            </Menu.Item> */}
        </Menu>
    )

    const success = useCallback((response: any) => {
        setLocaleSpin(false)
        let url = history.location.pathname
        history.push('/')
        history.push(url)
    }, [])

    const always = () => {
        setLocaleSpin(false)
    }
    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    return (
        <div id='navbar'>
            <div className='left'>
                <div className='button-toggle'>
                    {toggle ? (
                        <MenuUnfoldOutlined
                            className='icon-navbar'
                            onClick={() => setToggle(!toggle)}
                        />
                    ) : (
                        <MenuFoldOutlined
                            className='icon-navbar'
                            onClick={() => setToggle(!toggle)}
                        />
                    )}
                </div>
                <div className='logo'>
                    <img alt='logo switch' src={logo} />
                </div>
            </div>
            <div className='right'>
                <Tooltip placement='bottom' title={textTooltipHelp}>
                    <div className='rigth-item help'>
                        <QuestionCircleOutlined className='icon-navbar' />
                    </div>
                </Tooltip>
                <Dropdown overlay={NotificationPanel} trigger={['click']}>
                    <div className='rigth-item bell ant-dropdown-link'>
                        <Badge className='badge-bell' count={8}>
                            <BellOutlined className='icon-navbar' />
                        </Badge>
                    </div>
                </Dropdown>
                <Dropdown overlay={menuUser} trigger={['click']}>
                    <div className='rigth-item user ant-dropdown-link'>
                        <div className='icon'>
                            <UserOutlined className='icon-navbar' />
                        </div>
                        <div className='username'>{user?.given_name}</div>
                    </div>
                </Dropdown>
                <Dropdown overlay={menuTraslate} trigger={['click']}>
                    <div className='rigth-item traslate ant-dropdown-link'>
                        <GlobalOutlined className='icon-navbar' />
                    </div>
                </Dropdown>
            </div>
        </div>
    )
}
