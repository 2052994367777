import {
    RocketOutlined,
    SoundOutlined,
    BookOutlined,
    FileDoneOutlined,
    PictureOutlined,
    ToolOutlined,
    EyeOutlined,
    FileTextOutlined,
    TeamOutlined,
    BuildOutlined,
    PushpinOutlined,
    ScheduleOutlined,
    LineChartOutlined,
    AppstoreOutlined,
} from '@ant-design/icons'

// @ts-ignore
import MediosOutlined from '../asset/img/MediosOutlinedIcon.svg'

const IconsList = (name: string) => {
    const list = [
        { name: 'RocketOutlined', icon: <RocketOutlined /> },
        { name: 'SoundOutlined', icon: <SoundOutlined /> },
        { name: 'BookOutlined', icon: <BookOutlined /> },
        { name: 'FileDoneOutlined', icon: <FileDoneOutlined /> },
        { name: 'PictureOutlined', icon: <PictureOutlined /> },
        { name: 'ToolOutlined', icon: <ToolOutlined /> },
        { name: 'EyeOutlined', icon: <EyeOutlined /> },
        { name: 'FileTextOutlined', icon: <FileTextOutlined /> },
        { name: 'TeamOutlined', icon: <TeamOutlined /> },
        { name: 'BuildOutlined', icon: <BuildOutlined /> },
        { name: 'PushpinOutlined', icon: <PushpinOutlined /> },
        { name: 'ScheduleOutlined', icon: <ScheduleOutlined /> },
        { name: 'LineChartOutlined', icon: <LineChartOutlined /> },
        { name: 'AppstoreOutlined', icon: <AppstoreOutlined /> },
        { name: 'MediosOutlined', icon: MediosOutlined },
    ]
    let result = list.find((item: any) => item.name === name)

    return result?.icon
}

export default IconsList
