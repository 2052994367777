import { Form, DatePicker } from 'antd'
import { useEffect, useState } from 'react'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Moment } from 'moment'
import { Language } from '../../../config/Language'
const { RangePicker } = DatePicker
interface IProps {
    id?: string
    label: string
    name: string
    type?: any
    className?: string
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    value?: any
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
    size?: SizeType
    disabledDate?: (current: Moment) => boolean
}

export const CustomRangePicker = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        value,
        size = 'large',
        disabledDate,
        type,
    } = props
    const [selectedValue, setSelectedValue] = useState<any>(null)

    useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
        return () => {}
    }, [value])

    return (
        <Form.Item
            id={id}
            name={name}
            className={className}
            label={label}
            rules={[
                {
                    type: type,
                    required: required,
                    message: requiredMessage,
                },
            ]}>
            <RangePicker
                disabled={disabled}
                size={size}
                disabledDate={disabledDate}
            />
        </Form.Item>
    )
}
