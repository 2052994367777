import { IAssetsClassificationContext } from '../interface/IAssetsClassificationContext'
import { createContext } from 'react'
import { useCallback, useState, useMemo } from 'react'
import { IAssetsClassification } from '../interface/IAssetsClassification'

export const AssetsClassificationContext = createContext<any>({})

const AssetsClassificationContextProvider = ({ children }: any) => {
    let initialState = null
    const [materialList, setMaterialList] = useState<
        IAssetsClassification | any
    >(initialState)
    const [assetTypesList, setAssetTypesList] = useState<
        IAssetsClassification | any
    >(initialState)
    const [geopathList, setGeopathList] = useState<IAssetsClassification | any>(
        initialState,
    )

    const updateAssetTypesList = useCallback((item: IAssetsClassification) => {
        setAssetTypesList(item)
    }, [])
    const updateGeopathList = useCallback((item: IAssetsClassification) => {
        setGeopathList(item)
    }, [])
    const updateMaterialList = useCallback((item: IAssetsClassification) => {
        setMaterialList(item)
    }, [])

    const value: IAssetsClassificationContext = useMemo(
        () => ({
            updateAssetTypesList,
            updateGeopathList,
            updateMaterialList,
            assetTypesList,
            geopathList,
            materialList,
        }),
        [
            assetTypesList,
            materialList,
            geopathList,
            updateMaterialList,
            updateGeopathList,
            updateAssetTypesList,
        ],
    )

    // @ts-ignore
    return (
        <AssetsClassificationContext.Provider value={value}>
            {children}
        </AssetsClassificationContext.Provider>
    )
}

export default AssetsClassificationContextProvider
