export const translatedMessages = {
    aplicar: 'Apply',
    aceptar: 'Accept',
    cancelar: 'Cancel',
    eliminar: 'Remove',
    editar: 'Edit',
    logout: 'Logout',
    'briefs-form': 'Brief form',
    vendors: 'Vendors',
    activities: 'Activities',
    activity: 'Activity',
    'vendor-details': 'View or edit vendor',
    brand: 'Brand',
    brands: 'Brands',
    organization: 'Organization',
    accounts: 'Accounts',
    briefs: 'Brief',
    dashboard: 'Dashboard',
    seleccione: 'Select',
    guardado: 'Save',
    guardar_cambios: 'Save changes',
    guardar: 'Save',
    guardar_borrador: 'Save draft',
    los_datos_se_han_guardado_con_exito: 'Data has been successfully saved',
    los_datos_se_han_eliminado_con_exito: 'Data has been successfully deleted',
    mensaje: 'Message',
    error: 'Error',
    eliminado: 'Remove',
    en: 'English',
    es: 'Spanish',
    pt: 'Portuguese',
    nombre: 'Name',
    apellido: 'Last name',
    marca: 'Brand',
    pais: 'Country',
    ciudad: 'City',
    tipo_de_contacto: 'Contact type',
    position: 'Position',
    email: 'Email',
    genero: 'Gender',
    fecha_de_nacimiento: 'Date of birth',
    fecha: 'Date',
    mobile: 'Mobile',
    phone: 'Phone',
    observaciones: 'Observations',
    eliminar_contacto: 'Delete contact',
    medios: 'Vendors',
    crear_medio: 'Create vendor',
    crear_contacto: 'Create contact',
    crear_marca: 'Create brand',
    logo: 'Logo',
    contactos: 'Contacts',
    contacto: 'Contact',
    nuevo_medio: 'New vendor',
    nueva_organizacion: 'New organization',
    crear_actividad: 'Create activity',
    pasadas: 'Past',
    hoy: 'Today',
    proximas: 'Upcoming',
    inicio_fin: 'Start - End',
    clean_filters: 'Clean filters',
    ejecutivo: 'Executive',
    ejecutivo_cuenta: 'Account executive',
    nueva_actividad: 'New activity',
    tipo_actividad: 'Activity type',
    business_potentiality: 'Business potentiality',
    descripcion_actividad: 'Activity description',
    descripcion: 'Description',
    hora: 'Hour',
    sin_datos: 'No data.',
    crea_un_contacto: 'Create a contact',
    crea_una_marca: 'Create a brand',
    crear_organizacion: 'Create organization',
    medio: 'Vendor',
    last_activity: 'Last activity',
    nuevo_contacto: 'New contact',
    metrics: 'Metrics',
    ultima_campana: 'Last campaign',
    fecha_ultima_campana: 'Last campaign date',
    propuesta_enviada: 'Proposals submitted',
    historical_total: 'Historical total',
    campana_ejecutadas: 'Campaigns executed',
    tasa_conversion: 'Conversion rate',
    total_actividades: 'All the activities',
    reuniones: 'Meetings',
    promedio_semanal: 'Weekly average',
    metricas_campanas: 'Campaign Metrics',
    historial_propuestas: 'Proposal History',
    historial_inversion_campana: 'Investment history by campaign',
    historial_inversion_mensual: 'Monthly investment history',
    historial_inversion_medio: 'Investment history by vendor',
    prevision_inversion_campaña: 'Investment forecast by campaign',
    metricas_actividades: 'Activity metrics',
    por_tipo_actividad: 'By type of activity',
    evolucion_actividades: 'Evolution of activities',
    actividades_ejecutivo: 'Activities per executive',
    actividades_por_cuenta: 'Activities by account',
    tooltips_img_validation:
        'Size less than 2MB, PGN, JPG or GIF format, Measurement 400 x 400 pixels',
    informacion_general: 'General information',
    nombre_de_la_campana: 'Campaign name',
    fecha_estimada: 'Estimated date',
    moneda_para_la_propuesta: 'Currency for proposal',
    presupuesto: 'Budget',
    tipo_de_presupuesto: 'Budget type',
    pais_de_pago: 'Country of payment',
    pais_de_la_campana: 'Campaign country',
    ciudad_de_la_campana: 'Campaign city',
    publico_objetivo: 'Target audiences',
    perfil_psicografico: 'Psychographic profile',
    especificaciones_de_campana: 'Campaign specifications',
    objetivo_de_comunicacion: 'Communication objective',
    convertir_en_propuesta: 'Convert to proposal',
    nuevo_brief: 'New brief',
    modal_nueva_marca_detalle:
        'To create a Brand you must have an existing Organization. Select an Organization from the list or create a new Organization.',
    logotipo_imagen: 'Logo or image',
    discount_: 'Discount %',
    discount: 'Discount',
    discount_optional: '% Discount (Optional)',
    tipo: 'Type',
    sitio_web: 'Website',
    categoria: 'Category',
    sub_categoria: 'Sub Category',
    campaign: 'Campaign',
    campaigns: 'Campaigns',
    cliente: 'Client',
    inicio: 'Start',
    inicio_estimado: 'Estimated start',
    fin_estimado: 'Estimated end',
    finalizacion: 'Ending',
    estado: 'Status',
    acciones: 'Actions',
    accion: 'Action',
    nueva_campana: 'New campaign',
    listado_campanas: 'Campaigns list',
    listado_briefs: 'Briefs list',
    completar: 'To complete',
    progreso: 'Progress',
    etapa: 'Stage',
    inversion_a_futuro: 'Future investment',
    inversion_futuro_cliente: 'Future investment per client',
    gestor_tareas: 'Task manager',
    ajustes_personales: 'Personal settings',
    cerrar_sesion: 'Logout',
    notificaciones: 'Notifications',
    borrar_notificaciones: 'Clear notifications',
    volver: 'Go back',
    page_not_found_text: 'The page you are trying to visit does not exist',
    recientes: 'Recent',
    aceptadas: 'Accepted',
    rechazadas: 'Rejected',
    vencidas: 'Overdue',
    archivadas: 'Archived',
    envio: 'Shipment',
    vencimiento: 'Expiration',
    proposals: 'Proposals',
    'proposals-detail': 'Proposal detail',
    proposal_list: 'Proposals list',
    nombre_campana: 'Campaign name',
    presupuesto_estimado: 'Estimated budget',
    nivel_socioeconomico: 'Socioeconomic level',
    rangos_etarios: 'Age ranges',
    tipo_de_campaña: 'Campaign type',
    material: 'Material',
    puntos_de_interes: 'Points of interest',
    soportes: 'Assets',
    estrategia: 'Strategy',
    confirmar: 'Confirm',
    editar_propuesta: 'Edit proposal',
    archivar: 'Archivar',
    filtros: 'Filters',
    entorno: 'Environment',
    ots_min: 'OTS Min',
    ots_max: 'OTS Max',
    filters_message: 'Active Filters respond to your Campaign Brief.',
    agregar: 'Add',
    ver_mapa: 'See map',
    selection: 'Selection',
    fin: 'End',
    seleccion_de_soportes: 'Assets selection',
    nombre_comercial: 'Tradename',
    ubicacion: 'Location',
    soportes_totales: 'Total assets',
    total_OTS: 'Total OTS',
    costo_total: 'Total cost',
    precio_unitario: 'Unit price',
    of: 'of',
    items: 'items',
    already_added: 'You have already added it',
    write_here: 'Write here...',
    see_list: 'See list',
    MU: 'MU',
    CA: 'BU',
    PO: 'PO',
    PP: 'JP',
    PA: 'PA',
    DI: 'DI',
    layers: 'Layers',
    not_search_assets: 'You have not yet searched for Supports',
    referencias: 'References',
    digital: 'Digital',
    place_based: 'Place based',
    roadside: 'Roadside',
    printed_mesh: 'Printed/Mesh',
    billboard: 'Bulletin',
    display: 'Display',
    mural: 'Wall-Murals',
    panel: 'Panel',
    poster: 'Poster',
    poster_junior: 'Poster Pequeño',
    food_beverages: 'Food & beverages',
    vehicles: 'Vehicles',
    goverment: 'Goverment',
    education: 'Education',
    entertainment: 'Entertainment',
    finance: 'Finance',
    parks: 'Parks',
    health: 'Health',
    shops: 'Shops',
    transportation: 'Transportation',
    turism: 'Turism',
    ots_description:
        'If you turn on the view by OTS, you will see that the icons that identify the supports change in size. This change in size responds to the greater or lesser amount of OTS that the supports included in the search you have made have.',
    example: 'Example',
    comercial_name: 'Comercial name',
    download_specifications: 'Download Specifications',
    commercial_measures: 'Commercial measures',
    additional_information: 'Additional information',
    otros: 'Others',
    subCategoryEducation:
        'Educational Structure, Higher Education Facility (Higher Education), School',
    subCategoryEntertainment:
        'Amusement Park, Bowling Alley/Centre, Casino, Cinema, Convention Center (Convention/Exhibition Centre), Golf Course, Ice Skating Rink, Nightlife (Bar, Discotheque, Jazz Club, Karaoke Club and Private Club), Performing Arts (Comedy Club and Theater), Stadium (Sports Centre), Zoo (Animal Park)',
    subCategoryFinance: 'ATM, Bank, Currency Exchange, Financial Structure',
    subCategoryFoodBeverages:
        'Coffee Shop, Fast Food, Grocery Store, Restaurant, Winery',
    subCategoryGoverment:
        'City Hall, Community Center (Civic/Community Centre), Court House, Embassy, Government Structure (Government Office), Police Station, Post Office',
    subCategoryHealth: 'Hospital, Servicios médicos, Farmacia',
    subCategoryParks:
        'Park (Park/Recreation Area), Recreational Structure, Rest Area, Sports Complex (Playing Field and Race Track)',
    subCategoryShops:
        'Apparel Store (Clothing Store), Bookstore, Consumer Electronics Store, Convenience Store, Department Store, Home Improvement (Hardware and Home Furnishing Store), Office Supply Store (Office Supply & Services Store), Personal Care Facility, Shopping (Market and Shopping Center), Specialty Store (Hobby Store, Pet Store and Warehouse Club), Sporting Goods Store, Store, Veterinarian',
    subCategoryTransportation:
        'Airport, Airport Runway, Airport Terminal, Bus Station, Ferry Terminal, Heliport, Marina, Multi Modal Station, Port, Railway Station, Railway Station Entrance, Tollgate, Transportation Service (Metro Station, Railway and Transportation Structure), Tramway, Transportation Service (Metro Station, Railway and Transportation Structure)',
    subCategoryTurism:
        'Border Post (Border Crossing), Historical Monument (Battlefield, Fort and Historical Site), Hotel, Information Center (Tourist Information), Museum, Observation Point, Other Accommodation (Bed and Breakfast, Cabin, Hostel, Motel and Resort), Ruin, Ski Area (Ski Resort), Trailhead',
    subCategoryVehicles:
        'Auto Dealership, Auto Service & Maintenance, Gas Station (Petrol/Gasoline Station), Motorcycle Dealership, Park & Ride, Parking Garage/House, Rental Car Agency, Truck Dealership, Truck Stop (Truck Stop/Plaza), Vehicle Organization Office (Automobile Club), Weigh Station',
    required: 'Required',
    select_a_option: 'Select a option',
    ha_ocurrido_error_guardar: 'An error occurred while saving',
    without_code: 'Without code',
    code: 'Code',
    advertisers: 'Advertisers',
    advertiser: 'Advertiser',
    add_an_advertiser: 'Add an advertiser',
    add_advertiser: 'Add advertiser',
    'add-advertisers': 'Add an advertiser',
    'preview-advertisers': 'Existing advertiser',
    advertiser_list: 'Advertisers list',
    created_by: 'Created by',
    created: 'Created',
    'new-advertisers': 'Add an advertiser',
    select_advertaiser: 'Select advertiser',
    about_advertaisers: 'About advertisers',
    create_advertiser: 'Create advertiser',
    next: 'Next',
    help: 'Help',
    razon_social: 'Company name',
    you_can_skip: 'You can skip this step',
    create_an_advertiser: 'Create an advertiser',
    modal_advertiser_exist:
        'is already part of your advertisers. Do you want to visit the information page about this advertiser?',
    see_advertiser: 'See advertiser',
    close: 'Close',
    same_name_advertiser: 'Same name as the advertiser',
    same_logo_advertiser: 'Same logo as the advertiser',
    company_name_number: 'Company name number',
    legal_name: 'Legal name',
    skip_this_step: 'Skip this step',
    create_company_name: 'Create a company name',
    finish: 'Finish',
    you_will_create_contacts_end:
        'You will be able to create more Contacts for your Advertiser at the end.',
    edit_discont: 'Edit discount',
    company_names: 'Company names',
    add_company_name: 'Create company name',
    name_last_name: 'Name and surname',
    to_disable: 'Disable',
    table_trad: {
        filterTitle: 'Filter menu',
        filterConfirm: 'OK',
        filterReset: 'Reset',
        filterEmptyText: 'No filters',
        filterCheckall: 'Select all items',
        filterSearchPlaceholder: 'Search in filters',
        emptyText: 'No data',
        selectAll: 'Select current page',
        selectInvert: 'Invert current page',
        selectNone: 'Clear all data',
        selectionAll: 'Select all data',
        sortTitle: 'Sort',
        expand: 'Expand row',
        collapse: 'Collapse row',
        triggerDesc: 'Click to sort descending',
        triggerAsc: 'Click to sort ascending',
        cancelSort: 'Click to cancel sorting',
    },
    action_to_taken: 'Action to be taken',
    make_impact: 'Make impact',
    segment: 'Segment',
    search_coverage: 'Search coverage',
    search_frequency: 'Buscar frecuencia',
    login: 'Login',
    'send-proposal': 'Send proposal',
    abstract: 'Abstract',
    map: 'Map',
    proposal_expiration: 'Proposal expiration',
    subtotal: 'Subtotal',
    total: 'Total',
    cost_production: 'Cost of production',
    installation_cost: 'Installation cost',
    quantity: 'Quantity',
    price: 'Price',
    totals: 'Totals',
    total_CPM: 'Total CPM',
}
