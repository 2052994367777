import { Button, Empty, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { NEW_VENDOR } from '../../config/Path'
import { Vendor } from './Vendor'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { Language } from '../../config/Language'

export const VendorsList = () => {
    const { get } = useAxios()
    const { getTranslation } = Language()
    const { errorObjectWithData } = Notifications()
    const [generalSpin, setGeneralSpin] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        loadVendors()

        return () => {}
    }, [])

    const loadVendors = () => {
        setGeneralSpin(true)
        get(
            ENDPOINTS.GET_ORGANIZATION_VENDORS,
            {},
            successVendors,
            error,
            alwaysVendors,
        )
    }

    const successVendors = useCallback((response: any) => {
        setGeneralSpin(false)
        setData(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const alwaysVendors = () => {
        setGeneralSpin(false)
    }

    return (
        <div id='vendors-list'>
            <div className='header'>
                <div className='header-title'>{getTranslation('medios')}</div>
                <div className='header-button'>
                    <Button type='primary' size='small'>
                        <Link to={NEW_VENDOR}>
                            {getTranslation('crear_medio')}
                        </Link>
                    </Button>
                </div>
            </div>
            <Spin
                className='spin-local'
                indicator={antIcon}
                spinning={generalSpin}>
                {data.length > 0 ? (
                    <div className='body'>
                        {data.map((item: any, index: any) => {
                            return <Vendor data={item} key={index} />
                        })}
                    </div>
                ) : (
                    <Empty description={getTranslation('sin_datos')} />
                )}
            </Spin>
        </div>
    )
}
