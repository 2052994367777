import moment from 'moment'
import { useEffect, useState } from 'react'
import { Form, Button } from 'antd'
import { CustomRangePicker } from '../../common/custom-range-picker/CustomRangePicker'
import { Language } from '../../../config/Language'
import { ENDPOINTS } from '../../../config/EndPoints'
import { CustomSelectMultiple } from '../../common/custom-select-multiple/CustomSelectMultiple'

interface FormValue {
    rangesDates: any
    account: any
    contact: any
    worker: any
    activity: any
}

interface IProps {
    tab: string
    setParams: ({}) => void
}

export const FilterActivity = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const [form] = Form.useForm()
    const { tab, setParams } = props
    const [formValue, setFormValue] = useState<FormValue>({
        rangesDates: null,
        account: null,
        contact: null,
        worker: null,
        activity: null,
    })

    useEffect(() => {
        let newFormValue: FormValue = { ...formValue }
        newFormValue.rangesDates = []
        setFormValue(newFormValue)
        form.resetFields(['rangesDates'])
        return () => {}
    }, [tab])

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {}
        if (formValue.account) {
            result.accounts_id = formValue.account
        }
        if (formValue.contact) {
            result.contacts_id = formValue.contact
        }
        if (formValue.worker) {
            result.workers_id = formValue.worker
        }
        if (formValue.activity) {
            result.activity_types_id = formValue.activity
        }

        if (formValue.rangesDates && formValue.rangesDates.lengh > 0) {
            result.start_date = formValue.rangesDates[0].format('YYYY-MM-DD')
            result.end_date = formValue.rangesDates[1].format('YYYY-MM-DD')
        }

        return result
    }

    const onSave = (values: any) => {
        let result = getValues()
        setParams(result)
    }

    const onClean = () => {
        let newFormValue: FormValue | any = {
            rangesDates: [],
            account: undefined,
            contact: undefined,
            worker: undefined,
            activity: undefined,
        }
        form.resetFields(['rangesDates'])
        setParams({})
        setFormValue(newFormValue)
    }

    const disabledDate = (current: any) => {
        if (tab === '1') {
            return current && current > moment().startOf('day')
        } else if (tab === '3') {
            return current && current < moment().endOf('day')
        }
    }

    return (
        <div className='filter-activity'>
            <Form
                id='form-activity'
                form={form}
                layout='vertical'
                scrollToFirstError
                onValuesChange={onChange}
                onFinish={onSave}
                size='large'>
                <div className='form-container'>
                    <div className='form-body'>
                        <CustomSelectMultiple
                            label={getTranslation('accounts')}
                            name='account'
                            id='account'
                            mode={'multiple' as const}
                            size='small'
                            endpoint={ENDPOINTS.GET_ACCOUNTS}
                            value={formValue?.account}
                        />
                        <CustomSelectMultiple
                            label={getTranslation('contactos')}
                            name='contact'
                            id='contact'
                            mode={'multiple' as const}
                            size='small'
                            endpoint={ENDPOINTS.GET_CONTACTS}
                            value={formValue?.contact}
                        />
                        <CustomSelectMultiple
                            label={getTranslation('ejecutivo')}
                            name='worker'
                            id='worker'
                            mode={'multiple' as const}
                            size='small'
                            endpoint={ENDPOINTS.GET_WORKER_ACCOUNT_EXECUTIVES}
                            value={formValue?.worker}
                        />
                        <CustomSelectMultiple
                            label={getTranslation('activity')}
                            name='activity'
                            id='activity'
                            mode={'multiple' as const}
                            size='small'
                            endpoint={ENDPOINTS.GET_ACTIVITIES_TYPE}
                            value={formValue?.activity}
                        />
                        <CustomRangePicker
                            label={getTranslation('fecha')}
                            name='rangesDates'
                            id='rangesDates'
                            size='small'
                            disabledDate={disabledDate}
                            disabled={tab === '2'}
                            type={'array' as const}
                        />

                        <div className='buttons'>
                            <div className='separador-vertical'></div>
                            <Button
                                style={{ margin: '0 8px 0 0' }}
                                size='small'
                                onClick={onClean}>
                                {getTranslation('clean_filters')}
                            </Button>
                            <Button
                                type='primary'
                                size='small'
                                htmlType='submit'>
                                {getTranslation('aplicar')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
