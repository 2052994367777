import { BrowserRouter as Router, Switch } from 'react-router-dom'
import Login from '../login/Login'
import { PrivateRoute } from './PrivateRoute'
import MyBreadcrumbs from './MyBreadcrumbs'
import { PublicRoute } from './PublicRoute'
import {
    DASHBOARD,
    NOT_FOUND,
    LOGIN,
    BRIEFS,
    BRIEFS_FORM,
    CAMPAIGNS,
    PROPOSAL,
    COPY,
    CREATIVES,
    ACCOUNTS,
    USERS,
    PURCHASE_ORDERS,
    IMPLEMENTATION,
    CHECKING,
    BRIEFS_FORM__WITH_PARAMS,
    LOGOUT,
    PAGE403,
    NEW_ORGANIZATION__WITH_PARAMS,
    NEW_ORGANIZATION,
    VENDORS,
    NEW_VENDOR__WITH_PARAMS,
    NEW_VENDOR,
    ACTIVITY,
    NEW_ACTIVITY__WITH_PARAMS,
    NEW_ACTIVITY,
    METRICS,
    PROPOSAL_DETAIL,
    PROPOSAL_DETAIL__WITH_PARAMS,
    ADVERTISERS,
    NEW_ADVERTISERS,
    ADD_ADVERTISERS,
    PREVIEW_ADVERTISERS,
    PREVIEW_ADVERTISERS__WITH_PARAMS,
    ADVERTISERS_DETAIL__WITH_PARAMS,
    ADVERTISERS_DETAIL,
    SEND_PROPOSAL,
    SEND_PROPOSAL__WITH_PARAMS,
} from '../../config/Path'
import Dashboard from '../dashboard/Dashboard'
import { MainMenu } from '../menu/MainMenu'
import { NotFound } from '../notFound/NotFound'
import { Briefs } from '../briefs/Briefs'
import NewBriefs from '../briefs/BriefForm'
import { Campaign } from '../campaign/Campaign'
import { Proposal } from '../proposal/Proposal'
import { ProposalDetail } from '../proposal/ProposalDetail'
import { Creatives } from '../creatives/Creatives'
import { Copy } from '../copy/Copy'
import { AccountsList } from '../accounts/AccountsList'
import { Users } from '../users/Users'
import { PurchaseOrders } from '../purchase-orders/PurchaseOrders'
import { Implementation } from '../implementation/Implementation'
import { Checking } from '../checking/Checking'
import useCountryContext from '../../hooks/useCountryContext'
import { useAxios } from '../../hooks/useAxios'
import { ENDPOINTS } from '../../config/EndPoints'
import { useCallback, useEffect, useState } from 'react'
import { ICountry } from '../../interface/ICountry'
import { ICountries } from '../../interface/ICountries'
import useAuthContext from '../../hooks/useAuthContext'
import { Logout } from '../logout/Logout'
import { Page403 } from '../403/Page403'
import { Organization } from '../accounts/Organization'
import useCurrencyContext from '../../hooks/useCurrencyContext'
import { ICurrency } from '../../interface/ICurrency'
import { Notifications } from '../common/notifications/Notifications'
import { VendorsList } from '../vendors/VendorsList'
import { VendorDetails } from '../vendors/VendorDetails'
import { ActivityList } from '../activity/ActivityList'
import { ActivityDetail } from '../activity/ActivityDetail'
import { Metrics } from '../metrics/Metrics'
import { Spin } from 'antd'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { AdvertisersList } from '../advertisers/AdvertisersList'

import { AddAdvertiser } from '../advertisers/AddAdvertiser'
import { AdvertiserPreview } from '../advertisers/AdvertiserPreview'
import { SearchCreateAdvertiser } from '../advertisers/SearchCreateAdvertiser'
import { AdvertiserDetail } from '../advertisers/AdvertiserDetail'
import { SendProposal } from '../send-proposal/SendProposal'
import useAssetsClassificationContext from '../../hooks/useAssetsClassificationContext'

export const AppRouter = () => {
    const { isAuthenticated } = useAuthContext()
    const { country, updateCountry } = useCountryContext()
    const { currency, updateCurrency } = useCurrencyContext()
    const {
        updateAssetTypesList,
        updateGeopathList,
        updateMaterialList,
        assetTypesList,
        geopathList,
        materialList,
    } = useAssetsClassificationContext()
    const { errorObjectWithData } = Notifications()
    const { get } = useAxios()
    const [localeSpin, setLocaleSpin] = useState(false)

    const success = useCallback((response: any) => {
        let dataProcessed = response.data.data.reduce(
            (acc: ICountries[], item: ICountry) => [
                ...acc,
                {
                    label: item?.attributes?.name,
                    currency: item.attributes?.currency,
                    geolocation: item.attributes?.geolocation,
                    iso_31661: item.attributes?.iso_31661,
                    locale: item.attributes?.locale,
                    value: item.id,
                    en: item.translations?.en?.name,
                    es: item.translations?.es?.name,
                    pt: item.translations?.pt?.name,
                },
            ],
            [],
        )
        updateCountry(dataProcessed)
    }, [])

    const successCurrency = useCallback((response: any) => {
        let dataProcessed = response.data.data.reduce(
            (acc: ICurrency[], item: ICurrency) => [
                ...acc,
                {
                    label: item?.iso_code,
                    value: item?.id,
                    name: item?.name,
                    symbol: item?.symbol,
                },
            ],
            [],
        )
        updateCurrency(dataProcessed)
    }, [])

    const successMaterials = (response: any) => {
        updateMaterialList(response.data.data)
    }

    const successAssetTypes = (response: any) => {
        updateAssetTypesList(response.data.data)
    }

    const successGeopath = (response: any) => {
        updateGeopathList(response.data.data)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {}

    useEffect(() => {
        !country &&
            isAuthenticated &&
            get(ENDPOINTS.GET_COUNTRIES, {}, success, error, always)

        !materialList &&
            isAuthenticated &&
            get(
                ENDPOINTS.GET_BRIEF_MATERIALS,
                {},
                successMaterials,
                error,
                always,
                false,
            )

        !assetTypesList &&
            isAuthenticated &&
            get(
                ENDPOINTS.GET_BRIEF_ASSET_TYPES,
                {},
                successAssetTypes,
                error,
                always,
                false,
            )

        !geopathList &&
            isAuthenticated &&
            get(
                ENDPOINTS.GET_BRIEF_GEOPATH,
                {},
                successGeopath,
                error,
                always,
                false,
            )

        !currency &&
            isAuthenticated &&
            get(ENDPOINTS.GET_CURRENCIES, {}, successCurrency, error, always)
        return () => {}
    }, [isAuthenticated])

    return (
        <>
            <Spin
                className='spin-general'
                indicator={antIcon}
                spinning={localeSpin}>
                <Router>
                    <div className='switch-container'>
                        {isAuthenticated && (
                            <>
                                <MainMenu setLocaleSpin={setLocaleSpin} />
                                <MyBreadcrumbs />
                            </>
                        )}
                        <div className='switch-context'>
                            <Switch>
                                <PrivateRoute
                                    exact
                                    path='/'
                                    component={Dashboard}
                                />
                                <PrivateRoute
                                    exact
                                    path={DASHBOARD}
                                    component={Dashboard}
                                />
                                <PrivateRoute
                                    exact
                                    path={ADVERTISERS}
                                    component={AdvertisersList}
                                />
                                <PrivateRoute
                                    exact
                                    path={NEW_ADVERTISERS}
                                    component={SearchCreateAdvertiser}
                                />
                                <PrivateRoute
                                    exact
                                    path={ADD_ADVERTISERS}
                                    component={AddAdvertiser}
                                />
                                <PrivateRoute
                                    exact
                                    path={PREVIEW_ADVERTISERS__WITH_PARAMS}
                                    component={AdvertiserPreview}
                                />
                                <PrivateRoute
                                    exact
                                    path={PREVIEW_ADVERTISERS}
                                    component={AdvertiserPreview}
                                />

                                <PrivateRoute
                                    exact
                                    path={ADVERTISERS_DETAIL__WITH_PARAMS}
                                    component={AdvertiserDetail}
                                />

                                <PrivateRoute
                                    exact
                                    path={ADVERTISERS_DETAIL}
                                    component={AdvertiserDetail}
                                />

                                <PrivateRoute
                                    exact
                                    path={CAMPAIGNS}
                                    component={Campaign}
                                />

                                <PrivateRoute
                                    exact
                                    path={METRICS}
                                    component={Metrics}
                                />

                                <PrivateRoute
                                    exact
                                    path={BRIEFS}
                                    component={Briefs}
                                />
                                <PrivateRoute
                                    exact
                                    path={BRIEFS_FORM__WITH_PARAMS}
                                    component={NewBriefs}
                                />
                                <PrivateRoute
                                    exact
                                    path={BRIEFS_FORM}
                                    component={NewBriefs}
                                />
                                <PrivateRoute
                                    exact
                                    path={PROPOSAL}
                                    component={Proposal}
                                />
                                <PrivateRoute
                                    exact
                                    path={PROPOSAL_DETAIL__WITH_PARAMS}
                                    component={ProposalDetail}
                                />
                                <PrivateRoute
                                    exact
                                    path={PROPOSAL_DETAIL}
                                    component={ProposalDetail}
                                />
                                <PrivateRoute
                                    exact
                                    path={SEND_PROPOSAL__WITH_PARAMS}
                                    component={SendProposal}
                                />
                                <PrivateRoute
                                    exact
                                    path={SEND_PROPOSAL}
                                    component={SendProposal}
                                />
                                <PrivateRoute
                                    exact
                                    path={CREATIVES}
                                    component={Creatives}
                                />
                                <PrivateRoute
                                    exact
                                    path={COPY}
                                    component={Copy}
                                />
                                <PrivateRoute
                                    exact
                                    path={ACCOUNTS}
                                    component={AccountsList}
                                />

                                <PrivateRoute
                                    exact
                                    path={VENDORS}
                                    component={VendorsList}
                                />

                                <PrivateRoute
                                    exact
                                    path={NEW_VENDOR__WITH_PARAMS}
                                    component={VendorDetails}
                                />
                                <PrivateRoute
                                    exact
                                    path={NEW_VENDOR}
                                    component={VendorDetails}
                                />
                                <PrivateRoute
                                    exact
                                    path={ACTIVITY}
                                    component={ActivityList}
                                />
                                <PrivateRoute
                                    exact
                                    path={NEW_ACTIVITY__WITH_PARAMS}
                                    component={ActivityDetail}
                                />
                                <PrivateRoute
                                    exact
                                    path={NEW_ACTIVITY}
                                    component={ActivityDetail}
                                />

                                <PrivateRoute
                                    exact
                                    path={NEW_ORGANIZATION__WITH_PARAMS}
                                    component={Organization}
                                />
                                <PrivateRoute
                                    exact
                                    path={NEW_ORGANIZATION}
                                    component={Organization}
                                />
                                <PrivateRoute
                                    exact
                                    path={USERS}
                                    component={Users}
                                />
                                <PrivateRoute
                                    exact
                                    path={PURCHASE_ORDERS}
                                    component={PurchaseOrders}
                                />
                                <PrivateRoute
                                    exact
                                    path={IMPLEMENTATION}
                                    component={Implementation}
                                />
                                <PrivateRoute
                                    exact
                                    path={CHECKING}
                                    component={Checking}
                                />
                                <PrivateRoute
                                    exact
                                    path={PAGE403}
                                    component={Page403}
                                />
                                <PrivateRoute
                                    exact
                                    path={LOGOUT}
                                    component={Logout}
                                />
                                <PublicRoute
                                    exact
                                    path={LOGIN}
                                    component={Login}
                                />
                                <PrivateRoute
                                    path={NOT_FOUND}
                                    component={NotFound}
                                />
                            </Switch>
                        </div>
                    </div>
                </Router>
            </Spin>
        </>
    )
}
