import { Button, Menu, Dropdown, Tabs } from 'antd'
import { Language } from '../../../config/Language'
import { useCallback, useState } from 'react'
import { IProposal } from '../../../interface/IProposal'
import { MoreOutlined } from '@ant-design/icons'
import { useAxios } from '../../../hooks/useAxios'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Notifications } from '../../common/notifications/Notifications'
import { useHistory } from 'react-router'
import { PROPOSAL_DETAIL, SEND_PROPOSAL } from '../../../config/Path'
import { RenderStatusProposal } from '../../common/render-status/RenderStatusProposal'

const { TabPane } = Tabs

interface IProps {
    data: IProposal | null
    tab: string
    sendProposalSpin: boolean
    handleSendProposal: () => void
    setTab: (tab: string) => void
}

export const SendProposalTabs = ({ ...props }: IProps) => {
    const { data, tab, sendProposalSpin, handleSendProposal, setTab } = props
    const { put } = useAxios()
    const history = useHistory()
    const { successObject, errorObjectWithData } = Notifications()
    const { getTranslation } = Language()

    const handleTabs = (key: any) => {
        setTab(key)
    }

    return (
        <div className='proposal-tabs'>
            <Tabs
                className='tabs'
                defaultActiveKey='1'
                activeKey={tab}
                onChange={handleTabs}>
                <TabPane tab={getTranslation('abstract')} key='1' />
                <TabPane tab={getTranslation('soportes')} key='2' />
                <TabPane tab={getTranslation('map')} key='3' />
                <TabPane tab={getTranslation('estrategia')} key='4' />
            </Tabs>

            <div className='right-side'>
                <div className='item-right-side'>
                    <div className='title'>{getTranslation('estado')}</div>
                    {data && RenderStatusProposal(data)}
                </div>
                <div className='item-right-side'>
                    <div className='title visible-not'>
                        {getTranslation('estado')}
                    </div>
                    <div className='primary-dropdown'>
                        <Button
                            type='primary'
                            size='small'
                            loading={sendProposalSpin}
                            onClick={handleSendProposal}>
                            {getTranslation('send-proposal')}
                        </Button>
                        <Button
                            style={{ margin: '0 8px' }}
                            ghost
                            type='primary'
                            onClick={() =>
                                history.push(`${PROPOSAL_DETAIL}/${data?.id}`)
                            }
                            size='small'>
                            {getTranslation('cancelar')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
