import { Button, Table, Spin, Tabs, Tooltip } from 'antd'
import { AxiosInstance, CancelTokenSource } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { Language } from '../../config/Language'
import { BRIEFS_FORM, PROPOSAL_DETAIL } from '../../config/Path'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'

const { TabPane } = Tabs

export const Proposal = () => {
    const { get } = useAxios()
    const { getTranslation, getTranslationObject } = Language()
    const { errorObjectWithData } = Notifications()
    const [generalSpin, setGeneralSpin] = useState(false)
    const [secondSpin, setSecondSpin] = useState(false)
    const [data, setData] = useState([])
    const [tab, setTab] = useState('1')
    const [axiosRequest, setAxiosRequest] = useState<CancelTokenSource | null>(
        null,
    )

    const [params, setParams] = useState({
        column: 'organization',
        order: 'desc',
    })

    let height = 0
    const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
    )

    height = vh - 396

    useEffect(() => {
        loadProposals()
        return () => {}
    }, [params, tab])

    const loadProposals = () => {
        let endpoint = ''
        if (tab === '1') {
            endpoint = ENDPOINTS.GET_PROPOSALS_RECENT
        } else if (tab === '2') {
            endpoint = ENDPOINTS.GET_PROPOSALS_APPROVED
        } else if (tab === '3') {
            endpoint = ENDPOINTS.GET_PROPOSALS_REJECTD
        } else if (tab === '4') {
            endpoint = ENDPOINTS.GET_PROPOSALS_EXPIRED
        } else if (tab === '5') {
            endpoint = ENDPOINTS.GET_PROPOSALS_ARCHIVED
        }
        setGeneralSpin(true)
        setAxiosRequest(
            get(endpoint, params, successProposals, error, alwaysProposals),
        )
        // axioss.cancel()
    }

    const successProposals = useCallback((response: any) => {
        setGeneralSpin(false)
        setData(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const alwaysProposals = () => {
        setGeneralSpin(false)
    }
    const handleTabs = (key: any) => {
        setData([])
        if (axiosRequest) {
            axiosRequest.cancel()
            setAxiosRequest(null)
        }
        setTab(key)
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setParams({
            column: sorter.field,
            order: sorter.order,
        })
    }

    const columns: any[] = [
        {
            title: getTranslation('campaign'),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (name: any, item: any) => (
                <Tooltip placement='topLeft' title={name}>
                    <Link to={`${PROPOSAL_DETAIL}/${item.id}`}>{name}</Link>
                </Tooltip>
            ),
        },
        {
            title: getTranslation('organization'),
            dataIndex: 'organization',
            key: 'organization',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (organization: any) => (
                <Tooltip placement='topLeft' title={organization}>
                    {organization}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('brand'),
            dataIndex: 'brand',
            key: 'brand',
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (brand: any) => (
                <Tooltip placement='topLeft' title={brand}>
                    {brand}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('envio'),
            dataIndex: 'sent_date',
            key: 'sent_date',
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (sent_date: any) => (
                <Tooltip placement='topLeft' title={sent_date}>
                    {sent_date}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('vencimiento'),
            dataIndex: 'expiration_date',
            key: 'expiration',
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (expiration: any) => (
                <Tooltip placement='topLeft' title={expiration}>
                    {expiration}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('contacto'),
            dataIndex: 'contact',
            key: 'contact',
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (contact: any, item: any) => (
                <Tooltip placement='topLeft' title={contact}>
                    {contact}
                </Tooltip>
            ),
        },
    ]

    let ejecutivoColumn = {
        title: getTranslation('ejecutivo'),
        dataIndex: 'worker',
        key: 'worker',
        ellipsis: {
            showTitle: false,
        },
        sorter: true,
        render: (worker: any, item: any) => (
            <Tooltip placement='topLeft' title={item.user.name}>
                {item.user.name}
            </Tooltip>
        ),
    }
    let statusColumn = {
        title: getTranslation('estado'),
        dataIndex: 'status',
        key: 'status',
        ellipsis: {
            showTitle: false,
        },
        sorter: true,
        render: (status: any) => {
            let bgColor = ''
            let bordeColor = ''
            let fontColor = ''
            if (status.id === 6) {
                bgColor = '#E6F7FF'
                bordeColor = '#91D5FF'
                fontColor = '#1890FF'
            } else if (status.id === 1 || status.id === 5 || status.id === 9) {
                bgColor = '#FFF7E6'
                bordeColor = '#FFD591'
                fontColor = '#FA8C16'
            } else if (status.id === 11) {
                bgColor = '#FFF1F0'
                bordeColor = '#FFA39E'
                fontColor = '#F5222D'
            }
            return (
                <Tooltip placement='topLeft' title={status.name}>
                    <div
                        className='estado'
                        style={{
                            backgroundColor: bgColor,
                            borderColor: bordeColor,
                            color: fontColor,
                        }}>
                        <span>{status.name}</span>
                    </div>
                </Tooltip>
            )
        },
    }

    if (tab === '1') {
        columns.push(statusColumn, ejecutivoColumn)
    } else {
        columns.push(ejecutivoColumn)
    }

    return (
        <div id='proposal-list'>
            <div className='header'>
                <div className='title-box'>
                    <div className='header-title'>
                        {getTranslation('proposals')}
                    </div>
                </div>
                <Tabs
                    className='tabs-proposal'
                    defaultActiveKey='2'
                    activeKey={tab}
                    onChange={handleTabs}>
                    <TabPane tab={getTranslation('recientes')} key='1' />
                    <TabPane tab={getTranslation('aceptadas')} key='2' />
                    <TabPane tab={getTranslation('rechazadas')} key='3' />
                    <TabPane tab={getTranslation('vencidas')} key='4' />
                    <TabPane tab={getTranslation('archivadas')} key='5' />
                </Tabs>
            </div>
            <div className='body-proposal'>
                <div className='body-title'>
                    {getTranslation('proposal_list')}
                </div>
                <div className='body-table'>
                    <Table
                        className='proposal-table'
                        sticky
                        bordered
                        locale={getTranslationObject('table_trad')}
                        pagination={false}
                        scroll={{ x: 800, y: height }}
                        columns={columns}
                        loading={{ spinning: generalSpin, indicator: antIcon }}
                        dataSource={data}
                        onChange={handleTableChange}
                    />
                </div>
            </div>
        </div>
    )
}
