import { Modal } from 'antd'
import { useState } from 'react'
import { Language } from '../../../config/Language'

import digital_establecimiento from '../../../asset/img/icons-map/reference-digital-establecimiento.svg'
import digital_via_publica from '../../../asset/img/icons-map/reference-digital-via-publica.svg'
import impreso_establecimiento from '../../../asset/img/icons-map/reference-impreso-establecimiento.svg'
import impreso_via_publica from '../../../asset/img/icons-map/reference-impreso-via-publica.svg'
import { ReactComponent as OtsSvg } from '../../../asset/img/icons-map/SVG-ModalReferecnias-OTS.svg'
import { ReactComponent as Example } from '../../../asset/img/icons-map/example-icon.svg'

export const MapReference = () => {
    const { getTranslation } = Language()
    const [visible, setVisible] = useState(false)
    let title = getTranslation('referencias')

    let poi = (color: string) => (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='12'
            height='12'
            viewBox='0 0 16 16'>
            <path
                className='a'
                style={{ fill: color }}
                d='M6.211,3.578a2,2,0,0,1,3.578,0l4.764,9.528A2,2,0,0,1,12.764,16H3.236a2,2,0,0,1-1.789-2.894Z'
                transform='translate(16 16) rotate(180)'
            />
        </svg>
    )

    return (
        <>
            <div className='references' onClick={() => setVisible(true)}>
                {title}
            </div>
            <Modal
                title={title}
                centered
                visible={visible}
                footer={null}
                onCancel={() => setVisible(false)}>
                <div id='reference-map-modal'>
                    <div className='block'>
                        <div className='header'>
                            {getTranslation('soportes')}
                        </div>
                        <div className='body assets'>
                            <div className='material'>
                                <div className='digital'>
                                    <div className='title'>
                                        {getTranslation('digital')}
                                    </div>
                                    <div className='material-body'>
                                        <div>
                                            <img
                                                alt='digital establecimiento'
                                                src={digital_establecimiento}
                                            />
                                            {getTranslation('place_based')}
                                        </div>
                                        <div>
                                            <img
                                                alt='digital via pública'
                                                src={digital_via_publica}
                                            />
                                            {getTranslation('roadside')}
                                        </div>
                                    </div>
                                </div>
                                <div className='separador'></div>
                                <div className='impreso'>
                                    <div className='title'>
                                        {getTranslation('printed_mesh')}
                                    </div>
                                    <div className='material-body'>
                                        <div>
                                            <img
                                                alt='impreso establecimiento'
                                                src={impreso_establecimiento}
                                            />
                                            {getTranslation('place_based')}
                                        </div>
                                        <div>
                                            <img
                                                alt='impreso via pública'
                                                src={impreso_via_publica}
                                            />
                                            {getTranslation('roadside')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='type'>
                                <div className='type-title'>
                                    {getTranslation('tipo')}
                                </div>
                                <div className='type-body'>
                                    <div className='type-box'>
                                        <div className='title'>
                                            {getTranslation('billboard')}
                                        </div>
                                        <div className='value'>
                                            {getTranslation('CA')}
                                        </div>
                                    </div>
                                    <div className='separador'></div>
                                    <div className='type-box'>
                                        <div className='title'>
                                            {getTranslation('display')}
                                        </div>
                                        <div className='value'>
                                            {getTranslation('DI')}
                                        </div>
                                    </div>
                                    <div className='separador'></div>
                                    <div className='type-box'>
                                        <div className='title'>
                                            {getTranslation('mural')}
                                        </div>
                                        <div className='value'>
                                            {getTranslation('MU')}
                                        </div>
                                    </div>
                                    <div className='separador'></div>
                                    <div className='type-box'>
                                        <div className='title'>
                                            {getTranslation('panel')}
                                        </div>
                                        <div className='value'>
                                            {getTranslation('PA')}
                                        </div>
                                    </div>
                                    <div className='separador'></div>
                                    <div className='type-box'>
                                        <div className='title'>
                                            {getTranslation('poster')}
                                        </div>
                                        <div className='value'>
                                            {getTranslation('PO')}
                                        </div>
                                    </div>
                                    <div className='separador'></div>
                                    <div className='type-box'>
                                        <div className='title'>
                                            {getTranslation('poster_junior')}
                                        </div>
                                        <div className='value'>
                                            {getTranslation('PP')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='block'>
                        <div className='header'>POI</div>
                        <div className='body poi'>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#FFC069')}</div>
                                <div className='title'>
                                    {getTranslation('food_beverages')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#B37FEB')}</div>
                                <div className='title'>
                                    {getTranslation('entertainment')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#FF85C0')}</div>
                                <div className='title'>
                                    {getTranslation('shops')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#FF9C6E')}</div>
                                <div className='title'>
                                    {getTranslation('vehicles')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#D3F261')}</div>
                                <div className='title'>
                                    {getTranslation('finance')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#FFF566')}</div>
                                <div className='title'>
                                    {getTranslation('transportation')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#85A5F9')}</div>
                                <div className='title'>
                                    {getTranslation('goverment')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#95DE64')}</div>
                                <div className='title'>
                                    {getTranslation('parks')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#5CDBD3')}</div>
                                <div className='title'>
                                    {getTranslation('turism')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#FFD666')}</div>
                                <div className='title'>
                                    {getTranslation('education')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#FE7875')}</div>
                                <div className='title'>
                                    {getTranslation('health')}
                                </div>
                            </div>
                            <div className='poi-item'>
                                <div className='icon'>{poi('#BFBFBF')}</div>
                                <div className='title'>
                                    {getTranslation('otros')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='block'>
                        <div className='header'>OTS</div>
                        <div className='body ots'>
                            <div className='scale'>
                                <OtsSvg />
                            </div>
                            <div className='text'>
                                {getTranslation('ots_description')}
                            </div>
                        </div>
                    </div>
                    <div className='block'>
                        <div className='body example'>
                            <div className='title'>
                                {getTranslation('example')}
                            </div>
                            <div className='container'>
                                <div className='svg-example'>
                                    <Example />
                                </div>
                                <div className='text'>
                                    {`${getTranslation(
                                        'digital',
                                    )} / ${getTranslation(
                                        'place_based',
                                    )} / ${getTranslation('mural')}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
