import { Column } from '@ant-design/charts'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Tooltip, Divider } from 'antd'
import { Language } from '../../config/Language'

interface IProps {
    colors: string[]
    data?: any[]
}

const Metrics = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const { colors, data } = props
    const textTooltipInfo = 'Info'
    var config: any = {
        data: data,
        height: 70,
        xField: 'type',
        yField: 'sales',
        seriesField: 'sales',
        colorField: 'sales',
        color: colors,
        legend: false,
        xAxis: {
            visible: false,
            line: {
                visible: false,
            },
            grid: {
                visible: false,
            },
        },
        yAxis: {
            visible: false,
            line: {
                visible: false,
            },
            grid: {
                visible: false,
            },
        },
        columnWidthRatio: 0.9,
        padding: [5, 0, 5, 0],
        tooltip: {
            showTitle: false,
            formatter: (datum: any) => {
                return { name: datum.type, value: '$' + datum.sales }
            },
        },
    }

    return (
        <div className='metrics-item'>
            <div className='header'>
                <div className='header-title'>
                    {getTranslation('inversion_a_futuro')}
                </div>
                <Tooltip placement='top' title={textTooltipInfo}>
                    <div className='info'>
                        <ExclamationCircleOutlined />
                    </div>
                </Tooltip>
            </div>
            <div className='body'>
                <div className='body-title'>$135,000</div>
                <div className='body-chart'>
                    <Column {...config} />
                </div>
            </div>
            <div className='footer'>
                <Divider className='separador' />
                <div className='footer-text'>
                    {getTranslation('inversion_futuro_cliente')}
                </div>
            </div>
        </div>
    )
}

export default Metrics
