export const CONFIG = {
    END_POINT: 'https://api.tolber.io/dev/',
    // END_POINT: 'https://api.tolber.io/demo/',
    // END_POINT: 'https://api.tolber.io/',
    BASE_URL: 'https://localhost:3000',
    DEVELOP_PORTAL: 'https://api.portal-socios.dev.tolber.io/api/',
    PHOTO_DEFAULT_USER: '/assets/img/users/default.jpg',
    MAPBOX_API_KEY:
        'pk.eyJ1IjoibWZlcm5hbmRlemJlcnRvbGEiLCJhIjoiY2tvajY5eDRnMHViZDJ2azJkNmo2N3VqNCJ9.JNvVN5GZjZecYoj09NPK6A',
}
