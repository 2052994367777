import { Form, DatePicker, Button } from 'antd'
import { useState } from 'react'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Moment } from 'moment'
import {
    SwapRightOutlined,
    SaveOutlined,
    CloseOutlined,
    EditOutlined,
} from '@ant-design/icons'
import { Language } from '../../../config/Language'
const { RangePicker } = DatePicker
interface IProps {
    id?: string
    label?: string
    name: string
    type?: any
    className?: string
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
    size?: SizeType
    date?: string
    saveSpin: boolean
    editShow: boolean
    setEditShow: (bool: boolean) => void
    handleCloseEditDate: () => void
    disabledDate?: (current: Moment) => boolean
}

export const CustomDatePickerProposal = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        size = 'large',
        date,
        saveSpin,
        editShow,
        setEditShow,
        disabledDate,
        handleCloseEditDate,
    } = props

    const handleCancel = () => {
        setEditShow(false)
        handleCloseEditDate()
    }

    const dateFormatList = 'DD-MM-YYYY'

    return (
        <div id='custom-component-form-proposal'>
            <div className={`form-field ${editShow ? 'show' : ''}`}>
                <Form.Item
                    id={id}
                    name={name}
                    className={className}
                    label={label}
                    rules={[
                        {
                            required: required,
                            message: requiredMessage,
                        },
                    ]}>
                    <DatePicker
                        format={dateFormatList}
                        disabled={disabled}
                        disabledDate={disabledDate}
                        size={size}
                    />
                </Form.Item>
            </div>

            <div
                className={`field ${!editShow ? 'show' : ''}`}
                onClick={() => setEditShow(!editShow)}>
                <div>{date}</div>
            </div>

            <div className='actions-buttons show'>
                {editShow ? (
                    <>
                        <Button
                            shape='circle'
                            onClick={handleCancel}
                            style={{ marginRight: '5px' }}
                            icon={<CloseOutlined />}
                        />
                        <Button
                            shape='circle'
                            htmlType='submit'
                            loading={saveSpin}
                            icon={<SaveOutlined />}
                        />
                    </>
                ) : (
                    <Button
                        shape='circle'
                        onClick={() => setEditShow(!editShow)}
                        icon={<EditOutlined />}
                    />
                )}
            </div>
        </div>
    )
}
