import { Divider } from 'antd'
import { Language } from '../../config/Language'
// import { Language } from '../../config/Language'
import Task from './tasks/Task'

interface IProps {}

const Tasks = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    let tasks = [
        {
            id: '1',
            taskColor: '#E6F7FF',
            indicatorColor: '#1890FF',
            title: 'Nike',
            subtitle: 'Lanzamiento Dri Fit',
            estado: 'Completa el Brief.',
        },
        {
            id: '2',
            taskColor: '#FFF2E8',
            indicatorColor: '#FA541C',
            title: 'Adidas',
            subtitle: 'Lanzamiento Adidas Pro',
            estado: 'Completa el Brief.',
        },
        {
            id: '3',
            taskColor: '#FFFFFF',
            indicatorColor: '#FFFFFF',
            title: 'Coca cola',
            subtitle: 'Lanzamiento Coca Cola Fit',
            estado: 'Completa el Brief.',
        },
    ]

    return (
        <div className='tasks card'>
            <div className='header'>
                <div className='header-title'>
                    {getTranslation('gestor_tareas')}
                </div>
                <div className='header-separador'></div>
            </div>
            <Divider className='separador' />
            <div className='body'>
                <div className='task-list'>
                    {tasks.map((item: any, index: any) => (
                        <Task key={index} task={item} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Tasks
