import { Form, Slider } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Language } from '../../../config/Language'
import { Link } from 'react-router-dom'

interface IProps {
    id?: string | any
    label: string
    name: string
    className?: string | any
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    value?: any
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
    min?: number | any
    help?: string
    max?: number | any
    step?: number | any
    defaultValue?: number
    tooltipVisible?: boolean
}

export const CustomSlider = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        value,
        tooltipTitle,
        showTooltips,
        help,
        min = 0,
        max = 10,
        step = 1,
        defaultValue = 0,
        tooltipVisible = true,
    } = props

    const [selectedValue, setSelectedValue] = useState<any>(null)

    useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
        return () => {}
    }, [value])

    const configTooltips = showTooltips
        ? {
              title: tooltipTitle,
              icon: <InfoCircleOutlined />,
          }
        : false

    let labelContainer = (
        <div className='label-container-help'>
            {label} {help && <Link to={help}>{getTranslation('help')}</Link>}
        </div>
    )

    return (
        <Form.Item
            id={id}
            name={name}
            className={className}
            label={labelContainer}
            initialValue={defaultValue}
            rules={[
                {
                    required: required,
                    message: requiredMessage,
                },
            ]}
            tooltip={configTooltips}>
            <Slider
                min={min}
                max={max}
                step={step}
                disabled={disabled}
                tooltipVisible={tooltipVisible}
            />
        </Form.Item>
    )
}
