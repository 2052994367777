import { useState } from 'react'
import { Form, Button } from 'antd'
import { Language } from '../../config/Language'
import { ENDPOINTS } from '../../config/EndPoints'
import { CustomSelectMultiple } from '../common/custom-select-multiple/CustomSelectMultiple'

interface FormValue {
    account: any
    worker: any
}

interface IProps {
    setParams: ({}) => void
}

export const FilterMetrics = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const [form] = Form.useForm()
    const { setParams } = props
    const [formValue, setFormValue] = useState<FormValue>({
        account: null,
        worker: null,
    })

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {}
        if (formValue.account) {
            result.accounts_id = formValue.account
        }

        if (formValue.worker) {
            result.workers_id = formValue.worker
        }

        return result
    }

    const onSave = (values: any) => {
        let result = getValues()
        setParams(result)
    }

    const onClean = () => {
        let newFormValue: FormValue | any = {
            account: undefined,
            worker: undefined,
        }
        setParams({})
        setFormValue(newFormValue)
    }

    return (
        <div className='filter-metrics'>
            <Form
                id='form-metrics'
                form={form}
                layout='vertical'
                scrollToFirstError
                onValuesChange={onChange}
                onFinish={onSave}
                size='large'>
                <div className='form-container'>
                    <div className='form-body'>
                        <CustomSelectMultiple
                            label={getTranslation('accounts')}
                            name='account'
                            id='account'
                            mode={'multiple' as const}
                            size='small'
                            endpoint={ENDPOINTS.GET_ACCOUNTS}
                            value={formValue?.account}
                        />

                        <CustomSelectMultiple
                            label={getTranslation('ejecutivo')}
                            name='worker'
                            id='worker'
                            mode={'multiple' as const}
                            size='small'
                            endpoint={ENDPOINTS.GET_WORKER_ACCOUNT_EXECUTIVES}
                            value={formValue?.worker}
                        />

                        <div className='buttons'>
                            <div className='separador-vertical'></div>
                            <Button
                                style={{ margin: '0 8px 0 0' }}
                                size='small'
                                onClick={onClean}>
                                {getTranslation('clean_filters')}
                            </Button>
                            <Button
                                type='primary'
                                size='small'
                                htmlType='submit'>
                                {getTranslation('aplicar')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
