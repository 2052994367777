export const ENDPOINTS = {
    // ----------------GET----------------
    // PORTAL
    // ASSETS DETAIL
    GET_ELEMENT: 'assets/{id}',

    // ADVERTISER
    GET_ADVERTISER_SEARCH: 'organizations/search',
    GET_ADVERTISER_ACCOUNT_BY_ORGANIZATION:
        'organizations/{organization}/accounts',
    GET_COMPANY_NAMES: 'organizations/{organization}/company-names',

    // GEOLOCATIONS
    GET_PORTAL_GEOLOCATIONS: 'assets/geolocations',

    // ASSETS LIST
    GET_ELEMENTS_LIST: 'assets',

    // Menu
    GET_MENU: 'menus/switch',

    // Brief
    GET_BRIEF_SPECIDICATIONS: 'briefs-specifications',
    GET_BRIEF_OBJECTIVES: 'briefs-objectives',
    GET_BRIEF: 'briefs/{brief}',
    GET_BRIEF_LIST: 'briefs',
    GET_BRIEF_MATERIALS: 'assets/formats',
    GET_BRIEF_ASSET_TYPES: 'assets/types',
    GET_BRIEF_POI_CLASIFICATIONS: 'category-entity-types',
    GET_BRIEF_GEOPATH: 'assets/geopath-clasifications',

    // Proposal
    GET_PROPOSAL: 'proposals/switch/{proposal}',
    GET_PROPOSALS_RECENT: 'proposals/switch?status[]=1&status[]=12',
    GET_PROPOSALS_APPROVED: 'proposals/switch?status[]=4',
    GET_PROPOSALS_EXPIRED: 'proposals/switch?status[]=14',
    GET_PROPOSALS_REJECTD: 'proposals/switch?status[]=6',
    GET_PROPOSALS_ARCHIVED: 'proposals/switch?status[]=13',
    GET_ELEMENT_PROPOSALS: 'proposals/{proposal}/element-proposals',
    GET_PROPOSAL_COSTS: 'proposals/{proposal}/costs',

    // Clientes
    GET_CLIENTES: 'organizations/advertisers',

    // Marca
    GET_BRAND_BY_CLIENT: 'organizations/{organization}/brands',

    // Budget
    GET_BUDGET_TYPE: 'budget-types',

    // Countries
    GET_COUNTRIES: 'countries',
    GET_COUNTRY_STATES: 'countries/{country}/states',

    // Currency
    GET_CURRENCIES: 'currencies',

    // Support
    POST_SUPPORT: 'support/ticket',

    // Refresh
    POST_REFRESH: 'login/refresh',

    // Organizations
    GET_ORGANIZATIONS: 'organizations/advertisers',
    GET_ORGANIZATIONS_SEARCH: 'organizations/search',
    GET_ORGANIZATION: 'organizations/{organization}',
    GET_ORGANIZATION_ACCOUNTS: 'organizations/{organization}/accounts',
    GET_ORGANIZATION_VENDORS: 'organizations/vendors',
    GET_ORGANIZATION_WITH_ACCOUNTS: 'accounts/advertisers',

    // Organizations-Types
    GET_ORGANIZATIONS_TYPES: 'organization-types',

    // Brands
    GET_ORGANIZATIONS_BRANDS: 'organizations/{organization}/brands',
    GET_BRANDS_SEARCH: 'organizations/{organization}/brands/search',
    GET_BRAND: 'brands/{brand}',

    // Accounts
    GET_ADVERTISERS: 'accounts/advertisers',
    GET_ACCOUNTS: 'accounts',
    GET_ACCOUNT: 'accounts/{account}',
    GET_ACCOUNT_CONTACTS: 'accounts/{account}/contacts',

    // Category
    GET_CATEGORIES: 'organizations/categories',
    GET_SUBCATEGORIES: 'organizations/categories/{category}/subcategories',

    // Contacts
    GET_CONTACTS: 'contacts-list',
    GET_CONTACT_TYPES: 'contact-types',
    GET_CONTACT_POSITIONS: 'contacts/positions',
    GET_CONTACT_GENDERS: 'contacts/genders',

    // Activities
    GET_ACTIVITIES_TYPE: 'activity-types',
    GET_ACTIVITY: 'activities/{activity}',
    GET_ACTIVITIES_TODAY: 'activities/today',
    GET_ACTIVITIES_FUTURE: 'activities/future',
    GET_ACTIVITIES_PAST: 'activities/past',
    GET_ACTIVITY_CHART_BY_TYPES: 'metrics/types',
    GET_ACTIVITY_CHART_BY_MONTHS: 'metrics/months',
    GET_ACTIVITY_CHART_BY_WORKERS: 'metrics/users',
    GET_ACTIVITY_CHART_BY_ACCOUNTS: 'metrics/accounts',
    GET_ACTIVITY_KPIS: 'metrics/kpis',

    //BUSINESS POTENTIALITIES
    GET_BUSINESS_POTENTIALITIES: 'business-potentialities',

    // WORKERS
    GET_WORKER_ACCOUNT_EXECUTIVES: 'account-executives',

    // ----------------POST----------------

    // ADVERTISER
    POST_ADVERTISER: 'accounts-with-advertiser',
    POST_BRANDS: 'brands',
    POST_COMPANY_NAMES: 'company-names',

    // Activities
    POST_ACTIVITY: 'activities',

    // Brief
    POST_BRIEF: 'briefs',
    POST_BRIEF_CONVERT_PROPOSAL: 'briefs/{brief}/convert-to-proposal',

    // Proposal
    POST_ELEMENT_PROPOSALS: 'element-proposals',

    // Organizations
    POST_ORGANIZATION: 'organizations',
    POST_ORGANIZATION_VENDOR: 'organizations/vendors',
    POST_ORGANIZATION_CONTACT: 'organizations/{organization}/contact',
    POST_ORGANIZATION_RELATION_VENDOR: 'organizations/organization-vendors',

    // Accounts
    POST_ACCOUNT: 'accounts',
    POST_ACCOUNT_CONTACT: 'accounts/{account}/contact',

    // Login
    POST_LOGIN: 'login/switch',

    // ----------------PUT-----------------

    // Proposal
    PUT_PROPOSAL_ARCHIVE: 'proposals/{proposal}/archive',
    PUT_PROPOSAL_STRATEGY: 'proposals/{proposal}/strategy',
    PUT_PROPOSAL_DATES: 'proposals/{proposal}/dates',
    PUT_PROPOSAL_EXPIRATION: 'proposals/{proposal}/expiration',
    PUT_PROPOSAL_EMAIL: 'proposals/{proposal}/email',
    PUT_PROPOSAL_COSTS: 'proposals/{proposal}/costs',

    // Briefs
    PUT_BRIEF: 'briefs/{brief}',
    PUT_BRIEF_SEND: 'briefs/{brief}/send',

    // Accounts
    PUT_ACCOUNT: 'accounts/{account}',

    // Contact
    PUT_CONTACT: 'contacts/{contact}',

    // Activities
    PUT_ACTIVITY: 'activities/{activity}',

    // Locale
    PUT_LOCALE: 'users/locale',

    // ----------------DEL-----------------
    // Contact
    DEL_CONTACT: 'contacts/{contact}',

    // Activities
    DEL_ACTIVITY: 'activities/{activity}',

    // Proposal
    DEL_ELEMENT_PROPOSALS: 'element-proposals',
}

export const ENDPOINTS_WITH_OUT_TOKEN = {
    // Support
    POST_SUPPORT: 'support/ticket',
}
