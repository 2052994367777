import { useHistory } from 'react-router-dom'
import { NEW_VENDOR } from '../../config/Path'
import { ShowImage } from '../common/show-img/ShowImage'
import { Carousel, Empty } from 'antd'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'
import { Language } from '../../config/Language'

interface IProps {
    data: any
}

export const Vendor = ({ ...props }: IProps) => {
    const { data } = props
    const history = useHistory()
    const { getTranslation } = Language()

    const handleBrandClick = () => {
        history.push(`${NEW_VENDOR}/${data.id}`)
    }

    return (
        <div className='vendor-item'>
            <div className='vendor-header' onClick={handleBrandClick}>
                <div className='logo'>
                    <ShowImage url={data.file} alt={getTranslation('logo')} />
                </div>
                <div className='title'>
                    <span>{data.name}</span>
                </div>
            </div>
            <div className='vendor-body'>
                <div className='contacts-list'>
                    <div className='title'>{getTranslation('contactos')}</div>
                    {data.contacts.length > 0 ? (
                        <>
                            <Carousel>
                                {data.contacts?.map((item: any, index: any) => {
                                    return (
                                        <div key={index}>
                                            <div className='contact'>
                                                <div className='name space'>
                                                    {item.name}
                                                </div>
                                                <div className='phone space'>
                                                    <PhoneOutlined />
                                                    {item.phone}
                                                </div>
                                                <div className='email space'>
                                                    <MailOutlined />
                                                    {item.email}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </>
                    ) : (
                        <Empty
                            imageStyle={{ height: '70px' }}
                            description={
                                <span>{getTranslation('sin_datos')}</span>
                            }></Empty>
                    )}
                </div>
            </div>
        </div>
    )
}
