import { Link } from 'react-router-dom'
import { Language } from '../../../config/Language'

interface IProps {
    title: string
    help: any
    id: number
    currentStep: number
    children: any
}

export const BoxComponents = ({ ...props }: IProps) => {
    const { title, help, children, id, currentStep } = props
    const { getTranslation } = Language()

    return id === currentStep ? (
        <>
            <div className='header'>
                <div className='title'>{title}</div>
                {help && (
                    <div className='help-text'>
                        <Link to={help}>{getTranslation('help')}</Link>
                    </div>
                )}
            </div>
            <div className='body'>{children}</div>
        </>
    ) : (
        <></>
    )
}
