import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Row, Col } from 'antd'
import Metrics from './Metrics'
import Tasks from './Tasks'
import CampaignsTable from './CampaignsTable'

export const Dashboard = ({ ...props }: RouteComponentProps<any>) => {
    var data: any = [
        {
            type: 'asd1',
            sales: 38,
        },
        {
            type: 'asd2',
            sales: 52,
        },
        {
            type: 'asd3',
            sales: 61,
        },
        {
            type: 'asd4',
            sales: 145,
        },
    ]

    var data1: any = [
        {
            type: 'asd1',
            sales: 38,
        },
        {
            type: 'asd2',
            sales: 52,
        },
        {
            type: 'asd3',
            sales: 61,
        },
        {
            type: 'asd4',
            sales: 145,
        },
        {
            type: 'asd5',
            sales: 48,
        },
        {
            type: 'asd6',
            sales: 38,
        },
        {
            type: 'asd7',
            sales: 38,
        },
        {
            type: 'asd8',
            sales: 38,
        },
    ]

    let color1 = ['#66CCCC']
    let color2 = [
        '#66CCCC',
        '#7EFFFF',
        '#13023E',
        '#3B02BF',
        '#83D5D5',
        '#423464',
        '#6234CB',
    ]
    let color3 = ['#3B02BF', '#6234CB', '#8967D8', '#B099E5']

    return (
        <div id='dashboard'>
            <div className='metrics'>
                <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={8}>
                        <Metrics data={data} colors={color1} />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Metrics data={data1} colors={color2} />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Metrics data={data} colors={color3} />
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={10} lg={8}>
                        <Tasks />
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={16}>
                        <CampaignsTable />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default withRouter(Dashboard)
