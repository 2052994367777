import { Form, Button, Divider, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Language } from '../../../config/Language'
import { ADVERTISERS, NEW_ADVERTISERS } from '../../../config/Path'
import { useAxios } from '../../../hooks/useAxios'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomSelect } from '../../common/custom-select/CustomSelect'
import { CustomUploader } from '../../common/custom-uploader/CustomUploader'
import { Notifications } from '../../common/notifications/Notifications'
import { ShowImage } from '../../common/show-img/ShowImage'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    next: () => void
    setGlobalData: (formValue: {}) => void
    globalData: {}
}
interface FormValue {
    name: string
    file: any
    categoryId: number | null
    subCategoryId: number | null
    discount: number
}

export const AdvertiserForm = ({ ...props }: IProps) => {
    const { next, setGlobalData, globalData } = props
    const [form] = Form.useForm()
    const history = useHistory()
    const { getTranslation } = Language()
    const [showConfirm, setShowConfirm] = useState(false)
    const [categorySpin, setCategorySpin] = useState(false)
    const [SubcategorySpin, setSubCategorySpin] = useState(false)
    const [category, setCategory] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [confirmSpin, setConfirmSpin] = useState(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [file, setFile] = useState<any>(undefined)
    const [imageSrc, setImageSrc] = useState<any>(undefined)
    const { successObject, errorObjectWithData } = Notifications()
    const { get, post } = useAxios()

    useEffect(() => {
        loadCategory()
        return () => {}
    }, [])

    useEffect(() => {
        formValue.categoryId && loadSubCategory()
        return () => {}
    }, [formValue.categoryId])

    useEffect(() => {
        if (formValue.file !== imageSrc) {
            let newFormValue = { ...formValue }
            newFormValue.file = imageSrc
            setFormValue(newFormValue)
        }
        return () => {}
    }, [imageSrc])

    const loadCategory = () => {
        setCategorySpin(true)
        get(
            ENDPOINTS.GET_CATEGORIES,
            {},
            successCategory,
            error,
            alwaysCategory,
        )
    }

    const successCategory = useCallback((response: any) => {
        setCategorySpin(false)
        let result: any = response.data.data
        result = result.filter((item: any) => item.id !== 3)
        let dataResponse = result.reduce(
            (acc: any, item: any) => [
                ...acc,
                {
                    label: item.label || item.name || item.full_name,
                    value: item.id || item.value,
                },
            ],
            [],
        )
        setCategory(dataResponse)
    }, [])

    const alwaysCategory = () => {
        setCategorySpin(false)
    }

    const loadSubCategory = () => {
        setSubCategorySpin(true)
        get(
            ENDPOINTS.GET_SUBCATEGORIES.replace(
                '{category}',
                formValue.categoryId,
            ),
            {},
            successSubCategory,
            error,
            alwaysSubCategory,
        )
    }

    const successSubCategory = useCallback((response: any) => {
        setSubCategorySpin(false)
        let result: any = response.data.data
        result = result.filter((item: any) => item.id !== 3)
        let dataResponse = result.reduce(
            (acc: any, item: any) => [
                ...acc,
                {
                    label: item.label || item.name || item.full_name,
                    value: item.id || item.value,
                },
            ],
            [],
        )
        setSubCategory(dataResponse)
    }, [])

    const alwaysSubCategory = () => {
        setSubCategorySpin(false)
    }

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        if (values.categoryId) {
            newFormValue.subCategoryId = null
        }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            name: formValue.name,
            discount: formValue.discount,
            categoryId: formValue.categoryId,
            subCategoryId: formValue.subCategoryId,
        }

        let formData = new FormData()
        formData.append('name', result.name)
        result?.discount && formData.append('discount', result?.discount)
        formData.append('subcategory_id', result?.subCategoryId)

        if (file) {
            formData.append('file', file)
            return formData
        } else {
            return result
        }
    }

    const onSave = (values: any) => {
        setShowConfirm(true)
    }

    const onConfirm = (values: any) => {
        setConfirmSpin(true)
        let result = getValues()
        post(ENDPOINTS.POST_ADVERTISER, result, successSave, error, alwaysSave)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const successSave = (response: any) => {
        setConfirmSpin(false)
        successObject(response)
        setGlobalData({
            ...globalData,
            advertiserName: formValue.name,
            advertiserFile: file,
            advertiserFileString: formValue.file,
            advertiserId: response.data.data.id,
            organization_id: response.data.data.organization_id,
        })
        next()
    }

    const alwaysSave = () => {
        setConfirmSpin(false)
    }

    const onChangeFile = (imageSrc: any, file: any) => {
        setImageSrc(imageSrc)
        setFile(file)
    }

    const handleCancel = () => {
        history.push(ADVERTISERS)
    }

    const handleBack = () => {
        setShowConfirm(false)
    }

    return (
        <>
            <Spin
                className='spin-local'
                indicator={antIcon}
                spinning={confirmSpin}>
                {!showConfirm && (
                    <Form
                        className='form-advertiser'
                        form={form}
                        layout='vertical'
                        scrollToFirstError
                        onValuesChange={onChange}
                        onFinish={onSave}
                        size='large'>
                        <div className='form-container'>
                            <div className='form-body'>
                                <CustomInput
                                    name='name'
                                    id='name'
                                    label={getTranslation('nombre')}
                                    required={true}
                                />
                                <CustomUploader
                                    className='form-tooltip'
                                    name='file'
                                    id='file'
                                    label={getTranslation('logotipo_imagen')}
                                    value={formValue.file}
                                    onChange={onChangeFile}
                                    inputId='uploader-input-advertiser'
                                    showTooltips={true}
                                    required
                                    tooltipTitle='Tamaño menor a 2MB Formato PGN, JPG o GIF Medida 400 x 400 pixels'
                                />

                                <CustomSelect
                                    label={getTranslation('categoria')}
                                    name='categoryId'
                                    id='categoryId'
                                    data={category}
                                    loading={categorySpin}
                                    value={formValue?.categoryId}
                                    required={true}
                                />
                                <CustomSelect
                                    label={getTranslation('sub_categoria')}
                                    name='subCategoryId'
                                    id='subCategoryId'
                                    data={subCategory}
                                    loading={SubcategorySpin}
                                    value={formValue?.subCategoryId}
                                    required={true}
                                    disabled={!formValue.categoryId}
                                />

                                <CustomInput
                                    label={getTranslation('discount_optional')}
                                    name='discount'
                                    id='discount'
                                    type='number'
                                    maxNumber={100}
                                />
                            </div>
                            <div className='buttons-footer'>
                                <>
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        ghost
                                        type='primary'
                                        size='small'
                                        onClick={handleCancel}>
                                        {getTranslation('cancelar')}
                                    </Button>
                                    <Divider
                                        type='vertical'
                                        style={{
                                            borderColor: '#E8E8E8',
                                            height: '1.5em',
                                        }}
                                    />
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        ghost
                                        type='primary'
                                        size='small'
                                        onClick={handleCancel}>
                                        {getTranslation('volver')}
                                    </Button>

                                    <Button
                                        type='primary'
                                        size='small'
                                        htmlType='submit'>
                                        {getTranslation('guardar')}
                                    </Button>
                                </>
                            </div>
                        </div>
                    </Form>
                )}
                {showConfirm && (
                    <div className='confirmation-advertiser'>
                        <div className='confirmation-data'>
                            <div className='logo-name'>
                                <ShowImage
                                    url={formValue.file}
                                    alt={getTranslation('logo')}
                                />
                                <div className='description'>
                                    {formValue.name}
                                </div>
                            </div>

                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('categoria')}
                                </div>
                                <div className='description'>
                                    {category.map((item: any) => {
                                        if (item.value === formValue.categoryId)
                                            return item.label
                                    })}
                                </div>
                            </div>
                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('sub_categoria')}
                                </div>
                                <div className='description'>
                                    {subCategory.map((item: any) => {
                                        if (
                                            item.value ===
                                            formValue.subCategoryId
                                        )
                                            return item.label
                                    })}
                                </div>
                            </div>

                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('discount')}
                                </div>
                                <div className='description'>
                                    {`${
                                        formValue.discount
                                            ? formValue.discount
                                            : 0
                                    } %`}
                                </div>
                            </div>
                        </div>
                        <div className='buttons-footer'>
                            <>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    size='small'
                                    onClick={handleCancel}>
                                    {getTranslation('cancelar')}
                                </Button>
                                <Divider
                                    type='vertical'
                                    style={{
                                        borderColor: '#E8E8E8',
                                        height: '1.5em',
                                    }}
                                />
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    size='small'
                                    onClick={handleBack}>
                                    {getTranslation('volver')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onConfirm}>
                                    {getTranslation('confirmar')}
                                </Button>
                            </>
                        </div>
                    </div>
                )}
            </Spin>
        </>
    )
}
