import moment, { Moment } from 'moment'
import { ICountries } from '../interface/ICountries'
import { ICountry } from '../interface/ICountry'
import { Constants } from './Constants'
import { Language } from './Language'

export const Functions = {
    getCountriesByLanguage(countries: ICountry[]) {
        const { getLanguage } = Language()
        const lang = getLanguage()

        let dataProcessed: any = []
        countries.forEach((item: any) => {
            let traslate: any = item.label
            if (item.hasOwnProperty(lang)) {
                traslate = item[lang]
            }
            return dataProcessed.push({
                label: traslate,
                value: item.value,
            })
        })
        return dataProcessed
    },

    getTraslateForStateAndCityByLanguage(states: any, lang: string) {
        let traslate: any = states.attributes.name
        if (states.translations.hasOwnProperty(lang)) {
            traslate = states.translations[lang]?.name
        }
        return traslate
    },

    initialLettersToUpper(string: string) {
        let letters: string = ''
        let count: number = 0
        if (string) {
            let words = string.split(' ')
            if (words.length > 1) {
                words.forEach((value: string, i: number) => {
                    let letter = value.charAt(0)

                    if (letter.length && letter !== '' && count <= 1) {
                        letters += value.charAt(0)
                        count++
                    }
                })
            } else if (words.length === 1) {
                let word = words[0]
                letters =
                    word.charAt(0).toUpperCase() + word.charAt(1).toUpperCase()
            }
        }

        return letters.toUpperCase()
    },
    capitalize(text: string) {
        return text.replace(/^\w/, (c) => c.toUpperCase())
    },
    formatNumber(num: any) {
        num += ''
        let splitStr = num.split('.')
        let splitLeft = splitStr[0]
        let splitRight =
            splitStr.length > 1
                ? Constants.SEP_DECIMAL + splitStr[1].substring(0, 2)
                : ''
        let regx = /(\d+)(\d{3})/

        while (regx.test(splitLeft)) {
            splitLeft = splitLeft.replace(
                regx,
                '$1' + Constants.SEPARATOR + '$2',
            )
        }

        return splitLeft + splitRight
    },
    sortByText(a: any, b: any) {
        if (!a) {
            a = ''
        }
        if (!b) {
            b = ''
        }
        var nameA = typeof a === 'number' ? a : a.toUpperCase()
        var nameB = typeof b === 'number' ? b : b.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    },
    compareTwoArray(array1: any[], array2: any[]) {
        let equals = true

        if (array1.length !== array2.length) {
            return false
        }

        array1.forEach((value1) => {
            let find = false
            array2.forEach((value2) => {
                if (value1 === value2) {
                    find = true
                }
            })

            if (!find) {
                equals = false
            }
        })

        return equals
    },

    convertNumberToPorcentage(num: string, floating = 2) {
        const decimals = Math.pow(10, floating)
        let porcentage = parseFloat(
            //@ts-ignore
            Math.round(num * decimals) / decimals,
        ).toFixed(floating)

        if (porcentage === '0.00') {
            porcentage = '0'
        }

        return porcentage + '%'
    },

    // return format 03-ENE-2020
    getPrettyDateFromat(stringDate: string) {
        let date = new Date(stringDate)
        let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
            date,
        )
        let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(
            date,
        )
        let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)

        return `${day}-${month.toUpperCase()}-${year}`
    },

    truncateString(text: string, limit: number) {
        if (text.length <= limit) {
            return text
        }
        return text.slice(0, limit) + '...'
    },

    multiReplaceInString(text: string, obj: any) {
        var retStr = text
        for (var x in obj) {
            retStr = retStr.replace(new RegExp(x, 'g'), obj[x])
        }
        return retStr
    },

    compareTwoObjects(a: any, b: any): boolean {
        let aProps = Object.getOwnPropertyNames(a)
        let bProps = Object.getOwnPropertyNames(b)
        let index = 0
        let is_equal = true

        if (aProps.length !== bProps.length) {
            is_equal = false
        }

        while (index < aProps.length && is_equal) {
            let propName = aProps[index]

            if (!b.hasOwnProperty(propName)) {
                is_equal = false
            }

            index++
        }

        return is_equal
    },
    fixStrNumDecimal(number: string, size: number) {
        let num = number.split(',')

        number = `${number},00`

        if (num.length > 1) {
            let dec = num[1].substring(0, size)
            if (dec.length === 1) {
                dec = `${dec}0`
            }
            number = `${num[0]},${dec}`
        }

        if (size === 0) {
            number = `${num[0]}`
        }

        return number
    },

    sortArrayObjectWithParam(arr: any, param: string): [] {
        return arr.sort((a: any, b: any) => {
            if (a[param] < b[param]) {
                return -1
            }
            if (a[param] > b[param]) {
                return 1
            }
            return 0
        })
    },

    diffinMonthBetweenTwoDates(date1: Date, date2: Date): number {
        var year1 = date1.getFullYear()
        var year2 = date2.getFullYear()
        var month1 = date1.getMonth()
        var month2 = date2.getMonth()
        if (month1 === 0) {
            month1++
            month2++
        }

        return (year2 - year1) * 12 + (month2 - month1)
    },
    convertDateString(date: Date) {
        const day: string = ('0' + date.getDate()).slice(-2)
        const month: string = ('0' + (date.getMonth() + 1)).slice(-2)
        const year: number = date.getFullYear()
        const time: string = `${year}-${month}-${day}`
        return time
    },
    formatDateDayMonthYear(str: string) {
        let date: Date = new Date(str.replace('-', '/'))
        const day: string = ('0' + date.getDate()).slice(-2)
        const month: string = ('0' + (date.getMonth() + 1)).slice(-2)
        const year: number = date.getFullYear()
        const time: string = `${day}-${month}-${year}`
        return time
    },
    convertStringToDate(date: any) {
        let dateStr = new Date(date)
        dateStr.setMinutes(dateStr.getMinutes() + dateStr.getTimezoneOffset())
        return dateStr
    },
    onlyNumbers(event: any): boolean {
        const { keyCode } = event
        let zero: number = 48
        let zeroPad: number = 96
        let nine: number = 57
        let ninePad: number = 105
        let backspace: number = 8
        let left: number = 37
        let rigth: number = 39
        let keysAccepted = [backspace, left, rigth]
        if (
            (keyCode >= zero && keyCode <= nine) ||
            (keyCode >= zeroPad && keyCode <= ninePad) ||
            keysAccepted.indexOf(keyCode) !== -1
        ) {
            return true
        } else {
            event.preventDefault()
            return false
        }
    },
    getInitials(str: string) {
        const firstName = str.split(' ')[0]
        const lastName = str.split(' ').reduceRight((a) => a)

        // Extract the first two characters of a string
        if (firstName === lastName) {
            return firstName.trim().substring(0, 2).toUpperCase()
        }

        // Abbreviate firstName and lastName
        return [firstName, lastName]
            .map((name) => name[0])
            .join('')
            .toUpperCase()
    },
    abbreviateNumber(n: any): any {
        if (n < 1e3) return n
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
    },
}
