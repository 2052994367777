import { Button, Tooltip, Table, Form, Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Functions } from '../../../config/Functions'
import { Language } from '../../../config/Language'
import { useAxios } from '../../../hooks/useAxios'
import useCountryContext from '../../../hooks/useCountryContext'
import { IAccountGateway } from '../../../interface/IAccountGateway'
import { ICountries } from '../../../interface/ICountries'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomSelect } from '../../common/custom-select/CustomSelect'
import { Notifications } from '../../common/notifications/Notifications'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    accountData: IAccountGateway | null
    companyNamesDataSpin: boolean
    setCompanyNamesDataSpin: (bool: boolean) => void
}

interface FormValue {
    name: string
    fiscalNumber: any
    countryId: number | null
}

export const CompanyNamesDetail = ({ ...props }: IProps) => {
    const { accountData, companyNamesDataSpin, setCompanyNamesDataSpin } = props
    const { getTranslation, getTranslationObject } = Language()
    const { get, post } = useAxios()
    const [form] = Form.useForm()
    const { errorObjectWithData, successObject } = Notifications()
    const { country } = useCountryContext()
    const [data, setData] = useState<any>()
    const [showModal, setShowModal] = useState<any>(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [saveSpin, setSaveSpin] = useState(false)
    const [loadLocalSpin, setLoadLocalSpin] = useState(false)

    useEffect(() => {
        loadCompanyNamesData()
        return () => {}
    }, [accountData])

    const onChange = (values: any) => {
        let newFormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const loadCompanyNamesData = (local?: boolean) => {
        if (accountData) {
            local ? setLoadLocalSpin(true) : setCompanyNamesDataSpin(true)
            get(
                ENDPOINTS.GET_COMPANY_NAMES.replace(
                    '{organization}',
                    accountData?.organization_id.toString(),
                ),
                {},
                successLoad,
                error,
                alwaysLoad,
            )
        }
    }

    const successLoad = (response: any) => {
        setCompanyNamesDataSpin(false)
        setLoadLocalSpin(false)
        setData(response.data.data)
    }

    const alwaysLoad = () => {
        setCompanyNamesDataSpin(false)
        setLoadLocalSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const getValues = () => {
        let result: any = {
            name: formValue.name,
            fiscal_number: formValue.fiscalNumber.toString(),
            country_id: formValue.countryId,
            organization_id: accountData?.organization_id,
        }
        return result
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()
        accountData?.id &&
            post(
                ENDPOINTS.POST_COMPANY_NAMES,
                result,
                successSave,
                error,
                alwaysSave,
            )
    }

    const successSave = (response: any) => {
        setSaveSpin(false)
        setShowModal(false)
        loadCompanyNamesData(true)
        successObject(response)
    }

    const alwaysSave = () => {
        setSaveSpin(false)
        setFormValue({})
        form.setFieldsValue({ name: '', fiscalNumber: null, countryId: null })
    }

    const columns: any = [
        {
            title: getTranslation('legal_name'),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (name: any, item: any) => (
                <Tooltip placement='topLeft' title={name}>
                    {name}
                </Tooltip>
            ),
        },

        {
            title: getTranslation('pais'),
            dataIndex: 'country',
            width: 150,
            key: 'country',
            ellipsis: {
                showTitle: false,
            },
            render: (country: any, item: any) => (
                <Tooltip placement='topLeft' title={item.country.name}>
                    {item.country.name}
                </Tooltip>
            ),
        },

        {
            title: getTranslation('company_name_number'),
            dataIndex: 'fiscal_number',
            key: 'fiscal_number',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (fiscal_number: any) => (
                <Tooltip placement='topLeft' title={fiscal_number}>
                    {fiscal_number}
                </Tooltip>
            ),
        },
    ]

    let countriesList: ICountries[] =
        country && Functions.getCountriesByLanguage(country)

    return (
        <>
            <div className='company-names box'>
                <div className='header'>
                    {getTranslation('company_names')}
                    <Button
                        size='small'
                        type='primary'
                        onClick={() => setShowModal(true)}>
                        {getTranslation('add_company_name')}
                    </Button>
                </div>
                <div className='body'>
                    <Table
                        className='advertiser-table'
                        sticky
                        locale={getTranslationObject('table_trad')}
                        bordered
                        pagination={{
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} ${getTranslation(
                                    'of',
                                )} ${total} ${getTranslation('items')}`,
                        }}
                        scroll={{ x: 800, y: 489 }}
                        columns={columns}
                        loading={{
                            spinning: companyNamesDataSpin || loadLocalSpin,
                            indicator: antIcon,
                        }}
                        dataSource={data}
                    />
                </div>
            </div>
            <Modal
                title={getTranslation('add_company_name')}
                visible={showModal}
                width={800}
                onCancel={() => setShowModal(false)}
                footer={null}>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={saveSpin}>
                    <div id='modal-advertiser'>
                        <div className='body'>
                            <Form
                                className='form-advertiser'
                                form={form}
                                layout='vertical'
                                scrollToFirstError
                                onValuesChange={onChange}
                                size='large'>
                                <div className='form-container'>
                                    <div className='form-body'>
                                        <CustomInput
                                            name='name'
                                            id='name'
                                            label={getTranslation('legal_name')}
                                            required={true}
                                        />

                                        <CustomSelect
                                            label={getTranslation('pais')}
                                            name='countryId'
                                            id='countryId'
                                            data={countriesList}
                                            value={formValue?.countryId}
                                            required={true}
                                        />

                                        <CustomInput
                                            label={getTranslation(
                                                'company_name_number',
                                            )}
                                            name='fiscalNumber'
                                            id='fiscalNumber'
                                            type='number'
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </Form>

                            <div className='footer'>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    onClick={() => setShowModal(false)}
                                    size='small'>
                                    {getTranslation('cancelar')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onSave}>
                                    {getTranslation('guardar')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}
