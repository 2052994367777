import {
    UploadOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { ShowImage } from '../show-img/ShowImage'
import { Language } from '../../../config/Language'
import { Link } from 'react-router-dom'

interface IProps {
    id?: any
    value?: any
    label: string
    name: string
    className?: string | any
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    disabled?: boolean
    onChange?: any
    help?: string
    tooltipTitle?: string
    inputId: string
    type?: string | any
}

export const CustomUploader = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        value,
        help,
        tooltipTitle,
        showTooltips,
        onChange,
        inputId,
    } = props

    const [imageSrc, setImageSrc] = useState<any>(undefined)
    const [file, setFile] = useState<any>(undefined)

    useEffect(() => {
        if (value !== imageSrc) setImageSrc(value)
        return () => {}
    }, [value])

    useEffect(() => {
        if (value !== imageSrc) onChange(imageSrc, file)
        return () => {}
    }, [imageSrc])

    const handleChangeUploader = (files: any) => {
        let file = null
        file = files.target.files[0]
        if (file) {
            previewFile(file)
            setFile(file)
        }
    }

    const deleteFile = () => {
        let inputUploaderId: any = document.getElementById(inputId)
        inputUploaderId.value = null
        setImageSrc(null)
        setFile(null)
    }

    const previewFile = (file: any) => {
        const reader = new FileReader()
        reader.onloadend = (e) => {
            setImageSrc(e.target?.result)
        }
        reader.readAsDataURL(file)
    }

    const configTooltips = showTooltips
        ? {
              title: tooltipTitle,
              icon: <InfoCircleOutlined />,
          }
        : false

    let labelContainer = (
        <div className='label-container-help'>
            {label} {help && <Link to={help}>{getTranslation('help')}</Link>}
        </div>
    )

    return (
        <>
            <Form.Item
                id={id}
                className={className}
                name={name}
                label={labelContainer}
                tooltip={configTooltips}
                rules={[
                    {
                        required: required,
                        message: requiredMessage,
                    },
                ]}>
                <div id='image-cropper'>
                    <>
                        <div className='uploader-preview-img'>
                            <ShowImage url={imageSrc} />
                        </div>
                        {file && !disabled && (
                            <div className='delete-button'>
                                <DeleteOutlined onClick={deleteFile} />
                            </div>
                        )}
                    </>

                    <label
                        className={`button-uploader ${inputId} ${
                            disabled ? 'uploader-disabled' : ''
                        }`}
                        htmlFor={inputId}>
                        Clic para subir archivos
                        <UploadOutlined />
                    </label>

                    <input
                        id={inputId}
                        type='file'
                        accept='.png, .jpg, .jpeg, .gif'
                        onChange={handleChangeUploader}
                        hidden
                        disabled={disabled}
                    />
                </div>
            </Form.Item>
        </>
    )
}
