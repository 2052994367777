import { Spin, Form, Button } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { CustomInput } from '../common/custom-input/CustomInput'
import { CustomUploader } from '../common/custom-uploader/CustomUploader'
import { NEW_ORGANIZATION } from '../../config/Path'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { CustomAutocomplete } from '../common/custom-autocomplete-input/CustomAutocomplete'
import { Language } from '../../config/Language'

interface FormValue {
    name: string | null
    discount: number | null
    file: any | null
}

interface IProps {
    brand: any
    deleteNewBrand: () => void
    loadOrganizationData: () => void
    organizationId: any
    index: number
}

export const BrandForm = ({ ...props }: IProps) => {
    const {
        brand,
        deleteNewBrand,
        organizationId,
        index,
        loadOrganizationData,
    } = props
    const [form] = Form.useForm()
    const history = useHistory()
    const { get, post, put } = useAxios()
    const { getTranslation } = Language()
    const { successObject, errorObjectWithData } = Notifications()
    const [saveSpin, setSaveSpin] = useState(false)
    const [spinGeneral, setSpinGeneral] = useState(false)
    const [brandSpin, setBrandSpin] = useState(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [brandId, setBrandId] = useState<any>('')
    const [brandData, setBrandData] = useState<any>(null)

    const [imageSrc, setImageSrc] = useState<any>(undefined)
    const [file, setFile] = useState<any>(undefined)

    const [flagDisable, setFlagDisable] = useState(true)
    const [flagDisabledButton, setFlagDisabledButton] = useState(true)

    const loadBrandData = () => {
        setBrandSpin(true)
        get(
            ENDPOINTS.GET_BRAND.replace('{brand}', brandId),
            {},
            successBrand,
            error,
            alwaysBrand,
        )
    }

    useEffect(() => {
        setSpinGeneral(true)
        if (brandId !== '' && brandId !== null) {
            loadBrandData()
        }
        return () => {}
    }, [brandId])

    useEffect(() => {
        if (brand) {
            let newFormValue: FormValue | any = {
                name: brand.brand.name,
                discount: brand.discount_rate,
                file: brand.brand.file,
            }

            if (brand?.brand.file) {
                setFile(brand?.brand.file)
            }

            form.setFieldsValue(newFormValue)

            setFormValue(newFormValue)
        } else {
            let newFormValue: FormValue | any = {
                name: '',
                discount: 0,
                file: '',
            }

            setFile(null)

            form.setFieldsValue(newFormValue)

            setFormValue(newFormValue)
        }

        return () => {}
    }, [brand])

    useEffect(() => {
        if (brandData) {
            let newFormValue: FormValue | any = {
                name: brandData.name,
                discount: 0,
                file: brandData.file,
            }

            if (brandData?.file) {
                setFile(brandData?.file)
            }

            form.setFieldsValue(newFormValue)

            setFormValue(newFormValue)
        }
        return () => {}
    }, [brandData])

    useEffect(() => {
        if (formValue.file !== imageSrc) {
            let newFormValue = { ...formValue }
            newFormValue.file = imageSrc
            setFormValue(newFormValue)
        }
        return () => {}
    }, [imageSrc])

    const successBrand = useCallback((response: any) => {
        setBrandSpin(false)
        setBrandData(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const alwaysBrand = () => {
        setBrandSpin(false)
    }

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        let olgName = formValue.name
        if (values.discount !== formValue.discount) {
            setFlagDisabledButton(false)
        }
        setFormValue(newFormValue)
        if (brandId && values.name && olgName && values.name !== olgName) {
            resetForm()
        }
    }

    const getValues = () => {
        let result: any = {
            name: formValue.name,
            discount_rate: formValue.discount,
            advertiser_id: organizationId,
        }
        if (brandId) {
            result.brand_id = brandId
        }

        let formData = new FormData()
        formData.append('name', result.name)
        formData.append('discount_rate', result.discount_rate)
        formData.append('advertiser_id', organizationId)
        if (brandId) {
            formData.append('brand_id', brandId)
        }

        if (file) {
            !brandId && formData.append('file', file)
            return formData
        } else {
            return result
        }
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()

        if (brand) {
            let param = { discount_rate: formValue.discount }
            put(
                ENDPOINTS.PUT_ACCOUNT.replace('{account}', brand.id),
                param,
                successSave,
                error,
                alwaysSave,
            )
        } else {
            post(ENDPOINTS.POST_ACCOUNT, result, successSave, error, alwaysSave)
        }
    }

    const successSave = useCallback((response: any) => {
        setSaveSpin(false)
        successObject(response)
        loadOrganizationData()
        setFlagDisabledButton(true)
        history.push(`${NEW_ORGANIZATION}/${response.data.data.id}`)
    }, [])

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    const onChangeFile = (imageSrc: any, file: any) => {
        setImageSrc(imageSrc)
        setFile(file)
    }

    const onSelect = (data: any, option: any) => {
        setFlagDisable(false)
        if (option) {
            setBrandId(option.id)
        } else {
            resetForm()
        }
    }

    const resetForm = () => {
        let newFormValue: FormValue | any = {
            name: formValue.name,
            discount: '',
            file: '',
        }
        form.resetFields(['discount', 'file'])
        setFile(null)
        setBrandId(null)
        setBrandData(null)
        setFormValue(newFormValue)
    }

    const handleCancel = () => {
        if (brand) {
            setFlagDisabledButton(true)
            let newFormValue: FormValue | any = {
                name: brand.brand.name,
                discount: brand.discount_rate,
                file: brand.brand.file,
            }
            form.setFieldsValue(newFormValue)
            setFormValue(newFormValue)
        } else {
            setFlagDisable(true)
            setFlagDisabledButton(true)
            deleteNewBrand()
        }
    }

    if (!brandSpin && spinGeneral) {
        setSpinGeneral(false)
    }

    return (
        <div className='body'>
            <Spin indicator={antIcon} spinning={spinGeneral || saveSpin}>
                <Form
                    id='form-brand'
                    form={form}
                    layout='vertical'
                    scrollToFirstError
                    onValuesChange={onChange}
                    onFinish={onSave}
                    size='large'>
                    <div className='form-container'>
                        <div className='form-body'>
                            <CustomAutocomplete
                                label={getTranslation('nombre')}
                                name='name'
                                id='name'
                                endpoint={
                                    organizationId &&
                                    ENDPOINTS.GET_BRANDS_SEARCH.replace(
                                        '{organization}',
                                        organizationId.toString(),
                                    )
                                }
                                value={formValue?.name}
                                required={true}
                                disabled={
                                    (!!brand || !!brandData) && flagDisable
                                }
                                onSelect={onSelect}
                            />
                            <CustomUploader
                                className='form-tooltip'
                                name='file'
                                id='file'
                                label={getTranslation('logotipo_imagen')}
                                value={formValue.file}
                                onChange={onChangeFile}
                                inputId={`uploader-input-brand ${index}`}
                                showTooltips={true}
                                disabled={!!brand || !!brandData}
                                tooltipTitle={getTranslation(
                                    'tooltips_img_validation',
                                )}
                            />
                            <CustomInput
                                label={getTranslation('discount_')}
                                name='discount'
                                id='discount'
                                type='number'
                                maxNumber={100}
                            />
                        </div>
                        <div className='buttons-footer'>
                            <Button
                                type='primary'
                                size='small'
                                htmlType='submit'
                                disabled={brand && flagDisabledButton}>
                                {getTranslation('guardar_cambios')}
                            </Button>

                            <Button
                                style={{ margin: '0 16px' }}
                                ghost
                                type='primary'
                                size='small'
                                disabled={brand && flagDisabledButton}
                                onClick={handleCancel}>
                                {getTranslation('cancelar')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Spin>
        </div>
        // </div>
    )
}
