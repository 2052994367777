export const Constants = {
    EXPIRATION_TIME_KEYCLOAK: 30,
    CONTACT_TYPES: {
        AGENCY_ID: 1,
        CLIENT_ID: 2,
        PROVIDER_ID: 3,
    },
    NOTIFICATION_DURATION: 6000,
    COMPANY_TYPES: {
        AGENCY: 1,
        ACCOUNT: 2,
        PROVIDER: 3,
    },
    TIMELINE: {
        WORKER: 'worker',
        CONTACT: 'contact',
        AGENCY: 'agency',
        ACCOUNT: 'account',
        PROVIDER: 'provider',
    },
    SEPARATOR: '.',
    SEP_DECIMAL: ',',
    KEY_ESC_CODE: 27,
    CONTACT_MODULES: [
        {
            id: 'CRM',
            text: 'CRM',
        },
        {
            id: 'Inventario',
            text: 'Inventario',
        },
    ],
    PROPOSAL_STATUS: {
        IN_EDITION: 1,
        PENDING_SHIPPING: 2,
        SEND: 3,
        APPROVED: 4,
        UNSUBSCRIBED: 5,
        REJECTED: 6,
        CONFIRM: 7,
        PROCESSING: 8,
        CHANGES_REQUIRED: 9,
        RECEIVED: 10,
        CANCELLED: 11,
    },
    PROPOSAL_TYPES: {
        PROPOSAL: 1,
        CAMPAIGN: 2,
    },
    CAMPAIGN_MAP: {
        SUPPLIER_ALIAS: 1,
        REASON: 2,
        UNASSIGNED_REASON_COLOR: '#000',
    },
    TECHNOLOGY: {
        DIGITAL: 1,
        ANALOGIC: 2,
    },
    CARD_TYPES: {
        DIGITAL: 1,
        ANALOGIC_COLOR_TEST: 2,
        ANALOGIC_NO_COLOR_TEST: 3,
    },
    ARTS_FILES: {
        IMAGES: [
            'image/jpg',
            'image/jpeg',
            'image/png',
            'image/bmp',
            'image/tiff',
            'image/gif',
        ],
        VIDEO: ['video/avi', 'video/mp4', 'video/wmv', 'video/mov'],
        MAX_SIZE_FILE: 10485760, //bytes == 10Mb
    },
    DEFAULT_IMG:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTQiIGhlaWdodD0iNTQiDQogICAgdmlld0JveD0iMCAwIDU0IDU0Ij4NCiAgICA8ZGVmcz4NCiAgICAgICAgPGNsaXBQYXRoIGlkPSJhIiBmaWxsPSJub25lIj4NCiAgICAgICAgICAgIDxyZWN0IGNsYXNzPSJhIiBmaWxsPSJub25lIiB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIC8+DQogICAgICAgIDwvY2xpcFBhdGg+DQogICAgPC9kZWZzPg0KICAgIDxyZWN0IGNsYXNzPSJhIiBmaWxsPSJub25lIiB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIC8+DQogICAgPGcgY2xhc3M9ImIiIGNsaXAtcGF0aD0idXJsKCNhKSI+DQogICAgICAgIDxyZWN0IGNsYXNzPSJhIiBmaWxsPSJub25lIiB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIC8+DQogICAgICAgIDxwYXRoIGNsYXNzPSJjIiBmaWxsPSIjOGM4YzhjIg0KICAgICAgICAgICAgZD0iTTQ1LjU2MywzNy4xMjRIMS42ODdBMS42ODksMS42ODksMCwwLDEsMCwzNS40MzhWMS42ODdBMS42ODksMS42ODksMCwwLDEsMS42ODcsMEg0NS41NjNhMS42ODksMS42ODksMCwwLDEsMS42ODcsMS42ODdWMzUuNDM4QTEuNjg5LDEuNjg5LDAsMCwxLDQ1LjU2MywzNy4xMjRaTTE0Ljk1MiwxOS4yNDVhLjQxOC40MTgsMCwwLDAtLjMyNC4xNUw1LjY0OCwzMC4wNDlhLjQzNy40MzcsMCwwLDAtLjEuMjczLjQyMi40MjIsMCwwLDAsLjQyMS40MjFINDEuMjc2YS40MjUuNDI1LDAsMCwwLC4yNzMtLjEuNDMyLjQzMiwwLDAsMCwuMDYzLS41OTFMMjkuNSwxNS43M2EuMzQxLjM0MSwwLDAsMC0uMDM2LS4wMzdsLS4wMTctLjAxNmEuNDI2LjQyNiwwLDAsMC0uMjcyLS4xLjQxOC40MTgsMCwwLDAtLjMyNC4xNWwtOC4zMzcsOS44ODhMMTUuMjc3LDE5LjRhLjM0MS4zNDEsMCwwLDAtLjAzNi0uMDM3bC0uMDE3LS4wMTZBLjQyNi40MjYsMCwwLDAsMTQuOTUyLDE5LjI0NVptLS41LTExLjY1MmEzLjM3NSwzLjM3NSwwLDEsMCwzLjM3NiwzLjM3NkEzLjM3OCwzLjM3OCwwLDAsMCwxNC40NDgsNy41OTRaIg0KICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy4zNzUgOC40MzgpIiAvPg0KICAgIDwvZz4NCjwvc3ZnPg==',
    RADIO_MIN: 1,
    RADIO_DEFAULT: 800,
    RADIO_MAX: 1500,
    PROPOSAL_ACTION: {
        RELEASE: 20,
        EDIT_INFO: 21,
        CONVERT_TO_CAMPAIGN: 22,
    },
    PATH_IMG: '/src/asset/img/',
    REGEX_EMAIL:
        /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i,
    PRICE_TYPES: {},
    LANGUAGES: {
        EN: 'en',
        ES: 'es',
        PT: 'pt',
    },
    GENERIC_MARKER:
        'M12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
    SELECTED_MARKER:
        'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406z M12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
}
