export const translatedMessages = {
    aplicar: 'Aplicar',
    aceptar: 'Aceptar',
    cancelar: 'Cancelar',
    eliminar: 'Eliminar',
    editar: 'Editar',
    logout: 'Salir',
    'briefs-form': 'Formulario de Brief',
    brand: 'Marca',
    brands: 'Marcas',
    vendors: 'Medios',
    activities: 'Actividades',
    activity: 'Actividad',
    'vendor-details': 'Ver o editar medio',
    organization: 'Organización',
    accounts: 'Cuentas',
    briefs: 'Brief',
    dashboard: 'Dashboard',
    seleccione: 'Seleccione',
    guardado: 'Guardado',
    guardar_cambios: 'Guardar cambios',
    guardar: 'Guardar',
    guardar_borrador: 'Guardar borrador',
    los_datos_se_han_guardado_con_exito: 'Los datos se han guardado con éxito',
    los_datos_se_han_eliminado_con_exito:
        'Los datos se han eliminado con éxito',
    ha_ocurrido_error_guardar: 'Ha ocurrido un error al guardar',
    mensaje: 'Mensaje',
    error: 'Error',
    eliminado: 'Eliminado',
    en: 'Inglés',
    es: 'Español',
    pt: 'Portugués',
    nombre: 'Nombre',
    apellido: 'Apellido',
    marca: 'Marca',
    pais: 'País',
    ciudad: 'Ciudad',
    tipo_de_contacto: 'Tipo de contacto',
    position: 'Puesto',
    email: 'Email',
    genero: 'Género',
    fecha_de_nacimiento: 'Fecha de nacimiento',
    fecha: 'Fecha',
    mobile: 'Móvil',
    phone: 'Teléfono',
    observaciones: 'Observaciones',
    eliminar_contacto: 'Eliminar contacto',
    medios: 'Medios',
    medio: 'Medio',
    crear_medio: 'Crear medio',
    crear_contacto: 'Crear contacto',
    crear_marca: 'Crear marca',
    logo: 'Logo',
    contactos: 'Contactos',
    contacto: 'Contacto',
    nuevo_medio: 'Nuevo medio',
    nueva_organizacion: 'Nueva organización',
    crear_actividad: 'Crear actividad',
    pasadas: 'Pasadas',
    hoy: 'Hoy',
    proximas: 'Próximas',
    inicio_fin: 'Inicio - Fin',
    clean_filters: 'Limpiar filtros',
    ejecutivo: 'Ejecutivo',
    ejecutivo_cuenta: 'Ejecutivo de cuenta',
    nueva_actividad: 'Nueva actividad',
    tipo_actividad: 'Tipo de actividad',
    business_potentiality: 'Potencialidad del negocio',
    descripcion_actividad: 'Descripción de la actividad',
    descripcion: 'Descripción',
    hora: 'Hora',
    sin_datos: 'Sin datos.',
    crea_un_contacto: 'Crea un contacto',
    crea_una_marca: 'Crea una marca',
    crear_organizacion: 'Crear organización',
    last_activity: 'Última actividad',
    nuevo_contacto: 'Nuevo contacto',
    metrics: 'Métricas',
    ultima_campana: 'Última campaña',
    fecha_ultima_campana: 'Fecha última campaña',
    propuesta_enviada: 'Propuestas enviadas',
    historical_total: 'Total histórico',
    campana_ejecutadas: 'Campañas ejecutadas',
    tasa_conversion: 'Tasa de conversión',
    total_actividades: 'Todas las actividades',
    reuniones: 'Reuniones',
    promedio_semanal: 'Promedio semanal',
    metricas_campanas: 'Métricas de campañas',
    historial_propuestas: 'Historial de Propuestas',
    historial_inversion_campana: 'Historial de inversión por campaña',
    historial_inversion_mensual: 'Historial de inversión mensual',
    historial_inversion_medio: 'Historial de inversión por medio',
    prevision_inversion_campaña: 'Previsión de inversión por campaña',
    metricas_actividades: 'Métricas de actividades',
    por_tipo_actividad: 'Por tipo de actividad',
    evolucion_actividades: 'Evolución de las actividades',
    actividades_ejecutivo: 'Actividades por ejecutivo',
    actividades_por_cuenta: 'Actividades por cuenta',
    tooltips_img_validation:
        'Tamaño menor a 2MB, Formato PGN, JPG o GIF, Medida 400 x 400 pixels',
    informacion_general: 'Información general',
    nombre_de_la_campana: 'Nombre de la campaña',
    fecha_estimada: 'Fecha estimada',
    moneda_para_la_propuesta: 'Moneda para la propuesta',
    presupuesto: 'Presupuesto',
    tipo_de_presupuesto: 'Tipo de presupuesto',
    pais_de_pago: 'País de pago',
    pais_de_la_campana: 'País de la campaña',
    ciudad_de_la_campana: 'Ciudad de la campaña',
    publico_objetivo: 'Público objetivo',
    perfil_psicografico: 'Perfil psicográfico',
    especificaciones_de_campana: 'Especificaciones de campaña',
    objetivo_de_comunicacion: 'Objetivo de comunicación',
    convertir_en_propuesta: 'Convertir en propuesta',
    nuevo_brief: 'Nuevo brief',
    modal_nueva_marca_detalle:
        'Para crear una Marca debes contar con una Organización existente. Selecciona una Organización del listado o crea una Organización nueva.',
    logotipo_imagen: 'Logotipo o imagen',
    discount_: 'Descuento %',
    discount: 'Descuento',
    discount_optional: '% Descuento (Opcional)',
    tipo: 'Tipo',
    sitio_web: 'Sitio web',
    categoria: 'Categoría',
    sub_categoria: 'Subcategoría',
    campaign: 'Campaña',
    campaigns: 'Campañas',
    cliente: 'Cliente',
    inicio: 'Inicio',
    inicio_estimado: 'Inicio estimado',
    fin_estimado: 'Fin estimado',
    finalizacion: 'Finalización',
    estado: 'Estado',
    acciones: 'Acciones',
    accion: 'Acción',
    nueva_campana: 'Nueva campaña',
    listado_campanas: 'Listado de campañas',
    listado_briefs: 'Listado de briefs',
    completar: 'Completar',
    progreso: 'Progreso',
    etapa: 'Etapa',
    inversion_a_futuro: 'Inversión a futuro',
    inversion_futuro_cliente: 'Inversión a futuro por cliente',
    gestor_tareas: 'Gestor de tareas',
    ajustes_personales: 'Ajustes personales',
    cerrar_sesion: 'Cerrar sesión',
    notificaciones: 'Notificaciones',
    borrar_notificaciones: 'Borrar notificaciones',
    volver: 'Volver',
    page_not_found_text: 'La página que intentas visitar no existe',
    recientes: 'Recientes',
    aceptadas: 'Aceptadas',
    rechazadas: 'Rechazadas',
    vencidas: 'Vencidas',
    archivadas: 'Archivadas',
    envio: 'Envío',
    vencimiento: 'Vencimiento',
    proposal_expiration: 'Vencimiento de la propuesta',
    proposals: 'Propuestas',
    'proposals-detail': 'Detalle de propuesta',
    proposal_list: 'Listado de propuestas',
    nombre_campana: 'Nombre campaña',
    presupuesto_estimado: 'Presupuesto estimado',
    nivel_socioeconomico: 'Nivel Socioeconómico',
    rangos_etarios: 'Rangos etarios',
    tipo_de_campaña: 'Tipo de campaña',
    material: 'Material',
    puntos_de_interes: 'Puntos de interés',
    soportes: 'Soportes',
    estrategia: 'Estrategia',
    confirmar: 'Confirmar',
    editar_propuesta: 'Editar propuesta',
    archivar: 'Archivar',
    filtros: 'Filtros',
    entorno: 'Entorno',
    ots_min: 'OTS Min',
    ots_max: 'OTS Max',
    filters_message: 'Los Filtros activos responden a tu Brief de Campaña.',
    agregar: 'Agregar',
    ver_mapa: 'Ver mapa',
    selection: 'Selección',
    fin: 'Fin',
    seleccion_de_soportes: 'Selección de soportes',
    nombre_comercial: 'Nombre comercial',
    ubicacion: 'Ubicación',
    soportes_totales: 'Soportes totales',
    total_OTS: 'Total OTS',
    costo_total: 'Costo total',
    precio_unitario: 'Precio unitario',
    of: 'de',
    items: 'elementos',
    already_added: 'Ya lo has agregado',
    write_here: 'Escriba aquí...',
    see_list: 'Ver listado',
    MU: 'MU',
    CA: 'CA',
    PO: 'PO',
    PP: 'PP',
    PA: 'PA',
    DI: 'DI',
    layers: 'Capas',
    not_search_assets: 'Aún no has buscado Soportes',
    referencias: 'Referencias',
    digital: 'Digital',
    place_based: 'Establecimiento',
    roadside: 'Via pública',
    printed_mesh: 'Impreso',
    billboard: 'Cartelera',
    display: 'Display',
    mural: 'Mural',
    panel: 'Panel',
    poster: 'Poster',
    poster_junior: 'Poster Pequeño',
    food_beverages: 'Alimentos y bebidas',
    vehicles: 'Automotriz',
    goverment: 'Edificios gubernamentales',
    education: 'Educación',
    entertainment: 'Entretenimiento',
    finance: 'Finanzas',
    parks: 'Parques',
    health: 'Salud',
    shops: 'Tiendas y shoppings',
    transportation: 'Transportes',
    turism: 'Turismo',
    ots_description:
        'Si prendes la vista por OTS, verás que los íconos que identifican a los soportes cambian de tamaño. Este cambio de tamaño responde a la mayor o menor cantidad de OTS que posean los soportes incluidos en la búsqueda que has hecho.',
    example: 'Ejemplo',
    comercial_name: 'Nombre comercial',
    download_specifications: 'Descargar especificaciones',
    commercial_measures: 'Medidas comerciales',
    additional_information: 'Información adicional',
    otros: 'Otros',
    subCategoryEducation: 'Centro educativo, Educación superior, Colegio',
    subCategoryEntertainment:
        'Parque de atracciones, Bowling, Casino, Cine, Centro de convenciones o exposiciones, Campo de golf, Pista de patinaje, Vida Nocturna, Artes escénicas, Estadio, Zoológico',
    subCategoryFinance: 'Cajero automático, Banco, Cambio de divisas, Finanzas',
    subCategoryFoodBeverages:
        'Cafetería, Comida rápida, Tienda de comestibles, Restaurante, Vinoteca',
    subCategoryGoverment:
        'Municipalidad, Centro de la comunidad, Corte, Embajada, Oficina de gobierno, Estación de policía, Oficina de correos',
    subCategoryHealth: 'Hospital, Servicios médicos, Farmacia',
    subCategoryParks:
        'Parque o área de recreación, Área de esparcimiento, Zona de descanso, Complejo deportivo',
    subCategoryShops:
        'Tienda de ropa, Librería, Tienda de electrónicos, Tienda de conveniencia, Grandes almacenes, Muebles y herramientas para el hogar, Artículos de oficina, Cuidado personal, Shopping, Tienda de especialidades, Tienda de artículos deportivos, Tienda, Veterinario',
    subCategoryTransportation:
        'Aeropuerto, Pista de aeropuerto, Terminal de aeropuerto, Estación de autobuses, Terminal del ferry, Helipuerto, Zona portuaria, Terminal, Puerto, Estación de ferrocarril, Entrada estación de tren, Barrera de peaje, Servicio de transporte, Tranvía, Servicio de transporte',
    subCategoryTurism:
        'Puesto Fronterizo, Monumento histórico, Hotel, Centro de información turística, Museo, Mirador, Alojamientos varios, Ruina, Esquí, Sendero',
    subCategoryVehicles:
        'Concesionario de automóviles, Servicio y mantenimiento de automóviles, Estaciones de servicio, Concesionario de motos, Estacionamiento, Garage, Alquiler de vehículos, Venta de camiones, Estacionamiento de camiones, Automóvil club, Estación de pesaje',
    required: 'Obligatorio',
    select_a_option: 'Seleccione una opción',
    without_code: 'Sin código',
    code: 'Código',
    advertisers: 'Anunciantes',
    advertiser: 'Anunciante',
    add_an_advertiser: 'Agregar un anunciante',
    add_advertiser: 'Agregar anunciante',
    'add-advertisers': 'Agregar un anunciante',
    'preview-advertisers': 'Anunciante existente',
    advertiser_list: 'Listado de anunciantes',
    created_by: 'Creado por',
    created: 'Creado',
    'new-advertisers': 'Agregar un anunciante',
    select_advertaiser: 'Selecciona un anunciante',
    about_advertaisers: 'Sobre anunciantes',
    create_advertiser: 'Crear anunciante',
    next: 'Siguiente',
    help: 'Ayuda',
    razon_social: 'Razón social',
    you_can_skip: 'Puedes saltar este paso',
    create_an_advertiser: 'Crear un anunciante',
    modal_advertiser_exist:
        'ya forma parte de tus anunciantes. ¿Quieres visitar la página de información sobre este anunciante?',
    see_advertiser: 'Ver anunciante',
    close: 'Cerrar',
    same_name_advertiser: 'Mismo nombre que el anunciante',
    same_logo_advertiser: 'Mismo logo que el anunciante',
    company_name_number: 'Número de razón social',
    legal_name: 'Nombre legal',
    skip_this_step: 'Saltar este paso',
    create_company_name: 'Crear una razón social',
    finish: 'Finalizar',
    you_will_create_contacts_end:
        'Podrás crear más Contactos para tu Anunciante al finalizar.',
    detail: 'Detalle',
    edit_discont: 'Editar descuento',
    company_names: 'Razones sociales',
    add_company_name: 'Crear razón social',
    name_last_name: 'Nombre y apellido',
    to_disable: 'Deshabilitar',
    table_trad: {
        filterTitle: 'Filtrar menú',
        filterConfirm: 'Aceptar',
        filterReset: 'Reiniciar',
        filterEmptyText: 'Sin filtros',
        emptyText: 'Sin datos.',
        selectAll: 'Seleccionar todo',
        selectInvert: 'Invertir selección',
        selectNone: 'Vacíe todo',
        selectionAll: 'Seleccionar todos los datos',
        sortTitle: 'Ordenar',
        expand: 'Expandir fila',
        collapse: 'Colapsar fila',
        triggerDesc: 'Click para ordenar en orden descendente',
        triggerAsc: 'Click para ordenar en orden ascendente',
        cancelSort: 'Click para cancelar ordenamiento',
    },
    action_to_taken: 'Acción a realizar',
    make_impact: 'Producir impacto',
    segment: 'Segmentar',
    search_coverage: 'Buscar cobertura',
    search_frequency: 'Buscar frecuencia',
    login: 'Inicio de sesión',
    'send-proposal': 'Enviar propuesta',
    abstract: 'Resumen',
    map: 'Mapa',
    subtotal: 'Subtotal',
    total: 'Total',
    cost_production: 'Costo de producción',
    installation_cost: 'Costo de instalación',
    quantity: 'Cantidad',
    price: 'Precio',
    totals: 'Totales',
    total_CPM: 'CPM total',
}
