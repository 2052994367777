import { BookOutlined } from '@ant-design/icons'
import { Language } from '../../config/Language'

export const NotificationPanel = () => {
    // const { getTranslation } = Language()
    return (
        <div className='notificationPanel'>
            <div className='header'>Notificaciones</div>
            <div className='body'>
                <div className='notification-item'>
                    <div className='type'>
                        <BookOutlined className='icon-navbar' />
                    </div>
                    <div className='text'>
                        <div className='title'>Nike</div>
                        <div className='sub-title'>Lanzamiento Dri Fit</div>
                        <div className='description'>
                            El Brief fue enviado con éxito. En breve recibirás
                            la Propuesta.
                        </div>
                    </div>
                </div>
                <div className='notification-item'>
                    <div className='type'>
                        <BookOutlined className='icon-navbar' />
                    </div>
                    <div className='text'>
                        <div className='title'>Nike</div>
                        <div className='sub-title'>Lanzamiento Dri Fit</div>
                        <div className='description'>
                            El Brief fue enviado con éxito.
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div className='delete'>Borrar notificaciones</div>
            </div>
        </div>
    )
}
