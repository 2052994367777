import { IProposal } from '../../../interface/IProposal'

export const RenderStatusProposal = (data: IProposal) => {
    let bgColor = ''
    let bordeColor = ''
    let fontColor = ''
    if (data?.status.id === 6) {
        bgColor = '#E6F7FF'
        bordeColor = '#91D5FF'
        fontColor = '#1890FF'
    } else if (
        data?.status.id === 1 ||
        data?.status.id === 5 ||
        data?.status.id === 9
    ) {
        bgColor = '#FFF7E6'
        bordeColor = '#FFD591'
        fontColor = '#FA8C16'
    } else if (data?.status.id === 11) {
        bgColor = '#FFF1F0'
        bordeColor = '#FFA39E'
        fontColor = '#F5222D'
    } else if (data?.status.id === 10) {
        bgColor = '#F5F5F5'
        bordeColor = '#D9D9D9'
        fontColor = '#595959'
    }
    return (
        <div
            className='estado'
            style={{
                backgroundColor: bgColor,
                borderColor: bordeColor,
                color: fontColor,
            }}>
            <span>{data?.status.name}</span>
        </div>
    )
}
