import { useHistory } from 'react-router-dom'
import { NEW_ORGANIZATION } from '../../config/Path'
import { ShowImage } from '../common/show-img/ShowImage'
import { Carousel, Empty } from 'antd'
import {
    CalendarOutlined,
    ScheduleOutlined,
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
} from '@ant-design/icons'
import { Language } from '../../config/Language'

interface IProps {
    data: any
}

export const Account = ({ ...props }: IProps) => {
    const { data } = props
    const history = useHistory()
    const { getTranslation } = Language()
    const handleBrandClick = () => {
        history.push(`${NEW_ORGANIZATION}/${data.brand.organization.id}`)
    }

    return (
        <div className='account-item'>
            <div className='account-header' onClick={handleBrandClick}>
                <div className='logo'>
                    <ShowImage
                        url={data.brand.file}
                        alt={getTranslation('logo')}
                    />
                </div>
                <div className='title'>
                    {data.brand.organization?.name}
                    <span>{data.brand?.name}</span>
                </div>
            </div>
            <div className='account-body'>
                <div className='activity'>
                    <div className='title'>
                        {getTranslation('last_activity')}
                    </div>
                    {data.last_activity ? (
                        <>
                            <div className='date space'>
                                <CalendarOutlined />
                                {data.last_activity?.date}
                            </div>
                            <div className='event space'>
                                <ScheduleOutlined />
                                {data.last_activity?.activity_type?.name}
                            </div>
                            <div className='contact space'>
                                <UserOutlined />
                                {data.last_activity?.contact?.name}
                            </div>
                        </>
                    ) : (
                        <Empty
                            imageStyle={{ height: '70px' }}
                            description={
                                <span>{getTranslation('sin_datos')}</span>
                            }></Empty>
                    )}
                </div>
                <div className='contacts-list'>
                    <div className='title'>{getTranslation('contactos')}</div>
                    {data.contacts.length > 0 ? (
                        <>
                            <Carousel>
                                {data.contacts?.map((item: any, index: any) => {
                                    return (
                                        <div key={index}>
                                            <div className='contact'>
                                                <div className='name space'>
                                                    {`${item.name} ${item.last_name}`}
                                                </div>
                                                <div className='phone space'>
                                                    <PhoneOutlined />
                                                    {item.phone
                                                        ? item.phone
                                                        : item.mobile
                                                        ? item.mobile
                                                        : '-'}
                                                </div>
                                                <div className='email space'>
                                                    <MailOutlined />
                                                    {item.email}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </>
                    ) : (
                        <Empty
                            imageStyle={{ height: '70px' }}
                            description={
                                <span>{getTranslation('sin_datos')}</span>
                            }></Empty>
                    )}
                </div>
            </div>
        </div>
    )
}
