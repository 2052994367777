import { Button, Modal, Form, Spin } from 'antd'
import { useState } from 'react'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Functions } from '../../../config/Functions'
import { Language } from '../../../config/Language'
import { useAxios } from '../../../hooks/useAxios'
import useCountryContext from '../../../hooks/useCountryContext'
import { ICountries } from '../../../interface/ICountries'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomSelect } from '../../common/custom-select/CustomSelect'
import { Notifications } from '../../common/notifications/Notifications'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    showModal: boolean
    globalValue: any
    setShowModal: (bool: boolean) => void
    setGlobalValue: (aux: any) => void
    advertiserId: any
}

interface FormValue {
    name: string
    fiscalNumber: any
    countryId: number | null
}

export const CompanyNamesFormModal = ({ ...props }: IProps) => {
    const {
        showModal,
        globalValue,
        advertiserId,
        setShowModal,
        setGlobalValue,
    } = props
    const [form] = Form.useForm()
    const { getTranslation } = Language()
    const { post } = useAxios()
    const { errorObjectWithData, successObject } = Notifications()
    const { country } = useCountryContext()
    const [saveSpin, setSaveSpin] = useState(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})

    const onChange = (values: any) => {
        let newFormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            name: formValue.name,
            fiscal_number: formValue.fiscalNumber.toString(),
            country_id: formValue.countryId,
            organization_id: advertiserId,
        }
        return result
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()
        advertiserId &&
            post(
                ENDPOINTS.POST_COMPANY_NAMES,
                result,
                successSave,
                error,
                alwaysSave,
            )
    }

    const successSave = (response: any) => {
        setSaveSpin(false)
        setShowModal(false)
        let newGlobalValue: any = {
            ...globalValue,
            company_name_id: response.data.data.id,
        }
        setGlobalValue(newGlobalValue)
        successObject(response)
    }

    const alwaysSave = () => {
        setSaveSpin(false)
        setFormValue({})
        form.setFieldsValue({ name: '', fiscalNumber: null, countryId: null })
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    let countriesList: ICountries[] =
        country && Functions.getCountriesByLanguage(country)

    return (
        <>
            <Modal
                title={getTranslation('add_company_name')}
                visible={showModal}
                width={800}
                onCancel={() => setShowModal(false)}
                footer={null}>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={saveSpin}>
                    <div id='modal-advertiser'>
                        <div className='body'>
                            <Form
                                className='form-advertiser'
                                form={form}
                                layout='vertical'
                                scrollToFirstError
                                onValuesChange={onChange}
                                size='large'>
                                <div className='form-container'>
                                    <div className='form-body'>
                                        <CustomInput
                                            name='name'
                                            id='name'
                                            label={getTranslation('legal_name')}
                                            required={true}
                                        />

                                        <CustomSelect
                                            label={getTranslation('pais')}
                                            name='countryId'
                                            id='countryId'
                                            data={countriesList}
                                            value={formValue?.countryId}
                                            required={true}
                                        />

                                        <CustomInput
                                            label={getTranslation(
                                                'company_name_number',
                                            )}
                                            name='fiscalNumber'
                                            id='fiscalNumber'
                                            type='number'
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </Form>

                            <div className='footer'>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    onClick={() => setShowModal(false)}
                                    size='small'>
                                    {getTranslation('cancelar')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onSave}>
                                    {getTranslation('guardar')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}
