import { Form, Button } from 'antd'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

interface IProps {
    form: any
    onChange: (values: any) => void
    onSubmit: (values: any) => void
    openLogin: (bool: boolean) => void
    successBool: boolean
    errorBool: boolean
    error: any
}
export const ForgotPasswordForm = ({ ...props }: IProps) => {
    const {
        form,
        onChange,
        onSubmit,
        successBool,
        errorBool,
        openLogin,
        error,
    } = props

    let text =
        'Te hemos enviado un email con las instrucciones para recuperar tu contraseña.'
    if (error.response) {
        if (error.response.status === 401)
            text =
                'Nombre de usuario o contraseña incorrectos, por favor vuelve a intentarlo.'
        if (error.response.status === 500) text = 'Error de servidor'
    }
    return (
        <div className='context'>
            <div className='logo'></div>
            <div className='title'>Olvido de contraseña</div>
            {errorBool && (
                <div className='errors'>
                    <div className='icon'>
                        <CloseCircleOutlined />
                    </div>
                    <div className='text'>{text}</div>
                </div>
            )}
            {successBool ? (
                <div className='success'>
                    <div className='icon'>
                        <CheckCircleOutlined />
                    </div>
                    <div className='text'>{text}</div>
                </div>
            ) : (
                <div className='subtitle'>
                    Ingresa el email con el que te registraste para poder
                    recuperar la contraseña.
                </div>
            )}
            <Form
                // {...formItemLayout}
                layout='vertical'
                form={form}
                onValuesChange={onChange}
                onFinish={onSubmit}
                scrollToFirstError>
                {!successBool && (
                    <CustomInput
                        name='forgotEmail'
                        type='email'
                        label='Email'
                        required
                    />
                )}
                <Form.Item>
                    <div className='buttons-container'>
                        <a onClick={() => openLogin(true)} type='primary'>
                            Ir al Inicio de sesión
                        </a>
                        {!successBool ? (
                            <Button
                                htmlType='submit'
                                size='small'
                                type='primary'>
                                Enviar
                            </Button>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}
