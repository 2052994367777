import { notification } from 'antd'
import { Language } from '../../../config/Language'

export const Notifications = () => {
    const { getTranslation } = Language()
    const success = (title: string, description: string) => {
        notification.success({
            message: title,
            description: description,
            duration: 5,
            placement: 'bottomLeft',
            style: {
                left: '30px',
            },
        })
    }

    const info = (title: string, description: string) => {
        notification.info({
            message: title,
            description: description,
            duration: 5,
            placement: 'bottomLeft',
            style: {
                left: '30px',
            },
        })
    }

    const warning = (title: string, description: string) => {
        notification.warning({
            message: title,
            description: description,
            duration: 5,
            placement: 'bottomLeft',
            style: {
                left: '30px',
            },
        })
    }

    const error = (title: string, description: string) => {
        notification.error({
            message: title,
            description: description,
            duration: 5,
            placement: 'bottomLeft',
            style: {
                left: '30px',
            },
        })
    }

    const successObject = (response: any) => {
        let description = getTranslation('los_datos_se_han_guardado_con_exito')

        if (response.data.message) {
            description = response.data.message
        } else if (response.message) {
            description = response.message
        }

        success(getTranslation('guardado'), description)
    }

    const notificationObject = (response: any) => {
        let description = getTranslation('los_datos_se_han_guardado_con_exito')

        if (response.data.message) {
            description = response.data.message
        } else if (response.message) {
            description = response.message
        }

        success(getTranslation('mensaje'), description)
    }

    const errorObject = (errors: any) => {
        let description = getTranslation('ha_ocurrido_error_guardar')

        if (
            errors.response &&
            errors.response.data &&
            errors.response.data.message
        ) {
            description = errors.response.data.message
        } else if (errors.response && errors.response.message) {
            description = errors.response.message
        } else if (errors.message) {
            description = errors.message
        }

        error(getTranslation('error'), description)
    }

    const errorObjectWithData = (errors: any) => {
        let description = getTranslation('ha_ocurrido_error_guardar')

        if (
            errors.response &&
            errors.response.data &&
            errors.response.data.message
        ) {
            description = errors.response.data.message
        } else if (errors.response && errors.response.message) {
            description = errors.response.message
        } else if (errors.message) {
            description = errors.message
        }

        if (errors.response.data.errors) {
            let keys = Object.keys(errors.response.data.errors)
            let value: any
            keys.forEach((element) => {
                value = errors.response.data.errors[element]
                if (Array.isArray(value)) {
                    if (value.length === 1) {
                        description = value[0]
                    }
                }
            })
        }

        error(getTranslation('error'), description)
    }

    const deleteObject = (response: any) => {
        let description = getTranslation('los_datos_se_han_eliminado_con_exito')

        if (response.data.message) {
            description = response.data.message
        } else if (response.message) {
            description = response.message
        }

        success(getTranslation('eliminado'), description)
    }

    return {
        success,
        info,
        warning,
        error,
        successObject,
        deleteObject,
        notificationObject,
        errorObject,
        errorObjectWithData,
    }
}
