import { Button, Tooltip, Empty, Modal, Form, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Language } from '../../../config/Language'
import { useAxios } from '../../../hooks/useAxios'
import { IAccountGateway } from '../../../interface/IAccountGateway'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomUploader } from '../../common/custom-uploader/CustomUploader'
import { Notifications } from '../../common/notifications/Notifications'
import { ShowImage } from '../../common/show-img/ShowImage'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    accountData: IAccountGateway | null
    setBrandDataSpin: (bool: boolean) => void
}

interface FormValue {
    name: string | null
    file: any | null
}

export const BrandDetail = ({ ...props }: IProps) => {
    const { accountData, setBrandDataSpin } = props
    const { getTranslation } = Language()
    const [form] = Form.useForm()
    const { get, post } = useAxios()
    const { errorObjectWithData, successObject } = Notifications()
    const [data, setData] = useState<any>()
    const [showModal, setShowModal] = useState<any>(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [saveSpin, setSaveSpin] = useState(false)
    const [loadLocalSpin, setLoadLocalSpin] = useState(false)
    const [file, setFile] = useState<any>(undefined)
    const [imageSrc, setImageSrc] = useState<any>(undefined)

    useEffect(() => {
        loadBrandData()
        return () => {}
    }, [accountData])

    const onChange = (values: any) => {
        let newFormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const loadBrandData = (local?: boolean) => {
        if (accountData) {
            local ? setLoadLocalSpin(true) : setBrandDataSpin(true)
            get(
                ENDPOINTS.GET_BRAND_BY_CLIENT.replace(
                    '{organization}',
                    accountData?.organization_id.toString(),
                ),
                {},
                successLoad,
                error,
                alwaysLoad,
            )
        }
    }

    const successLoad = (response: any) => {
        setBrandDataSpin(false)
        setLoadLocalSpin(false)
        setData(response.data.data)
    }

    const alwaysLoad = () => {
        setLoadLocalSpin(false)
        setBrandDataSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const getValues = () => {
        let formData = new FormData()
        if (accountData) {
            formData.append('name', formValue.name)
            formData.append(
                'organization_id',
                accountData?.organization_id.toString(),
            )
            formData.append('file', file)
        }

        return formData
    }

    const onChangeFile = (imageSrc: any, file: any) => {
        setImageSrc(imageSrc)
        setFile(file)
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()
        accountData?.id &&
            post(ENDPOINTS.POST_BRANDS, result, successSave, error, alwaysSave)
    }

    const successSave = (response: any) => {
        setSaveSpin(false)
        setShowModal(false)
        loadBrandData(true)
        successObject(response)
    }

    const alwaysSave = () => {
        setSaveSpin(false)
        setFormValue({})
        form.setFieldsValue({ name: '', file: '' })
        setImageSrc('')
        setFile('')
    }

    return (
        <>
            <div className='brand box'>
                <div className='header'>
                    {getTranslation('brands')}
                    <Button
                        size='small'
                        type='primary'
                        onClick={() => setShowModal(true)}>
                        {getTranslation('crear_marca')}
                    </Button>
                </div>
                <div className='body'>
                    <Spin
                        className='spin-local'
                        indicator={antIcon}
                        spinning={loadLocalSpin}>
                        <div className='brand-container'>
                            {data?.length ? (
                                data.map((item: any, index: any) => (
                                    <div className='brand-item' key={index}>
                                        <ShowImage
                                            url={item?.file}
                                            alt={getTranslation('logo')}
                                        />
                                        <Tooltip
                                            placement='bottomLeft'
                                            title={item?.name}>
                                            <div className='description'>
                                                {item?.name}
                                            </div>
                                        </Tooltip>
                                    </div>
                                ))
                            ) : (
                                <Empty
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, 0)',
                                        margin: '25px 0px 25px 0px',
                                    }}
                                    description={getTranslation('sin_datos')}
                                />
                            )}
                        </div>
                    </Spin>
                </div>
            </div>

            <Modal
                title={getTranslation('crear_marca')}
                visible={showModal}
                width={800}
                onCancel={() => setShowModal(false)}
                footer={null}>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={saveSpin}>
                    <div id='modal-advertiser'>
                        <div className='body'>
                            <Form
                                className='form-advertiser'
                                form={form}
                                layout='vertical'
                                scrollToFirstError
                                onValuesChange={onChange}
                                size='large'>
                                <div className='form-container'>
                                    <div className='form-body'>
                                        <CustomInput
                                            name='name'
                                            id='name'
                                            label={getTranslation('nombre')}
                                            required={true}
                                        />
                                        <CustomUploader
                                            className='form-tooltip'
                                            name='file'
                                            id='file'
                                            label={getTranslation(
                                                'logotipo_imagen',
                                            )}
                                            value={formValue.file}
                                            onChange={onChangeFile}
                                            inputId='uploader-input-brand-advertiser'
                                            showTooltips={true}
                                            required
                                            tooltipTitle='Tamaño menor a 2MB Formato PGN, JPG o GIF Medida 400 x 400 pixels'
                                        />
                                    </div>
                                </div>
                            </Form>

                            <div className='footer'>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    onClick={() => setShowModal(false)}
                                    size='small'>
                                    {getTranslation('cancelar')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onSave}>
                                    {getTranslation('guardar')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}
