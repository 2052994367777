interface IProps {
    data: any
    title: string
    textFooter: string
    typeData?: string
}

export const GeneralBoxContainer = ({ ...props }: IProps) => {
    const { data, title, textFooter, typeData } = props
    return (
        <div className='box-container'>
            <div className='box-container-addon-bar'></div>
            <div>
                <div className='box-container-header'>
                    <div className='box-container-title'>{title}</div>
                </div>
                <div className='box-container-body'>
                    <div className='box-container-data'>
                        {data ? (
                            <span>
                                {data}
                                {typeData}
                            </span>
                        ) : (
                            <span className='data-empty'>
                                {data}
                                {typeData}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='box-container-footer'>
                <div className='box-container-divisor'></div>
                <div className='box-container-footer-text'>{textFooter}</div>
            </div>
        </div>
    )
}
