import * as React from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'
import { DASHBOARD } from '../../config/Path'
import useAuthContext from '../../hooks/useAuthContext'
interface PublicRouteParams extends RouteProps {
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>
}

export function PublicRoute({
    component: Component,
    ...props
}: PublicRouteParams) {
    const { isAuthenticated } = useAuthContext()
    return (
        <Route
            {...props}
            render={(props) =>
                !isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={DASHBOARD} />
                )
            }
        />
    )
}
