import { Button, Modal, Form, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Language } from '../../../config/Language'
import { useAxios } from '../../../hooks/useAxios'
import useBreadCrumbContext from '../../../hooks/useBreadCrumbContext'
import { IAccountGateway } from '../../../interface/IAccountGateway'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { Notifications } from '../../common/notifications/Notifications'
import { ShowImage } from '../../common/show-img/ShowImage'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    id: string
    accountData: IAccountGateway | null
    setAccountData: (data: any) => void
    setAccountDataSpin: (bool: boolean) => void
}

export const AccountDetail = ({ ...props }: IProps) => {
    const { id, accountData, setAccountDataSpin, setAccountData } = props
    const { getTranslation } = Language()
    const [form] = Form.useForm()
    const { get, put } = useAxios()
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { successObject, errorObjectWithData } = Notifications()
    const [showModal, setShowModal] = useState<any>(false)
    const [formValue, setFormValue] = useState<any>()
    const [saveSpin, setSaveSpin] = useState(false)

    useEffect(() => {
        loadAdvertiserData()
        return () => {}
    }, [])

    const onChange = (values: any) => {
        let newFormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const loadAdvertiserData = () => {
        setAccountDataSpin(true)
        get(
            ENDPOINTS.GET_ACCOUNT.replace('{account}', id),
            {},
            successLoad,
            error,
            alwaysLoad,
        )
    }

    const successLoad = (response: any) => {
        setAccountDataSpin(false)
        updateBreadCrumb(response.data.data.name)
        let newFormValue = { ...formValue }
        newFormValue.discount = response.data.data.discount
        setFormValue(newFormValue)
        form.setFieldsValue(newFormValue)
        setAccountData(response.data.data)
    }

    const alwaysLoad = () => {
        setAccountDataSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result: any = {
            name: accountData?.name,
        }
        if (formValue?.discount) {
            result.discount = formValue?.discount
        } else {
            result.discount = 0
        }
        accountData?.id &&
            put(
                ENDPOINTS.PUT_ACCOUNT.replace(
                    '{account}',
                    accountData?.id.toString(),
                ),
                result,
                successSave,
                error,
                alwaysSave,
            )
    }

    const successSave = (response: any) => {
        setSaveSpin(false)
        setShowModal(false)
        loadAdvertiserData()
        successObject(response)
    }

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    return (
        <>
            <div className='account box'>
                <div className='header'>
                    {getTranslation('advertiser')}
                    <Button
                        size='small'
                        onClick={() => setShowModal(true)}
                        type='primary'>
                        {getTranslation('edit_discont')}
                    </Button>
                </div>
                <div className='body'>
                    <div className='confirmation-data'>
                        <div className='logo-name'>
                            <ShowImage
                                url={accountData?.organization_logo}
                                alt={getTranslation('logo')}
                            />
                            <div className='description'>
                                {accountData?.name}
                            </div>
                        </div>
                        <div className='title-description'>
                            <div className='title'>
                                {getTranslation('categoria')}
                            </div>
                            <div className='description'>
                                {accountData?.subcategory.category.name}
                            </div>
                        </div>
                        <div className='title-description'>
                            <div className='title'>
                                {getTranslation('sub_categoria')}
                            </div>
                            <div className='description'>
                                {accountData?.subcategory.name}
                            </div>
                        </div>

                        {accountData?.discount && (
                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('discount')}
                                </div>
                                <div className='description'>
                                    {`${accountData.discount} %`}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                title={getTranslation('edit_discont')}
                visible={showModal}
                width={800}
                onCancel={() => setShowModal(false)}
                footer={null}>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={saveSpin}>
                    <div id='modal-advertiser'>
                        <div className='body'>
                            <div className='logo-name'>
                                <ShowImage
                                    url={accountData?.organization_logo}
                                    alt={getTranslation('logo')}
                                />
                                <div className='description'>
                                    {accountData?.name}
                                </div>
                            </div>
                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('categoria')}
                                </div>
                                <div className='description'>
                                    {accountData?.subcategory?.category?.name}
                                </div>
                            </div>
                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('sub_categoria')}
                                </div>
                                <div className='description'>
                                    {accountData?.subcategory?.name}
                                </div>
                            </div>
                            <Form
                                className='form-advertiser'
                                form={form}
                                layout='vertical'
                                scrollToFirstError
                                onValuesChange={onChange}
                                size='large'>
                                <div className='form-container'>
                                    <div className='form-body'>
                                        <CustomInput
                                            label={getTranslation(
                                                'discount_optional',
                                            )}
                                            name='discount'
                                            id='discount'
                                            type='number'
                                            maxNumber={100}
                                        />
                                    </div>
                                </div>
                            </Form>

                            <div className='footer'>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    onClick={() => setShowModal(false)}
                                    size='small'>
                                    {getTranslation('cancelar')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onSave}>
                                    {getTranslation('guardar')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}
