import { Form, Select, Spin, Empty } from 'antd'
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { useAxios } from '../../../hooks/useAxios'
import { Language } from '../../../config/Language'
import { Link } from 'react-router-dom'

interface IProps {
    id?: string | any
    label: string
    name: string
    varToShow?: any
    required?: boolean
    requiredMessage?: string
    className?: string | any
    placeholder?: string
    endpoint?: string
    data?: any
    value?: any
    disabled?: boolean
    help?: string
    groupBy?: string
    params?: any
    tooltipTitle?: any
    showTooltips?: any
    handleSuccess?: (data: any) => void
}

export const CustomSelectGroup = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        endpoint,
        value,
        handleSuccess,
        data,
        className,
        help,
        varToShow,
        tooltipTitle,
        showTooltips = false,
    } = props
    const { get } = useAxios()
    const [selectedValue, setSelectedValue] = useState<any>(null)
    const [localParams, setLocalParams] = useState<any>({})
    const [dataResponse, setDataResponse] = useState<any>([])
    const [localEndpoint, setLocalEndpoint] = useState<any>('')
    const [spinner, setSpinner] = useState<boolean>(false)

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: '#13023E' }} spin />
    )

    useEffect(() => {
        if (value && dataResponse.length) {
            setSelectedValue(value)
        }

        return () => {}
    }, [dataResponse])

    useEffect(() => {
        if (selectedValue !== value && dataResponse.length) {
            setSelectedValue(value)
        }
        if (endpoint !== localEndpoint) {
            setLocalEndpoint(endpoint)
            getData()
        }
        if (data) {
            getData()
        }
        return () => {}
    }, [value, endpoint, data])

    const onFocus = (event: any) => {
        if (event.target.autocomplete) {
            event.target.autocomplete = 'whatever'
        }
        getData()
    }

    const getData = () => {
        setSpinner(true)
        if (endpoint) {
            setDataResponse([])
            get(endpoint, localParams, success, error, always)
        } else if (data) {
            let result = data.reduce(
                (acc: any, item: any) => [
                    ...acc,
                    {
                        label:
                            item[varToShow] ||
                            item.label ||
                            item.name ||
                            item.full_name,
                        value: item.id || item.value,
                    },
                ],
                [],
            )
            setDataResponse(result)
            setSpinner(false)
        }
    }

    const success = useCallback((response: any) => {
        let dataResponse: any = []
        if (handleSuccess) {
            dataResponse = handleSuccess(response.data.data)
        } else {
            let data: any = response.data.data
            let result = data
            dataResponse = result.reduce(
                (acc: any, item: any) => [
                    ...acc,
                    {
                        label: item[varToShow] || item.name || item.full_name,
                        value: item.id,
                    },
                ],
                [],
            )
        }
        setDataResponse(dataResponse)
        setSpinner(false)
    }, [])

    const error = (errors: any) => {
        setSpinner(false)
    }

    const always = () => {
        // setSpinner(false)
    }

    const configTooltips = showTooltips
        ? {
              title: tooltipTitle,
              icon: <InfoCircleOutlined />,
          }
        : false

    let labelContainer = (
        <div className='label-container-help'>
            {label} {help && <Link to={help}>{getTranslation('help')}</Link>}
        </div>
    )
    return (
        <Form.Item
            id={id}
            name={name}
            label={labelContainer}
            className={className}
            valuePropName='option'
            rules={[
                {
                    required: required,
                    message: requiredMessage,
                },
            ]}
            tooltip={configTooltips}>
            <Select
                disabled={disabled}
                showSearch
                value={selectedValue}
                onFocus={onFocus}
                notFoundContent={
                    spinner ? (
                        <Spin
                            className='spin-select'
                            indicator={antIcon}
                            size='small'
                        />
                    ) : (
                        <Empty description={false} />
                    )
                }
                placeholder={getTranslation('seleccione')}
                allowClear
                filterOption={(input: any, option: any) => {
                    return (
                        option.label
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    )
                }}>
                {dataResponse &&
                    dataResponse.map((item: any) => (
                        <Select.OptGroup label={item.label} key={item.id}>
                            {item.cities.map((element: any) => (
                                <Select.Option
                                    key={element.value}
                                    value={element.value}>
                                    {element.label}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    ))}
            </Select>
        </Form.Item>
    )
}
