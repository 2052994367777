import { Form, Button } from 'antd'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { CustomTextArea } from '../../common/custom-textarea/CustomTextArea'

interface IProps {
    form: any
    onChange: (values: any) => void
    onSubmit: (values: any) => void
    openLogin: (bool: boolean) => void
    successBool: boolean
    errorBool: boolean
    error: any
}
export const Help = ({ ...props }: IProps) => {
    const {
        form,
        onChange,
        onSubmit,
        successBool,
        errorBool,
        openLogin,
        error,
    } = props

    let text =
        'Hemos recibido tu mensaje correctamente. En breve nos pondremos en contacto.'
    if (error.response) {
        if (error.response.status === 401)
            text =
                'Nombre de usuario o contraseña incorrectos, por favor vuelve a intentarlo.'
        if (error.response.status === 500) text = 'Error de servidor'
    }
    return (
        <div className='context'>
            <div className='logo'></div>
            <div className='title'>Ayuda</div>
            {errorBool && (
                <div className='errors'>
                    <div className='icon'>
                        <CloseCircleOutlined />
                    </div>
                    <div className='text'>{text}</div>
                </div>
            )}
            {successBool ? (
                <div className='success'>
                    <div className='icon'>
                        <CheckCircleOutlined />
                    </div>
                    <div className='text'>{text}</div>
                </div>
            ) : (
                <div className='subtitle'>Déjanos tu mensaje.</div>
            )}
            <Form
                // {...formItemLayout}
                layout='vertical'
                form={form}
                onValuesChange={onChange}
                onFinish={onSubmit}
                scrollToFirstError>
                {!successBool && (
                    <>
                        <CustomInput
                            name='helpEmail'
                            type='email'
                            label='Email'
                            required
                        />
                        <CustomTextArea
                            name='mensaje'
                            label='Mensaje'
                            className='form-textarea'
                            required
                        />
                    </>
                )}
                <Form.Item>
                    <div className='buttons-container'>
                        <a onClick={() => openLogin(true)} type='primary'>
                            Ir al Inicio de sesión
                        </a>
                        {!successBool ? (
                            <Button
                                htmlType='submit'
                                size='small'
                                type='primary'>
                                Enviar
                            </Button>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}
