import { useState } from 'react'
import { NavBar } from './NavBar'
import { SideBar } from './SideBar'

interface IProps {
    setLocaleSpin: (bool: boolean) => void
}

export const MainMenu = ({ ...props }: IProps) => {
    const [toggle, setToggle] = useState(false)
    const { setLocaleSpin } = props
    return (
        <div id='mainmenu'>
            <NavBar
                setLocaleSpin={setLocaleSpin}
                toggle={toggle}
                setToggle={setToggle}
            />
            <SideBar toggle={toggle} setToggle={setToggle} />
        </div>
    )
}
