import { Column } from '@ant-design/charts'
import { Empty } from 'antd'
import { Language } from '../../../config/Language'

interface IProps {
    data: any[]
}

export const CustomColumnChart = ({ ...props }: IProps) => {
    const { data } = props
    const { getTranslation } = Language()

    var config: any = {
        data: data,
        xField: 'name',
        yField: 'value',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            type: { alias: '类别' },
            sales: { alias: '销售额' },
        },
    }
    return (
        <>
            {data.length > 0 ? (
                <div style={{ height: '400px' }}>
                    <Column {...config} />
                </div>
            ) : (
                <div className='data-ant-empty'>
                    <Empty description={getTranslation('sin_datos')} />
                </div>
            )}
        </>
    )
}
