import { useRef, useState, useEffect } from 'react'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { IFilterDataProposal } from '../../../interface/IFilterDataProposal'
interface IProps {
    filterData: any
}

export const SliderFilters = ({ ...props }: IProps) => {
    const { filterData } = props
    const listRef = useRef(null)
    const [data, setData] = useState<any[]>([])

    useEffect(() => {
        formatData()
        return () => {}
    }, [filterData])

    const formatData = () => {
        let data: any[] = []
        for (const key in filterData) {
            if (Object.prototype.hasOwnProperty.call(filterData, key)) {
                const element: any = filterData[key]
                if (
                    Array.isArray(element.description) &&
                    element.description.length > 0
                ) {
                    let aux = ''
                    element.description.map((item: any, index: any) => {
                        if (index === element.description.length - 1) {
                            aux = aux + `${item}`
                        } else {
                            aux = aux + `${item}, `
                        }
                    })
                    let item = {
                        id: element.title,
                        label: aux,
                    }
                    data.push(item)
                } else if (
                    typeof element.description === 'string' ||
                    typeof element.description === 'number'
                ) {
                    let item = {
                        id: element.title,
                        label: element.description,
                    }
                    data.push(item)
                }
            }
        }

        setData(data)
    }

    const scrollLeft = () => {
        if (listRef.current) {
            // @ts-ignore
            listRef.current?.scrollBy({
                top: 0,
                left: 200,
                behavior: 'smooth',
            })
            // @ts-ignore
        } else return
    }

    const scrollRight = () => {
        if (listRef.current) {
            // @ts-ignore
            listRef.current?.scrollBy({
                top: 0,
                left: -200,
                behavior: 'smooth',
            })
        } else return
    }

    return (
        <div id='slide-container'>
            <div className='left'>
                <Button
                    shape='circle'
                    size='small'
                    onClick={scrollRight}
                    icon={<LeftOutlined />}
                />
            </div>

            {data.length > 0 && (
                <div className='items-container' ref={listRef}>
                    {Array.isArray(data) &&
                        data.map((item: any, index: any) => {
                            let tag = item.id + ': ' + item.label + ' '
                            return (
                                <div key={index} className='tag-item'>
                                    {tag}
                                </div>
                            )
                        })}
                </div>
            )}
            <div className='right'>
                <Button
                    shape='circle'
                    size='small'
                    onClick={scrollLeft}
                    icon={<RightOutlined />}
                />
            </div>
        </div>
    )
}
