import { Form, Button, Divider, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Functions } from '../../../config/Functions'
import { Language } from '../../../config/Language'
import {} from '../../../config/Path'
import { useAxios } from '../../../hooks/useAxios'
import useCountryContext from '../../../hooks/useCountryContext'
import { ICountries } from '../../../interface/ICountries'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomSelect } from '../../common/custom-select/CustomSelect'
import { CustomUploader } from '../../common/custom-uploader/CustomUploader'
import { Notifications } from '../../common/notifications/Notifications'
import { ShowImage } from '../../common/show-img/ShowImage'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    next: () => void
    skip: () => void
    setGlobalData: (formValue: {}) => void
    globalData: any
}
interface FormValue {
    name: string
    fiscalNumber: any
    countryId: number | null
}

export const CompanyNamesForm = ({ ...props }: IProps) => {
    const { next, setGlobalData, skip, globalData } = props
    const [form] = Form.useForm()
    const history = useHistory()
    const { getTranslation, getLanguage } = Language()
    const [showConfirm, setShowConfirm] = useState(false)
    const [confirmSpin, setConfirmSpin] = useState(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const { country } = useCountryContext()
    const { successObject, errorObjectWithData } = Notifications()
    const { get, post } = useAxios()

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            name: formValue.name,
            fiscal_number: formValue.fiscalNumber.toString(),
            country_id: formValue.countryId,
            organization_id: globalData.organization_id,
        }
        return result
    }

    const onSave = (values: any) => {
        setShowConfirm(true)
    }

    const onConfirm = (values: any) => {
        setConfirmSpin(true)
        let result = getValues()
        post(
            ENDPOINTS.POST_COMPANY_NAMES,
            result,
            successSave,
            error,
            alwaysSave,
        )
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const successSave = (response: any) => {
        setConfirmSpin(false)
        successObject(response)
        setGlobalData({
            ...globalData,
            companyNames: formValue.name,
        })
        next()
    }

    const alwaysSave = () => {
        setConfirmSpin(false)
    }

    const handleBack = () => {
        setShowConfirm(false)
    }

    let countriesList: ICountries[] =
        country && Functions.getCountriesByLanguage(country)

    return (
        <>
            <Spin
                className='spin-local'
                indicator={antIcon}
                spinning={confirmSpin}>
                {!showConfirm && (
                    <Form
                        className='form-advertiser'
                        form={form}
                        layout='vertical'
                        scrollToFirstError
                        onValuesChange={onChange}
                        onFinish={onSave}
                        size='large'>
                        <div className='form-container'>
                            <div className='form-body'>
                                <CustomInput
                                    name='name'
                                    id='name'
                                    label={getTranslation('legal_name')}
                                    required={true}
                                />

                                <CustomSelect
                                    label={getTranslation('pais')}
                                    name='countryId'
                                    id='countryId'
                                    data={countriesList}
                                    value={formValue?.countryId}
                                    required={true}
                                />

                                <CustomInput
                                    label={getTranslation(
                                        'company_name_number',
                                    )}
                                    name='fiscalNumber'
                                    id='fiscalNumber'
                                    type='number'
                                    required={true}
                                />
                            </div>
                            <div className='buttons-footer'>
                                <>
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        ghost
                                        type='primary'
                                        size='small'
                                        onClick={skip}>
                                        {getTranslation('skip_this_step')}
                                    </Button>

                                    <Button
                                        type='primary'
                                        size='small'
                                        htmlType='submit'>
                                        {getTranslation('guardar')}
                                    </Button>
                                </>
                            </div>
                        </div>
                    </Form>
                )}
                {showConfirm && (
                    <div className='confirmation-advertiser'>
                        <div className='confirmation-data'>
                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('legal_name')}
                                </div>
                                <div className='description'>
                                    {formValue.name}
                                </div>
                            </div>
                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('pais')}
                                </div>
                                <div className='description'>
                                    {countriesList.map((item: any) => {
                                        if (item.value === formValue.countryId)
                                            return item.label
                                    })}
                                </div>
                            </div>

                            <div className='title-description'>
                                <div className='title'>
                                    {getTranslation('company_name_number')}
                                </div>
                                <div className='description'>
                                    {formValue.fiscalNumber}
                                </div>
                            </div>
                        </div>
                        <div className='buttons-footer'>
                            <>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    size='small'
                                    onClick={skip}>
                                    {getTranslation('skip_this_step')}
                                </Button>
                                <Divider
                                    type='vertical'
                                    style={{
                                        borderColor: '#E8E8E8',
                                        height: '1.5em',
                                    }}
                                />
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    size='small'
                                    onClick={handleBack}>
                                    {getTranslation('volver')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onConfirm}>
                                    {getTranslation('confirmar')}
                                </Button>
                            </>
                        </div>
                    </div>
                )}
            </Spin>
        </>
    )
}
