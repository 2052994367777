import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useParams } from 'react-router'
import { ENDPOINTS } from '../../config/EndPoints'
import { Language } from '../../config/Language'
import { useAxios } from '../../hooks/useAxios'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { IProposal } from '../../interface/IProposal'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { Abstract } from './abstract/Abstract'
import { AssetsMap } from './assets-map/AssetsMap'
import { AssetsProposalList } from './assets-proposal-list/AssetsProposalList'
import { AssetsStrategy } from './assets-strategy/AssetsStrategy'
import { SendProposalTabs } from './send-proposal-tabs/SendProposalTabs'

interface RouteParams {
    id: string
}

interface FormValue {
    production_cost: any
    installation_cost: any
    discount: any
}

export const SendProposal = () => {
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { get, put } = useAxios()
    const { getTranslation } = Language()
    const { errorObjectWithData, successObject } = Notifications()
    const [form] = Form.useForm()
    const [formValueCosts, setFormValueCosts] = useState<FormValue | any>({})
    const [proposalId, setProposalId] = useState<string>('')
    const [proposalData, setProposalData] = useState<IProposal | null>(null)
    const [proposalSpin, setProposalSpin] = useState(false)
    const [sendProposalSpin, setSendProposalSpin] = useState(false)
    const [selectionListSpin, setSelectionListSpin] = useState(false)
    const [tab, setTab] = useState('1')
    const [selectionList, setSelectionList] = useState<any[]>([])
    const [totalElements, setTotalElements] = useState()
    const [assetsIds, setAssetsIds] = useState<number[]>()
    const [costProposalData, setCostProposalData] = useState()
    const [costProposalspin, setCostProposalSpin] = useState(false)
    const [page, setPage] = useState<any>({
        page: 1,
        per_page: 10,
    })

    let { id } = useParams<RouteParams>()
    if (id && id !== proposalId) {
        setProposalId(id)
    }

    useEffect(() => {
        if (proposalId !== '') {
            loadProposal()
            loadCostProposal()
        } else {
            updateBreadCrumb(getTranslation('send-proposal'))
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (proposalData !== null) {
            loadAssetsList()
            let newFormValue: FormValue | any = {
                discount: proposalData.discount
                    ? proposalData.discount
                    : proposalData?.brief?.account?.discount,
                production_cost: proposalData?.production_cost,
                installation_cost: proposalData?.installation_cost,
            }
            form.setFieldsValue(newFormValue)
            setFormValueCosts(newFormValue)
        }
    }, [proposalData, page])

    const onChangeCosts = (values: any) => {
        let newFormValue: FormValue = { ...formValueCosts, ...values }
        setFormValueCosts(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            discount: formValueCosts?.discount,
            production_cost: formValueCosts?.production_cost,
            installation_cost: formValueCosts?.installation_cost,
        }
        return result
    }

    const loadProposal = () => {
        setProposalSpin(true)
        get(
            ENDPOINTS.GET_PROPOSAL.replace('{proposal}', proposalId),
            {},
            success,
            error,
            always,
        )
    }

    const success = (response: any) => {
        setProposalSpin(false)
        updateBreadCrumb(response.data.data.name)
        setProposalData(response.data.data)
        let assetsIds: number[] = []
        let data = response.data.data
        data.element_proposals.map((item: any, idex: any) => {
            assetsIds.push(item.element_id)
        })
        setAssetsIds(assetsIds)
    }

    const always = () => {
        setProposalSpin(false)
    }

    const loadCostProposal = () => {
        setCostProposalSpin(true)
        get(
            ENDPOINTS.GET_PROPOSAL_COSTS.replace('{proposal}', proposalId),
            {},
            successCost,
            error,
            alwaysCost,
        )
    }

    const successCost = (response: any) => {
        setCostProposalSpin(false)
        setCostProposalData(response.data)
    }

    const alwaysCost = () => {
        setCostProposalSpin(false)
    }

    const loadAssetsList = () => {
        setSelectionListSpin(true)
        proposalData &&
            get(
                ENDPOINTS.GET_ELEMENT_PROPOSALS.replace(
                    '{proposal}',
                    proposalData?.id.toString(),
                ),
                { ...page },
                successAssetsList,
                error,
                alwaysAssetsList,
            )
    }

    const successAssetsList = (response: any) => {
        setSelectionListSpin(false)
        response.data.data.forEach((element: any) => {
            element.key = element.id
        })
        setTotalElements(response.data.meta.total)
        setSelectionList(response.data.data)
    }

    const alwaysAssetsList = () => {
        setSelectionListSpin(false)
    }

    const handleSendProposal = () => {
        setSendProposalSpin(true)
        let result = getValues()
        // Aca va el endpoint para enviar propuesta pero por el momento solo se guardan los costos y el descuento
        put(
            ENDPOINTS.PUT_PROPOSAL_COSTS.replace(
                '{proposal}',
                proposalData?.id ? proposalData?.id.toString() : '',
            ),
            result,
            successSend,
            error,
            alwaysSend,
        )
    }

    const successSend = (response: any) => {
        setSendProposalSpin(false)
        successObject(response)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const alwaysSend = () => {
        setSendProposalSpin(false)
    }

    return (
        <Spin
            className='spin-general'
            indicator={antIcon}
            spinning={proposalSpin || costProposalspin}>
            <div id='send-proposal'>
                <SendProposalTabs
                    tab={tab}
                    sendProposalSpin={sendProposalSpin}
                    handleSendProposal={handleSendProposal}
                    setTab={setTab}
                    data={proposalData}
                />

                <div className='body-container'>
                    {tab === '1' && (
                        <Abstract
                            formCosts={form}
                            onChangeCosts={onChangeCosts}
                            selectionList={selectionList}
                            data={proposalData}
                            loadProposal={loadProposal}
                            formValueCosts={formValueCosts}
                            costProposalData={costProposalData}
                        />
                    )}
                    {tab === '2' && (
                        <AssetsProposalList
                            page={page}
                            setPage={setPage}
                            totalElements={totalElements}
                            selectionList={selectionList}
                            selectionListSpin={selectionListSpin}
                        />
                    )}
                    {tab === '3' && <AssetsMap assetsIds={assetsIds} />}
                    {tab === '4' && <AssetsStrategy data={proposalData} />}
                </div>
            </div>
        </Spin>
    )
}
