import React from 'react'
import { Link } from 'react-router-dom'
import { Language } from '../../../config/Language'
import { HELP } from '../../../config/Path'
import { Slider } from 'antd'
import { CustomSlider } from '../../common/custom-slider/CustomSlider'

export const SliderContainer = ({ ...props }) => {
    const { showTooltipSlider } = props
    const { getTranslation } = Language()

    return (
        <div className='slider-brief-container'>
            <div className='title'>
                {getTranslation('action_to_taken')}
                <div className='help-text'>
                    <Link to={HELP}>{getTranslation('help')}</Link>
                </div>
            </div>
            <div className='body'>
                <CustomSlider
                    label={getTranslation('make_impact')}
                    name='impact'
                    id='impact'
                    min={0}
                    max={10}
                    step={1}
                    tooltipVisible={showTooltipSlider}
                    defaultValue={5}
                />
                <CustomSlider
                    label={getTranslation('segment')}
                    name='segmentation'
                    id='segmentation'
                    min={0}
                    max={10}
                    step={1}
                    tooltipVisible={showTooltipSlider}
                    defaultValue={5}
                />
                <CustomSlider
                    label={getTranslation('search_coverage')}
                    name='coverage'
                    id='coverage'
                    min={0}
                    max={10}
                    step={1}
                    tooltipVisible={showTooltipSlider}
                    defaultValue={5}
                />
                <CustomSlider
                    label={getTranslation('search_frequency')}
                    name='frecuency'
                    id='frecuency'
                    min={0}
                    max={10}
                    step={1}
                    tooltipVisible={showTooltipSlider}
                    defaultValue={5}
                />
            </div>
        </div>
    )
}
