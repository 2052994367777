import { Table, Tooltip, Typography } from 'antd'
import { Functions } from '../../../config/Functions'
import { Language } from '../../../config/Language'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    selectionListSpin: boolean
    page: any
    selectionList: any[]
    totalElements: any
    setPage: ({}) => void
}

const { Text } = Typography

export const AssetsProposalList = ({ ...props }: IProps) => {
    const { selectionList, page, totalElements, selectionListSpin, setPage } =
        props
    const { getTranslationObject, getTranslation } = Language()

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setPage({
            page: pagination.current,
            per_page: pagination.pageSize,
        })
    }

    const columns: any[] = [
        {
            title: getTranslation('medio'),
            dataIndex: 'vendor',
            key: 'vendor',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element.vendor.name,
                    b.element.vendor.name,
                ),
            render: (vendor: any, item: any) => (
                <Tooltip placement='topLeft' title={item.element.vendor.name}>
                    {item.element.vendor.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('material'),
            dataIndex: 'material',
            key: 'material',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element.material.name,
                    b.element.material.name,
                ),
            render: (material: any, item: any) => (
                <Tooltip placement='topLeft' title={item.element.material.name}>
                    {item.element.material.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('entorno'),
            dataIndex: 'geopath_clasification',
            key: 'geopath_clasification',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element.geopath_clasification.name,
                    b.element.geopath_clasification.name,
                ),
            render: (geopath_clasification: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={item.element.geopath_clasification.name}>
                    {item.element.geopath_clasification.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('tipo'),
            dataIndex: 'asset_type',
            key: 'asset_type',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element.asset_type.name,
                    b.element.asset_type.name,
                ),
            render: (asset_type: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={item.element.asset_type.name}>
                    {item.element.asset_type.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('quantity'),
            dataIndex: 'cant',
            key: 'cant',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) => Functions.sortByText(1, 1),
            render: (cant: any, item: any) => (
                // <Tooltip placement='topLeft' title={item.element.cant}>
                //     {item.element.cant}
                // </Tooltip>
                <Tooltip placement='topLeft' title={1}>
                    <div className='quantity'>1</div>
                </Tooltip>
            ),
        },
        {
            title: getTranslation('inicio'),
            dataIndex: 'start_date',
            key: 'start_date',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.start_date, b.start_date),
            render: (cant: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={Functions.formatDateDayMonthYear(item.start_date)}>
                    {Functions.formatDateDayMonthYear(item.start_date)}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('finalizacion'),
            dataIndex: 'end_date',
            key: 'end_date',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.end_date, b.end_date),
            render: (cant: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={Functions.formatDateDayMonthYear(item.end_date)}>
                    {Functions.formatDateDayMonthYear(item.end_date)}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('total_OTS'),
            dataIndex: 'ots',
            key: 'ots',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.element.ots, b.element.ots),
            render: (ots: any, item: any) => (
                <Tooltip placement='topLeft' title={item.element.ots}>
                    {item.element.ots}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('price'),
            dataIndex: 'price',
            key: 'price',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.element.price, b.element.price),
            render: (price: any, item: any) => (
                <Tooltip placement='topLeft' title={item.element.price}>
                    {item.element.price}
                </Tooltip>
            ),
        },
    ]

    return (
        <div className='assets-list-container'>
            <Table
                sticky
                bordered
                loading={{
                    spinning: selectionListSpin,
                    indicator: antIcon,
                }}
                locale={getTranslationObject('table_trad')}
                pagination={{
                    current: page.page,
                    pageSize: page.per_page,
                    showSizeChanger: true,
                    total: totalElements,
                    showTotal: (total: any, range: any) =>
                        `${range[0]}-${range[1]} ${getTranslation(
                            'of',
                        )} ${total} ${getTranslation('items')}`,
                }}
                scroll={{ x: 800, y: 430 }}
                columns={columns}
                dataSource={selectionList}
                onChange={handleTableChange}
                summary={(pageData) => {
                    let cant = 0
                    let totalOTS = 0
                    let totalPrice = 0

                    pageData.forEach((item: any) => {
                        cant += 1
                        totalOTS += item.element.ots
                        totalPrice += item.element.price
                    })

                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row className='summary-table'>
                                <Table.Summary.Cell index={0} colSpan={4}>
                                    <Text>{getTranslation('totals')}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell
                                    className='summary-table-cant'
                                    index={1}>
                                    <Text>{cant}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell
                                    index={2}
                                    colSpan={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>
                                    <Text>{totalOTS}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>
                                    <Text>{totalPrice}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }}
            />
        </div>
    )
}
