import {
    PhoneTwoTone,
    MailTwoTone,
    StarTwoTone,
    VideoCameraTwoTone,
} from '@ant-design/icons'

const IconsByActivityType = (type: number) => {
    const list = [
        { type: 1, icon: <MailTwoTone /> },
        { type: 2, icon: <StarTwoTone /> },
        { type: 3, icon: <StarTwoTone /> },
        { type: 4, icon: <PhoneTwoTone /> },
        { type: 5, icon: <StarTwoTone /> },
        { type: 6, icon: <VideoCameraTwoTone /> },
        { type: 7, icon: <StarTwoTone /> },
    ]
    let result = list.find((item: any) => item.type === type)

    return result?.icon
}

export default IconsByActivityType
