export const NOT_FOUND: string = '/*'
export const DASHBOARD: string = '/dashboard'
export const LOGOUT: string = '/logout'
export const LOGIN: string = '/login'
export const PROFILE: string = '/profile'
export const PAGE403: string = '/unauthorized'
export const BRIEFS: string = '/briefs'
export const BRIEFS_FORM__WITH_PARAMS: string = '/briefs/briefs-form/:id'
export const BRIEFS_FORM: string = '/briefs/briefs-form'
export const CAMPAIGNS: string = '/campaigns'
export const PROPOSAL: string = '/proposals'
export const PROPOSAL_DETAIL__WITH_PARAMS: string =
    '/proposals/proposals-detail/:id'
export const PROPOSAL_DETAIL: string = '/proposals/proposals-detail'
export const SEND_PROPOSAL__WITH_PARAMS: string = '/proposals/send-proposal/:id'
export const SEND_PROPOSAL: string = '/proposals/send-proposal'
export const PURCHASE_ORDERS: string = '/purchase-orders'
export const COPY: string = '/copy'
export const CREATIVES: string = '/creatives'
export const IMPLEMENTATION: string = '/implementation'
export const CHECKING: string = '/checking'
export const ACCOUNTS: string = '/accounts'
export const VENDORS: string = '/vendors'
export const ACTIVITY: string = '/activities'
export const NEW_BRAND: string = '/accounts/brand'
export const NEW_BRAND__WITH_PARAMS: string = '/accounts/brand/:id'
export const NEW_ORGANIZATION: string = '/accounts/organization'
export const NEW_ORGANIZATION__WITH_PARAMS: string =
    '/accounts/organization/:id'
export const NEW_VENDOR: string = '/vendors/vendor-details'
export const NEW_VENDOR__WITH_PARAMS: string = '/vendors/vendor-details/:id'
export const NEW_ACTIVITY: string = '/activities/activity'
export const NEW_ACTIVITY__WITH_PARAMS: string = '/activities/activity/:id'
export const USERS: string = '/users'
export const BRIEFS_FORM_ALONG: string = '/briefs-form'
export const METRICS: string = '/metrics'
export const ADVERTISERS: string = '/advertisers'
export const NEW_ADVERTISERS: string = '/advertisers/new-advertisers'
export const ADD_ADVERTISERS: string = '/advertisers/add-advertisers'
export const PREVIEW_ADVERTISERS: string = '/advertisers/preview-advertisers'
export const PREVIEW_ADVERTISERS__WITH_PARAMS: string =
    '/advertisers/preview-advertisers/:id'
export const ADVERTISERS_DETAIL: string = '/advertisers/detail'
export const ADVERTISERS_DETAIL__WITH_PARAMS: string = '/advertisers/detail/:id'
export const HELP: string = '/help'
