import { Form, Select, Spin, Empty } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { useAxios } from '../../../hooks/useAxios'
import { Language } from '../../../config/Language'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Link } from 'react-router-dom'

interface IProps {
    id?: string | any
    label: string
    name: string
    varToShow?: any
    className?: string | any
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    endpoint?: string
    data?: any
    value?: any
    disabled?: boolean
    groupBy?: string
    params?: any
    help?: string
    mode?: any
    type?: any
    size?: SizeType
    handleSuccess?: (data: any) => void
    getValuesList?: (valuesList: {}) => void
}

export const CustomSelectMultiple = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        endpoint,
        value,
        mode = '',
        handleSuccess,
        className,
        data,
        help,
        varToShow,
        size = 'large',
        getValuesList,
    } = props
    const { get } = useAxios()

    const [selectedValue, setSelectedValue] = useState<any>([])
    const [localParams, setLocalParams] = useState<any>({})
    const [dataResponse, setDataResponse] = useState<any>([])
    const [localEndpoint, setLocalEndpoint] = useState<any>('')
    const [spinner, setSpinner] = useState<boolean>(false)

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: '#13023E' }} spin />
    )

    useEffect(() => {
        if (value && dataResponse.length) {
            setSelectedValue(value)
        }

        return () => {}
    }, [dataResponse])

    useEffect(() => {
        if (selectedValue !== value && dataResponse.length) {
            setSelectedValue(value)
        }
        if (endpoint !== localEndpoint) {
            setLocalEndpoint(endpoint)
            getData()
        }
        if (data) {
            getData()
        }
        return () => {}
    }, [value, endpoint, data])

    const onFocus = (event: any) => {
        if (event.target.autocomplete) {
            event.target.autocomplete = 'whatever'
        }
        getData()
    }

    const getData = () => {
        setSpinner(true)
        if (endpoint) {
            setDataResponse([])
            get(endpoint, localParams, success, error, always)
        } else if (data) {
            let result = data.reduce(
                (acc: any, item: any) => [
                    ...acc,
                    {
                        label:
                            item[varToShow] ||
                            item.name ||
                            item.full_name ||
                            item.alias_name,
                        value: item.id,
                    },
                ],
                [],
            )
            setDataResponse(result)
            setSpinner(false)
        }
    }

    const success = useCallback((response: any) => {
        let dataResponse: any = []
        if (handleSuccess) {
            dataResponse = handleSuccess(response.data.data)
        } else {
            let data: any = response.data.data
            let result = data
            dataResponse = result.reduce(
                (acc: any, item: any) => [
                    ...acc,
                    {
                        label: item[varToShow] || item.name || item.full_name,
                        value: item.id,
                    },
                ],
                [],
            )
        }
        setDataResponse(dataResponse)
        setSpinner(false)
    }, [])

    const error = (errors: any) => {
        setSpinner(false)
    }

    const always = () => {
        // setSpinner(false)
    }
    const onChange = (value: any[], item: any) => {
        let text = ''
        item.map((element: any, index: any) => {
            let data = element.label
            if (index === item.length - 1) {
                text += data
            } else {
                text += data + ', '
            }
        })
        return getValuesList && getValuesList({ id: label, label: text })
    }
    let labelContainer = (
        <div className='label-container-help'>
            {label} {help && <Link to={help}>{getTranslation('help')}</Link>}
        </div>
    )
    return (
        <Form.Item
            id={id}
            className={className}
            name={name}
            label={labelContainer}
            valuePropName='option'
            rules={[
                {
                    required: required,
                    message: requiredMessage,
                },
            ]}>
            <Select
                size={size}
                mode={mode}
                style={{ width: '100%' }}
                disabled={disabled}
                showSearch
                value={selectedValue}
                onFocus={onFocus}
                onChange={onChange}
                notFoundContent={
                    spinner ? (
                        <Spin
                            className='spin-select'
                            indicator={antIcon}
                            size='small'
                        />
                    ) : (
                        <Empty description={false} />
                    )
                }
                placeholder={getTranslation('seleccione')}
                allowClear
                maxTagCount={'responsive' as const}
                options={dataResponse}
                filterOption={(input: any, option: any) =>
                    option.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }></Select>
        </Form.Item>
    )
}
