import { Language } from '../../../config/Language'

export const CreateIconsList = () => {
    const { getTranslation } = Language()

    let textList = [
        {
            name: 'mural',
            label: getTranslation('MU'),
            postion: { x: '-9.637', y: 0 },
        },
        {
            name: 'cartelera',
            label: getTranslation('CA'),
            postion: { x: '-8.137', y: 0 },
        },
        {
            name: 'poster',
            label: getTranslation('PO'),
            postion: { x: '-8.014', y: 0 },
        },
        {
            name: 'posterPequeno',
            label: getTranslation('PP'),
            postion: { x: '-7.014', y: 0 },
        },
        {
            name: 'panel',
            label: getTranslation('PA'),
            postion: { x: '-7.014', y: 0 },
        },
        {
            name: 'display',
            label: getTranslation('DI'),
            postion: { x: '-6.137', y: 0 },
        },
    ]
    let materialList = [
        {
            name: 'digital',
            fill: '%23F9F0FF',
            stroke: '%23722ED1',
        },
        {
            name: 'impreso',
            fill: '%23fffbe6',
            stroke: '%23ad4e00',
        },
    ]
    let stats = ['default', 'selected']

    let entorno = [
        {
            name: 'viaPublica',
            strokeType: 'none',
        },
        {
            name: 'establecimiento',
            strokeType: '2 4.5',
        },
    ]

    let ostSize = [
        {
            size: 'base',
            width: '24',
            height: '30',
            viewBox: '0 0 24 30',
            groupTranslate: 'translate(-937 -90)',
            markerTranslate: 'translate(947 116)',
            circleDetail: {
                translate: 'translate(937 90)',
                bg: { cx: '12', cy: '12', r: '12' },
                stroke: { cx: '12', cy: '12', r: '11.6' },
            },
            text: {
                textTranlate: 'translate(949 106)',
            },
        },
        {
            size: 'xs',
            width: '32',
            height: '38',
            viewBox: '0 0 32 38',
            groupTranslate: 'translate(-1197 -89)',
            markerTranslate: 'translate(1211 123)',
            circleDetail: {
                translate: 'translate(1197 89)',
                bg: { cx: '16', cy: '16', r: '16' },
                stroke: { cx: '16', cy: '16', r: '15.6' },
            },
            text: {
                textTranlate: 'translate(1213 109)',
            },
        },
        {
            size: 'sm',
            width: '40',
            height: '46',
            viewBox: '0 0 40 46',
            groupTranslate: 'translate(-1259 -89)',
            markerTranslate: 'translate(1277 131)',
            circleDetail: {
                translate: 'translate(1259 89)',
                bg: { cx: '20', cy: '20', r: '20' },
                stroke: { cx: '20', cy: '20', r: '19.6' },
            },
            text: {
                textTranlate: 'translate(1279 113)',
            },
        },
        {
            size: 'md',
            width: '48',
            height: '54',
            viewBox: '0 0 48 54',
            groupTranslate: 'translate(-1327 -89)',
            markerTranslate: 'translate(1349 139)',
            circleDetail: {
                translate: 'translate(1327 89)',
                bg: { cx: '24', cy: '24', r: '24' },
                stroke: { cx: '24', cy: '24', r: '23.6' },
            },
            text: {
                textTranlate: 'translate(1351 117)',
            },
        },
        {
            size: 'lg',
            width: '56',
            height: '62',
            viewBox: '0 0 56 62',
            groupTranslate: 'translate(-1400 -89)',
            markerTranslate: 'translate(1426 147)',
            circleDetail: {
                translate: 'translate(1400 89)',
                bg: { cx: '28', cy: '28', r: '28' },
                stroke: { cx: '28', cy: '28', r: '27.6' },
            },
            text: {
                textTranlate: 'translate(1428 121)',
            },
        },
    ]

    const customSvg = (
        bgColor: string,
        strokeColor: string,
        markerColor: string,
        text: any,
        svgSize: any,
        strokeType: string,
    ) => {
        let svg = `<svg xmlns='http://www.w3.org/2000/svg' width='${svgSize.width}' height='${svgSize.height}' viewBox='${svgSize.viewBox}'><g transform='${svgSize.groupTranslate}'><circle cx='2' cy='2' r='2' transform='${svgSize.markerTranslate}' fill='${markerColor}'/><g transform='${svgSize.circleDetail.translate}' fill='${bgColor}' stroke='${strokeColor}' stroke-dasharray='${strokeType}'><circle cx='${svgSize.circleDetail.bg.cx}' cy='${svgSize.circleDetail.bg.cy}' r='${svgSize.circleDetail.bg.r}' stroke='none'/><circle cx='${svgSize.circleDetail.stroke.cx}' cy='${svgSize.circleDetail.stroke.cy}' r='${svgSize.circleDetail.stroke.r}' fill='none'/></g><text transform='${svgSize.text.textTranlate}' fill='${strokeColor}' font-size='12px' font-family='SegoeUI-Semibold, Segoe UI' font-weight='600'><tspan x='${text.postion.x}' y='${text.postion.y}'>${text.label}</tspan></text></g></svg>`
        return svg
    }

    let result: any[] = []
    textList.forEach((text) => {
        materialList.forEach((material) => {
            entorno.forEach((entorno) => {
                ostSize.forEach((ots) => {
                    stats.forEach((stats) => {
                        let fillColor = material.fill
                        let strokeColor = material.stroke
                        if (stats !== 'default') {
                            fillColor = material.stroke
                            strokeColor = material.fill
                        }
                        result.push({
                            svg: customSvg(
                                fillColor,
                                strokeColor,
                                material.stroke,
                                text,
                                ots,
                                entorno.strokeType,
                            ),
                            width: ots.width,
                            height: ots.height,
                            name: `${text.name}_${material.name}_${entorno.name}_${ots.size}_${stats}`,
                        })
                    })
                })
            })
        })
    })
    return result
}

export const IconsPois = (id: any, size: number) => {
    const { getTranslation } = Language()
    let color = ''
    let tooltipText = ''

    if (parseInt(id) === 19) {
        color = '#FFC069'
        tooltipText = getTranslation('subCategoryFoodBeverages')
    } else if (parseInt(id) === 20) {
        color = '#FF9C6E'
        tooltipText = getTranslation('subCategoryVehicles')
    } else if (parseInt(id) === 21) {
        color = '#85A5F9'
        tooltipText = getTranslation('subCategoryGoverment')
    } else if (parseInt(id) === 22) {
        color = '#FFD666'
        tooltipText = getTranslation('subCategoryEducation')
    } else if (parseInt(id) === 23) {
        color = '#B37FEB'
        tooltipText = getTranslation('subCategoryEntertainment')
    } else if (parseInt(id) === 24) {
        color = '#D3F261'
        tooltipText = getTranslation('subCategoryFinance')
    } else if (parseInt(id) === 25) {
        color = '#95DE64'
        tooltipText = getTranslation('subCategoryParks')
    } else if (parseInt(id) === 26) {
        color = '#FE7875'
        tooltipText = getTranslation('subCategoryHealth')
    } else if (parseInt(id) === 27) {
        color = '#FF85C0'
        tooltipText = getTranslation('subCategoryShops')
    } else if (parseInt(id) === 28) {
        color = '#FFF566'
        tooltipText = getTranslation('subCategoryTransportation')
    } else if (parseInt(id) === 29) {
        color = '#5CDBD3'
        tooltipText = getTranslation('subCategoryTurism')
    }

    let icon = (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={size}
            height={size}
            viewBox='0 0 16 16'>
            <path
                className='a'
                style={{ fill: color }}
                d='M6.211,3.578a2,2,0,0,1,3.578,0l4.764,9.528A2,2,0,0,1,12.764,16H3.236a2,2,0,0,1-1.789-2.894Z'
                transform='translate(16 16) rotate(180)'
            />
        </svg>
    )

    return { icon, tooltipText }
}
