import { Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { ActivityForm } from './ActivityForm'
import { Language } from '../../config/Language'

interface RouteParams {
    id: string
}

export const ActivityDetail = () => {
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { get } = useAxios()
    const { errorObjectWithData } = Notifications()
    const { getTranslation } = Language()
    const [generalSpin, setGeneralSpin] = useState(false)
    const [activitySpin, setActivitySpin] = useState(false)
    const [executiveSpin, setExecutiveSpin] = useState(false)

    const [activityId, setActivityId] = useState<number | any>('')
    const [activity, setActivity] = useState<any>(null)

    let { id } = useParams<RouteParams>()

    if (id && id !== activityId) {
        setActivityId(id)
    }

    const loadActivityData = () => {
        setActivitySpin(true)
        setGeneralSpin(true)
        get(
            ENDPOINTS.GET_ACTIVITY.replace('{activity}', activityId),
            {},
            success,
            error,
            always,
        )
    }

    useEffect(() => {
        if (activityId !== '') {
            loadActivityData()
        } else {
            updateBreadCrumb(getTranslation('nueva_actividad'))
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (activityId !== '' && activityId !== null) {
            loadActivityData()
        } else {
            updateBreadCrumb(getTranslation('nueva_actividad'))
            setActivity(null)
        }
        return () => {}
    }, [activityId])

    const success = useCallback((response: any) => {
        setActivitySpin(false)
        let activity = response.data.data
        setActivity(activity)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        setActivitySpin(false)
    }

    const handleactivityId = (id: number | null) => {
        if (id) {
            setActivityId(id)
        } else {
            setActivityId(null)
        }
    }

    if (!activitySpin && generalSpin) {
        setGeneralSpin(false)
    }

    return (
        <>
            <Spin
                className='spin-local'
                indicator={antIcon}
                spinning={generalSpin || executiveSpin}>
                <ActivityForm
                    setActivityId={handleactivityId}
                    activity={activity}
                    setExecutiveSpin={setExecutiveSpin}
                    executiveSpin={executiveSpin}
                />
            </Spin>
        </>
    )
}
