import { Spin, Form, Button } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { CustomSelect } from '../common/custom-select/CustomSelect'

import { ACTIVITY } from '../../config/Path'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'

import { Language } from '../../config/Language'
import { CustomDatePicker } from '../common/custom-date-picker/CustomDatePicker'
import { CustomTextArea } from '../common/custom-textarea/CustomTextArea'
import useUserContext from '../../hooks/useUserContext'
import moment, { Moment } from 'moment'
import { CustomTimePicker } from '../common/custom-time-picker/CustomTimePicker'
interface FormValue {
    accountId: number | undefined
    contactId: number | undefined
    workerId: number | undefined
    activityTypeId: number | undefined
    businessPotentialityId: number | undefined
    date: Moment | undefined
    hour: Moment | undefined
    description: string | undefined
}

interface IProps {
    setActivityId: (id: number | null) => void
    activity: any
    executiveSpin: boolean
    setExecutiveSpin: (bool: boolean) => void
}

export const ActivityForm = ({ ...props }: IProps) => {
    const [form] = Form.useForm()
    const history = useHistory()
    const { user } = useUserContext()
    const { getTranslation } = Language()
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { get, post, put, del } = useAxios()
    const { successObject, errorObjectWithData } = Notifications()
    const [saveSpin, setSaveSpin] = useState(false)
    const [deleteSpin, setDeleteSpin] = useState(false)

    const [formValue, setFormValue] = useState<FormValue>({
        accountId: undefined,
        contactId: undefined,
        workerId: undefined,
        activityTypeId: undefined,
        businessPotentialityId: undefined,
        date: undefined,
        hour: undefined,
        description: '',
    })
    const { activity, setExecutiveSpin } = props
    const [flagDisabledButton, setFlagDisabledButton] = useState(true)
    const [executive, setExecutive] = useState([])
    const [contactList, setContactList] = useState<any[]>([])
    const [contactListSpin, setContactListSpin] = useState(false)

    const loadExecutive = () => {
        setExecutiveSpin(true)

        get(
            ENDPOINTS.GET_WORKER_ACCOUNT_EXECUTIVES,
            {},
            successExecutive,
            error,
            alwaysExecutive,
        )
    }

    const successExecutive = useCallback((response: any) => {
        setExecutiveSpin(false)
        let result: any = response.data.data
        let worker = result.find((item: any) => item.email === user.email)
        let dataResponse = result.reduce(
            (acc: any, item: any) => [
                ...acc,
                {
                    label: item.label || item.name || item.full_name,
                    value: item.id || item.value,
                },
            ],
            [],
        )
        let newFormValue: FormValue = formValue
        newFormValue.workerId = worker.id
        form.setFieldsValue(newFormValue)
        setFormValue(newFormValue)
        setExecutive(dataResponse)
    }, [])

    const alwaysExecutive = () => {
        setExecutiveSpin(false)
    }

    const loadContactList = () => {
        setContactListSpin(true)

        let endpoint = formValue?.accountId
            ? ENDPOINTS.GET_ACCOUNT_CONTACTS.replace(
                  '{account}',
                  formValue?.accountId.toString(),
              )
            : ''

        get(endpoint, {}, successContactList, error, alwaysContactList)
    }

    const successContactList = useCallback((response: any) => {
        setContactListSpin(false)
        let result: any = response.data.data
        let dataResponse = result.reduce(
            (acc: any, item: any) => [
                ...acc,
                {
                    label: item.label || item.name || item.full_name,
                    value: item.id || item.value,
                },
            ],
            [],
        )

        setContactList(dataResponse)
    }, [])

    const alwaysContactList = () => {
        setContactListSpin(false)
    }

    useEffect(() => {
        if (executive.length === 0) {
            loadExecutive()
        }

        if (!activity) {
            let today = moment()
            let hour = moment(today, ['h:mm a']).format('h:00 a')
            let newFormValue: FormValue = formValue
            newFormValue.date = today
            newFormValue.hour = moment(hour, ['h:mm a'])
            form.setFieldsValue(newFormValue)
            setFormValue(newFormValue)
        }
        return () => {}
    }, [])

    useEffect(() => {
        formValue.accountId && loadContactList()
        return () => {}
    }, [formValue.accountId])

    useEffect(() => {
        if (activity) {
            let newFormValue: FormValue = {
                accountId: activity.account.id,
                contactId: activity.contact.id,
                workerId: activity.worker.id,
                activityTypeId: activity.activity_type.id,
                businessPotentialityId: activity.business_potentiality.id,
                date: moment(new Date(activity.date)).utc(),
                hour: moment(activity.hour, ['h:mm a']),
                description: activity.description,
            }
            form.setFieldsValue(newFormValue)
            updateBreadCrumb(activity.activity_type.name)
            setFormValue(newFormValue)
        }
        return () => {}
    }, [activity])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const onChange = (values: any, timeString: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFlagDisabledButton(false)
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            account_id: formValue.accountId,
            contact_id: formValue.contactId,
            worker_id: formValue.workerId,
            activity_type_id: formValue.activityTypeId,
            business_potentiality_id: formValue.businessPotentialityId,
            date: formValue.date?.format('YYYY-MM-DD'),
            hour: formValue.hour?.format('h:mm a'),
            description: formValue.description,
        }

        return result
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()

        if (activity) {
            put(
                ENDPOINTS.PUT_ACTIVITY.replace('{activity}', activity.id),
                result,
                successSave,
                error,
                alwaysSave,
            )
        } else {
            post(
                ENDPOINTS.POST_ACTIVITY,
                result,
                successSave,
                error,
                alwaysSave,
            )
        }
    }

    const successSave = useCallback((response: any) => {
        setFlagDisabledButton(true)
        setSaveSpin(false)
        successObject(response)
        history.push(`${ACTIVITY}`)
    }, [])

    const handleDelete = () => {
        setDeleteSpin(true)
        del(
            ENDPOINTS.DEL_ACTIVITY.replace(
                '{activity}',
                activity.id.toString(),
            ),
            {},
            successDelete,
            error,
            alwaysDelete,
        )
    }

    const successDelete = useCallback((response: any) => {
        setDeleteSpin(false)
        successObject(response)
        history.push(`${ACTIVITY}`)
    }, [])

    const alwaysDelete = () => {
        setDeleteSpin(false)
    }

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    const handleCancel = () => {
        if (activity) {
            setFlagDisabledButton(true)
            let newFormValue: FormValue | any = {
                accountId: activity.account.id,
                contactId: activity.contact.id,
                workerId: activity.worker.id,
                activityTypeId: activity.activity_type.id,
                businessPotentialityId: activity.business_potentiality.id,
                date: moment(new Date(activity.date)).utc(),
                hour: moment(activity.hour, ['h:mm a']),
                description: activity.description,
            }
            form.setFieldsValue(newFormValue)
            setFormValue(newFormValue)
        } else {
            history.push(ACTIVITY)
        }
    }

    if (activity?.contact) {
        if (
            !contactList.find(
                (item: any) => item.value === activity?.contact?.id,
            )
        ) {
            let newContact: any = {
                label: activity?.contact?.name,
                value: activity?.contact?.id,
            }
            setContactList([...contactList, newContact])
        }
    }

    return (
        <>
            <div id='activity-form'>
                <div className='header'>
                    <div className='header-title'>
                        {getTranslation('activity')}
                    </div>
                </div>
                <div className='body'>
                    <Spin
                        className='spin-local'
                        indicator={antIcon}
                        spinning={saveSpin || deleteSpin}>
                        <Form
                            id='form-activity'
                            form={form}
                            layout='vertical'
                            scrollToFirstError
                            onValuesChange={onChange}
                            onFinish={onSave}
                            size='large'>
                            <div className='form-container'>
                                <div className='form-body'>
                                    <CustomSelect
                                        label={getTranslation('tipo_actividad')}
                                        name='activityTypeId'
                                        id='activityTypeId'
                                        endpoint={ENDPOINTS.GET_ACTIVITIES_TYPE}
                                        value={formValue?.activityTypeId}
                                        required={true}
                                    />
                                    <CustomDatePicker
                                        label={getTranslation('fecha')}
                                        name='date'
                                        id='date'
                                        required={true}
                                        type={'object' as const}
                                        value={formValue.date}
                                    />

                                    <CustomTimePicker
                                        label={getTranslation('hora')}
                                        name='hour'
                                        id='hour'
                                        required={true}
                                        type={'object' as const}
                                        value={formValue.hour}
                                        minuteStep={15}
                                    />

                                    <CustomSelect
                                        label={getTranslation('marca')}
                                        name='accountId'
                                        id='accountId'
                                        endpoint={ENDPOINTS.GET_ACCOUNTS}
                                        value={formValue?.accountId}
                                        required={true}
                                        disabled={!!activity}
                                    />

                                    <CustomSelect
                                        label={getTranslation('contacto')}
                                        name='contactId'
                                        id='contactId'
                                        data={contactList}
                                        value={formValue?.contactId}
                                        required={true}
                                        disabled={!formValue.accountId}
                                    />

                                    <CustomSelect
                                        label={getTranslation(
                                            'business_potentiality',
                                        )}
                                        name='businessPotentialityId'
                                        id='businessPotentialityId'
                                        endpoint={
                                            ENDPOINTS.GET_BUSINESS_POTENTIALITIES
                                        }
                                        value={
                                            formValue?.businessPotentialityId
                                        }
                                        required={true}
                                    />

                                    <CustomSelect
                                        label={getTranslation(
                                            'ejecutivo_cuenta',
                                        )}
                                        name='workerId'
                                        id='workerId'
                                        data={executive}
                                        value={formValue.workerId}
                                        required={true}
                                    />
                                    <CustomTextArea
                                        className='form-textarea'
                                        name='description'
                                        id='description'
                                        required={true}
                                        label={getTranslation(
                                            'descripcion_actividad',
                                        )}
                                    />
                                </div>
                                <div className='buttons-footer'>
                                    <>
                                        <Button
                                            type='primary'
                                            size='small'
                                            disabled={
                                                activity && flagDisabledButton
                                            }
                                            htmlType='submit'>
                                            {getTranslation('guardar_cambios')}
                                        </Button>

                                        <Button
                                            style={{ margin: '0 16px' }}
                                            ghost
                                            disabled={
                                                activity && flagDisabledButton
                                            }
                                            type='primary'
                                            size='small'
                                            onClick={handleCancel}>
                                            {getTranslation('cancelar')}
                                        </Button>

                                        {activity && (
                                            <Button
                                                ghost
                                                type='primary'
                                                size='small'
                                                onClick={handleDelete}>
                                                {getTranslation('eliminar')}
                                            </Button>
                                        )}
                                    </>
                                </div>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </div>
        </>
    )
}
