export const translatedMessages = {
    aplicar: 'Aplicar',
    aceptar: 'Aceitar',
    cancelar: 'Cancelar',
    eliminar: 'Remover',
    editar: 'Editar',
    logout: 'Sair',
    'briefs-form': 'Formulário de Brief',
    vendors: 'Meios',
    activities: 'Atividades',
    activity: 'Atividade',
    'vendor-details': 'Ver ou editar meios',
    brand: 'Marca',
    brands: 'Marcas',
    organization: 'Organização',
    accounts: 'Contas',
    briefs: 'Brief',
    dashboard: 'Dashboard',
    seleccione: 'Seleccionar',
    guardado: 'Guardado',
    guardar_cambios: 'Guardar mudanças',
    guardar: 'Guardar',
    guardar_borrador: 'Salvar rascunho',
    los_datos_se_han_guardado_con_exito: 'Os dados foram salvos com sucesso',
    los_datos_se_han_eliminado_con_exito: 'Os dados foram excluído com sucesso',
    mensaje: 'Mensagem',
    error: 'Erro',
    eliminado: 'Removido',
    en: 'Inglês',
    es: 'Espanhol',
    pt: 'Português',
    nombre: 'Nome',
    apellido: 'Sobrenome',
    marca: 'Marca',
    pais: 'País',
    ciudad: 'Cidade',
    tipo_de_contacto: 'Tipo de contato',
    position: 'Posição',
    email: 'Email',
    genero: 'Gênero',
    fecha_de_nacimiento: 'Data de nascimento',
    fecha: 'Data',
    mobile: 'Móvel',
    phone: 'telefone',
    observaciones: 'Observações',
    eliminar_contacto: 'Excluir contato',
    medios: 'Meios',
    crear_medio: 'Criar meio',
    crear_contacto: 'Criar contato',
    crear_marca: 'Crie marca',
    logo: 'Logotipo',
    contactos: 'Contatos',
    contacto: 'Contato',
    nuevo_medio: 'Novo Meios',
    nueva_organizacion: 'Nova organização',
    crear_actividad: 'Criar atividade',
    pasadas: 'Passado',
    hoy: 'Hoje',
    proximas: 'Por vir',
    inicio_fin: 'Inicio - Fim',
    clean_filters: 'Filtros limpos',
    ejecutivo: 'Executivo',
    ejecutivo_cuenta: 'Executivo de contas',
    nueva_actividad: 'Nova atividade',
    tipo_actividad: 'Tipo de atividade',
    business_potentiality: 'Potencialidade de negócios',
    descripcion_actividad: 'Descrição da atividade',
    descripcion: 'Descrição',
    hora: 'Hora',
    sin_datos: 'Não há dados.',
    crea_un_contacto: 'Criar um contato',
    crea_una_marca: 'Crie uma marca',
    crear_organizacion: 'Criar organização',
    medio: 'Meio',
    last_activity: 'Ultima atividade',
    nuevo_contacto: 'Novo contato',
    metrics: 'Métricas',
    ultima_campana: 'Última campanha',
    fecha_ultima_campana: 'Última data da campanha',
    propuesta_enviada: 'Propostas submetidas',
    historical_total: 'Total histórico',
    campana_ejecutadas: 'Campanhas executadas',
    tasa_conversion: 'Taxa de conversão',
    total_actividades: 'Todas as atividades',
    reuniones: 'Encontros',
    promedio_semanal: 'Média semanal',
    metricas_campanas: 'Métricas de campanha',
    historial_propuestas: 'Histórico da proposta',
    historial_inversion_campana: 'Histórico de investimento por campanha',
    historial_inversion_mensual: 'Histórico de investimento mensal',
    historial_inversion_medio: 'Histórico de investimento por médio',
    prevision_inversion_campaña: 'Previsão de investimento por campanha',
    metricas_actividades: 'Métricas de atividade',
    por_tipo_actividad: 'Por tipo de atividade',
    evolucion_actividades: 'Evolução das atividades',
    actividades_ejecutivo: 'Atividades por executivo',
    actividades_por_cuenta: 'Atividades por conta',
    tooltips_img_validation:
        'Tamanho inferior a 2 MB, formato PGN, JPG ou GIF, medição 400 x 400 pixels',
    informacion_general: 'Informações gerais',
    nombre_de_la_campana: 'Nome da campanha',
    fecha_estimada: 'Data estimada',
    moneda_para_la_propuesta: 'Moeda para proposta',
    presupuesto: 'Despesas',
    tipo_de_presupuesto: 'Tipo de orçamento',
    pais_de_pago: 'País de pagamento',
    pais_de_la_campana: 'País da campanha',
    ciudad_de_la_campana: 'Cidade da campanha',
    publico_objetivo: 'Público objetivo',
    perfil_psicografico: 'Perfil psicográfico',
    especificaciones_de_campana: 'Especificações de campanha',
    objetivo_de_comunicacion: 'Objetivo de comunicação',
    convertir_en_propuesta: 'Converter em proposta',
    nuevo_brief: 'Novo brief',
    modal_nueva_marca_detalle:
        'Para criar uma marca, você deve ter uma organização existente. Selecione uma organização na lista ou crie uma nova organização.',
    logotipo_imagen: 'Logotipo ou imagem',
    discount_: 'Desconto %',
    discount: 'Desconto',
    discount_optional: '% Desconto (Opcional)',
    tipo: 'Tudo ',
    sitio_web: 'Site web',
    categoria: 'Categoria',
    sub_categoria: 'Subcategoria',
    campaign: 'Campanha',
    campaigns: 'Campanhas',
    cliente: 'Cliente',
    inicio: 'Começar',
    inicio_estimado: 'Estimativa de início',
    fin_estimado: 'Fim estimado',
    finalizacion: 'Final',
    estado: 'Doença',
    acciones: 'Ações',
    accion: 'Açao',
    nueva_campana: 'Nova campanha',
    listado_campanas: 'Lista de campanhas',
    listado_briefs: 'Lista de briefs',
    completar: 'Completar',
    progreso: 'Progresso',
    etapa: 'Etapa',
    inversion_a_futuro: 'Investimento futuro',
    inversion_futuro_cliente: 'Investimento futuro por cliente',
    gestor_tareas: 'Gerenciador de tarefas',
    ajustes_personales: 'Configurações pessoais',
    cerrar_sesion: 'Sair',
    notificaciones: 'Notificações',
    borrar_notificaciones: 'Limpar notificações',
    volver: 'Volte',
    page_not_found_text: 'A página que você está tentando visitar não existe',
    recientes: 'Recente',
    aceptadas: 'Aceitaram',
    rechazadas: 'Rejeitado',
    vencidas: 'Atrasado',
    archivadas: 'Arquivado',
    envio: 'Envio',
    vencimiento: 'Vencimento',
    proposals: 'Proposta',
    'proposals-detail': 'Detalhe da proposta',
    proposal_list: 'Lista de propostas',
    nombre_campana: 'Nome campanha',
    presupuesto_estimado: 'Orçamento estimado',
    nivel_socioeconomico: 'Nivel socioeconômico',
    rangos_etarios: 'Age ranges',
    tipo_de_campaña: 'Tipo de campanha',
    material: 'Material',
    puntos_de_interes: 'Pontos de interesse',
    soportes: 'Suportes',
    estrategia: 'Estratégia',
    confirmar: 'Confirme',
    editar_propuesta: 'Editar proposta',
    archivar: 'Arquivo',
    filtros: 'Filtros',
    entorno: 'Meio Ambiente',
    ots_min: 'OTS Min',
    ots_max: 'OTS Max',
    filters_message: 'Filtros ativos respondem ao seu resumo da campanha.',
    agregar: 'adicionar',
    ver_mapa: 'Ver mapa',
    selection: 'Seleção',
    fin: 'Fim',
    seleccion_de_soportes: 'Seleção de suportes',
    nombre_comercial: 'Nome comercial',
    ubicacion: 'Localização',
    soportes_totales: 'Suporte total',
    total_OTS: 'OTS total',
    costo_total: 'Custo total',
    precio_unitario: 'Preço unitário',
    of: 'do',
    items: 'itens',
    already_added: 'Você já adicionou',
    write_here: 'Escreva aqui...',
    see_list: 'Ver lista',
    MU: 'MU',
    CA: 'CA',
    PO: 'PO',
    PP: 'PP',
    PA: 'PA',
    DI: 'DI',
    layers: 'Camadas',
    not_search_assets: 'Você ainda não pesquisou por suportes',
    referencias: 'Referências',
    digital: 'Digital',
    place_based: 'Com base no local',
    roadside: 'Via pública',
    printed_mesh: 'impresso',
    billboard: 'Boletim',
    display: 'Exibição',
    mural: 'Wall-Murals',
    panel: 'Painel',
    poster: 'Poster',
    poster_junior: 'Pequeno Pôster',
    food_beverages: 'Alimentos e bebidas',
    vehicles: 'Veículos',
    goverment: 'Governo',
    education: 'Educação',
    entertainment: 'Entretenimento',
    finance: 'Finanças',
    parks: 'Parques',
    health: 'Saúde',
    shops: 'Lojas',
    transportation: 'Transporte',
    turism: 'Turismo',
    ots_description:
        'Se você ativar a visualização pelo OTS, verá que os ícones que identificam os suportes mudam de tamanho. Esta mudança de tamanho responde à maior ou menor quantidade de OTS que os suportes incluídos na pesquisa que você fez possuem.',
    example: 'Exemplo',
    comercial_name: 'Nome comercial',
    download_specifications: 'Baixar especificações',
    commercial_measures: 'Medidas comerciais',
    additional_information: 'Informação adicional',
    otros: 'outros',
    subCategoryEducation: 'Centro Educativo, Educação superior, Escola',
    subCategoryEntertainment:
        'Parque de diversões, boliche, cassino, cinema, centro de convenções ou exposições, campo de golfe, pista de patinação, vida noturna, artes cênicas, estádio, zoológico',
    subCategoryFinance: 'ATM, banco, câmbio, finanças',
    subCategoryFoodBeverages:
        'Cafeteria, Fast Food, Mercearia, Restaurante, Wine Bar',
    subCategoryGoverment:
        'Município, Centro Comunitário, Tribunal, Embaixada, Gabinete do Governo, Delegacia de Polícia, Correios',
    subCategoryHealth: 'Hospital, serviços médicos, farmácia',
    subCategoryParks:
        'Parque ou área de lazer, área de lazer, área de descanso, complexo esportivo',
    subCategoryShops:
        'Loja de roupas, livraria, loja de eletrônicos, loja de conveniência, loja de departamentos, móveis e utensílios domésticos, material de escritório, cuidados pessoais, compras, loja especializada, loja de artigos esportivos, loja, veterinário',
    subCategoryTransportation:
        'Aeroporto, pista do aeroporto, terminal do aeroporto, estação rodoviária, terminal da balsa, heliporto, área portuária, terminal, porto, estação ferroviária, entrada da estação ferroviária, pedágio, serviço de traslado, bonde, serviço de traslado',
    subCategoryTurism:
        'Posto fronteiriço, monumento histórico, hotel, centro de informações turísticas, museu, mirante, várias acomodações, ruínas, esqui, trilha',
    subCategoryVehicles:
        'Concessionária de Veículos, Serviço e Manutenção de Veículos, Postos de Serviço, Concessionária de Motos, Estacionamento, Garagem, Aluguel de Veículos, Venda de Caminhões, Estacionamento de Caminhões, Clube Automóvel, Estação de Pesagem',
    required: 'Obrigatório',
    select_a_option: 'Selecione uma opção',
    ha_ocurrido_error_guardar: 'Ocorreu um erro ao salvar',
    without_code: 'Sem código',
    code: 'Código',
    advertisers: 'Anunciantes',
    advertiser: 'Anunciante',
    add_an_advertiser: 'Adicionar um anunciante',
    add_advertiser: 'Adicionar anunciante',
    'add-advertisers': 'Adicionar um anunciante',
    'preview-advertisers': 'Anunciante existente',
    advertiser_list: 'Lista de anunciantes',
    created_by: 'Criado pela',
    created: 'Criado',
    'new-advertisers': 'Adicionar um anunciante',
    select_advertaiser: 'Selecione um anunciante',
    about_advertaisers: 'Sobre anunciantes',
    create_advertiser: 'Criar anunciantes',
    next: 'Segue',
    help: 'Ajuda',
    razon_social: 'Razão social',
    you_can_skip: 'Você pode pular esta etapa',
    create_an_advertiser: 'Criar um anunciantes',
    modal_advertiser_exist:
        'já faz parte de seus anunciantes. Quer visitar a página de informações sobre este anunciante?',
    see_advertiser: 'Ver anunciante',
    close: 'Fechar',
    same_name_advertiser: 'Mesmo nome do anunciante',
    same_logo_advertiser: 'Mesmo logotipo do anunciante',
    company_name_number: 'Número do nome da empresa',
    legal_name: 'Nome legal',
    skip_this_step: 'Pular esta etapa',
    create_company_name: 'Crie o nome de uma empresa',
    finish: 'Termine',
    you_will_create_contacts_end:
        'Você poderá criar mais contatos para seu anunciante no final.',
    edit_discont: 'editar desconto',
    company_names: 'Razões sociais',
    add_company_name: 'Crie o nome da empresa',
    name_last_name: 'Nome e sobrenome',
    to_disable: 'Desabilitar',
    table_trad: {
        filterTitle: 'Menu de Filtro',
        filterConfirm: 'OK',
        filterReset: 'Resetar',
        filterEmptyText: 'Sem filtros',
        filterCheckall: 'Selecione todos os itens',
        filterSearchPlaceholder: 'Pesquisa em filtros',
        emptyText: 'Sem conteúdo',
        selectAll: 'Selecionar página atual',
        selectInvert: 'Inverter seleção',
        selectNone: 'Apagar todo o conteúdo',
        selectionAll: 'Selecionar todo o conteúdo',
        sortTitle: 'Ordenar título',
        expand: 'Expandir linha',
        collapse: 'Colapsar linha',
        triggerDesc: 'Clique organiza por descendente',
        triggerAsc: 'Clique organiza por ascendente',
        cancelSort: 'Clique para cancelar organização',
    },
    action_to_taken: 'Ação a ser realizada',
    make_impact: 'Causar impacto',
    segment: 'Segmento',
    search_coverage: 'Cobertura de pesquisa',
    search_frequency: 'Frequência de pesquisa',
    login: 'Iniciar sessão',
    'send-proposal': 'Enviar proposta',
    abstract: 'Resumo',
    map: 'Mapa',
    proposal_expiration: 'Vencimento da proposta',
    subtotal: 'Subtotal',
    total: 'Total',
    cost_production: 'Custo de produção',
    installation_cost: 'Custo de instalação',
    quantity: 'Quantia',
    price: 'preço',
    totals: 'Totais',
    total_CPM: 'CPM total',
}
