import { ICountry } from '../interface/ICountry'
import { ICountryContext } from '../interface/ICountryContext'
import { createContext } from 'react'
import { useCallback, useState, useMemo } from 'react'

export const CountryContext = createContext<any>({})

const CountryContextProvider = ({ children }: any) => {
    let initialState = null

    const [country, setCountry] = useState<ICountry | null>(initialState)

    const updateCountry = useCallback((user: ICountry) => {
        setCountry(user)
    }, [])

    const value: ICountryContext = useMemo(
        () => ({
            updateCountry,
            country,
        }),
        [country, updateCountry],
    )

    // @ts-ignore
    return (
        <CountryContext.Provider value={value}>
            {children}
        </CountryContext.Provider>
    )
}

export default CountryContextProvider
