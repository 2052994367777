import axios, { AxiosError } from 'axios'
import { CONFIG } from '../config/Config'
import { Language } from '../config/Language'
import jwt_decode from 'jwt-decode'
import { useHistory } from 'react-router-dom'
import { LOGOUT, NOT_FOUND, PAGE403 } from '../config/Path'
import useAuthContext from './useAuthContext'
import { LocalStorage } from '../common/LocalStorage'
import { ENDPOINTS } from '../config/EndPoints'
import useUserContext from './useUserContext'

export const useAxios = () => {
    const { getLanguage } = Language()
    const { login, isAuthenticated, logout } = useAuthContext()
    const token = LocalStorage.getToken()
    const { updateUser } = useUserContext()
    let authorization = isAuthenticated ? `Bearer ${token}` : undefined
    const history = useHistory()
    const redirectLogin = () => {
        logout()
        history.push(LOGOUT)
    }

    const redirect403 = () => {
        history.push(PAGE403)
    }

    const redirect404 = () => {
        history.push(NOT_FOUND)
    }

    let isRefreshing = false
    let failedQueue: any = []

    const processQueue = (error: any, token = null) => {
        failedQueue.forEach((prom: any) => {
            if (error) {
                prom.reject(error)
            } else {
                prom.resolve(token)
            }
        })

        failedQueue = []
    }

    axios.interceptors.response.use(
        (response) => {
            const originalRequest = response.config
            if (originalRequest.url === `${ENDPOINTS.PUT_LOCALE}`) {
                const refreshToken = LocalStorage.getRefreshToken()
                return axios
                    .post(CONFIG.END_POINT + ENDPOINTS.POST_REFRESH, {
                        refresh_token: refreshToken,
                    })
                    .then((response: any) => {
                        if (response.status === 200) {
                            let token = response.data.data.access_token
                            let refresh = response.data.data.refresh_token
                            let user = jwt_decode(token)
                            login(token, refresh)
                            updateUser(user)
                            return response
                        } else {
                            redirectLogin()
                        }
                        return
                    })
                    .catch(() => redirectLogin())
            }
            return response
        },
        function (error) {
            const originalRequest = error.config

            if (
                error.response.status === 401 &&
                originalRequest.url === `${ENDPOINTS.POST_LOGIN}`
            ) {
                return
            }

            if (
                error.response.status === 401 &&
                originalRequest.url === `${ENDPOINTS.POST_REFRESH}`
            ) {
                redirectLogin()
                return Promise.reject(error)
            }

            if (error.response.status === 401 && !originalRequest._retry) {
                const refreshToken = LocalStorage.getRefreshToken()
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject })
                    })
                        .then((token) => {
                            originalRequest.headers.Authorization =
                                'Bearer ' + token
                            return axios(originalRequest)
                        })
                        .catch((err) => {
                            return Promise.reject(err)
                        })
                }

                originalRequest._retry = true
                isRefreshing = true

                return new Promise(function (resolve, reject) {
                    axios
                        .post(CONFIG.END_POINT + ENDPOINTS.POST_REFRESH, {
                            refresh_token: refreshToken,
                        })
                        .then((response: any) => {
                            let token = response.data.data.access_token
                            let refresh = response.data.data.refresh_token
                            login(token, refresh)
                            originalRequest.headers.Authorization =
                                'Bearer ' + token
                            processQueue(null, token)
                            resolve(axios(originalRequest))
                        })
                        .catch((err) => {
                            processQueue(err, null)
                            redirectLogin()
                        })
                        .then(() => {
                            isRefreshing = false
                        })
                })
            }

            return Promise.reject(error)
        },
    )

    const getTest = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        data.lang = getLanguage()
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        axios
            .get(CONFIG.END_POINT + pathRelative, {
                params: data,
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: authorization,
                    'Access-Control-Allow-Origin': '*',
                },
                validateStatus: function (status: any) {
                    return validateStatus(status)
                },
            })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const get = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
        hasToken: boolean = true,
    ) => {
        data.lang = getLanguage()
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        axios
            .get(CONFIG.END_POINT + pathRelative, {
                params: data,
                cancelToken: source.token,
                headers: getHeaders(hasToken),
                validateStatus: function (status: any) {
                    return validateStatus(status)
                },
            })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const post = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        data.lang = getLanguage()
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'post',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            baseURL: CONFIG.END_POINT,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorization,
                'Access-Control-Allow-Origin': '*',
            },
            validateStatus: function (status: any) {
                return validateStatus(status)
            },
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const postWithOutToken = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        data.lang = getLanguage()
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'post',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            baseURL: CONFIG.END_POINT,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            validateStatus: function (status: any) {
                return validateStatus(status)
            },
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const postLogin = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'post',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            baseURL: CONFIG.END_POINT,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            validateStatus: function (status: any) {
                return validateStatus(status)
            },
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors: AxiosError) => {
                if (errors.response && error) {
                    error(errors)
                } else {
                    let errors = {
                        response: {
                            status: 401,
                            data: {
                                message: 'Error de servidor',
                            },
                        },
                    }
                    error(errors)
                }
            })
            // .catch((errors) => {
            //     console.log(errors)
            //     if (errors.response && error) {
            //         error(errors)
            //     }
            // })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const del = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        data.lang = getLanguage()
        let self = this
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'delete',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            baseURL: CONFIG.END_POINT,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorization,
                'Access-Control-Allow-Origin': '*',
            },
            validateStatus: function (status: any) {
                return validateStatus(status)
            },
        })
            .then(function (response) {
                if (success) {
                    success(response)
                }
            })
            .catch(function (errors) {
                if (error) {
                    error(errors)
                }
            })
            .then(function () {
                if (always) {
                    always()
                }
            })
        return source
    }

    const put = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        data.lang = getLanguage()
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        axios({
            method: 'put',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            baseURL: CONFIG.END_POINT,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorization,
                'Access-Control-Allow-Origin': '*',
            },
            validateStatus: function (status: any) {
                return validateStatus(status)
            },
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const validateStatus = (status: number) => {
        let success = 200
        let successDelete = 204
        let successCreated = 201
        let unauthorized = 401
        let unauthenticated = 403
        let notFound = 404

        if (status === unauthenticated) {
            redirect403()
        }

        if (status === notFound) {
            redirect404()
        }

        return (
            status === success ||
            status === successDelete ||
            status === successCreated
        )
    }

    const getHeaders = (hasToken: boolean) => {
        return hasToken
            ? {
                  'Content-Type': 'application/json',
                  Authorization: authorization,
                  'Access-Control-Allow-Origin': '*',
              }
            : {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
              }
    }

    return {
        get,
        post,
        put,
        del,
        postLogin,
        postWithOutToken,
        getHeaders,
        getTest,
    }
}
