export const LocalStorage = {
    getToken(): string | null {
        return window.localStorage.getItem('token')
    },
    setToken(token: string): void | null {
        return window.localStorage.setItem('token', token)
    },
    removeToken(): void | null {
        return window.localStorage.removeItem('token')
    },

    getRefreshToken(): string | null {
        return window.localStorage.getItem('refresh_token')
    },
    setRefreshToken(refresh_token: string): void | null {
        return window.localStorage.setItem('refresh_token', refresh_token)
    },
    removeRefreshToken(): void | null {
        return window.localStorage.removeItem('refresh_token')
    },

    getUser(): string | null {
        return window.localStorage.getItem('user')
    },
    setUser(user: string): void | null {
        return window.localStorage.setItem('user', user)
    },
    removeUser(): void | null {
        return window.localStorage.removeItem('user')
    },

    getExpirationTime(): string | null {
        return window.localStorage.getItem('expTime')
    },
    setExpirationTime(expTime: string): void | null {
        return window.localStorage.setItem('expTime', expTime)
    },
    removeExpirationTime(): void | null {
        return window.localStorage.removeItem('expTime')
    },
}
