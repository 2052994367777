import { useCallback, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { ENDPOINTS, ENDPOINTS_WITH_OUT_TOKEN } from '../../config/EndPoints'
import jwt_decode from 'jwt-decode'
import { DASHBOARD } from '../../config/Path'
import useAuthContext from '../../hooks/useAuthContext'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { Form, Row, Col } from 'antd'
import useUserContext from '../../hooks/useUserContext'
import { LoginForm } from './login-form/LoginForm'
import { ForgotPasswordForm } from './forgot-password/ForgotPasswordForm'
import { Help } from './help/Help'
import imagen from '../../asset/img/imageLogin.jpg'

interface FormValue {
    email: string | null
    password: string | null
}

const Login = () => {
    const { login, isAuthenticated } = useAuthContext()
    const { updateUser } = useUserContext()
    const { postLogin, postWithOutToken } = useAxios()
    const { errorObjectWithData } = Notifications()
    const location = useLocation<{ [key: string]: unknown }>()
    const currentLocationState = location.state || {
        from: { pathname: DASHBOARD },
    }

    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [spin, setSpin] = useState(false)

    const [loginBool, setLoginBool] = useState(true)
    const [errorloginBool, setErrorloginBool] = useState(false)
    const [errorLogin, setErrorLogin] = useState({})

    const [forgotBool, setForgotBool] = useState(false)
    const [errorForgotBool, setErrorForgotBool] = useState(false)
    const [errorForgot, setErrorForgot] = useState({})
    const [successForgotBool, setSuccessForgotBool] = useState(false)

    const [helpBool, setHelpBool] = useState(false)
    const [successHelpBool, setSuccessHelpBool] = useState(false)
    const [errorHelpBool, setErrorHelpBool] = useState(false)
    const [errorHelp, setErrorHelp] = useState({})

    const [imageLoaded, setImageLoaded] = useState(false)

    const [form] = Form.useForm()

    const onChange = (values: any) => {
        let newFormValue: any = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const onSubmit = (values: any) => {
        setSpin(true)
        setErrorloginBool(false)
        if (values) {
            let data = {
                email: values.email,
                password: values.password,
            }
            postLogin(ENDPOINTS.POST_LOGIN, data, success, error, always)
        }
    }

    const onSubmitForgotPassword = (values: any) => {
        if (formValue.forgotEmail) {
            let data = {
                email: formValue.forgotEmail,
            }
            setErrorForgotBool(false)
            // postLogin(
            //     ENDPOINTS.POST_LOGIN,
            //     data,
            //     successForgotPassword,
            //     errorForgotPassword,
            //     always,
            // )

            let errors = {
                response: {
                    status: 500,
                    data: {
                        message: 'Error de servidor',
                    },
                },
            }
            let success = {
                response: {
                    status: 200,
                    data: {
                        message: 'Se envio el correo',
                    },
                },
            }
            // errorForgotPassword(errors)
            successForgotPassword(success)
        }
    }

    const onSubmitHelp = (values: any) => {
        if (formValue.mensaje) {
            let data = {
                subject: formValue.helpEmail,
                body: formValue.mensaje,
            }
            setErrorHelpBool(false)
            postWithOutToken(
                ENDPOINTS_WITH_OUT_TOKEN.POST_SUPPORT,
                data,
                successHelp,
                handleErrorHelp,
                always,
            )
        }
    }

    const success = useCallback(
        (response: any) => {
            setSpin(false)
            let token = response.data.data.access_token
            let refresh = response.data.data.refresh_token
            let user = jwt_decode(token)
            login(token, refresh)
            updateUser(user)
        },
        [setSpin, login],
    )

    const successForgotPassword = useCallback(
        (response: any) => {
            setSpin(false)
            setSuccessForgotBool(true)
        },
        [setSpin],
    )

    const successHelp = useCallback(
        (response: any) => {
            setSpin(false)
            setSuccessHelpBool(true)
        },
        [setSpin],
    )

    const error = (errors: any) => {
        errorObjectWithData(errors)
        setErrorLogin(errors)
        setErrorloginBool(true)
    }

    const errorForgotPassword = (errors: any) => {
        errorObjectWithData(errors)
        setErrorForgot(errors)
        setErrorForgotBool(true)
    }

    const handleErrorHelp = (errors: any) => {
        errorObjectWithData(errors)
        setErrorHelp(errors)
        setErrorHelpBool(true)
    }

    const always = () => {
        setSpin(false)
    }

    const handleForgotPassword = (bool: boolean) => {
        setFormValue({})
        form.setFieldsValue({
            email: '',
            password: '',
            forgotEmail: '',
            helpEmail: '',
            mensaje: '',
        })
        setForgotBool(bool)
        setLoginBool(false)
        setHelpBool(false)
        errorsClean()
    }

    const handleHelp = (bool: boolean) => {
        setFormValue({})
        form.setFieldsValue({
            email: '',
            password: '',
            forgotEmail: '',
            mensaje: '',
        })
        setHelpBool(bool)
        setLoginBool(false)
        setForgotBool(false)
        errorsClean()
    }

    const handleLogin = (bool: boolean) => {
        setFormValue({})
        form.setFieldsValue({
            email: '',
            password: '',
            forgotEmail: '',
            mensaje: '',
        })
        setLoginBool(bool)
        setForgotBool(false)
        setHelpBool(false)
        errorsClean()
    }

    const errorsClean = () => {
        setErrorForgotBool(false)
        setErrorHelpBool(false)
        setErrorloginBool(false)
    }

    if (isAuthenticated) {
        return <Redirect to={currentLocationState?.from as string} />
    }

    return (
        <div id='login'>
            <Row>
                <Col
                    className='left-container'
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    xl={14}>
                    <img
                        src={imagen}
                        alt='imagen de login'
                        className={`smooth-image image-${
                            imageLoaded ? 'visible' : 'hidden'
                        }`}
                        onLoad={() => setImageLoaded(true)}
                    />
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className='right-container'>
                        {loginBool && (
                            <LoginForm
                                form={form}
                                onChange={onChange}
                                onSubmit={onSubmit}
                                spin={spin}
                                openForgotPassword={handleForgotPassword}
                                openHelp={handleHelp}
                                errorBool={errorloginBool}
                                error={errorLogin}
                            />
                        )}
                        {forgotBool && (
                            <ForgotPasswordForm
                                form={form}
                                onChange={onChange}
                                onSubmit={onSubmitForgotPassword}
                                openLogin={handleLogin}
                                successBool={successForgotBool}
                                errorBool={errorForgotBool}
                                error={errorForgot}
                            />
                        )}
                        {helpBool && (
                            <Help
                                form={form}
                                onChange={onChange}
                                onSubmit={onSubmitHelp}
                                openLogin={handleLogin}
                                successBool={successHelpBool}
                                errorBool={errorHelpBool}
                                error={errorHelp}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login
