import { Button, Empty, Spin, Tabs } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { Language } from '../../config/Language'
import { NEW_ACTIVITY } from '../../config/Path'

import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { Activity } from './Activity'
import { FilterActivity } from './filter-activity/FilterActivity'

const { TabPane } = Tabs

export const ActivityList = () => {
    const { get } = useAxios()
    const { getTranslation } = Language()
    const { errorObjectWithData } = Notifications()
    const [generalSpin, setGeneralSpin] = useState(false)
    const [deleteSpin, setDeleteSpin] = useState(false)
    const [data, setData] = useState([])
    const [tab, setTab] = useState('2')
    const [params, setParams] = useState({})

    useEffect(() => {
        loadActivities()

        return () => {}
    }, [params, tab])

    const loadActivities = () => {
        let endpoint = ENDPOINTS.GET_ACTIVITIES_TODAY
        if (tab === '1') {
            endpoint = ENDPOINTS.GET_ACTIVITIES_PAST
        } else if (tab === '3') {
            endpoint = ENDPOINTS.GET_ACTIVITIES_FUTURE
        }
        setGeneralSpin(true)
        get(endpoint, params, successActivities, error, alwaysActivities)
    }

    const successActivities = useCallback((response: any) => {
        setGeneralSpin(false)
        setData(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const alwaysActivities = () => {
        setGeneralSpin(false)
    }

    const handleSuccessDelete = (tab: string) => {
        setTab(tab)
        loadActivities()
    }

    const handleTabs = (key: any) => {
        setTab(key)
    }

    return (
        <div id='activity-list'>
            <div className='header'>
                <div className='title-box'>
                    <div className='header-title'>
                        {getTranslation('activities')}
                    </div>
                    <div className='header-button'>
                        <Button type='primary' size='small'>
                            <Link to={NEW_ACTIVITY}>
                                {getTranslation('crear_actividad')}
                            </Link>
                        </Button>
                    </div>
                </div>
                <Tabs
                    className='tabs-activity'
                    defaultActiveKey='2'
                    activeKey={tab}
                    onChange={handleTabs}>
                    <TabPane tab={getTranslation('pasadas')} key='1' />
                    <TabPane tab={getTranslation('hoy')} key='2' />
                    <TabPane tab={getTranslation('proximas')} key='3' />
                </Tabs>
            </div>
            <div className='body-filters'>
                <FilterActivity tab={tab} setParams={setParams} />
            </div>
            <div className='body-activity'>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={generalSpin || deleteSpin}>
                    {data.length > 0 ? (
                        <div className='body'>
                            {data.map((item: any, index: any) => {
                                return (
                                    <Activity
                                        setDeleteSpin={setDeleteSpin}
                                        handleSuccessDelete={
                                            handleSuccessDelete
                                        }
                                        tab={tab}
                                        data={item}
                                        key={index}
                                    />
                                )
                            })}
                        </div>
                    ) : (
                        <Empty description={getTranslation('sin_datos')} />
                    )}
                </Spin>
            </div>
        </div>
    )
}
