import { Spin, Form, Button } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { CustomSelect } from '../common/custom-select/CustomSelect'
import { CustomInput } from '../common/custom-input/CustomInput'
import { CustomUploader } from '../common/custom-uploader/CustomUploader'
import { ACCOUNTS, NEW_ORGANIZATION } from '../../config/Path'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { CustomAutocomplete } from '../common/custom-autocomplete-input/CustomAutocomplete'
import { Language } from '../../config/Language'
interface FormValue {
    name: string | null
    typeId: number | null
    webSite: string | null
    categoryId: number | null
    subCategoryId: number | null
    file: any | null
}

interface IProps {
    setOrganizationId: (id: number | null) => void
    organization: any
    hasAccounts: boolean
}

interface RouteParams {
    id: string
}

export const OrganizationForm = ({ ...props }: IProps) => {
    const [form] = Form.useForm()
    const history = useHistory()
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { get, post } = useAxios()
    const { getTranslation } = Language()
    const { successObject, errorObjectWithData } = Notifications()
    const [saveSpin, setSaveSpin] = useState(false)
    const [organizationTypesSpin, setOrganizationTypesSpin] = useState(false)
    const [organizationTypes, setOrganizationTypes] = useState(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const { organization, setOrganizationId } = props
    const [imageSrc, setImageSrc] = useState<any>(undefined)
    const [file, setFile] = useState<any>(undefined)
    const [flagDisable, setFlagDisable] = useState(true)
    const [flagDisabledButton, setFlagDisabledButton] = useState(true)
    const [disabledAll, setDisabledAll] = useState(false)

    let { id } = useParams<RouteParams>()
    if (id && id === organization?.id) {
        setDisabledAll(true)
    }

    const loadOrganizationTypes = () => {
        setOrganizationTypesSpin(true)
        get(
            ENDPOINTS.GET_ORGANIZATIONS_TYPES,
            {},
            successOrganizationTypes,
            error,
            alwaysOrganizationTypes,
        )
    }

    const successOrganizationTypes = useCallback((response: any) => {
        setOrganizationTypesSpin(false)
        let result: any = response.data.data
        result = result.filter((item: any) => item.id !== 3)
        let dataResponse = result.reduce(
            (acc: any, item: any) => [
                ...acc,
                {
                    label: item.label || item.name || item.full_name,
                    value: item.id || item.value,
                },
            ],
            [],
        )
        let newFormValue: FormValue | any = formValue
        newFormValue.typeId = 1

        form.setFieldsValue(newFormValue)
        setFormValue(newFormValue)
        setOrganizationTypes(dataResponse)
    }, [])

    const alwaysOrganizationTypes = () => {
        setOrganizationTypesSpin(false)
    }

    useEffect(() => {
        if (!organizationTypes) {
            loadOrganizationTypes()
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (organization) {
            let newFormValue: FormValue | any = {
                name: organization.alias_name,
                typeId: organization.organization_type.id,
                webSite: organization.web_site,
                categoryId: organization.subcategory.category.id,
                subCategoryId: organization.subcategory.id,
                file: organization.file,
            }

            if (organization?.file) {
                setFile(organization?.file)
            }

            form.setFieldsValue(newFormValue)
            updateBreadCrumb(organization.alias_name)
            setFormValue(newFormValue)
        }
        return () => {}
    }, [organization])

    useEffect(() => {
        if (formValue.file !== imageSrc) {
            let newFormValue = { ...formValue }
            newFormValue.file = imageSrc
            setFormValue(newFormValue)
        }
        return () => {}
    }, [imageSrc])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        let olgName = formValue.name
        if (values.categoryId) {
            newFormValue.subCategoryId = null
        }
        setFormValue(newFormValue)
        if (organization && values.name && olgName && values.name !== olgName) {
            resetForm()
        }
    }

    const getValues = () => {
        let result: any = {
            name: formValue.name,
            typeId: formValue.typeId,
            webSite: formValue.webSite,
            categoryId: formValue.categoryId,
            subCategoryId: formValue.subCategoryId,
        }

        let formData = new FormData()
        formData.append('alias_name', result.name)
        formData.append('organization_type_id', result.typeId)
        formData.append('web_site', result?.webSite)
        formData.append('subcategory_id', result?.subCategoryId)

        if (file) {
            formData.append('file', file)
            return formData
        } else {
            return result
        }
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()

        post(
            ENDPOINTS.POST_ORGANIZATION,
            result,
            successSave,
            error,
            alwaysSave,
        )
    }

    const successSave = useCallback((response: any) => {
        setFlagDisabledButton(true)
        setSaveSpin(false)
        successObject(response)
        history.push(`${NEW_ORGANIZATION}/${response.data.data.id}`)
    }, [])

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    const onChangeFile = (imageSrc: any, file: any) => {
        setImageSrc(imageSrc)
        setFile(file)
    }

    const onSelect = (data: any, option: any) => {
        setFlagDisable(false)
        if (option) {
            setOrganizationId(option.id)
        } else {
            resetForm()
        }
    }

    const resetForm = () => {
        let newFormValue: FormValue | any = {
            name: formValue.name,
            typeId: '',
            webSite: '',
            categoryId: '',
            subCategoryId: '',
            file: '',
        }
        form.resetFields([
            'webSite',
            'typeId',
            'categoryId',
            'subCategoryId',
            'file',
        ])
        setFile(null)
        setOrganizationId(null)
        setFormValue(newFormValue)
    }

    const handleCancel = () => {
        history.push(ACCOUNTS)
    }

    return (
        <>
            <div id='organization-form'>
                <div className='header'>
                    <div className='header-title'>
                        {getTranslation('organization')}
                    </div>
                </div>
                <div className='body'>
                    <Spin
                        className='spin-general'
                        indicator={antIcon}
                        spinning={saveSpin || organizationTypesSpin}>
                        <Form
                            id='form-organization'
                            form={form}
                            layout='vertical'
                            scrollToFirstError
                            onValuesChange={onChange}
                            onFinish={onSave}
                            size='large'>
                            <div className='form-container'>
                                <div className='form-body'>
                                    <CustomAutocomplete
                                        label={getTranslation('nombre')}
                                        name='name'
                                        id='name'
                                        endpoint={
                                            ENDPOINTS.GET_ORGANIZATIONS_SEARCH
                                        }
                                        value={formValue?.name}
                                        required={true}
                                        onSelect={onSelect}
                                        disabled={
                                            (!!organization && flagDisable) ||
                                            disabledAll
                                        }
                                    />
                                    <CustomSelect
                                        label={getTranslation('tipo')}
                                        name='typeId'
                                        id='typeId'
                                        data={organizationTypes}
                                        value={formValue?.typeId}
                                        required={true}
                                        disabled={!!organization}
                                    />
                                    <CustomInput
                                        name='webSite'
                                        id='webSite'
                                        type='url'
                                        label={getTranslation('sitio_web')}
                                        disabled={!!organization}
                                        required={true}
                                    />

                                    <CustomSelect
                                        label={getTranslation('categoria')}
                                        name='categoryId'
                                        id='categoryId'
                                        endpoint={ENDPOINTS.GET_CATEGORIES}
                                        value={formValue?.categoryId}
                                        required={true}
                                        disabled={!!organization}
                                    />
                                    <CustomSelect
                                        label={getTranslation('sub_categoria')}
                                        name='subCategoryId'
                                        id='subCategoryId'
                                        endpoint={
                                            formValue.categoryId &&
                                            ENDPOINTS.GET_SUBCATEGORIES.replace(
                                                '{category}',
                                                formValue.categoryId,
                                            )
                                        }
                                        value={formValue?.subCategoryId}
                                        required={true}
                                        disabled={
                                            !!organization &&
                                            formValue.categoryId
                                        }
                                    />

                                    <CustomUploader
                                        className='form-tooltip'
                                        name='file'
                                        id='file'
                                        label={getTranslation(
                                            'logotipo_imagen',
                                        )}
                                        value={formValue.file}
                                        onChange={onChangeFile}
                                        inputId='uploader-input-organizations'
                                        showTooltips={true}
                                        required
                                        disabled={!!organization}
                                        tooltipTitle='Tamaño menor a 2MB Formato PGN, JPG o GIF Medida 400 x 400 pixels'
                                    />
                                </div>
                                <div className='buttons-footer'>
                                    {(!flagDisable ||
                                        !organization ||
                                        disabledAll) && (
                                        <>
                                            <Button
                                                type='primary'
                                                size='small'
                                                disabled={
                                                    organization &&
                                                    flagDisabledButton
                                                }
                                                htmlType='submit'>
                                                {getTranslation(
                                                    'guardar_cambios',
                                                )}
                                            </Button>

                                            <Button
                                                style={{ margin: '0 16px' }}
                                                ghost
                                                disabled={
                                                    organization &&
                                                    flagDisabledButton
                                                }
                                                type='primary'
                                                size='small'
                                                onClick={handleCancel}>
                                                {getTranslation('cancelar')}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </div>
        </>
    )
}
