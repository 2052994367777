/* eslint-disable no-loop-func */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
// @ts-ignore
import * as MapboxLanguage from '@mapbox/mapbox-gl-language'
import {
    Language,
    MAPBOX_STYLE_EN,
    MAPBOX_STYLE_ES,
    MAPBOX_STYLE_PT,
} from '../../../config/Language'
import { Spin } from 'antd'
import { antIcon } from '../../common/spin-icon/SpinIcon'
import { CONFIG } from '../../../config/Config'
import { useEffect, useRef, useState } from 'react'
import { CreateIconsList, IconsPois } from '../../common/icons-map/IconsMap'
import { CancelTokenSource } from 'axios'
import { useAxios } from '../../../hooks/useAxios'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Notifications } from '../../common/notifications/Notifications'
import { InfoAssetModalAbstract } from './InfoAssetModalAbstract'
import { IProposal } from '../../../interface/IProposal'
import { ReferenceAssetsMap } from './ReferenceAssetsMap'
import useAssetsClassificationContext from '../../../hooks/useAssetsClassificationContext'
mapboxgl.accessToken = CONFIG.MAPBOX_API_KEY
var geojsonExtent = require('@mapbox/geojson-extent')

interface IProps {
    assetsIds: number[] | undefined
}

export const AssetsMap = ({ ...props }: IProps) => {
    const { assetsIds } = props
    const { get } = useAxios()
    const { assetTypesList, geopathList, materialList } =
        useAssetsClassificationContext()
    const { errorObjectWithData, successObject } = Notifications()
    const { getTranslation, getTranslationMap } = Language()
    const mapContainer: any = useRef(null)
    const map: any = useRef(null)
    const [iconsList, setIconsList] = useState<any[]>(CreateIconsList())
    const [assetsListMap, setAssetsListMap] = useState<any>(null)
    const [pointId, setPointId] = useState<any>(null)
    const [visible, setVisible] = useState(false)
    const [assetsListMapSpin, setAssetsListMapSpin] = useState<boolean>(false)
    const [objectReferences, setObjectReferences] = useState<any[]>([])

    const [referencesClassifications, setReferencesClassifications] = useState<
        any[]
    >([])

    const classificationsAssets = () => {
        let referencesObj: any[] = []
        let totalLength =
            assetTypesList?.length + geopathList?.length + materialList?.length
        for (var i: any = 0; i < assetsListMap.features.length; i++) {
            if (referencesObj.length === totalLength) break
            materialList.map((item: any) => {
                if (
                    assetsListMap.features[i].properties.material_id ===
                        item.id &&
                    !referencesObj.find((element) => element.id === item.id)
                ) {
                    referencesObj.push({
                        id: item.id,
                        label: item.name,
                        key: 'material_id',
                        color: item.color,
                    })
                }
            })
            geopathList.map((item: any) => {
                if (
                    assetsListMap.features[i].properties
                        .geopath_clasification_id === item.id &&
                    !referencesObj.find((element) => element.id === item.id)
                ) {
                    referencesObj.push({
                        id: item.id,
                        label: item.name,
                        key: 'geopath_clasification_id',
                        symbol: item.svg_icon,
                    })
                }
            })
            assetTypesList.map((item: any) => {
                if (
                    assetsListMap.features[i].properties.asset_type_id ===
                        item.id &&
                    !referencesObj.find((element) => element.id === item.id)
                ) {
                    referencesObj.push({
                        id: item.id,
                        label: item.name,
                        key: 'asset_type_id',
                    })
                }
            })
        }

        setReferencesClassifications(referencesObj)
    }

    useEffect(() => {
        loadAssetsListMap()
    }, [])

    useEffect(() => {
        filterDataLayer()
        return () => {}
    }, [assetsListMap, objectReferences])

    useEffect(() => {
        assetsListMap && classificationsAssets()
    }, [assetsListMap])

    const handleSwitch = (checked: boolean, number: number, type: string) => {
        let newObject = {
            id: number,
            type,
            checked,
        }
        let index = objectReferences.findIndex(
            (item: any) => item.id === number,
        )
        if (index !== -1) objectReferences.splice(index, 1)
        setObjectReferences([...objectReferences, newObject])
    }

    const filterDataLayer = () => {
        if (objectReferences.length) {
            let dataCopy = { ...assetsListMap }
            objectReferences.map((item: any, index: any) => {
                if (!item.checked) {
                    dataCopy.features = dataCopy?.features.filter(
                        (aux: any) => aux.properties[item.type] !== item.id,
                    )
                }
            })
            return (
                map.current &&
                map.current.getSource('assets') &&
                map.current.getSource('assets').setData(dataCopy)
            )
        } else {
            return (
                map.current &&
                map.current.getSource('assets') &&
                map.current.getSource('assets').setData(assetsListMap)
            )
        }
    }

    const loadAssetsListMap = () => {
        setAssetsListMapSpin(true)
        get(
            ENDPOINTS.GET_PORTAL_GEOLOCATIONS,
            { asset_ids: assetsIds },
            successAssetsListMap,
            error,
            alwaysAssetsListMap,
        )
    }

    const successAssetsListMap = (response: any) => {
        setAssetsListMapSpin(false)
        setAssetsListMap(response.data)
    }

    const alwaysAssetsListMap = () => {
        setAssetsListMapSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    useEffect(() => {
        if (!assetsListMap?.features?.length) return
        if (map.current) return

        setTimeout(() => {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: getTranslationMap(),
                center: [-58, -34],
                zoom: 7,
            })

            map.current.addControl(
                new mapboxgl.NavigationControl(),
                'top-right',
            )

            map.current.addControl(
                new MapboxLanguage({ defaultLanguage: 'es' }),
            )

            map.current.on('load', () => {
                iconsList.forEach((icon) => {
                    let customIcon: HTMLImageElement = new Image(
                        icon.width,
                        icon.height,
                    )
                    customIcon.onload = () =>
                        map.current.addImage(icon.name, customIcon)
                    var utf8Image = `data:image/svg+xml, ${icon.svg}`
                    customIcon.src = utf8Image
                })

                map.current.addSource('assets', {
                    type: 'geojson',

                    data: assetsListMap,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                })

                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'assets',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#1890FF',
                            100,
                            '#69C0FF',
                            750,
                            '#BAE7FF',
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40,
                        ],
                    },
                })

                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'assets',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Bold',
                        ],
                        'text-size': 12,
                    },
                })

                map.current.addLayer({
                    id: 'points',
                    source: 'assets',
                    type: 'symbol',
                    layout: {
                        'icon-image': [...rulesIconImage],
                        'icon-anchor': 'bottom',
                        'icon-allow-overlap': true,
                        'symbol-avoid-edges': true,
                    },
                    filter: ['!', ['has', 'point_count']],
                })
            })
            map.current.on('click', 'clusters', (e: any) => {
                const features = map.current.queryRenderedFeatures(e.point, {
                    layers: ['clusters'],
                })
                const clusterId = features[0].properties.cluster_id
                map.current
                    .getSource('assets')
                    .getClusterExpansionZoom(
                        clusterId,
                        (err: any, zoom: any) => {
                            if (err) return

                            map.current.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom,
                            })
                        },
                    )
            })

            map.current.on('click', 'points', function (e: any) {
                map.current.flyTo({
                    center: e.features[0].geometry.coordinates,
                })
                setPointId(e.features[0].properties)
                setVisible(true)
            })

            map.current.on('mouseenter', 'points', () => {
                map.current.getCanvas().style.cursor = 'pointer'
            })

            map.current.on('mouseleave', 'points', () => {
                map.current.getCanvas().style.cursor = ''
            })

            map.current.on('mouseenter', 'clusters', () => {
                map.current.getCanvas().style.cursor = 'pointer'
            })
            map.current.on('mouseleave', 'clusters', () => {
                map.current.getCanvas().style.cursor = ''
            })

            map.current.fitBounds(geojsonExtent(assetsListMap), { padding: 20 })
        }, 333)
    }, [assetsListMap])

    const rulesByOtsAndSelected = (id: string) => {
        let withOutOts = `${id}_base_default`
        return withOutOts
    }

    const rulesIconImage = [
        'case', //materia
        ['==', ['get', 'material_id'], 11], //digital

        [
            'case', //entorno
            ['==', ['get', 'geopath_clasification_id'], 1], // establecimiento
            [
                'case', //tipos
                ['==', ['get', 'asset_type_id'], 13], //mural
                rulesByOtsAndSelected('mural_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 14], //cartelera
                rulesByOtsAndSelected('cartelera_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 15], //poster
                rulesByOtsAndSelected('poster_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 16], //posterPequeño
                rulesByOtsAndSelected('posterPequeno_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 17], //Panel
                rulesByOtsAndSelected('panel_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 18], //diplay
                rulesByOtsAndSelected('display_digital_establecimiento'),
                'iconPinPoi',
            ],
            ['==', ['get', 'geopath_clasification_id'], 2], //via-publica
            [
                'case', //tipos
                ['==', ['get', 'asset_type_id'], 13], //mural
                rulesByOtsAndSelected('mural_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 14], //cartelera
                rulesByOtsAndSelected('cartelera_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 15], //poster
                rulesByOtsAndSelected('poster_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 16], //posterPequeño
                rulesByOtsAndSelected('posterPequeno_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 17], //Panel
                rulesByOtsAndSelected('panel_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 18], //diplay
                rulesByOtsAndSelected('display_digital_viaPublica'),
                'iconPinPoi',
            ],
            'iconPinPoi',
        ],
        ['==', ['get', 'material_id'], 12], //impreso
        [
            'case', //entorno
            ['==', ['get', 'geopath_clasification_id'], 1], // establecimiento
            [
                'case', //tipos
                ['==', ['get', 'asset_type_id'], 13], //mural
                rulesByOtsAndSelected('mural_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 14], //cartelera
                rulesByOtsAndSelected('cartelera_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 15], //poster
                rulesByOtsAndSelected('poster_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 16], //posterPequeño
                rulesByOtsAndSelected('posterPequeno_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 17], //Panel
                rulesByOtsAndSelected('panel_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 18], //diplay
                rulesByOtsAndSelected('display_impreso_establecimiento'),
                'iconPinPoi',
            ],
            ['==', ['get', 'geopath_clasification_id'], 2], //via-publica
            [
                'case', //tipos
                ['==', ['get', 'asset_type_id'], 13], //mural
                rulesByOtsAndSelected('mural_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 14], //cartelera
                rulesByOtsAndSelected('cartelera_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 15], //poster
                rulesByOtsAndSelected('poster_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 16], //posterPequeño
                rulesByOtsAndSelected('posterPequeno_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 17], //Panel
                rulesByOtsAndSelected('panel_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 18], //diplay
                rulesByOtsAndSelected('display_impreso_viaPublica'),
                'iconPinPoi',
            ],
            'iconPinPoi',
        ],

        'iconPinPoi',
    ]

    let title = (title: string | undefined, code: string | undefined) => {
        // ejemplo es point
        let text = '',
            bgColor = '#595959',
            strokeColor = '#262626',
            strokeType = 'none',
            position = ''

        if (pointId) {
            if (pointId.asset_type_id === 13) {
                text = getTranslation('MU')
                position = '-9.637'
            } else if (pointId.asset_type_id === 14) {
                text = getTranslation('CA')
                position = '-8.137'
            } else if (pointId.asset_type_id === 15) {
                text = getTranslation('PO')
                position = '-8.014'
            } else if (pointId.asset_type_id === 16) {
                text = getTranslation('PP')
                position = '-7.014'
            } else if (pointId.asset_type_id === 17) {
                text = getTranslation('PA')
                position = '-7.014'
            } else if (pointId.asset_type_id === 18) {
                text = getTranslation('DI')
                position = '-6.137'
            }

            if (pointId.material_id === 11) {
                bgColor = '#F9F0FF'
                strokeColor = '#722ED1'
            } else if (pointId.material_id === 12) {
                bgColor = '#fffbe6'
                strokeColor = '#ad4e00'
            }

            if (pointId.geopath_clasification_id === 1) {
                strokeType = '2 4.5'
            } else if (pointId.geopath_clasification_id === 2) {
                strokeType = 'none'
            }
        }
        let icon = (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={30}
                viewBox='0 0 24 30'>
                <g transform='translate(-937 -90)'>
                    <circle
                        cx='2'
                        cy='2'
                        r='2'
                        transform='translate(947 116)'
                        fill={strokeColor}
                    />
                    <g
                        transform='translate(937 90)'
                        fill={bgColor}
                        stroke={strokeColor}
                        strokeDasharray={strokeType}>
                        <circle cx='12' cy='12' r='12' stroke='none' />
                        <circle cx='12' cy='12' r='11.6' fill='none' />
                    </g>
                    <text
                        transform='translate(949 106)'
                        fill={strokeColor}
                        fontSize='12px'
                        fontFamily='SegoeUI-Semibold, Segoe UI'
                        fontWeight='600'>
                        <tspan x={position} y='0'>
                            {text}
                        </tspan>
                    </text>
                </g>
            </svg>
        )

        return (
            <div className='header'>
                <div className='icon'>{icon}</div>
                <div className='info'>
                    <div className='title'>
                        {title ? title : getTranslation('comercial_name')}
                    </div>
                    <div className='name'>
                        {code
                            ? `${getTranslation('code')}: ${code}`
                            : getTranslation('without_code')}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Spin
            className='spin-local'
            indicator={antIcon}
            spinning={assetsListMapSpin}>
            <div className='map-container'>
                <div ref={mapContainer} className='map' />
                <ReferenceAssetsMap
                    referencesClassifications={referencesClassifications}
                    handleSwitch={handleSwitch}
                />
            </div>
            <InfoAssetModalAbstract
                title={title}
                pointId={pointId}
                visible={visible}
                setVisible={setVisible}
            />
        </Spin>
    )
}
