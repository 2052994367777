import { Button, Empty } from 'antd'
import { useEffect, useState } from 'react'
import { Language } from '../../config/Language'
import { BrandForm } from './BrandForm'

interface IProps {
    brandList: any[]
    organization: any
    loadOrganizationData: () => void
}

export const BrandList = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const { brandList, organization, loadOrganizationData } = props
    const [brandListData, setBrandListData] = useState<any[]>([])

    useEffect(() => {
        setBrandListData(brandList)
        return () => {}
    }, [brandList])

    const newBrand = () => {
        setBrandListData(['', ...brandListData])
    }

    const deleteNewBrand = () => {
        if (brandListData[0] === '') {
            let newArray = brandListData.filter((item: any) => item !== '')
            setBrandListData(newArray)
        }
    }

    const showBrandForm = () => {
        let result: any = []
        brandListData.map((item: any, index: any) => {
            result.push(
                <BrandForm
                    organizationId={organization?.id}
                    loadOrganizationData={loadOrganizationData}
                    key={index}
                    index={index}
                    brand={item}
                    deleteNewBrand={deleteNewBrand}
                />,
            )
        })
        return result
    }

    return (
        <>
            <div id='brand-form'>
                <div className='header'>
                    <div className='header-title'>
                        {getTranslation('brands')}
                    </div>
                    <div className='header-button'>
                        <Button
                            type='primary'
                            disabled={brandListData[0] === ''}
                            onClick={newBrand}
                            size='small'>
                            {getTranslation('crear_marca')}
                        </Button>
                    </div>
                </div>

                {brandListData.length > 0 ? (
                    showBrandForm()
                ) : (
                    <Empty
                        style={{ margin: '24px' }}
                        description={
                            <span>{getTranslation('crea_una_marca')}</span>
                        }></Empty>
                )}
            </div>
        </>
    )
}
