import { Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ENDPOINTS } from '../../config/EndPoints'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'

import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'

import { ContactsList } from '../accounts/ContactsList'
import { Language } from '../../config/Language'
import { VerdorForm } from './VendorForm'

interface RouteParams {
    id: string
}

export const VendorDetails = () => {
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { get } = useAxios()
    const { errorObjectWithData } = Notifications()
    const { getTranslation } = Language()
    const [generalSpin, setGeneralSpin] = useState(false)
    const [vendorSpin, setVendorSpin] = useState(false)

    const [vendorId, setVendorId] = useState<number | any>('')
    const [vendor, setVendor] = useState<any>(null)
    const [hasOrganizationVendor, setHasOrganizationVendor] =
        useState<any>(false)

    const [contactsList, setContactsList] = useState<any>([])

    let { id } = useParams<RouteParams>()

    if (id && id !== vendorId) {
        setVendorId(id)
    }

    const loadVendorData = () => {
        updateBreadCrumb(getTranslation(''))
        setVendorSpin(true)
        setGeneralSpin(true)
        get(
            ENDPOINTS.GET_ORGANIZATION.replace('{organization}', vendorId),
            {},
            success,
            error,
            always,
        )
    }

    useEffect(() => {
        if (vendorId !== '') {
            loadVendorData()
        } else {
            updateBreadCrumb(getTranslation('nuevo_medio'))
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (vendorId !== '' && vendorId !== null) {
            loadVendorData()
        } else {
            updateBreadCrumb(getTranslation('nuevo_medio'))
            setVendor(null)
            setHasOrganizationVendor(false)
        }
        return () => {}
    }, [vendorId])

    const success = useCallback((response: any) => {
        setVendorSpin(false)
        let vendor = response.data.organization
        let hasOrganizationVendor = !!response.data.organization_vendor
        let contacts = response.data.contacts || []
        setContactsList(contacts)
        setVendor(vendor)
        setHasOrganizationVendor(hasOrganizationVendor)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        setVendorSpin(false)
    }

    const handleVendorId = (id: number | null) => {
        if (id) {
            setVendorId(id)
        } else {
            setVendorId(null)
        }
    }

    if (!vendorSpin && generalSpin) {
        setGeneralSpin(false)
    }

    return (
        <>
            <Spin
                className='spin-general'
                indicator={antIcon}
                spinning={generalSpin}>
                <VerdorForm
                    setVendorId={handleVendorId}
                    vendor={vendor}
                    hasOrganizationVendor={hasOrganizationVendor}
                />

                {vendor && hasOrganizationVendor && (
                    <ContactsList
                        contactsList={contactsList}
                        organization={vendor}
                        loadOrganizationData={loadVendorData}
                    />
                )}
            </Spin>
        </>
    )
}
