import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import './asset/scss/index.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

import AuthContextProvider from './context/AuthContext'
import { AppRouter } from './components/router/AppRouter'
import UserContextProvider from './context/UserContext'
import CountryContextProvider from './context/CountryContext'
import BreadCrumbContextProvider from './context/BreadCrumbContext'
import CurrencyContextProvider from './context/CurrencyContext'
import AssetsClassificationContextProvider from './context/AssetsClassificationContext'

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <UserContextProvider>
                <CountryContextProvider>
                    <CurrencyContextProvider>
                        <BreadCrumbContextProvider>
                            <AssetsClassificationContextProvider>
                                <AppRouter />
                            </AssetsClassificationContextProvider>
                        </BreadCrumbContextProvider>
                    </CurrencyContextProvider>
                </CountryContextProvider>
            </UserContextProvider>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)
