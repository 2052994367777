import IconsList from '../IconsList'
import { NavLink } from 'react-router-dom'
import { useAxios } from '../../hooks/useAxios'
import { useCallback, useEffect, useState } from 'react'
import { ENDPOINTS } from '../../config/EndPoints'
import { Tooltip } from 'antd'
import useUserContext from '../../hooks/useUserContext'

interface IProps {
    setToggle: (bool: boolean) => void
    toggle: boolean
}

export const SideBar = ({ ...props }: IProps) => {
    const { setToggle, toggle } = props
    const { user } = useUserContext()
    const { get } = useAxios()
    const [menuItems, setMenuItems] = useState<any[]>([])

    useEffect(() => {
        user && get(ENDPOINTS.GET_MENU, {}, success, error, always)
        return () => {}
    }, [user])

    const success = useCallback((response: any) => {
        setMenuItems(response.data.data)
    }, [])

    const error = (errors: any) => {}

    const always = () => {}

    const RenderMenu = () => {
        let data = menuItems.map((item: any, index: any) => {
            let content: any = IconsList(item.icon)
            if (typeof content === 'string') {
                content = <img src={content} alt='icon-menu' />
            }
            let text = <span className='text-menu'>{item.name}</span>
            const tooltip = <span className='tooltip-menu'>{item.name}</span>

            return (
                <NavLink
                    key={index}
                    to={item.url}
                    activeClassName='menu-item-active'
                    onClick={() => setToggle(!toggle)}>
                    <Tooltip
                        id='menu-tooltip'
                        placement='right'
                        color='#13023E'
                        key='#13023E'
                        trigger='hover'
                        title={tooltip}>
                        <span className={`cursor menu-item`}>
                            <div className='selected'></div>
                            <div className='item'>
                                {content}
                                {text}
                            </div>
                        </span>
                    </Tooltip>
                </NavLink>
            )
        })
        return data
    }

    return (
        <>
            <div
                className={`backdrop ${toggle ? 'active' : ''}`}
                onClick={() => setToggle(!toggle)}></div>
            <div id='sidebar' className={toggle ? 'active' : ''}>
                <div className='menu'>{menuItems && RenderMenu()}</div>
                <div className='logo-tdt'>
                    <div className='logo'></div>
                </div>
            </div>
        </>
    )
}
