import { Button, Modal, Form, Spin } from 'antd'
import { useState } from 'react'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Language } from '../../../config/Language'
import { useAxios } from '../../../hooks/useAxios'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomUploader } from '../../common/custom-uploader/CustomUploader'
import { Notifications } from '../../common/notifications/Notifications'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    showModal: boolean
    globalValue: any
    setShowModal: (bool: boolean) => void
    setGlobalValue: (aux: any) => void
    advertiserId: any
}

interface FormValue {
    name: string | null
    file: any | null
}

export const BrandFormModal = ({ ...props }: IProps) => {
    const {
        showModal,
        globalValue,
        advertiserId,
        setShowModal,
        setGlobalValue,
    } = props
    const [form] = Form.useForm()
    const { getTranslation } = Language()
    const { post } = useAxios()
    const { errorObjectWithData, successObject } = Notifications()
    const [saveSpin, setSaveSpin] = useState(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [file, setFile] = useState<any>(undefined)
    const [imageSrc, setImageSrc] = useState<any>(undefined)

    const onChange = (values: any) => {
        let newFormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let formData = new FormData()
        if (advertiserId) {
            formData.append('name', formValue.name)
            formData.append('organization_id', advertiserId.toString())
            formData.append('file', file)
        }

        return formData
    }

    const onChangeFile = (imageSrc: any, file: any) => {
        setImageSrc(imageSrc)
        setFile(file)
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()
        advertiserId &&
            post(ENDPOINTS.POST_BRANDS, result, successSave, error, alwaysSave)
    }

    const successSave = (response: any) => {
        setSaveSpin(false)
        setShowModal(false)

        let newGlobalValue: any = {
            ...globalValue,
            brand_id: response.data.data.id,
        }
        setGlobalValue(newGlobalValue)

        successObject(response)
    }

    const alwaysSave = () => {
        setSaveSpin(false)
        setFormValue({})
        form.setFieldsValue({ name: '', file: '' })
        setImageSrc('')
        setFile('')
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    return (
        <>
            <Modal
                title={getTranslation('crear_marca')}
                visible={showModal}
                width={800}
                onCancel={() => setShowModal(false)}
                footer={null}>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={saveSpin}>
                    <div id='modal-advertiser'>
                        <div className='body'>
                            <Form
                                className='form-advertiser'
                                form={form}
                                layout='vertical'
                                scrollToFirstError
                                onValuesChange={onChange}
                                size='large'>
                                <div className='form-container'>
                                    <div className='form-body'>
                                        <CustomInput
                                            name='name'
                                            id='name'
                                            label={getTranslation('nombre')}
                                            required={true}
                                        />
                                        <CustomUploader
                                            className='form-tooltip'
                                            name='file'
                                            id='file'
                                            label={getTranslation(
                                                'logotipo_imagen',
                                            )}
                                            value={formValue.file}
                                            onChange={onChangeFile}
                                            inputId='uploader-input-brand-advertiser'
                                            showTooltips={true}
                                            required
                                            tooltipTitle='Tamaño menor a 2MB Formato PGN, JPG o GIF Medida 400 x 400 pixels'
                                        />
                                    </div>
                                </div>
                            </Form>

                            <div className='footer'>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    onClick={() => setShowModal(false)}
                                    size='small'>
                                    {getTranslation('cancelar')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onSave}>
                                    {getTranslation('guardar')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}
