import { IContacts } from '../../interface/IContacts'
import { Spin, Form, Button } from 'antd'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { useCallback, useEffect, useState } from 'react'
import { useAxios } from '../../hooks/useAxios'
import { ENDPOINTS } from '../../config/EndPoints'
import { Notifications } from '../common/notifications/Notifications'
import { CustomInput } from '../common/custom-input/CustomInput'
import { Language } from '../../config/Language'
import { Functions } from '../../config/Functions'
import { CustomSelect } from '../common/custom-select/CustomSelect'
import { ICountries } from '../../interface/ICountries'
import useCountryContext from '../../hooks/useCountryContext'
import { CustomSelectGroup } from '../common/custom-select-group/CustomSelectGroup'
import { CustomDatePicker } from '../common/custom-date-picker/CustomDatePicker'
import moment from 'moment'
import { CustomTextArea } from '../common/custom-textarea/CustomTextArea'

interface IProps {
    contact: IContacts
    deleteNewContact: () => void
    loadOrganizationData: () => void
    organization: any
    index: number
    setPanelId: (id: number) => void
}

interface FormValue {
    name: string | null
    lastName: string | null
    accountId: number | null
    countryId: number | null
    cityId: number | null
    contactTypeId: number | null
    contactPositionId: number | null
    gengerId: number | null
    email: string | null
    dateBirthday: string | null
    mobile: string | null
    phone: string | null
    observations: string | null
}

export const ContactForm = ({ ...props }: IProps) => {
    const {
        contact,
        deleteNewContact,
        loadOrganizationData,
        organization,
        setPanelId,
    } = props
    const { getLanguage } = Language()
    const [form] = Form.useForm()
    const { del, post, put } = useAxios()
    const { getTranslation } = Language()
    const { successObject, errorObjectWithData } = Notifications()
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const { country } = useCountryContext()
    const [saveSpin, setSaveSpin] = useState(false)
    const [deleteSpin, setDeleteSpin] = useState(false)
    const [flagDisabledButton, setFlagDisabledButton] = useState(true)

    useEffect(() => {
        loadForm()
        return () => {}
    }, [contact])

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFlagDisabledButton(false)
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            name: formValue.name,
            last_name: formValue.lastName,
            email: formValue.email,
            city_id: formValue.cityId,
            contact_type_id: formValue.contactTypeId,
        }

        if (formValue.dateBirthday) {
            result.birthday = formValue.dateBirthday.format('YYYY-MM-DD')
        }

        if (formValue.mobile) {
            result.mobile = formValue.mobile
        }

        if (formValue.phone) {
            result.phone = formValue.phone
        }

        if (formValue.observations) {
            result.observations = formValue.observations
        }

        if (formValue.gengerId) {
            result.gender_id = formValue.gengerId
        }

        if (formValue.contactPositionId) {
            result.contact_position_id = formValue.contactPositionId
        }

        return result
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()
        let endpoint = ENDPOINTS.POST_ACCOUNT_CONTACT.replace(
            '{account}',
            formValue.accountId,
        )
        if (organization.organization_type.id === 3) {
            endpoint = ENDPOINTS.POST_ORGANIZATION_CONTACT.replace(
                '{organization}',
                organization.id,
            )
        }
        if (contact) {
            put(
                ENDPOINTS.PUT_CONTACT.replace(
                    '{contact}',
                    contact.id.toString(),
                ),
                result,
                successSave,
                error,
                alwaysSave,
            )
        } else {
            post(endpoint, result, successSave, error, alwaysSave)
        }
    }

    const successSave = useCallback((response: any) => {
        setSaveSpin(false)
        successObject(response)
        setPanelId(contact.id)
        loadOrganizationData()
        setFlagDisabledButton(true)
    }, [])

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const loadForm = () => {
        if (contact) {
            let newFormValue: FormValue | any = {
                name: contact.name,
                lastName: contact.last_name,
                accountId: contact.account?.id,
                countryId: contact.city.state.country.id,
                cityId: contact.city.id,
                contactTypeId: contact.contact_type.id,
                contactPositionId: contact.contact_position?.id,
                gengerId: contact.gender?.id,
                email: contact.email,
                dateBirthday: contact.birthday && moment(contact.birthday),
                mobile: contact.mobile,
                phone: contact.phone,
                observations: contact.observations,
            }

            form.setFieldsValue(newFormValue)
            setFormValue(newFormValue)
        } else {
            let newFormValue: FormValue | any = {
                name: '',
                lastName: '',
                accountId: null,
                countryId: null,
                cityId: null,
                contactTypeId: null,
                contactPositionId: null,
                gengerId: null,
                email: '',
                date: '',
                mobile: '',
                phone: '',
                observations: '',
            }

            form.setFieldsValue(newFormValue)
            setFormValue(newFormValue)
        }
    }
    const handleCancel = () => {
        if (contact) {
            setFlagDisabledButton(true)
            loadForm()
        } else {
            deleteNewContact()
        }
    }

    const handleDelete = () => {
        setDeleteSpin(true)
        del(
            ENDPOINTS.DEL_CONTACT.replace('{contact}', contact.id.toString()),
            {},
            successDelete,
            error,
            alwaysDelete,
        )
    }

    const successDelete = useCallback((response: any) => {
        setDeleteSpin(false)
        successObject(response)
        loadOrganizationData()
    }, [])

    const alwaysDelete = () => {
        setDeleteSpin(false)
    }

    let countriesList: ICountries[] =
        country && Functions.getCountriesByLanguage(country)
    const handleStates = (response: any) => {
        let result: any = response.map((item: any, index: any) => {
            let cities = item.relationships.cities.map((element: any) => {
                return {
                    value: element.id,
                    label: Functions.getTraslateForStateAndCityByLanguage(
                        element,
                        getLanguage(),
                    ),
                }
            })
            return {
                label: Functions.getTraslateForStateAndCityByLanguage(
                    item,
                    getLanguage(),
                ),
                cities: cities,
                value: item.id,
            }
        })

        return result
    }
    return (
        <div className='body'>
            <Spin indicator={antIcon} spinning={saveSpin || deleteSpin}>
                <Form
                    id='form-contact'
                    form={form}
                    layout='vertical'
                    scrollToFirstError
                    onValuesChange={onChange}
                    onFinish={onSave}
                    size='large'>
                    <div className='form-container'>
                        <div className='form-body'>
                            <CustomInput
                                name='name'
                                id='name'
                                label={getTranslation('nombre')}
                                required={true}
                            />

                            <CustomInput
                                name='lastName'
                                id='lastName'
                                label={getTranslation('apellido')}
                                required={true}
                            />

                            {organization.organization_type.id !== 3 && (
                                <CustomSelect
                                    label={getTranslation('marca')}
                                    name='accountId'
                                    id='accountId'
                                    endpoint={ENDPOINTS.GET_ORGANIZATION_ACCOUNTS.replace(
                                        '{organization}',
                                        organization?.id,
                                    )}
                                    value={formValue?.accountId}
                                    required={true}
                                />
                            )}

                            <CustomSelect
                                label={getTranslation('pais')}
                                name='countryId'
                                id='countryId'
                                data={countriesList}
                                value={formValue?.countryId}
                                required={true}
                            />
                            <CustomSelectGroup
                                label={getTranslation('ciudad')}
                                name='cityId'
                                id='cityId'
                                endpoint={
                                    formValue?.countryId &&
                                    ENDPOINTS.GET_COUNTRY_STATES.replace(
                                        '{country}',
                                        formValue?.countryId,
                                    )
                                }
                                value={formValue?.cityId}
                                required={true}
                                handleSuccess={handleStates}
                                disabled={!formValue?.countryId}
                            />
                            <CustomSelect
                                label={getTranslation('tipo_de_contacto')}
                                name='contactTypeId'
                                id='contactTypeId'
                                endpoint={ENDPOINTS.GET_CONTACT_TYPES}
                                value={formValue?.contactTypeId}
                                required={true}
                            />
                            <CustomSelect
                                label={getTranslation('position')}
                                name='contactPositionId'
                                id='contactPositionId'
                                endpoint={ENDPOINTS.GET_CONTACT_POSITIONS}
                                value={formValue?.contactPositionId}
                            />
                            <CustomInput
                                name='email'
                                id='email'
                                type='email'
                                label={getTranslation('email')}
                                required={true}
                            />

                            <CustomSelect
                                label={getTranslation('genero')}
                                name='gengerId'
                                id='gengerId'
                                endpoint={ENDPOINTS.GET_CONTACT_GENDERS}
                                value={formValue?.gengerId}
                            />

                            <CustomDatePicker
                                label={getTranslation('fecha_de_nacimiento')}
                                name='dateBirthday'
                                id='dateBirthday'
                                type={'object' as const}
                                value={formValue.dateBirthday}
                            />

                            <CustomInput
                                name='mobile'
                                id='mobile'
                                label={getTranslation('mobile')}
                            />

                            <CustomInput
                                name='phone'
                                id='phone'
                                label={getTranslation('phone')}
                            />

                            <CustomTextArea
                                className='form-textarea'
                                name='observations'
                                id='observations'
                                label={getTranslation('observaciones')}
                            />
                        </div>
                        <div className='buttons-footer'>
                            <>
                                <Button
                                    type='primary'
                                    size='small'
                                    htmlType='submit'
                                    disabled={contact && flagDisabledButton}>
                                    {getTranslation('guardar_cambios')}
                                </Button>

                                <Button
                                    style={{ margin: '0 16px' }}
                                    ghost
                                    type='primary'
                                    size='small'
                                    disabled={contact && flagDisabledButton}
                                    onClick={handleCancel}>
                                    {getTranslation('cancelar')}
                                </Button>

                                <Button
                                    ghost
                                    type='primary'
                                    size='small'
                                    disabled={!contact}
                                    onClick={handleDelete}>
                                    {getTranslation('eliminar_contacto')}
                                </Button>
                            </>
                        </div>
                    </div>
                </Form>
            </Spin>
        </div>
    )
}
