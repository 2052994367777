import { Button } from 'antd'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Language } from '../../config/Language'
import { NOT_FOUND } from '../../config/Path'
import logo from '../../asset/img/404.svg'

export const NotFound = () => {
    const { getTranslation } = Language()
    const history = useHistory()
    useEffect(() => {
        history.push(NOT_FOUND)
        return () => {}
    }, [])
    return (
        <div id='not-found'>
            <img src={logo} alt='Not found' />

            <div className='text'>
                <h1>404</h1>
                <span> {getTranslation('page_not_found_text')}</span>
            </div>
            <Button type='primary' size='small' onClick={() => history.go(-2)}>
                {getTranslation('volver')}
            </Button>
        </div>
    )
}
