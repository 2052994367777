import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Modal, Tooltip, Table, Spin } from 'antd'
import { Language } from '../../../config/Language'
import { SliderFilters } from './SliderFilters'
import { IProposal } from '../../../interface/IProposal'
import { EnvironmentOutlined, MenuOutlined } from '@ant-design/icons'
import { IFilterDataProposal } from '../../../interface/IFilterDataProposal'
import { useAxios } from '../../../hooks/useAxios'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Notifications } from '../../common/notifications/Notifications'
import { antIcon } from '../../common/spin-icon/SpinIcon'
import { Functions } from '../../../config/Functions'
import useCurrencyContext from '../../../hooks/useCurrencyContext'

interface IProps {
    data: IProposal | null
    filterData: IFilterDataProposal
    selectionIdsList: any[]
    assetsSelected: any[]
    showMap: boolean
    loadPage: () => void
    addAssetsToProposal: () => void
    handleShowMap: (bool: boolean) => void
}

export const ProposalHeaderAssets = ({ ...props }: IProps) => {
    const {
        data,
        filterData,
        selectionIdsList,
        assetsSelected,
        showMap,
        loadPage,
        handleShowMap,
        addAssetsToProposal,
    } = props
    const { get, del } = useAxios()
    const { currency } = useCurrencyContext()
    const { getTranslation, getTranslationObject } = Language()
    const { successObject, errorObjectWithData } = Notifications()
    const [visible, setVisible] = useState(false)
    const [generalSpin, setGeneralSpin] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [selection, setSelection] = useState<any[]>([])
    const [selectionList, setSelectionList] = useState<any[]>([])
    const [totalElements, setTotalElements] = useState()
    const [page, setPage] = useState<any>({
        page: 1,
        per_page: 10,
    })

    const onSelectChange = (selectedRowKeys: any) => {
        setSelection(selectedRowKeys)
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setPage({
            page: pagination.current,
            per_page: pagination.pageSize,
        })
    }

    useEffect(() => {
        visible && loadAssetsList()
        return () => {}
    }, [visible])

    useEffect(() => {
        loadAssetsList()
        return () => {}
    }, [page])

    const loadAssetsList = () => {
        setGeneralSpin(true)
        data &&
            get(
                ENDPOINTS.GET_ELEMENT_PROPOSALS.replace(
                    '{proposal}',
                    data?.id.toString(),
                ),
                { ...page },
                successAssetsList,
                error,
                alwaysAssetsList,
            )
    }

    const successAssetsList = (response: any) => {
        setGeneralSpin(false)
        response.data.data.forEach((element: any) => {
            element.key = element.id
        })
        setTotalElements(response.data.meta.total)
        setSelectionList(response.data.data)
    }

    const alwaysAssetsList = () => {
        setGeneralSpin(false)
    }

    const deleteSelection = () => {
        setGeneralSpin(true)
        del(
            ENDPOINTS.DEL_ELEMENT_PROPOSALS,
            { ids: selection },
            deleteSuccess,
            error,
            deleteAlways,
        )
    }

    const deleteSuccess = (response: any) => {
        setGeneralSpin(false)
        setIsDelete(true)
        setSelection([])
        loadAssetsList()
        // successObject(response)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const deleteAlways = () => {
        // setGeneralSpin(false)
    }

    const columns: any[] = [
        {
            title: getTranslation('ciudad'),
            dataIndex: 'city',
            key: 'city',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element?.city.name,
                    b.element?.city.name,
                ),
            render: (city: any, item: any) => (
                <Tooltip placement='topLeft' title={item.element?.city.name}>
                    {item.element?.city.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('medio'),
            dataIndex: 'vendor',
            key: 'vendor',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element?.vendor.name,
                    b.element?.vendor.name,
                ),
            render: (vendor: any, item: any) => (
                <Tooltip placement='topLeft' title={item.element?.vendor.name}>
                    {item.element?.vendor.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('material'),
            dataIndex: 'material',
            key: 'material',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element?.material.name,
                    b.element?.material.name,
                ),
            render: (material: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={item.element?.material.name}>
                    {item.element?.material.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('entorno'),
            dataIndex: 'geopath_clasification',
            key: 'geopath_clasification',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element?.geopath_clasification.name,
                    b.element?.geopath_clasification.name,
                ),
            render: (geopath_clasification: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={item.element?.geopath_clasification.name}>
                    {item.element?.geopath_clasification.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('tipo'),
            dataIndex: 'asset_type',
            key: 'asset_type',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element?.asset_type.name,
                    b.element?.asset_type.name,
                ),
            render: (asset_type: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={item.element?.asset_type.name}>
                    {item.element?.asset_type.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('nombre_comercial'),
            dataIndex: 'suplier_alias',
            key: 'suplier_alias',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.element?.supplier_alias.name,
                    b.element?.supplier_alias.name,
                ),
            render: (suplier_alias: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={item.element?.supplier_alias.name}>
                    {item.element?.supplier_alias.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('ubicacion'),
            dataIndex: 'address',
            key: 'address',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.element?.address, b.element?.address),
            render: (address: any, item: any) => (
                <Tooltip placement='topLeft' title={item.element?.address}>
                    {item.element?.address}
                </Tooltip>
            ),
        },
    ]

    const rowSelection = {
        selection,
        onChange: onSelectChange,
    }

    const afterClose = () => {
        isDelete && loadPage()
    }

    const calcOTSTotal = () => {
        let otsTotal: any = 0
        selectionList.map((item: any, index: any) => {
            otsTotal += item.element?.ots
        })
        return otsTotal
    }

    const costTotal = () => {
        let costTotal: any = 0
        selectionList.map((item: any, index: any) => {
            costTotal += item.element?.price ? item.element?.price : 0
        })
        return costTotal
    }

    const calcCPMTotal = () => {
        return costTotal() / (calcOTSTotal() / 1000)
    }

    let currencyLabel: any =
        currency &&
        currency.find(
            (item: any, index: any) => item.value === data?.brief.currency_id,
        )

    return (
        <>
            <div className='header'>
                {/* <div className='align-left'> */}
                {!showMap && (
                    <>
                        <Button
                            type='primary'
                            size='small'
                            disabled={selectionIdsList.length === 0}
                            onClick={addAssetsToProposal}>
                            {getTranslation('agregar')}
                        </Button>
                        <Divider type='vertical' />
                    </>
                )}
                <SliderFilters filterData={filterData} />
                {/* </div> */}
                <div className='align-right'>
                    <Divider type='vertical' />
                    <Button
                        size='small'
                        onClick={() => handleShowMap(!showMap)}
                        icon={
                            showMap ? <MenuOutlined /> : <EnvironmentOutlined />
                        }>
                        {showMap
                            ? getTranslation('see_list')
                            : getTranslation('ver_mapa')}
                    </Button>
                    <Divider type='vertical' />
                    <Button
                        size='small'
                        className='selection-button'
                        onClick={() => setVisible(true)}>
                        {`${getTranslation('selection')} (${
                            assetsSelected.length
                        })`}
                    </Button>
                </div>
            </div>
            <Modal
                title={getTranslation('seleccion_de_soportes')}
                centered
                afterClose={afterClose}
                visible={visible}
                className='seleccion-modal'
                footer={null}
                onCancel={() => setVisible(false)}
                width={'90%'}>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={generalSpin}>
                    <Button
                        type='primary'
                        size='small'
                        onClick={deleteSelection}
                        disabled={selection.length === 0}>
                        {getTranslation('eliminar')}
                    </Button>
                    <div className='body-table'>
                        <Table
                            sticky
                            bordered
                            locale={getTranslationObject('table_trad')}
                            pagination={{
                                current: page.page,
                                pageSize: page.per_page,
                                total: totalElements,
                                showSizeChanger: true,
                                showTotal: (total: any, range: any) =>
                                    `${range[0]}-${range[1]} ${getTranslation(
                                        'of',
                                    )} ${total} ${getTranslation('items')}`,
                            }}
                            scroll={{ x: 800, y: 400 }}
                            columns={columns}
                            dataSource={selectionList}
                            rowSelection={rowSelection}
                            onChange={handleTableChange}
                        />
                    </div>
                    {selectionList.length !== 0 && (
                        <div className='cost-container'>
                            <div className='header'>
                                <div className='row'>
                                    <div className='title'>
                                        {getTranslation('soportes_totales')}
                                    </div>
                                    <div className='value'>
                                        {selectionList.length}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='title'>
                                        {getTranslation('total_OTS')}
                                    </div>
                                    <div className='value'>
                                        {Functions.formatNumber(calcOTSTotal())}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='title'>CPM</div>
                                    <div className='value'>
                                        {`${
                                            currencyLabel?.symbol
                                        } ${Functions.formatNumber(
                                            calcCPMTotal(),
                                        )}`}
                                    </div>
                                </div>
                            </div>
                            <div className='footer'>
                                <div className='row'>
                                    <div className='title'>
                                        {getTranslation('costo_total')}
                                    </div>
                                    <div className='value'>
                                        {`${
                                            currencyLabel?.symbol
                                        } ${Functions.formatNumber(
                                            costTotal(),
                                        )}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Spin>
            </Modal>
        </>
    )
}
