import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Modal } from 'antd'
import { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { ENDPOINTS } from '../../config/EndPoints'
import { Language } from '../../config/Language'
import {
    ADD_ADVERTISERS,
    ADVERTISERS,
    ADVERTISERS_DETAIL,
    HELP,
    PREVIEW_ADVERTISERS,
} from '../../config/Path'
import { CustomAutocomplete } from '../common/custom-autocomplete-input/CustomAutocomplete'
import { useHistory } from 'react-router-dom'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'

export const SearchCreateAdvertiser = () => {
    const [form] = Form.useForm()
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { getTranslation } = Language()
    const [formValue, setFormValue] = useState<any>({})
    const { get, post } = useAxios()
    const { successObject, errorObjectWithData } = Notifications()
    const [advertiserId, setAdvertiserId] = useState<any>(null)
    const [advertiserName, setAdvertiserName] = useState<any>(null)
    const [showModal, setShowModal] = useState<any>(false)
    const [searchAdvertiserSpin, setSearchAdvertiserSpin] = useState<any>(false)
    const [disabled, setDisabled] = useState<any>(true)
    const [accountSelected, setAccountSelected] = useState<any>()
    const history = useHistory()

    const onChange = (values: any) => {
        let newFormValue: any = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    useEffect(() => {
        updateBreadCrumb(getTranslation('add_advertiser'))
        return () => {}
    }, [])

    const onSelect = (data: any, option: any) => {
        if (option) {
            setDisabled(false)
            setAdvertiserId(option.id)
            setAdvertiserName(option.label)
        } else {
            setDisabled(true)
            resetForm()
        }
    }

    const resetForm = () => {
        let newFormValue: any = {
            name: formValue.name,
        }
        // setAdvertiserId(null)
        // setAdvertiserName(null)
        setFormValue(newFormValue)
    }

    const onSearchAdvertiser = () => {
        setSearchAdvertiserSpin(true)
        get(
            ENDPOINTS.GET_ADVERTISER_ACCOUNT_BY_ORGANIZATION.replace(
                '{organization}',
                advertiserId,
            ),
            {},
            successSearch,
            error,
            alwaysSearch,
        )
    }

    const successSearch = (response: any) => {
        setSearchAdvertiserSpin(false)
        if (response.data.data.length === 0) {
            history.push(`${PREVIEW_ADVERTISERS}/${advertiserId}`)
        } else {
            setAccountSelected(response.data.data[0].id)
            setShowModal(true)
        }
    }

    const alwaysSearch = () => {
        setSearchAdvertiserSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const modalHandleOK = () => {
        accountSelected &&
            history.push(`${ADVERTISERS_DETAIL}/${accountSelected.toString()}`)
    }

    return (
        <div id='search-create-advertiser'>
            <div className='header'>
                <div className='title'>
                    {getTranslation('select_advertaiser')}
                </div>
                <div className='help-text'>
                    <Link to={HELP}>{getTranslation('help')}</Link>
                </div>
            </div>
            <div className='body'>
                <div className='text'>
                    Switch nuclea infomación de numerosos anunciantes. Utiliza
                    el selector para comprobar si el anunciante con el que
                    trabajarás ya ha existe.
                    <div>Si no existe, puedes crear uno nuevo.</div>
                </div>
                <div className='search-advertiser'>
                    <Form
                        form={form}
                        layout='horizontal'
                        scrollToFirstError
                        onValuesChange={onChange}
                        size='small'>
                        <div className='form-container'>
                            <div className='form-body'>
                                <CustomAutocomplete
                                    label=''
                                    name='name'
                                    id='name'
                                    endpoint={ENDPOINTS.GET_ADVERTISER_SEARCH}
                                    value={formValue?.name}
                                    searchType='1'
                                    required={false}
                                    onSelect={onSelect}
                                />
                                <Button
                                    type='primary'
                                    size='small'
                                    disabled={disabled}
                                    loading={searchAdvertiserSpin}
                                    onClick={onSearchAdvertiser}>
                                    {getTranslation('next')}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className='create-advertiser'>
                    <Link to={ADD_ADVERTISERS}>
                        <PlusOutlined /> {getTranslation('create_advertiser')}
                    </Link>
                </div>
            </div>
            <div className='footer'>
                <Button ghost type='primary' size='small'>
                    <Link to={ADVERTISERS}>{getTranslation('cancelar')}</Link>
                </Button>
            </div>
            <Modal
                title={getTranslation('preview-advertisers')}
                visible={showModal}
                okText={getTranslation('see_advertiser')}
                cancelText={getTranslation('close')}
                onOk={modalHandleOK}
                onCancel={() => setShowModal(false)}>
                <p>
                    {`${advertiserName} ${getTranslation(
                        'modal_advertiser_exist',
                    )}`}
                </p>
            </Modal>
        </div>
    )
}
