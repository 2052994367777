import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Language } from '../../config/Language'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { IAccountGateway } from '../../interface/IAccountGateway'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { AccountDetail } from './components/AccountDetail'
import { BrandDetail } from './components/BrandDetail'
import { CompanyNamesDetail } from './components/CompanyNamesDetail'
import { ContactDetail } from './components/ContactDetail'

interface RouteParams {
    id: string
}

export const AdvertiserDetail = () => {
    let { id } = useParams<RouteParams>()
    const [accountData, setAccountData] = useState<IAccountGateway | null>(null)
    const [accountDataSpin, setAccountDataSpin] = useState(false)
    const [brandDataSpin, setBrandDataSpin] = useState(false)
    const [companyNamesDataSpin, setCompanyNamesDataSpin] = useState(false)
    const [contactDataSpin, setContactDataSpin] = useState(false)
    const [generalSpin, setGeneralSpin] = useState(false)
    const [advertiserId, setAdvertiserId] = useState<any>(null)
    if (id && id !== advertiserId) {
        setAdvertiserId(id)
    }

    useEffect(() => {
        if (
            accountDataSpin ||
            brandDataSpin ||
            contactDataSpin ||
            companyNamesDataSpin
        ) {
            setGeneralSpin(true)
        }
        if (
            !accountDataSpin &&
            !brandDataSpin &&
            !contactDataSpin &&
            !companyNamesDataSpin &&
            generalSpin
        ) {
            setGeneralSpin(false)
        }

        return () => {}
    }, [
        accountDataSpin,
        brandDataSpin,
        companyNamesDataSpin,
        contactDataSpin,
        generalSpin,
    ])

    return (
        <Spin
            className='spin-general'
            indicator={antIcon}
            spinning={generalSpin}>
            <div id='advertiser-detail'>
                <div className='container-top'>
                    <AccountDetail
                        id={id}
                        setAccountDataSpin={setAccountDataSpin}
                        accountData={accountData}
                        setAccountData={setAccountData}
                    />
                    <BrandDetail
                        accountData={accountData}
                        setBrandDataSpin={setBrandDataSpin}
                    />
                </div>
                <CompanyNamesDetail
                    accountData={accountData}
                    companyNamesDataSpin={companyNamesDataSpin}
                    setCompanyNamesDataSpin={setCompanyNamesDataSpin}
                />
                <ContactDetail
                    accountData={accountData}
                    contactDataSpin={contactDataSpin}
                    setContactDataSpin={setContactDataSpin}
                />
            </div>
        </Spin>
    )
}
