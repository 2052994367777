import { Button, Menu, Dropdown, Tabs } from 'antd'
import { Language } from '../../../config/Language'
import { useCallback, useState } from 'react'
import { IProposal } from '../../../interface/IProposal'
import { MoreOutlined } from '@ant-design/icons'
import { useAxios } from '../../../hooks/useAxios'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Notifications } from '../../common/notifications/Notifications'
import { useHistory } from 'react-router'
import { SEND_PROPOSAL } from '../../../config/Path'
import { RenderStatusProposal } from '../../common/render-status/RenderStatusProposal'

const { TabPane } = Tabs

interface IProps {
    data: IProposal | null
    tab: string
    assetsSelected: any[]
    loadPage: () => void
    setTab: (tab: string) => void
}

export const ProposalTabs = ({ ...props }: IProps) => {
    const { data, tab, loadPage, setTab, assetsSelected } = props
    const { put } = useAxios()
    const history = useHistory()
    const { successObject, errorObjectWithData } = Notifications()
    const { getTranslation } = Language()
    const [archiveProposalSpin, setArchiveProposalSpin] = useState(false)

    const handleTabs = (key: any) => {
        setTab(key)
    }

    const handleMenuClick = (e: any) => {
        if (e.key === '2') {
            setArchiveProposalSpin(true)
            put(
                ENDPOINTS.PUT_PROPOSAL_ARCHIVE.replace(
                    '{proposal}',
                    data?.id ? data?.id.toString() : '',
                ),
                {},
                success,
                error,
                always,
            )
        }
    }

    const success = useCallback((response: any) => {
        setArchiveProposalSpin(false)
        loadPage()
        successObject(response)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        setArchiveProposalSpin(false)
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key='1'>{getTranslation('editar_propuesta')}</Menu.Item>
            <Menu.Item key='2'>{getTranslation('archivar')}</Menu.Item>
        </Menu>
    )

    return (
        <div className='proposal-tabs'>
            <Tabs
                className='tabs'
                defaultActiveKey='2'
                activeKey={tab}
                onChange={handleTabs}>
                <TabPane tab={getTranslation('soportes')} key='1' />
                <TabPane tab={getTranslation('estrategia')} key='2' />
            </Tabs>

            <div className='right-side'>
                <div className='item-right-side'>
                    <div className='title'>{getTranslation('estado')}</div>
                    {data && RenderStatusProposal(data)}
                </div>
                <div className='item-right-side'>
                    <div className='title visible-not'>
                        {getTranslation('estado')}
                    </div>
                    <div className='primary-dropdown'>
                        <Button
                            type='primary'
                            size='small'
                            onClick={() =>
                                history.push(`${SEND_PROPOSAL}/${data?.id}`)
                            }
                            disabled={assetsSelected.length === 0}>
                            {getTranslation('confirmar')}
                        </Button>
                        <Dropdown trigger={['click']} overlay={menu}>
                            <Button
                                type='primary'
                                icon={<MoreOutlined rotate={90} />}
                                size='small'
                                loading={archiveProposalSpin}
                            />
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    )
}
