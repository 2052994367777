import { Form, Button, Divider, Spin, Checkbox } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Language } from '../../../config/Language'
import { ADVERTISERS_DETAIL } from '../../../config/Path'
import { useAxios } from '../../../hooks/useAxios'
import { CustomCheckbox } from '../../common/custom-checkbox/CustomCheckbox'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomUploader } from '../../common/custom-uploader/CustomUploader'
import { Notifications } from '../../common/notifications/Notifications'
import { ShowImage } from '../../common/show-img/ShowImage'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    next: () => void
    setGlobalData: (formValue: {}) => void
    globalData: any
}
interface FormValue {
    brandName: string
    brandFile: any
    checkName: boolean
    checkFile: boolean
}

export const BrandForm = ({ ...props }: IProps) => {
    const { next, setGlobalData, globalData } = props
    const [form] = Form.useForm()
    const history = useHistory()
    const { getTranslation } = Language()
    const [showConfirm, setShowConfirm] = useState(false)
    const [confirmSpin, setConfirmSpin] = useState(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [file, setFile] = useState<any>(undefined)
    const [imageSrc, setImageSrc] = useState<any>(undefined)
    const { successObject, errorObjectWithData } = Notifications()
    const { get, post } = useAxios()

    useEffect(() => {
        if (formValue.brandFile !== imageSrc) {
            let newFormValue = { ...formValue }
            newFormValue.brandFile = imageSrc
            setFormValue(newFormValue)
        }
        return () => {}
    }, [imageSrc])

    const onChange = (values: any, item: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        if (
            values.checkName !== undefined &&
            values.checkName !== formValue.checkName
        ) {
            newFormValue.brandName = values.checkName
                ? globalData.advertiserName
                : ''
        }
        if (
            values.checkFile !== undefined &&
            values.checkFile !== formValue.checkFile
        ) {
            newFormValue.brandFile = values.checkFile
                ? globalData.advertiserFileString
                : ''
        }
        form.setFieldsValue(newFormValue)
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            brandName: formValue.brandName,
        }

        let formData = new FormData()
        formData.append('name', result.brandName)
        formData.append('organization_id', globalData.organization_id)
        formData.append('file', file ? file : globalData.advertiserFile)

        return formData
    }

    const onSave = (values: any) => {
        setShowConfirm(true)
    }

    const onConfirm = (values: any) => {
        setConfirmSpin(true)
        let result = getValues()

        post(ENDPOINTS.POST_BRANDS, result, successSave, error, alwaysSave)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const successSave = (response: any) => {
        setConfirmSpin(false)
        successObject(response)
        setGlobalData({
            ...globalData,
            ...formValue,
            brandId: response.data.data.id,
        })
        next()
    }

    const alwaysSave = () => {
        setConfirmSpin(false)
    }

    const onChangeFile = (imageSrc: any, file: any) => {
        setImageSrc(imageSrc)
        setFile(file)
    }

    const handleCancel = () => {
        history.push(`${ADVERTISERS_DETAIL}/${globalData.advertiserId}`)
    }

    const handleBack = () => {
        setShowConfirm(false)
    }

    return (
        <>
            <Spin
                className='spin-local'
                indicator={antIcon}
                spinning={confirmSpin}>
                {!showConfirm && (
                    <Form
                        className='form-advertiser'
                        form={form}
                        layout='vertical'
                        scrollToFirstError
                        onValuesChange={onChange}
                        onFinish={onSave}
                        size='large'>
                        <div className='form-container'>
                            <div className='form-body'>
                                <div className='form-item-checkbox'>
                                    <CustomInput
                                        name='brandName'
                                        id='brandName'
                                        disabled={formValue.checkName}
                                        label={getTranslation('nombre')}
                                        required={true}
                                    />
                                    <CustomCheckbox
                                        label=''
                                        title={getTranslation(
                                            'same_name_advertiser',
                                        )}
                                        name='checkName'
                                        id='checkName'
                                        value={formValue.checkName}
                                    />
                                </div>
                                <div className='form-item-checkbox'>
                                    <CustomUploader
                                        className='form-tooltip'
                                        name='brandFile'
                                        id='brandFile'
                                        disabled={formValue.checkFile}
                                        label={getTranslation(
                                            'logotipo_imagen',
                                        )}
                                        value={formValue.brandFile}
                                        onChange={onChangeFile}
                                        inputId='uploader-input-advertiser'
                                        showTooltips={true}
                                        required
                                        tooltipTitle='Tamaño menor a 2MB Formato PGN, JPG o GIF Medida 400 x 400 pixels'
                                    />
                                    <CustomCheckbox
                                        label=''
                                        title={getTranslation(
                                            'same_logo_advertiser',
                                        )}
                                        name='checkFile'
                                        id='checkFile'
                                        value={formValue.checkFile}
                                    />
                                </div>
                            </div>
                            <div className='buttons-footer'>
                                <>
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        ghost
                                        type='primary'
                                        size='small'
                                        onClick={handleCancel}>
                                        {getTranslation('cancelar')}
                                    </Button>

                                    <Button
                                        type='primary'
                                        size='small'
                                        htmlType='submit'>
                                        {getTranslation('guardar')}
                                    </Button>
                                </>
                            </div>
                        </div>
                    </Form>
                )}
                {showConfirm && (
                    <div className='confirmation-advertiser'>
                        <div className='confirmation-data'>
                            <div className='logo-name'>
                                <ShowImage
                                    url={formValue.brandFile}
                                    alt={getTranslation('logo')}
                                />
                                <div className='description'>
                                    {formValue.brandName}
                                </div>
                            </div>
                        </div>
                        <div className='buttons-footer'>
                            <>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    size='small'
                                    onClick={handleCancel}>
                                    {getTranslation('cancelar')}
                                </Button>
                                <Divider
                                    type='vertical'
                                    style={{
                                        borderColor: '#E8E8E8',
                                        height: '1.5em',
                                    }}
                                />
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    size='small'
                                    onClick={handleBack}>
                                    {getTranslation('volver')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onConfirm}>
                                    {getTranslation('confirmar')}
                                </Button>
                            </>
                        </div>
                    </div>
                )}
            </Spin>
        </>
    )
}
