import { ICurrency } from '../interface/ICurrency'
import { ICurrencyContext } from '../interface/ICurrencyContext'
import { createContext } from 'react'
import { useCallback, useState, useMemo } from 'react'

export const CurrencyContext = createContext<any>({})

const CurrencyContextProvider = ({ children }: any) => {
    let initialState = null

    const [currency, setCurrency] = useState<ICurrency | null>(initialState)

    const updateCurrency = useCallback((user: ICurrency) => {
        setCurrency(user)
    }, [])

    const value: ICurrencyContext = useMemo(
        () => ({
            updateCurrency,
            currency,
        }),
        [currency, updateCurrency],
    )

    // @ts-ignore
    return (
        <CurrencyContext.Provider value={value}>
            {children}
        </CurrencyContext.Provider>
    )
}

export default CurrencyContextProvider
