import { Spin, Form, Button } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { CustomInput } from '../common/custom-input/CustomInput'
import { CustomUploader } from '../common/custom-uploader/CustomUploader'
import { VENDORS } from '../../config/Path'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { CustomAutocomplete } from '../common/custom-autocomplete-input/CustomAutocomplete'

import { Language } from '../../config/Language'
interface FormValue {
    name: string | null
    typeId: number | null
    webSite: string | null
    categoryId: number | null
    subCategoryId: number | null
    file: any | null
}

interface IProps {
    setVendorId: (id: number | null) => void
    vendor: any
    hasOrganizationVendor: boolean
}

export const VerdorForm = ({ ...props }: IProps) => {
    const [form] = Form.useForm()
    const history = useHistory()
    const { getTranslation } = Language()
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { post } = useAxios()
    const { successObject, errorObjectWithData } = Notifications()
    const [saveSpin, setSaveSpin] = useState(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const { vendor, setVendorId, hasOrganizationVendor } = props
    const [imageSrc, setImageSrc] = useState<any>(undefined)
    const [file, setFile] = useState<any>(undefined)

    const [flagDisable, setFlagDisable] = useState(true)
    const [flagDisabledButton, setFlagDisabledButton] = useState(true)

    const [saved, setSaved] = useState(false)

    useEffect(() => {
        if (vendor) {
            let newFormValue: FormValue | any = {
                name: vendor.alias_name,
                webSite: vendor.web_site,
                file: vendor.file,
            }

            if (vendor?.file) {
                setFile(vendor?.file)
            }

            form.setFieldsValue(newFormValue)
            updateBreadCrumb(vendor.alias_name)
            setFormValue(newFormValue)
        }
        return () => {}
    }, [vendor])

    useEffect(() => {
        if (formValue.file !== imageSrc) {
            let newFormValue = { ...formValue }
            newFormValue.file = imageSrc
            setFormValue(newFormValue)
        }
        return () => {}
    }, [imageSrc])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        let olgName = formValue.name

        setFormValue(newFormValue)
        if (vendor && values.name && olgName && values.name !== olgName) {
            resetForm()
        }
    }

    const getValues = () => {
        let result: any = {
            alias_name: formValue.name,
            web_site: formValue.webSite,
        }

        let formData = new FormData()
        formData.append('alias_name', result.alias_name)
        formData.append('web_site', result?.web_site)

        if (file) {
            formData.append('file', file)
            return formData
        } else {
            return result
        }
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()

        if (vendor && !hasOrganizationVendor) {
            result.append('vendor_id', vendor.id)
            post(
                ENDPOINTS.POST_ORGANIZATION_RELATION_VENDOR,
                result,
                successSave,
                error,
                alwaysSave,
            )
        } else if (!vendor) {
            post(
                ENDPOINTS.POST_ORGANIZATION_VENDOR,
                result,
                successSave,
                error,
                alwaysSave,
            )
        }
    }

    const successSave = useCallback((response: any) => {
        setFlagDisabledButton(true)
        setSaveSpin(false)
        successObject(response)
        if (response.data.id) {
            setVendorId(response.data.id)
        }
        setSaved(true)
    }, [])

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    const onChangeFile = (imageSrc: any, file: any) => {
        setImageSrc(imageSrc)
        setFile(file)
    }

    const onSelect = (data: any, option: any) => {
        setFlagDisable(false)
        if (option) {
            setVendorId(option.id)
        } else {
            resetForm()
        }
    }

    const resetForm = () => {
        let newFormValue: FormValue | any = {
            name: formValue.name,
            webSite: '',
            file: '',
        }
        form.resetFields(['webSite', 'file'])
        setFile(null)
        setVendorId(null)
        setFlagDisable(true)
        setFormValue(newFormValue)
        setVendorId(null)
    }

    const isDisabledBottons = () => {
        let bool = false
        if (hasOrganizationVendor || saved) {
            bool = true
        }
        if (vendor && hasOrganizationVendor) {
            bool = true
        }
        return bool
    }

    const handleCancel = () => {
        history.push(VENDORS)
    }

    return (
        <>
            <div id='vendor-form'>
                <div className='header'>
                    <div className='header-title'>
                        {getTranslation('medio')}
                    </div>
                </div>
                <div className='body'>
                    <Spin
                        className='spin-general'
                        indicator={antIcon}
                        spinning={saveSpin}>
                        <Form
                            id='form-vendor'
                            form={form}
                            layout='vertical'
                            scrollToFirstError
                            onValuesChange={onChange}
                            onFinish={onSave}
                            size='large'>
                            <div className='form-container'>
                                <div className='form-body'>
                                    <CustomAutocomplete
                                        label='Nombre'
                                        name='name'
                                        id='name'
                                        endpoint={
                                            ENDPOINTS.GET_ORGANIZATIONS_SEARCH
                                        }
                                        value={formValue?.name}
                                        required={true}
                                        searchType='3'
                                        onSelect={onSelect}
                                        disabled={!!vendor && flagDisable}
                                        // disabled={isDisabled()}
                                    />

                                    <CustomUploader
                                        className='form-tooltip'
                                        name='file'
                                        id='file'
                                        label='Logotipo o imagen'
                                        value={formValue.file}
                                        onChange={onChangeFile}
                                        inputId='uploader-input-organizations'
                                        showTooltips={true}
                                        required
                                        disabled={!!vendor}
                                        tooltipTitle='Tamaño menor a 2MB Formato PGN, JPG o GIF Medida 400 x 400 pixels'
                                    />

                                    <CustomInput
                                        name='webSite'
                                        id='webSite'
                                        type='url'
                                        label='Sitio Web'
                                        disabled={!!vendor}
                                        required={true}
                                    />
                                </div>
                                <div className='buttons-footer'>
                                    {/* {(!flagDisable || !vendor) && ( */}
                                    <>
                                        <Button
                                            type='primary'
                                            size='small'
                                            // disabled={
                                            //     vendor &&
                                            //     flagDisabledButton &&
                                            //     !hasOrganizationVendor
                                            // }
                                            disabled={isDisabledBottons()}
                                            htmlType='submit'>
                                            {getTranslation('guardar_cambios')}
                                        </Button>

                                        <Button
                                            style={{ margin: '0 16px' }}
                                            ghost
                                            // disabled={
                                            //     vendor &&
                                            //     flagDisabledButton &&
                                            //     !hasOrganizationVendor
                                            // }
                                            disabled={isDisabledBottons()}
                                            type='primary'
                                            size='small'
                                            onClick={handleCancel}>
                                            {getTranslation('cancelar')}
                                        </Button>
                                    </>
                                    {/* )} */}
                                </div>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </div>
        </>
    )
}
