import { Form, InputNumber } from 'antd'
import { useEffect, useState } from 'react'
import { Language } from '../../../config/Language'

interface IProps {
    id?: string
    label: string
    name: string
    className?: string
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    value?: any
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
}

export const CustomInputNumber = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        requiredMessage = getTranslation('required'),
        value,
    } = props
    const [selectedValue, setSelectedValue] = useState<any>(null)

    useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
        return () => {}
    }, [value])

    return (
        <Form.Item
            id={id}
            name={name}
            className={className}
            // valuePropName='option'
            label={label}
            rules={[
                {
                    required: required,
                    message: requiredMessage,
                },
            ]}>
            <InputNumber
                style={{ width: '100%' }}
                formatter={(value: any) =>
                    `$ ${value}`
                        .replace(/\./, ',')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                }
                parser={(x: any) => {
                    let a: any = parseFloat(
                        `${x}`
                            .replace('$ ', '')
                            .replace(/,/, '#')
                            .replace(/\./g, '')
                            .replace(/#/, '.'),
                    )
                    return a
                }}
            />
        </Form.Item>
    )
}
