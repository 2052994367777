import { Collapse, Switch } from 'antd'
import { Language } from '../../../config/Language'
import parse from 'html-react-parser'
const { Panel } = Collapse
interface IProps {
    referencesClassifications: IReferences[]
    handleSwitch: (checked: boolean, number: number, type: string) => void
}

interface IReferences {
    id: number
    label: string
    key: string
    symbol?: any
    color?: any
}

export const ReferenceAssetsMap = ({ ...props }: IProps) => {
    const { referencesClassifications, handleSwitch } = props
    const { getTranslation } = Language()

    const renderReferences = () => {
        let itemHtmlMaterial: any = []
        let itemHtmlGeoPath: any = []
        let itemHtmlTypes: any = []
        let panelMaterial = (
            <Panel header={getTranslation('material')} key='1'>
                <div>{itemHtmlMaterial}</div>
            </Panel>
        )
        let panelGeoPath = (
            <Panel header={getTranslation('entorno')} key='2'>
                <div>{itemHtmlGeoPath}</div>
            </Panel>
        )
        let panelTypes = (
            <Panel header={getTranslation('tipo')} key='3'>
                <div>{itemHtmlTypes}</div>
            </Panel>
        )
        referencesClassifications.map((item, index) => {
            if (item.key === 'material_id') {
                itemHtmlMaterial.push(
                    <div className='item' key={index}>
                        <div className='left'>
                            <div
                                className='icon material'
                                style={{
                                    backgroundColor: item.color
                                        ? item.color
                                        : 'red',
                                }}></div>
                            <div className='text'>{item.label}</div>
                        </div>
                        <div className='right'>
                            <Switch
                                size='small'
                                defaultChecked={true}
                                onClick={(e) =>
                                    handleSwitch(e, item.id, item.key)
                                }
                            />
                        </div>
                    </div>,
                )
            }
            if (item.key === 'geopath_clasification_id') {
                itemHtmlGeoPath.push(
                    <div className='item' key={index}>
                        <div className='left'>
                            <div className='icon geopath'>
                                {item.symbol ? parse(item.symbol) : ''}
                            </div>
                            <div className='text'>{item.label}</div>
                        </div>
                        <div className='right'>
                            <Switch
                                size='small'
                                defaultChecked={true}
                                onClick={(e) =>
                                    handleSwitch(e, item.id, item.key)
                                }
                            />
                        </div>
                    </div>,
                )
            }
            if (item.key === 'asset_type_id') {
                itemHtmlTypes.push(
                    <div className='item' key={index}>
                        <div className='left'>
                            <div className='icon assets-types'>
                                {item.label.slice(0, 2).toLocaleUpperCase()}
                            </div>
                            <div className='text'>{item.label}</div>
                        </div>
                        <div className='right'>
                            <Switch
                                size='small'
                                defaultChecked={true}
                                onClick={(e) =>
                                    handleSwitch(e, item.id, item.key)
                                }
                            />
                        </div>
                    </div>,
                )
            }
        })

        return [panelMaterial, panelGeoPath, panelTypes]
    }
    return (
        <div className='reference'>
            <div className='title'>{getTranslation('referencias')}</div>
            <div className='body'>
                <Collapse defaultActiveKey={['1', '2', '3']} ghost>
                    {renderReferences()}
                </Collapse>
            </div>
        </div>
    )
}
