// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
// @ts-ignore
import * as MapboxLanguage from '@mapbox/mapbox-gl-language'

import { useEffect, useRef, useState } from 'react'
import { CONFIG } from '../../../config/Config'

import { Collapse, Spin, Switch, Tooltip } from 'antd'
import {
    Language,
    MAPBOX_STYLE_EN,
    MAPBOX_STYLE_ES,
    MAPBOX_STYLE_PT,
} from '../../../config/Language'
import { antIcon } from '../../common/spin-icon/SpinIcon'
import { CreateIconsList, IconsPois } from '../../common/icons-map/IconsMap'
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { IFilterDataProposal } from '../../../interface/IFilterDataProposal'
import { MapReference } from './MapReference'
import { InfoAssetModal } from './InfoAssetModal'
import { IProposal } from '../../../interface/IProposal'
const { Panel } = Collapse
mapboxgl.accessToken = CONFIG.MAPBOX_API_KEY
var geojsonExtent = require('@mapbox/geojson-extent')
interface IProps {
    data: IProposal | null
    classname: string
    assetsSelected: any[]
    assetsListMap: any
    assetsListMapSpin: boolean
    filterData: IFilterDataProposal
}

export const ProposalMap = ({ ...props }: IProps) => {
    const {
        data,
        classname,
        assetsListMap,
        assetsListMapSpin,
        filterData,
        assetsSelected,
    } = props
    const { getTranslation, getTranslationMap } = Language()
    const [visible, setVisible] = useState(false)
    const [generalSpin, setGeneralSpin] = useState(false)
    const mapContainer: any = useRef(null)
    const map: any = useRef(null)
    const [lng, setLng] = useState(-58.477719)
    const [lat, setLat] = useState(-34.625007)
    const [zoom, setZoom] = useState(7)
    const [dataMap, setDataMap] = useState<any>()
    const [pointId, setPointId] = useState<any>(null)
    const [showOts, setShowOts] = useState<boolean>(false)
    const [objectLayer, setObjectLayer] = useState<any[]>([])
    const [showLayersMenu, setShowLayersMenu] = useState<boolean>(false)
    const [iconsList, setIconsList] = useState<any[]>(CreateIconsList())

    useEffect(() => {
        if (assetsListMap?.features?.length) {
            setDataMap(assetsListMap)
        }
        return () => {}
    }, [assetsListMap])

    useEffect(() => {
        map.current && handlePaintSize()
        return () => {}
    }, [showOts])

    useEffect(() => {
        filterDataLayer()
        return () => {}
    }, [dataMap, objectLayer])

    const rulesByOtsAndSelected = (id: string) => {
        let withOts = [
            'case', //ots
            ['==', ['get', 'ots_size'], 1], //xs
            [
                'case',
                ['in', ['get', 'id'], ['literal', assetsSelected]],
                `${id}_xs_selected`,
                `${id}_xs_default`,
            ],
            ['==', ['get', 'ots_size'], 2], //sm
            [
                'case',
                ['in', ['get', 'id'], ['literal', assetsSelected]],
                `${id}_sm_selected`,
                `${id}_sm_default`,
            ],
            ['==', ['get', 'ots_size'], 3], //md
            [
                'case',
                ['in', ['get', 'id'], ['literal', assetsSelected]],
                `${id}_md_selected`,
                `${id}_md_default`,
            ],
            ['==', ['get', 'ots_size'], 4], //lg
            [
                'case',
                ['in', ['get', 'id'], ['literal', assetsSelected]],
                `${id}_lg_selected`,
                `${id}_lg_default`,
            ],
            'iconPinPoi',
        ]
        let withOutOts = [
            'case',
            ['in', ['get', 'id'], ['literal', assetsSelected]],
            `${id}_base_selected`,
            `${id}_base_default`,
        ]
        return showOts ? withOts : withOutOts
    }

    const rulesIconImage = [
        'case', //materia
        ['==', ['get', 'material_id'], 11], //digital

        [
            'case', //entorno
            ['==', ['get', 'geopath_clasification_id'], 1], // establecimiento
            [
                'case', //tipos
                ['==', ['get', 'asset_type_id'], 13], //mural
                rulesByOtsAndSelected('mural_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 14], //cartelera
                rulesByOtsAndSelected('cartelera_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 15], //poster
                rulesByOtsAndSelected('poster_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 16], //posterPequeño
                rulesByOtsAndSelected('posterPequeno_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 17], //Panel
                rulesByOtsAndSelected('panel_digital_establecimiento'),
                ['==', ['get', 'asset_type_id'], 18], //diplay
                rulesByOtsAndSelected('display_digital_establecimiento'),
                'iconPinPoi',
            ],
            ['==', ['get', 'geopath_clasification_id'], 2], //via-publica
            [
                'case', //tipos
                ['==', ['get', 'asset_type_id'], 13], //mural
                rulesByOtsAndSelected('mural_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 14], //cartelera
                rulesByOtsAndSelected('cartelera_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 15], //poster
                rulesByOtsAndSelected('poster_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 16], //posterPequeño
                rulesByOtsAndSelected('posterPequeno_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 17], //Panel
                rulesByOtsAndSelected('panel_digital_viaPublica'),
                ['==', ['get', 'asset_type_id'], 18], //diplay
                rulesByOtsAndSelected('display_digital_viaPublica'),
                'iconPinPoi',
            ],
            'iconPinPoi',
        ],
        ['==', ['get', 'material_id'], 12], //impreso
        [
            'case', //entorno
            ['==', ['get', 'geopath_clasification_id'], 1], // establecimiento
            [
                'case', //tipos
                ['==', ['get', 'asset_type_id'], 13], //mural
                rulesByOtsAndSelected('mural_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 14], //cartelera
                rulesByOtsAndSelected('cartelera_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 15], //poster
                rulesByOtsAndSelected('poster_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 16], //posterPequeño
                rulesByOtsAndSelected('posterPequeno_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 17], //Panel
                rulesByOtsAndSelected('panel_impreso_establecimiento'),
                ['==', ['get', 'asset_type_id'], 18], //diplay
                rulesByOtsAndSelected('display_impreso_establecimiento'),
                'iconPinPoi',
            ],
            ['==', ['get', 'geopath_clasification_id'], 2], //via-publica
            [
                'case', //tipos
                ['==', ['get', 'asset_type_id'], 13], //mural
                rulesByOtsAndSelected('mural_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 14], //cartelera
                rulesByOtsAndSelected('cartelera_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 15], //poster
                rulesByOtsAndSelected('poster_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 16], //posterPequeño
                rulesByOtsAndSelected('posterPequeno_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 17], //Panel
                rulesByOtsAndSelected('panel_impreso_viaPublica'),
                ['==', ['get', 'asset_type_id'], 18], //diplay
                rulesByOtsAndSelected('display_impreso_viaPublica'),
                'iconPinPoi',
            ],
            'iconPinPoi',
        ],

        'iconPinPoi',
    ]

    useEffect(() => {
        if (map.current) return

        setTimeout(() => {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: getTranslationMap(),
                center: [lng, lat],
                zoom: zoom,
            })

            map.current.addControl(
                new mapboxgl.NavigationControl(),
                'top-right',
            )

            map.current.addControl(
                new MapboxLanguage({ defaultLanguage: 'es' }),
            )

            map.current.on('load', () => {
                iconsList.forEach((icon) => {
                    let customIcon: HTMLImageElement = new Image(
                        icon.width,
                        icon.height,
                    )
                    customIcon.onload = () =>
                        map.current.addImage(icon.name, customIcon)
                    var utf8Image = `data:image/svg+xml, ${icon.svg}`
                    customIcon.src = utf8Image
                })

                map.current.addSource('assets', {
                    type: 'geojson',

                    data: assetsListMap,
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
                })

                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'assets',
                    filter: ['has', 'point_count'],
                    paint: {
                        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                        // with three steps to implement three types of circles:
                        //   * Blue, 20px circles when point count is less than 100
                        //   * Yellow, 30px circles when point count is between 100 and 750
                        //   * Pink, 40px circles when point count is greater than or equal to 750
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#1890FF',
                            100,
                            '#69C0FF',
                            750,
                            '#BAE7FF',
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40,
                        ],
                    },
                })

                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'assets',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Bold',
                        ],
                        'text-size': 12,
                    },
                })

                map.current.addLayer({
                    id: 'points',
                    source: 'assets',
                    type: 'symbol',
                    layout: {
                        'icon-image': [...rulesIconImage],
                        'icon-anchor': 'bottom',
                        'icon-allow-overlap': true,
                        'symbol-avoid-edges': true,
                    },
                    filter: ['!', ['has', 'point_count']],
                })
            })
            map.current.on('click', 'clusters', (e: any) => {
                const features = map.current.queryRenderedFeatures(e.point, {
                    layers: ['clusters'],
                })
                const clusterId = features[0].properties.cluster_id
                map.current
                    .getSource('assets')
                    .getClusterExpansionZoom(
                        clusterId,
                        (err: any, zoom: any) => {
                            if (err) return

                            map.current.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom,
                            })
                        },
                    )
            })

            map.current.on('click', 'points', function (e: any) {
                map.current.flyTo({
                    center: e.features[0].geometry.coordinates,
                })
                setPointId(e.features[0].properties)
                setVisible(true)
            })

            map.current.on('mouseenter', 'points', () => {
                map.current.getCanvas().style.cursor = 'pointer'
            })

            map.current.on('mouseleave', 'points', () => {
                map.current.getCanvas().style.cursor = ''
            })

            map.current.on('mouseenter', 'clusters', () => {
                map.current.getCanvas().style.cursor = 'pointer'
            })
            map.current.on('mouseleave', 'clusters', () => {
                map.current.getCanvas().style.cursor = ''
            })

            map.current.fitBounds(geojsonExtent(assetsListMap), { padding: 20 })
        }, 333)
    }, [])

    const onChangeSwitch = (checked: boolean) => {
        setShowOts(checked)
    }

    const handlePaintSize = () => {
        map.current.setLayoutProperty('points', 'icon-image', [
            ...rulesIconImage,
        ])
    }

    const handleLayersMenu = () => {
        setShowLayersMenu(!showLayersMenu)
    }

    const handleSwitch = (checked: boolean, number: number, type: string) => {
        let newObject = {
            id: number,
            type,
            checked,
        }
        let index = objectLayer.findIndex((item: any) => item.id === number)
        if (index !== -1) objectLayer.splice(index, 1)
        setObjectLayer([...objectLayer, newObject])
    }

    const filterDataLayer = () => {
        if (objectLayer.length) {
            let dataCopy = { ...dataMap }
            objectLayer.map((item: any, index: any) => {
                if (!item.checked) {
                    dataCopy.features = dataCopy?.features.filter(
                        (aux: any) => aux.properties[item.type] !== item.id,
                    )
                }
            })
            return (
                map.current &&
                map.current.getSource('assets') &&
                map.current.getSource('assets').setData(dataCopy)
            )
        } else {
            return (
                map.current &&
                map.current.getSource('assets') &&
                map.current.getSource('assets').setData(dataMap)
            )
        }
    }

    const renderItemsLayers = () => {
        let result = []
        let itemHtmlVendors: any = []

        if (
            filterData.vendor_ids.array?.length &&
            filterData.vendor_ids.array?.length > 1
        ) {
            let panelVendors = (
                <Panel header={filterData.vendor_ids.title} key='1'>
                    <p>{itemHtmlVendors}</p>
                </Panel>
            )
            filterData.vendor_ids.array?.map((item, index) => {
                return itemHtmlVendors.push(
                    <div className='item' key={index}>
                        <div className='left'>
                            {filterData?.vendor_ids?.description &&
                                filterData?.vendor_ids?.description[index]}
                        </div>
                        <div className='right'>
                            <Switch
                                size='small'
                                defaultChecked={true}
                                onClick={(e) =>
                                    handleSwitch(e, item, 'vendor_id')
                                }
                            />
                        </div>
                    </div>,
                )
            })
            result.push(panelVendors)
        }

        let itemHtmlMaterial: any = []
        if (
            filterData.material_ids.array?.length &&
            filterData.material_ids.array?.length > 1
        ) {
            let panelMaterial = (
                <Panel header={filterData.material_ids.title} key='2'>
                    <p>{itemHtmlMaterial}</p>
                </Panel>
            )
            filterData.material_ids.array?.map((item, index) => {
                return itemHtmlMaterial.push(
                    <div className='item' key={index}>
                        <div className='left'>
                            {filterData?.material_ids?.description &&
                                filterData?.material_ids?.description[index]}
                        </div>
                        <div className='right'>
                            <Switch
                                size='small'
                                defaultChecked={true}
                                onClick={(e) =>
                                    handleSwitch(e, item, 'material_id')
                                }
                            />
                        </div>
                    </div>,
                )
            })
            result.push(panelMaterial)
        }

        let itemHtmlEntorno: any = []

        if (
            filterData.geopath_clasification_ids.array?.length &&
            filterData.geopath_clasification_ids.array?.length > 1
        ) {
            let panelEntorno = (
                <Panel
                    header={filterData.geopath_clasification_ids.title}
                    key='3'>
                    <p>{itemHtmlEntorno}</p>
                </Panel>
            )
            filterData.geopath_clasification_ids.array?.map((item, index) => {
                return itemHtmlEntorno.push(
                    <div className='item' key={index}>
                        <div className='left'>
                            {filterData?.geopath_clasification_ids
                                ?.description &&
                                filterData?.geopath_clasification_ids
                                    ?.description[index]}
                        </div>
                        <div className='right'>
                            <Switch
                                size='small'
                                defaultChecked={true}
                                onClick={(e) =>
                                    handleSwitch(
                                        e,
                                        item,
                                        'geopath_clasification_id',
                                    )
                                }
                            />
                        </div>
                    </div>,
                )
            })
            result.push(panelEntorno)
        }

        let itemHtmlAssetType: any = []

        if (
            filterData.asset_type_ids.array?.length &&
            filterData.asset_type_ids.array?.length > 1
        ) {
            let panelAssetType = (
                <Panel header={filterData.asset_type_ids.title} key='4'>
                    <p>{itemHtmlAssetType}</p>
                </Panel>
            )
            filterData.asset_type_ids.array?.map((item, index) => {
                return itemHtmlAssetType.push(
                    <div className='item' key={index}>
                        <div className='left'>
                            {filterData?.asset_type_ids?.description &&
                                filterData?.asset_type_ids?.description[index]}
                        </div>
                        <div className='right'>
                            <Switch
                                size='small'
                                defaultChecked={true}
                                onClick={(e) =>
                                    handleSwitch(e, item, 'asset_type_id')
                                }
                            />
                        </div>
                    </div>,
                )
            })
            result.push(panelAssetType)
        }

        if (result.length === 0) {
            result.push(
                <div className='empty-layers'>
                    {getTranslation('not_search_assets')}
                </div>,
            )
        }
        return result
    }

    const renderPoisLayers = () => {
        let result = []
        let itemHtmlPois: any = []

        if (
            filterData.poi_ids.array?.length &&
            filterData.poi_ids.array?.length > 1
        ) {
            let panelPois = <div className='pois-box'>{itemHtmlPois}</div>
            filterData.poi_ids.array?.map((item, index) => {
                let { icon, tooltipText } = IconsPois(item, 16)
                return itemHtmlPois.push(
                    <div className='item' key={index}>
                        <div className='left'>
                            {icon}
                            {filterData?.poi_ids?.description &&
                                filterData?.poi_ids?.description[index]}

                            <Tooltip title={tooltipText}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className='right'>
                            <Switch
                                size='small'
                                defaultChecked={true}
                                onClick={(e) => handleSwitch(e, item, 'poi_id')}
                            />
                        </div>
                    </div>,
                )
            })
            result.push(panelPois)
        }

        if (result.length === 0) {
            result.push(
                <div className='empty-layers'>
                    {getTranslation('not_search_assets')}
                </div>,
            )
        }
        return result
    }

    let title = (title: string | undefined, code: string | undefined) => {
        // ejemplo es point
        let text = '',
            bgColor = '#595959',
            strokeColor = '#262626',
            strokeType = 'none',
            position = ''

        if (pointId) {
            if (pointId.asset_type_id === 13) {
                text = getTranslation('MU')
                position = '-9.637'
            } else if (pointId.asset_type_id === 14) {
                text = getTranslation('CA')
                position = '-8.137'
            } else if (pointId.asset_type_id === 15) {
                text = getTranslation('PO')
                position = '-8.014'
            } else if (pointId.asset_type_id === 16) {
                text = getTranslation('PP')
                position = '-7.014'
            } else if (pointId.asset_type_id === 17) {
                text = getTranslation('PA')
                position = '-7.014'
            } else if (pointId.asset_type_id === 18) {
                text = getTranslation('DI')
                position = '-6.137'
            }

            if (pointId.material_id === 11) {
                bgColor = '#F9F0FF'
                strokeColor = '#722ED1'
            } else if (pointId.material_id === 12) {
                bgColor = '#fffbe6'
                strokeColor = '#ad4e00'
            }

            if (pointId.geopath_clasification_id === 1) {
                strokeType = '2 4.5'
            } else if (pointId.geopath_clasification_id === 2) {
                strokeType = 'none'
            }
        }
        let icon = (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={30}
                viewBox='0 0 24 30'>
                <g transform='translate(-937 -90)'>
                    <circle
                        cx='2'
                        cy='2'
                        r='2'
                        transform='translate(947 116)'
                        fill={strokeColor}
                    />
                    <g
                        transform='translate(937 90)'
                        fill={bgColor}
                        stroke={strokeColor}
                        strokeDasharray={strokeType}>
                        <circle cx='12' cy='12' r='12' stroke='none' />
                        <circle cx='12' cy='12' r='11.6' fill='none' />
                    </g>
                    <text
                        transform='translate(949 106)'
                        fill={strokeColor}
                        fontSize='12px'
                        fontFamily='SegoeUI-Semibold, Segoe UI'
                        fontWeight='600'>
                        <tspan x={position} y='0'>
                            {text}
                        </tspan>
                    </text>
                </g>
            </svg>
        )

        return (
            <div className='header'>
                <div className='icon'>{icon}</div>
                <div className='info'>
                    <div className='title'>
                        {title ? title : getTranslation('comercial_name')}
                    </div>
                    <div className='name'>
                        {code
                            ? `${getTranslation('code')}: ${code}`
                            : getTranslation('without_code')}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`map ${classname}`}>
            <div className='layers-container'>
                <div className='layers-button' onClick={handleLayersMenu}>
                    {getTranslation('layers')}
                    <DownOutlined />
                </div>

                <div
                    className={`layers-menu ${
                        showLayersMenu ? 'open' : 'close'
                    }`}>
                    <div className='block'>
                        <div className='title'>
                            {getTranslation('soportes')}
                        </div>
                        <div className='body'>
                            <Collapse
                                defaultActiveKey={['1', '2', '3', '4']}
                                ghost>
                                {renderItemsLayers()}
                            </Collapse>
                        </div>
                    </div>
                    <div className='block'>
                        <div className='title'>
                            {getTranslation('puntos_de_interes')}
                        </div>
                        <div className='body'>{renderPoisLayers()}</div>
                    </div>
                    <div className='block'>
                        <div className='title'>OTS</div>
                        <div className='body'>
                            <div className='item'>
                                <div className='left'>OTS</div>
                                <div className='right'>
                                    <Switch
                                        size='small'
                                        checked={showOts}
                                        onChange={onChangeSwitch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MapReference />

            <Spin
                className='spin-local'
                indicator={antIcon}
                spinning={assetsListMapSpin}>
                <div ref={mapContainer} className='map-container' />
            </Spin>

            <InfoAssetModal
                data={data}
                filterData={filterData}
                title={title}
                pointId={pointId}
                visible={visible}
                setVisible={setVisible}
            />
        </div>
    )
}
