import * as React from 'react'
import {
    Redirect,
    Route,
    RouteComponentProps,
    RouteProps,
} from 'react-router-dom'
import { LOGIN } from '../../config/Path'
import useAuthContext from '../../hooks/useAuthContext'
interface PrivateRouteParams extends RouteProps {
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>
}

export function PrivateRoute({
    component: Component,
    ...props
}: PrivateRouteParams) {
    const { isAuthenticated } = useAuthContext()

    return (
        <Route
            {...props}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: LOGIN,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    )
}
