import { Button, Form, Alert } from 'antd'
import { Language } from '../../../config/Language'
import { CustomRangePicker } from '../../common/custom-range-picker/CustomRangePicker'
import { CustomSelectMultiple } from '../../common/custom-select-multiple/CustomSelectMultiple'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { ENDPOINTS } from '../../../config/EndPoints'
import { useState, useEffect } from 'react'
import { RightOutlined } from '@ant-design/icons'
import moment from 'moment'
import { IProposal } from '../../../interface/IProposal'
import { IFilterDataProposal } from '../../../interface/IFilterDataProposal'

interface FormValue {
    rangesDates: any
    vendor_ids: any
    material_ids: any
    geopath_clasification_ids: any
    asset_type_ids: any
    poi_ids: any
    ots_min: any
    ots_max: any
}

interface IProps {
    data: IProposal | null
    flagFirstReloadFilters: boolean
    setFlagFirstReloadFilters: (bool: boolean) => void
    filterData: IFilterDataProposal
    filterShow: boolean
    filterList: any[]
    vendorsList: any[]
    materialList: any[]
    assetTypesList: any[]
    poisClasificationsList: any[]
    geopathList: any[]
    setParams: (formValue: any) => void
    setFilterShow: (bool: boolean) => void
    setFilterList: (array: any[]) => void
}

export const ProposalFilter = ({ ...props }: IProps) => {
    const {
        data,
        flagFirstReloadFilters,
        filterData,
        filterShow,
        filterList,
        vendorsList,
        materialList,
        assetTypesList,
        poisClasificationsList,
        geopathList,
        setParams,
        setFilterShow,
        setFilterList,
        setFlagFirstReloadFilters,
    } = props
    const { getTranslation } = Language()
    const [form] = Form.useForm()

    const [formValue, setFormValue] = useState<FormValue>({
        rangesDates: null,
        vendor_ids: null,
        material_ids: null,
        geopath_clasification_ids: null,
        asset_type_ids: null,
        poi_ids: null,
        ots_min: null,
        ots_max: null,
    })

    useEffect(() => {
        if (data) {
            let newFormValue: FormValue | any = {}

            if (
                Array.isArray(filterData.poi_ids.array) &&
                filterData.poi_ids.array?.length > 0
            ) {
                newFormValue.poi_ids = filterData.poi_ids.array
            }

            if (
                Array.isArray(filterData.vendor_ids.array) &&
                filterData.vendor_ids.array?.length > 0
            ) {
                newFormValue.vendor_ids = filterData.vendor_ids.array
            }

            if (
                Array.isArray(filterData.asset_type_ids.array) &&
                filterData.asset_type_ids.array?.length > 0
            ) {
                newFormValue.asset_type_ids = filterData.asset_type_ids.array
            }

            if (
                Array.isArray(filterData.geopath_clasification_ids.array) &&
                filterData.geopath_clasification_ids.array?.length > 0
            ) {
                newFormValue.geopath_clasification_ids =
                    filterData.geopath_clasification_ids.array
            }

            if (
                Array.isArray(filterData.material_ids.array) &&
                filterData.material_ids.array?.length > 0
            ) {
                newFormValue.material_ids = filterData.material_ids.array
            }

            if (flagFirstReloadFilters) {
                let result: any = newFormValue
                result.start_date = data.start_date
                result.end_date = data.end_date
                setParams(result)
                setFlagFirstReloadFilters(false)
            }

            newFormValue.rangesDates = [
                moment(
                    new Date(
                        filterData.start_date.description
                            ? filterData.start_date.description
                            : '',
                    ),
                ).utc(),
                moment(
                    new Date(
                        filterData.end_date.description
                            ? filterData.end_date.description
                            : '',
                    ),
                ).utc(),
            ]
            form.setFieldsValue(newFormValue)
            setFormValue(newFormValue)
        }
        return () => {}
    }, [data, filterData])

    const onClean = () => {
        let newFormValue: FormValue | any = {
            rangesDates: data && [
                moment(new Date(data.start_date)).utc(),
                moment(new Date(data.end_date)).utc(),
            ],
            vendor_ids: undefined,
            material_ids: undefined,
            geopath_clasification_ids: undefined,
            asset_type_ids: undefined,
            poi_ids: undefined,
            ots_min: undefined,
            ots_max: undefined,
        }
        let result: any = {}
        if (data) {
            result.start_date = data.start_date
            result.end_date = data.end_date
        }

        form.setFieldsValue(newFormValue)
        setParams(result)
        setFilterList([])
        setFormValue(newFormValue)
    }

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {}
        if (
            formValue.vendor_ids &&
            Array.isArray(formValue.vendor_ids) &&
            formValue.vendor_ids.length > 0
        ) {
            result.vendor_ids = formValue.vendor_ids
        }
        if (
            formValue.asset_type_ids &&
            Array.isArray(formValue.asset_type_ids) &&
            formValue.asset_type_ids.length > 0
        ) {
            result.asset_type_ids = formValue.asset_type_ids
        }
        if (
            formValue.poi_ids &&
            Array.isArray(formValue.poi_ids) &&
            formValue.poi_ids.length > 0
        ) {
            result.poi_ids = formValue.poi_ids
        }
        if (
            formValue.material_ids &&
            Array.isArray(formValue.material_ids) &&
            formValue.material_ids.length > 0
        ) {
            result.material_ids = formValue.material_ids
        }

        if (
            formValue.geopath_clasification_ids &&
            Array.isArray(formValue.geopath_clasification_ids) &&
            formValue.geopath_clasification_ids.length > 0
        ) {
            result.geopath_clasification_ids =
                formValue.geopath_clasification_ids
        }

        if (formValue.rangesDates && formValue.rangesDates.length > 0) {
            result.start_date = formValue.rangesDates[0].format('YYYY-MM-DD')
            result.end_date = formValue.rangesDates[1].format('YYYY-MM-DD')
        } else {
            if (data) {
                let newFormValue: FormValue | any = formValue
                newFormValue.rangesDates = data && [
                    moment(new Date(data.start_date)).utc(),
                    moment(new Date(data.end_date)).utc(),
                ]
                form.setFieldsValue(newFormValue)
                setFormValue(newFormValue)

                result.start_date = data.start_date
                result.end_date = data.end_date
            }
        }

        if (formValue.ots_min) {
            result.ots_min = formValue.ots_min
        }
        if (formValue.ots_max) {
            result.ots_max = formValue.ots_max
        }

        return result
    }

    const onSave = (values: any) => {
        let result = getValues()
        setParams(result)
    }

    const getValuesList = (item: any) => {
        let res = filterList.filter((element: any) => element.id !== item.id)
        if (item.label !== '') {
            setFilterList([...res, item])
        } else {
            setFilterList([...res])
        }
    }

    const disabledDate = (current: any) => {
        let startCheck = true
        let endCheck = true

        if (data?.start_date) {
            startCheck =
                current && current < moment(data?.start_date, 'YYYY-MM-DD')
        }
        if (data?.end_date) {
            endCheck = current && current > moment(data?.end_date, 'YYYY-MM-DD')
        }
        return (
            (!!data?.start_date && startCheck) || (!!data?.end_date && endCheck)
        )
    }

    return (
        <div className='filter'>
            <div className='title'>{getTranslation('filtros')}</div>
            <div className='filter-form'>
                <Alert
                    message={getTranslation('filters_message')}
                    style={{ alignItems: 'baseline' }}
                    type='info'
                    showIcon
                    closable
                />
                <Form
                    id='form-filter'
                    form={form}
                    layout='vertical'
                    scrollToFirstError
                    onValuesChange={onChange}
                    onFinish={onSave}
                    size='large'>
                    <div className='form-container'>
                        <div className='form-body'>
                            <CustomSelectMultiple
                                label={getTranslation('vendors')}
                                name='vendor_ids'
                                id='vendor_ids'
                                mode={'multiple' as const}
                                size='small'
                                data={vendorsList}
                                getValuesList={getValuesList}
                                value={formValue?.vendor_ids}
                            />
                            <CustomSelectMultiple
                                label={getTranslation('material')}
                                name='material_ids'
                                id='material_ids'
                                mode={'multiple' as const}
                                size='small'
                                getValuesList={getValuesList}
                                data={materialList}
                                value={formValue?.material_ids}
                            />
                            <CustomSelectMultiple
                                label={getTranslation('entorno')}
                                name='geopath_clasification_ids'
                                id='geopath_clasification_ids'
                                mode={'multiple' as const}
                                size='small'
                                getValuesList={getValuesList}
                                data={geopathList}
                                value={formValue?.geopath_clasification_ids}
                            />
                            <CustomSelectMultiple
                                label={getTranslation('tipo')}
                                name='asset_type_ids'
                                id='asset_type_ids'
                                mode={'multiple' as const}
                                size='small'
                                getValuesList={getValuesList}
                                data={assetTypesList}
                                value={formValue?.asset_type_ids}
                            />
                            <CustomRangePicker
                                label={getTranslation('fecha')}
                                name='rangesDates'
                                id='rangesDates'
                                size='small'
                                disabledDate={disabledDate}
                                // disabledDate={disabledDate}
                                // disabled={tab === '2'}
                                type={'array' as const}
                            />
                            <CustomSelectMultiple
                                label={getTranslation('puntos_de_interes')}
                                name='poi_ids'
                                id='poi_ids'
                                mode={'multiple' as const}
                                size='small'
                                getValuesList={getValuesList}
                                data={poisClasificationsList}
                                value={formValue?.poi_ids}
                            />

                            <div className='numbers'>
                                <CustomInput
                                    label={getTranslation('ots_min')}
                                    type='number'
                                    name='ots_min'
                                    size='small'
                                />

                                <CustomInput
                                    label={getTranslation('ots_max')}
                                    type='number'
                                    name='ots_max'
                                    size='small'
                                />
                            </div>

                            <div className='buttons'>
                                <Button
                                    style={{
                                        margin: '0 8px 0 0',
                                    }}
                                    size='small'
                                    onClick={onClean}>
                                    {getTranslation('clean_filters')}
                                </Button>
                                <Button
                                    type='primary'
                                    size='small'
                                    htmlType='submit'>
                                    {getTranslation('aplicar')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>

            <div className='button-collapse'>
                <Button
                    type='primary'
                    icon={
                        <RightOutlined className={filterShow ? 'open' : ''} />
                    }
                    onClick={() => setFilterShow(!filterShow)}
                    size='middle'
                />
            </div>
        </div>
    )
}
