import { Form, AutoComplete, Spin, Empty } from 'antd'
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { useAxios } from '../../../hooks/useAxios'
import { Language } from '../../../config/Language'
import { Link } from 'react-router-dom'

interface IProps {
    id?: string | any
    label: string
    name: string
    varToShow?: any
    required?: boolean
    requiredMessage?: string
    className?: string | any
    placeholder?: string
    endpoint?: string
    data?: any
    value?: any
    disabled?: boolean
    groupBy?: string
    params?: any
    tooltipTitle?: any
    help?: string
    showTooltips?: any
    onSelect?: any
    searchType?: string
    handleSuccess?: (data: any) => void
}

export const CustomAutocomplete = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        placeholder = getTranslation('select_a_option'),
        endpoint,
        value,
        params = {},
        handleSuccess,
        data,
        className,
        varToShow,
        tooltipTitle,
        onSelect,
        help,
        searchType,
        showTooltips = false,
    } = props
    const { get } = useAxios()

    const [selectedValue, setSelectedValue] = useState<any>(null)
    const [dataResponse, setDataResponse] = useState<any>([])
    const [localEndpoint, setLocalEndpoint] = useState<any>('')
    const [spinner, setSpinner] = useState<boolean>(false)

    const onSearch = (searchText: string) => {
        if (!searchText) {
            setDataResponse([])
        } else {
            getData(searchText)
        }
    }

    const onChange = (data: any, option: any) => {
        if (data === undefined) {
            onSelect(null, null)
        }
        setSelectedValue(data)
    }

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: '#13023E' }} spin />
    )

    useEffect(() => {
        if (value && dataResponse.length) {
            setSelectedValue(value)
        }

        return () => {}
    }, [dataResponse])

    useEffect(() => {
        if (selectedValue !== value && dataResponse.length) {
            setSelectedValue(value)
        }
        if (endpoint !== localEndpoint) {
            setLocalEndpoint(endpoint)
        }
        return () => {}
    }, [value, endpoint, data])

    const getData = (searchText?: any) => {
        setSpinner(true)
        if (endpoint) {
            setDataResponse([])
            let newEndpoint = `${endpoint}?text=${searchText}&type=${
                searchType || ''
            }`
            get(newEndpoint, {}, success, error, always)
        } else if (data) {
            let result = data.reduce(
                (acc: any, item: any) => [
                    ...acc,
                    {
                        label:
                            item[varToShow] ||
                            item.label ||
                            item.name ||
                            item.full_name,
                        value: item.id || item.value,
                    },
                ],
                [],
            )
            setDataResponse(result)
            setSpinner(false)
        }
    }

    const success = useCallback((response: any) => {
        let dataResponse: any = []
        if (handleSuccess) {
            dataResponse = handleSuccess(response.data.data)
        } else {
            let data: any = response.data.data
            let result = data
            dataResponse = result.reduce(
                (acc: any, item: any) => [
                    ...acc,
                    {
                        label:
                            item[varToShow] ||
                            item.label ||
                            item.name ||
                            item.full_name,
                        value:
                            item[varToShow] ||
                            item.label ||
                            item.name ||
                            item.full_name,
                        id: item.id || item.value,
                    },
                ],
                [],
            )
        }
        setDataResponse(dataResponse)
        setSpinner(false)
    }, [])

    const error = (errors: any) => {
        setSpinner(false)
    }

    const always = () => {
        setSpinner(false)
    }

    const configTooltips = showTooltips
        ? {
              title: tooltipTitle,
              icon: <InfoCircleOutlined />,
          }
        : false

    let labelContainer = (
        <div className='label-container-help'>
            {label} {help && <Link to={help}>{getTranslation('help')}</Link>}
        </div>
    )

    return (
        <>
            <Form.Item
                id={id}
                name={name}
                label={label && labelContainer}
                className={className}
                rules={[
                    {
                        required: required,
                        message: requiredMessage,
                    },
                ]}
                tooltip={configTooltips}>
                <AutoComplete
                    value={selectedValue}
                    options={dataResponse}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    onChange={onChange}
                    placeholder=''
                    allowClear
                    disabled={disabled}
                    notFoundContent={
                        spinner ? (
                            <Spin
                                className='spin-select'
                                indicator={antIcon}
                                size='small'
                            />
                        ) : (
                            ''
                        )
                    }
                />
            </Form.Item>
        </>
    )
}
