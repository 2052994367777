import { createContext, useCallback, useMemo, useState } from 'react'
import { LocalStorage } from '../common/LocalStorage'
import { IAuthContext } from '../interface/IAuthContext'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { Constants } from '../config/Constants'

export const AuthContext = createContext<any>({})

const AuthContextProvider = ({ children }: any) => {
    const getToken = () => {
        let token = LocalStorage.getToken()
        if (token) {
            let expirationTime = LocalStorage.getExpirationTime()
            let now = moment()
            let time = moment(expirationTime).add(
                Constants.EXPIRATION_TIME_KEYCLOAK,
                'm',
            )

            if (now >= time) {
                LocalStorage.removeToken()
                LocalStorage.removeRefreshToken()
                LocalStorage.removeUser()
                LocalStorage.removeExpirationTime()
                return false
            } else if (now < time) {
                return true
            }
        }
        return token
    }
    const [isAuthenticated, setIsAuthenticated] = useState<any>(getToken())

    const login = useCallback(
        (token: any, refresh: any) => {
            LocalStorage.setToken(token)
            LocalStorage.setRefreshToken(refresh)
            LocalStorage.setExpirationTime(moment().toString())
            let user = jwt_decode(token)
            LocalStorage.setUser(JSON.stringify(user))
            setIsAuthenticated(true)
        },
        [setIsAuthenticated],
    )

    const logout = useCallback(() => {
        LocalStorage.removeToken()
        LocalStorage.removeRefreshToken()
        LocalStorage.removeUser()
        LocalStorage.removeExpirationTime()
        setIsAuthenticated(false)
    }, [setIsAuthenticated])

    const value: IAuthContext = useMemo(
        () => ({
            login,
            logout,
            isAuthenticated,
        }),
        [isAuthenticated, login],
    )

    // @ts-ignore
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
