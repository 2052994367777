import { Bar } from '@ant-design/charts'
import { Empty } from 'antd'
import { Language } from '../../../config/Language'

export const CustomBarChart = () => {
    const { getTranslation } = Language()
    var data: any[] = [
        // {
        //     name: 'asd asdasd',
        //     value: 27,
        // },
        // {
        //     name: 'qwesdf sdf',
        //     value: 25,
        // },
        // {
        //     name: 'zxcsdfsd sdf sd',
        //     value: 18,
        // },
        // {
        //     name: 'qaze wewe  w',
        //     value: 15,
        // },
        // {
        //     name: 'wsx sdsdf  sdf',
        //     value: 10,
        // },
        // {
        //     name: 'rfv33e e edw  wd  d',
        //     value: 5,
        // },
    ]

    var config: any = {
        data: data,
        xField: 'value',
        yField: 'name',
        legend: { position: 'top-left' },
        barBackground: { style: { fill: 'rgba(0,0,0,0.1)' } },
        interactions: [
            {
                type: 'active-region',
                enable: false,
            },
        ],
    }
    return (
        <>
            {data.length > 0 ? (
                <div style={{ height: '400px' }}>
                    <Bar {...config} />
                </div>
            ) : (
                <div className='data-ant-empty'>
                    <Empty description={getTranslation('sin_datos')} />
                </div>
            )}
        </>
    )
}
