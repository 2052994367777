import useUserContext from '../hooks/useUserContext'

export const MAPBOX_STYLE_EN =
    'mapbox://styles/mfernandezbertola/ckrgvcc5140ml17okp4iwfkpd'
export const MAPBOX_STYLE_ES =
    'mapbox://styles/mfernandezbertola/cksot700c4zrr18louisxz8wg'
export const MAPBOX_STYLE_PT =
    'mapbox://styles/mfernandezbertola/cksot7y514zsk18lotghafqdt'

export const Language = () => {
    const { user } = useUserContext()
    let language = user?.locale

    let translated: any = ''
    if (language) {
        translated = require('../asset/lang/' +
            language +
            '/Export_translations')
    } else {
        translated = require('../asset/lang/es/Export_translations')
    }

    const getLanguage = (): string => {
        if (language) {
            return language
        }
        return 'es'
    }

    const getTranslation = (key: string): string => {
        if (translated.default[key]) {
            return translated.default[key]
        }

        console.log('traducción no encontrada para: ', key)
        return ''
    }

    const getTranslationObject = (key: string): any => {
        if (translated.default[key]) {
            return translated.default[key]
        }

        console.log('traducción no encontrada para: ', key)
        return ''
    }

    const getTranslationMap = (): any => {
        let style
        switch (getLanguage()) {
            case 'es':
                style = MAPBOX_STYLE_ES
                break
            case 'pt':
                style = MAPBOX_STYLE_PT
                break
            default:
                style = MAPBOX_STYLE_EN
                break
        }
        return style
    }

    return {
        getTranslationObject,
        getTranslation,
        getLanguage,
        getTranslationMap,
    }
}
