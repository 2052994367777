import { Button, Descriptions, Modal, Spin, Carousel, Image } from 'antd'
import { Language } from '../../../config/Language'
import { DownloadOutlined } from '@ant-design/icons'
import { antIcon } from '../../common/spin-icon/SpinIcon'
import { useCallback, useEffect, useState } from 'react'
import { ReactComponent as ImageDefault } from '../../../asset/img/image-default.svg'
import { Notifications } from '../../common/notifications/Notifications'
import { ENDPOINTS } from '../../../config/EndPoints'
import { useAxios } from '../../../hooks/useAxios'
import { IAssetData } from '../../../interface/IAssetData'
import { Constants } from '../../../config/Constants'
import { IProposal } from '../../../interface/IProposal'
import { IFilterDataProposal } from '../../../interface/IFilterDataProposal'

interface IProps {
    data: IProposal | null
    filterData: IFilterDataProposal
    pointId: any
    title: (title: string | undefined, code: string | undefined) => any
    visible: boolean
    setVisible: (bool: boolean) => void
}
export const InfoAssetModal = ({ ...props }: IProps) => {
    const { errorObjectWithData, successObject } = Notifications()
    const { get, post } = useAxios()
    const { data, title, visible, setVisible, pointId, filterData } = props
    const [previewImage, setPreviewImage] = useState<boolean>(false)
    const [assetSpin, setAssetSpin] = useState<boolean>(false)
    const [addAssetsSpin, setAddAssetsSpin] = useState<boolean>(false)
    const [assetData, setAssetData] = useState<IAssetData | null>()
    const { getTranslation } = Language()

    useEffect(() => {
        setAssetData(null)
        pointId && loadAsset()
        return () => {}
    }, [pointId])

    const addAssetsToProposal = () => {
        setAddAssetsSpin(true)
        post(
            ENDPOINTS.POST_ELEMENT_PROPOSALS,
            {
                elements_ids: [assetData?.id],
                proposal_id: data?.id,
                start_date: filterData.start_date.description,
                end_date: filterData.end_date.description,
            },
            successAdd,
            error,
            alwaysAdd,
        )
    }

    const successAdd = useCallback((response: any) => {
        setAddAssetsSpin(false)
        successObject(response)
    }, [])

    const alwaysAdd = () => {
        setAddAssetsSpin(false)
    }

    const loadAsset = () => {
        setAssetSpin(true)
        get(
            ENDPOINTS.GET_ELEMENT.replace('{id}', pointId.id),
            {},
            success,
            error,
            always,
        )
    }

    const success = useCallback((response: any) => {
        setAssetSpin(false)
        setAssetData(response.data.data)
    }, [])

    const always = () => {
        setAssetSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    return (
        <>
            <Modal
                title={title(assetData?.supplier_alias?.name, assetData?.code)}
                centered
                visible={visible}
                className='asset-info-modal'
                footer={[
                    <Button size='small'>
                        {getTranslation('download_specifications')}
                        <DownloadOutlined />
                    </Button>,
                    <Button
                        size='small'
                        onClick={addAssetsToProposal}
                        type='primary'>
                        {getTranslation('agregar')}
                    </Button>,
                ]}
                onCancel={() => setVisible(false)}
                width='820px'>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={assetSpin || addAssetsSpin}>
                    <div className='body'>
                        <div className='image'>
                            <Carousel>
                                {!assetData?.photos.data.length && (
                                    <div className='image-container'>
                                        <ImageDefault />
                                    </div>
                                )}
                                {assetData?.photos.data.map((item, index) => (
                                    <div
                                        className='image-container'
                                        key={index}>
                                        <img
                                            src={item.url}
                                            alt={item.description}
                                            className='img-gallery'
                                            onError={(ev) => {
                                                ev.currentTarget.src =
                                                    Constants.DEFAULT_IMG
                                                ev.currentTarget.className =
                                                    'img-default'
                                            }}
                                            onClick={() =>
                                                setPreviewImage(true)
                                            }
                                        />
                                    </div>
                                ))}
                            </Carousel>

                            <div style={{ display: 'none' }}>
                                <Image.PreviewGroup
                                    preview={{
                                        visible: previewImage,
                                        onVisibleChange: (vis) => {
                                            setPreviewImage(vis)
                                        },
                                    }}>
                                    {assetData?.photos.data.map(
                                        (item, index) => (
                                            <Image
                                                placeholder={
                                                    <img
                                                        src={
                                                            Constants.DEFAULT_IMG
                                                        }
                                                        alt={item.description}
                                                    />
                                                }
                                                alt={item.description}
                                                key={index}
                                                src={item.url}
                                                fallback={Constants.DEFAULT_IMG}
                                            />
                                        ),
                                    )}
                                </Image.PreviewGroup>
                            </div>
                        </div>
                        <div className='table'>
                            <Descriptions size='small' column={1} bordered>
                                {assetData?.price && (
                                    <Descriptions.Item
                                        label={getTranslation(
                                            'precio_unitario',
                                        )}>
                                        {assetData?.price}
                                    </Descriptions.Item>
                                )}
                                {assetData?.vendor.name && (
                                    <Descriptions.Item
                                        label={getTranslation('medio')}>
                                        {assetData?.vendor.name}
                                    </Descriptions.Item>
                                )}
                                {assetData?.material.name && (
                                    <Descriptions.Item
                                        label={getTranslation('material')}>
                                        {assetData?.material.name}
                                    </Descriptions.Item>
                                )}
                                {assetData?.geopath_clasification.name && (
                                    <Descriptions.Item
                                        label={getTranslation('entorno')}>
                                        {assetData?.geopath_clasification.name}
                                    </Descriptions.Item>
                                )}
                                {assetData?.asset_type.name && (
                                    <Descriptions.Item
                                        label={getTranslation('tipo')}>
                                        {assetData?.asset_type.name}
                                    </Descriptions.Item>
                                )}
                                {assetData?.address && (
                                    <Descriptions.Item
                                        label={getTranslation('ubicacion')}>
                                        {assetData?.address}
                                    </Descriptions.Item>
                                )}
                                {assetData?.ots && (
                                    <Descriptions.Item label='OTS'>
                                        {assetData?.ots}
                                    </Descriptions.Item>
                                )}
                                {assetData?.measure_unit.name && (
                                    <Descriptions.Item
                                        label={getTranslation(
                                            'commercial_measures',
                                        )}>
                                        {assetData?.measure_unit.name}
                                    </Descriptions.Item>
                                )}
                                {assetData?.description && (
                                    <Descriptions.Item
                                        label={getTranslation(
                                            'additional_information',
                                        )}>
                                        {assetData?.description}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}
