import { Affix } from 'antd'
import { useState } from 'react'
import { IFilterDataProposal } from '../../../interface/IFilterDataProposal'
import { IProposal } from '../../../interface/IProposal'
import { ProposalFilter } from './ProposalFilter'
import { ProposalHeaderAssets } from './ProposalHeaderAssets'
import { ProposalMap } from './ProposalMap'
import { ProposalTable } from './ProposalTable'

interface IProps {
    classname: string
    loadPage: () => void
    data: IProposal | null
    flagFirstReloadFilters: boolean
    filterData: IFilterDataProposal
    filterShow: boolean
    filterList: any[]
    vendorsList: any[]
    materialList: any[]
    assetTypesList: any[]
    poisClasificationsList: any[]
    geopathList: any[]
    selectionIdsList: any[]
    assetsSelected: any[]
    page: any
    totalElements: number
    assetsList: any[]
    assetsListSpin: boolean
    assetsListMap: any[]
    assetsListMapSpin: boolean
    setPage: ({}) => void
    setParams: (formValue: any) => void
    setFilterShow: (bool: boolean) => void
    setFilterList: (array: any[]) => void
    addAssetsToProposal: () => void
    setFlagFirstReloadFilters: (bool: boolean) => void
    setSelectionIdsList: (list: any[]) => void
}

export const AssetsComponent = ({ ...props }: IProps) => {
    const [showMap, setShowMap] = useState(false)
    const {
        classname,
        data,
        flagFirstReloadFilters,
        filterData,
        filterShow,
        filterList,
        vendorsList,
        materialList,
        assetTypesList,
        poisClasificationsList,
        geopathList,
        selectionIdsList,
        assetsSelected,
        assetsList,
        page,
        totalElements,
        assetsListSpin,
        assetsListMap,
        assetsListMapSpin,
        loadPage,
        setPage,
        addAssetsToProposal,
        setParams,
        setFilterShow,
        setFilterList,
        setFlagFirstReloadFilters,
        setSelectionIdsList,
    } = props

    const handleShowMap = (bool: boolean) => {
        bool && setFilterShow(false)
        setShowMap(bool)
    }
    return (
        <div className={classname}>
            <div className={`body-left ${filterShow ? 'open' : ''}`}>
                <Affix className='fixed' offsetTop={48}>
                    <ProposalFilter
                        data={data}
                        flagFirstReloadFilters={flagFirstReloadFilters}
                        setFlagFirstReloadFilters={setFlagFirstReloadFilters}
                        filterData={filterData}
                        filterShow={filterShow}
                        filterList={filterList}
                        vendorsList={vendorsList}
                        materialList={materialList}
                        assetTypesList={assetTypesList}
                        geopathList={geopathList}
                        poisClasificationsList={poisClasificationsList}
                        setParams={setParams}
                        setFilterShow={setFilterShow}
                        setFilterList={setFilterList}
                    />
                </Affix>
            </div>

            <div className={`body-right ${filterShow ? '' : 'open'}`}>
                <Affix offsetTop={48}>
                    <ProposalHeaderAssets
                        data={data}
                        filterData={filterData}
                        showMap={showMap}
                        selectionIdsList={selectionIdsList}
                        addAssetsToProposal={addAssetsToProposal}
                        assetsSelected={assetsSelected}
                        handleShowMap={handleShowMap}
                        loadPage={loadPage}
                    />
                </Affix>
                {showMap ? (
                    <ProposalMap
                        data={data}
                        assetsListMap={assetsListMap}
                        assetsListMapSpin={assetsListMapSpin}
                        assetsSelected={assetsSelected}
                        filterData={filterData}
                        classname={
                            showMap ? 'component-visible' : 'component-hidden'
                        }
                    />
                ) : (
                    <ProposalTable
                        classname={
                            showMap ? 'component-hidden' : 'component-visible'
                        }
                        page={page}
                        totalElements={totalElements}
                        setPage={setPage}
                        assetsListSpin={assetsListSpin}
                        assetsList={assetsList}
                        selectionIdsList={selectionIdsList}
                        setSelectionIdsList={setSelectionIdsList}
                        assetsSelected={assetsSelected}
                    />
                )}
            </div>
        </div>
    )
}
