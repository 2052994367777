import {
    RouteComponentProps,
    useHistory,
    useParams,
    withRouter,
} from 'react-router-dom'
import { Form, Button, Col, Row, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useAxios } from '../../hooks/useAxios'
import { ENDPOINTS } from '../../config/EndPoints'
import { CustomSelect } from '../common/custom-select/CustomSelect'
import { CustomSelectWithModal } from '../common/custom-select-with-modal/CustomSelectWithModal'
import { CustomSelectMultiple } from '../common/custom-select-multiple/CustomSelectMultiple'
import { CustomInput } from '../common/custom-input/CustomInput'
import { CustomInputNumber } from '../common/custom-input-number/CustomInputNumber'
import { CustomRangePicker } from '../common/custom-range-picker/CustomRangePicker'
import moment from 'moment'
import { CustomCheckboxGroup } from '../common/custom-checkbox/CustomCheckboxGroup'
import { CustomTextArea } from '../common/custom-textarea/CustomTextArea'
import useCountryContext from '../../hooks/useCountryContext'
import useCurrencyContext from '../../hooks/useCurrencyContext'
import { Functions } from '../../config/Functions'
import { ICountries } from '../../interface/ICountries'
import { IBrief } from '../../interface/IBrief'
import { CustomSelectGroup } from '../common/custom-select-group/CustomSelectGroup'
import { Language } from '../../config/Language'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { ICurrency } from '../../interface/ICurrency'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { BRIEFS, HELP, PROPOSAL } from '../../config/Path'
import { BrandFormModal } from './components/BrandFormModal'
import { CompanyNamesFormModal } from './components/CompanyNamesFormModal'
import { SliderContainer } from './components/SliderContainer'

interface FormValue {
    name: string | null
    cliente: string | null
    rangesDates: any | null
    brand_id: number | null
    currency_id: number | null
    budget_type_id: number | null
    country_campaign_id: number | null
    city_campaign_id: number | null
    country_payment_id: number | null
    budget: number | null
    company_name_id: number | null
    psychographic_profile: string | null
    communication_objective: string | null
    objectives: number[] | null
    specifications: number[] | null
}

interface RouteParams {
    id: string
}

const BriefForm = ({ ...props }: RouteComponentProps<any>) => {
    const { getLanguage, getTranslation } = Language()
    const { successObject, errorObjectWithData } = Notifications()
    const { updateBreadCrumb } = useBreadCrumbContext()
    const [form] = Form.useForm()
    const history = useHistory()
    const { get, post, put } = useAxios()
    const { country } = useCountryContext()
    const { currency } = useCurrencyContext()
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [objectivesFields, setObjectivesFields] = useState<any[]>([])
    const [specificationFields, setSpecificationFields] = useState<any[]>([])
    const [advertiserList, setAdvertiserList] = useState<any[]>([])
    const [objectiveSpin, setObjectiveSpin] = useState(false)
    const [specificationSpin, setSpecificationSpin] = useState(false)
    const [advertiserSpin, setAdvertiserSpin] = useState(false)
    const [briefSpin, setBriefSpin] = useState(false)
    const [spinGeneral, setSpinGeneral] = useState(false)
    const [briefId, setBriefId] = useState<string>('')
    const [brief, setBrief] = useState<any | null>(null)

    const [saveSpin, setSaveSpin] = useState(false)
    const [sendBool, setSendBool] = useState(false)
    const [brandModal, setBrandModal] = useState(false)
    const [companyNamesModal, setCompanyNamesModal] = useState(false)
    const [showTooltipSlider, setShowTooltipSlider] = useState(false)

    let { id } = useParams<RouteParams>()
    if (id && id !== briefId) {
        setBriefId(id)
    }

    useEffect(() => {
        setSpinGeneral(true)
        loadObjectives()
        loadSpecification()
        loadAdvertisers()
        if (briefId !== '') {
            loadBriefData()
        } else {
            updateBreadCrumb(getTranslation('nuevo_brief'))
        }
        return () => {}
    }, [])

    const getObjetSpecifIds = (arr: any[]) => {
        let list: any[] = []
        arr.map((item) => {
            item.relationships.subitems.map((elem: any) => {
                list.push(elem.id)
            })
        })
        return list
    }

    useEffect(() => {
        if (
            objectivesFields.length > 0 &&
            specificationFields.length > 0 &&
            brief
        ) {
            let genderList: any[] = []
            let socioeconomicList: any[] = []
            let rangeAgesList: any[] = []
            let campaignTypeList = ''
            let isPredeterminadoList = ''
            let formatTypeList: any[] = []
            let formatList: any[] = []
            let objectivePointsList: any[] = []

            let objetives = getObjetSpecifIds(brief?.relationships?.objectives)
            let specifications = getObjetSpecifIds(
                brief?.relationships?.specifications,
            )
            objectivesFields[0]?.subitems?.map((item: any, index: any) => {
                if (objetives?.find((aux: any) => aux === item.id)) {
                    genderList.push(item.id)
                }
            })
            objectivesFields[1]?.subitems?.map((item: any, index: any) => {
                if (objetives?.find((aux: any) => aux === item.id)) {
                    socioeconomicList.push(item.id)
                }
            })
            objectivesFields[2]?.subitems?.map((item: any, index: any) => {
                if (objetives?.find((aux: any) => aux === item.id)) {
                    rangeAgesList.push(item.id)
                }
            })
            specificationFields[0]?.subitems?.map((item: any, index: any) => {
                if (specifications?.find((aux: any) => aux === item.id)) {
                    campaignTypeList = item.id
                }
            })
            specificationFields[1]?.subitems?.map((item: any, index: any) => {
                if (specifications?.find((aux: any) => aux === item.id)) {
                    isPredeterminadoList = item.id
                }
            })
            specificationFields[2]?.subitems?.map((item: any, index: any) => {
                if (specifications?.find((aux: any) => aux === item.id)) {
                    formatTypeList.push(item.id)
                }
            })
            specificationFields[3]?.subitems?.map((item: any, index: any) => {
                if (specifications?.find((aux: any) => aux === item.id)) {
                    formatList.push(item.id)
                }
            })
            specificationFields[4]?.subitems?.map((item: any, index: any) => {
                if (specifications?.find((aux: any) => aux === item.id)) {
                    objectivePointsList.push(item.id)
                }
            })

            let newFormValue: FormValue | any = {
                cliente: brief.attributes.account_id,
                brand_id: brief.attributes.brand_id,
                name: brief.attributes.name,
                rangesDates: [
                    moment(new Date(brief.attributes.start_date)).utc(),
                    moment(new Date(brief.attributes.end_date)).utc(),
                ],
                budget_type_id: brief.attributes.budget_type_id,
                budget: brief.attributes.budget,
                gender: genderList,
                company_name_id: brief.attributes.company_name_id,
                socioeconomic: socioeconomicList,
                range_ages: rangeAgesList,
                psychographic_profile: brief.attributes.psychographic_profile,
                communication_objective:
                    brief.attributes.communication_objective,
                campaignType: campaignTypeList,
                isPredeterminado: isPredeterminadoList,
                formatType: formatTypeList,
                formatList: formatList,
                currency_id: brief.attributes.currency_id,
                objectivePoints: objectivePointsList,
                country_payment_id: brief.attributes.country_payment_id,
                country_campaign_id: brief.attributes.country_campaign_id,
                city_campaign_id: brief.attributes.city_campaign_id,
                impact: brief.attributes.impact ? brief.attributes.impact : 5,
                segmentation: brief.attributes.segmentation
                    ? brief.attributes.segmentation
                    : 5,
                coverage: brief.attributes.coverage
                    ? brief.attributes.coverage
                    : 5,
                frecuency: brief.attributes.frecuency
                    ? brief.attributes.frecuency
                    : 5,
            }
            updateBreadCrumb(brief.attributes.name)
            form.setFieldsValue(newFormValue)

            setFormValue(newFormValue)
        }
        return () => {}
    }, [brief, objectivesFields, specificationFields])

    useEffect(() => {
        form.setFieldsValue(formValue)
        return () => {}
    }, [formValue])

    const goToViolation = (id: any) => {
        document.getElementById(id)?.scrollIntoView({
            behavior: 'smooth',
        })
    }

    const loadBriefData = () => {
        setBriefSpin(true)
        get(
            ENDPOINTS.GET_BRIEF.replace('{brief}', briefId),
            {},
            successBrief,
            error,
            alwaysBrief,
        )
    }

    const loadObjectives = () => {
        setObjectiveSpin(true)
        get(
            ENDPOINTS.GET_BRIEF_OBJECTIVES,
            {},
            successObjectives,
            error,
            alwaysObjectives,
        )
    }
    const loadSpecification = () => {
        setSpecificationSpin(true)
        get(
            ENDPOINTS.GET_BRIEF_SPECIDICATIONS,
            {},
            successSpecification,
            error,
            alwaysSpecification,
        )
    }

    const loadAdvertisers = () => {
        setAdvertiserSpin(true)
        get(
            ENDPOINTS.GET_ACCOUNTS,
            {},
            successAdvertiders,
            error,
            alwaysAdvertiders,
        )
    }

    const successAdvertiders = useCallback((response: any) => {
        setAdvertiserSpin(false)
        let result: any = response.data.data
        let dataResponse = result.reduce(
            (acc: any, item: any) => [
                ...acc,
                {
                    label: item.label || item.name || item.full_name,
                    value: item.id || item.value,
                    account_id: item.organization_id,
                },
            ],
            [],
        )
        setAdvertiserList(dataResponse)
    }, [])

    const alwaysAdvertiders = () => {
        setAdvertiserSpin(false)
    }

    const successBrief = useCallback((response: any) => {
        setBriefSpin(false)
        setBrief(response.data.data)
    }, [])

    const successObjectives = useCallback((response: any) => {
        setObjectiveSpin(false)
        setObjectivesFields(response.data.data)
    }, [])
    const successSpecification = useCallback((response: any) => {
        setSpecificationSpin(false)
        setSpecificationFields(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const alwaysBrief = () => {
        setBriefSpin(false)
    }
    const alwaysSpecification = () => {
        setSpecificationSpin(false)
    }
    const alwaysObjectives = () => {
        setObjectiveSpin(false)
    }

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        if (values.cliente) {
            newFormValue.brand_id = null
        }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let gender = []
        let socioeconomic = []
        let range_ages = []
        let formatType = []
        let formatList = []
        let objectivePoints = []
        let campaignType = []
        let isPredeterminado = []
        if (formValue.gender) {
            gender = formValue.gender
        }
        if (formValue.socioeconomic) {
            socioeconomic = formValue.socioeconomic
        }
        if (formValue.range_ages) {
            range_ages = formValue.range_ages
        }
        if (formValue.campaignType) {
            campaignType.push(formValue.campaignType)
        }
        if (formValue.isPredeterminado) {
            isPredeterminado.push(formValue.isPredeterminado)
        }
        if (formValue.formatType) {
            formatType = formValue.formatType
        }
        if (formValue.formatList) {
            formatList = formValue.formatList
        }
        if (formValue.objectivePoints) {
            objectivePoints = formValue.objectivePoints
        }

        let result: any = {
            brand_id: formValue?.brand_id,
            name: formValue.name,
            start_date: formValue?.rangesDates[0].format('YYYY-MM-DD'),
            end_date: formValue?.rangesDates[1].format('YYYY-MM-DD'),
            currency_id: formValue.currency_id,
            company_name_id: formValue.company_name_id,
            budget_type_id: formValue.budget_type_id,
            country_campaign_id: formValue.country_campaign_id,
            city_campaign_id: formValue.city_campaign_id,
            country_payment_id: formValue.country_payment_id,
            budget: formValue.budget,
            objectives: [...gender, ...socioeconomic, ...range_ages],
            specifications: [
                ...campaignType,
                ...isPredeterminado,
                ...formatType,
                ...formatList,
                ...objectivePoints,
            ],
            impact: formValue.impact,
            segmentation: formValue.segmentation,
            coverage: formValue.coverage,
            frecuency: formValue.frecuency,
        }
        if (briefId !== null) {
            result.id = briefId
        }
        result.account_id = formValue.cliente

        if (formValue.psychographic_profile) {
            result.psychographic_profile = formValue.psychographic_profile
        }
        if (formValue.communication_objective) {
            result.communication_objective = formValue.communication_objective
        }
        return result
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()

        if (briefId) {
            put(
                ENDPOINTS.PUT_BRIEF.replace('{brief}', briefId),
                result,
                successSave,
                error,
                alwaysSave,
            )
        } else {
            post(ENDPOINTS.POST_BRIEF, result, successSave, error, alwaysSave)
        }
    }

    const onSend = () => {
        setSendBool(true)
        form.validateFields()
            .then((response) => {
                let result = getValues()
                if (briefId) {
                    post(
                        ENDPOINTS.POST_BRIEF_CONVERT_PROPOSAL.replace(
                            '{brief}',
                            briefId,
                        ),
                        result,
                        successSend,
                        error,
                        alwaysSave,
                    )
                } else
                    post(
                        ENDPOINTS.POST_BRIEF_CONVERT_PROPOSAL.replace(
                            '/{brief}',
                            '',
                        ),
                        result,
                        successSend,
                        error,
                        alwaysSave,
                    )
            })
            .catch((errors) => {
                let errorId = errors.errorFields[0].name
                goToViolation(Array.isArray(errorId) ? errorId[0] : errorId)
            })
    }

    const successSave = useCallback((response: any) => {
        setSaveSpin(false)
        successObject(response)
        history.push(BRIEFS)
    }, [])

    const successSend = useCallback((response: any) => {
        setSaveSpin(false)
        successObject(response)
        history.push(PROPOSAL)
    }, [])

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const handleStates = (response: any) => {
        let result: any = response.map((item: any, index: any) => {
            let cities = item.relationships.cities.map((element: any) => {
                return {
                    value: element.id,
                    label: Functions.getTraslateForStateAndCityByLanguage(
                        element,
                        getLanguage(),
                    ),
                }
            })
            return {
                label: Functions.getTraslateForStateAndCityByLanguage(
                    item,
                    getLanguage(),
                ),
                cities: cities,
                value: item.id,
            }
        })

        return result
    }

    const handleCancel = () => {
        history.push(BRIEFS)
    }

    if (
        !briefSpin &&
        !objectiveSpin &&
        !specificationSpin &&
        !advertiserSpin &&
        spinGeneral
    ) {
        setSpinGeneral(false)
        setShowTooltipSlider(true)
    }

    let countriesList: ICountries[] =
        country && Functions.getCountriesByLanguage(country)

    let currencyList: ICurrency[] = currency

    return (
        <div id='brief-form'>
            <Spin
                className='spin-general'
                indicator={antIcon}
                spinning={spinGeneral || saveSpin}>
                <Form
                    form={form}
                    layout='vertical'
                    scrollToFirstError
                    onValuesChange={onChange}
                    onFinish={onSave}
                    size='large'>
                    <div className='form-container'>
                        <div className='card general-info'>
                            <div className='header'>
                                {getTranslation('informacion_general')}
                            </div>
                            <div className='body'>
                                <CustomSelect
                                    label={getTranslation('advertiser')}
                                    name='cliente'
                                    id='cliente'
                                    data={advertiserList}
                                    value={formValue?.cliente}
                                    required={true}
                                    help={HELP}
                                    // dropdownLabel={getTranslation(
                                    //     'add_advertiser',
                                    // )}
                                    // showModal={setBrandModal}
                                />

                                <CustomSelectWithModal
                                    label={getTranslation('brand')}
                                    name='brand_id'
                                    id='brand_id'
                                    endpoint={
                                        formValue.cliente &&
                                        ENDPOINTS.GET_ORGANIZATIONS_BRANDS.replace(
                                            '{organization}',
                                            brief
                                                ? brief?.relationships?.account
                                                      ?.organization_id
                                                : advertiserList.find(
                                                      (item) =>
                                                          item.value ===
                                                          formValue.cliente,
                                                  ).account_id,
                                        )
                                    }
                                    value={formValue?.brand_id}
                                    required={true}
                                    disabled={!formValue.cliente}
                                    dropdownLabel={getTranslation(
                                        'crear_marca',
                                    )}
                                    showModal={setBrandModal}
                                    forceReload
                                    help={HELP}
                                />

                                <CustomInput
                                    className='form-tooltip'
                                    name='name'
                                    id='name'
                                    label={getTranslation(
                                        'nombre_de_la_campana',
                                    )}
                                    showTooltips={true}
                                    tooltipTitle='Tooltip with customize icon'
                                    required={true}
                                />

                                <CustomRangePicker
                                    label={getTranslation('fecha_estimada')}
                                    name='rangesDates'
                                    id='rangesDates'
                                    type={'array' as const}
                                    required={true}
                                />

                                <CustomSelect
                                    label={getTranslation(
                                        'moneda_para_la_propuesta',
                                    )}
                                    name='currency_id'
                                    id='currency_id'
                                    data={currencyList}
                                    value={formValue?.currency_id}
                                    required={true}
                                />

                                <CustomInputNumber
                                    name='budget'
                                    id='budget'
                                    label={getTranslation('presupuesto')}
                                    required={true}
                                />

                                <CustomSelect
                                    label={getTranslation(
                                        'tipo_de_presupuesto',
                                    )}
                                    name='budget_type_id'
                                    id='budget_type_id'
                                    endpoint={ENDPOINTS.GET_BUDGET_TYPE}
                                    value={formValue?.budget_type_id}
                                    required={true}
                                />

                                <CustomSelect
                                    className='form-tooltip'
                                    label={getTranslation('pais_de_la_campana')}
                                    name='country_campaign_id'
                                    id='country_campaign_id'
                                    data={countriesList}
                                    value={formValue?.country_campaign_id}
                                    required={true}
                                    showTooltips={true}
                                    tooltipTitle='Tooltip with customize icon'
                                />

                                <CustomSelectGroup
                                    label={getTranslation(
                                        'ciudad_de_la_campana',
                                    )}
                                    name='city_campaign_id'
                                    id='city_campaign_id'
                                    endpoint={
                                        formValue?.country_campaign_id &&
                                        ENDPOINTS.GET_COUNTRY_STATES.replace(
                                            '{country}',
                                            formValue?.country_campaign_id,
                                        )
                                    }
                                    value={formValue?.city_campaign_id}
                                    required={true}
                                    handleSuccess={handleStates}
                                    disabled={!formValue?.country_campaign_id}
                                />

                                <CustomSelect
                                    className='form-tooltip'
                                    label={getTranslation('pais_de_pago')}
                                    name='country_payment_id'
                                    id='country_payment_id'
                                    data={countriesList}
                                    value={formValue?.country_payment_id}
                                    required={true}
                                    showTooltips={true}
                                    tooltipTitle='Tooltip with customize icon'
                                />

                                <CustomSelectWithModal
                                    label={getTranslation('razon_social')}
                                    name='company_name_id'
                                    id='company_name_id'
                                    endpoint={
                                        formValue.cliente &&
                                        ENDPOINTS.GET_COMPANY_NAMES.replace(
                                            '{organization}',
                                            brief
                                                ? brief?.relationships?.account
                                                      ?.organization_id
                                                : advertiserList.find(
                                                      (item) =>
                                                          item.value ===
                                                          formValue.cliente,
                                                  ).account_id,
                                        )
                                    }
                                    value={formValue?.company_name_id}
                                    disabled={!formValue.cliente}
                                    required={true}
                                    dropdownLabel={getTranslation(
                                        'add_company_name',
                                    )}
                                    showModal={setCompanyNamesModal}
                                    forceReload
                                    help={HELP}
                                />
                            </div>
                        </div>
                        <div className='card objetive-public'>
                            <div className='header'>
                                {getTranslation('publico_objetivo')}
                            </div>
                            <div className='body'>
                                <CustomCheckboxGroup
                                    name='gender'
                                    id='gender'
                                    label={objectivesFields[0]?.name}
                                    data={objectivesFields[0]?.subitems}
                                    required={sendBool}
                                />
                                <CustomCheckboxGroup
                                    name='socioeconomic'
                                    id='socioeconomic'
                                    label={objectivesFields[1]?.name}
                                    data={objectivesFields[1]?.subitems}
                                    required={sendBool}
                                />

                                <CustomSelectMultiple
                                    label={objectivesFields[2]?.name}
                                    className='form-select-multiple'
                                    name='range_ages'
                                    id='range_ages'
                                    mode='multiple'
                                    type='array'
                                    data={objectivesFields[2]?.subitems}
                                    value={formValue?.range_ages}
                                    required={sendBool}
                                />

                                <CustomTextArea
                                    className='form-textarea'
                                    name='psychographic_profile'
                                    id='psychographic_profile'
                                    label={getTranslation(
                                        'perfil_psicografico',
                                    )}
                                    required={sendBool}
                                />
                            </div>
                        </div>
                        <div className='card campaign-specification'>
                            <div className='header'>
                                {getTranslation('especificaciones_de_campana')}
                            </div>
                            <div className='body'>
                                <CustomSelect
                                    label={specificationFields[0]?.name}
                                    name='campaignType'
                                    id='campaignType'
                                    data={specificationFields[0]?.subitems}
                                    value={formValue?.campaignType}
                                    required={sendBool}
                                />

                                <SliderContainer
                                    showTooltipSlider={showTooltipSlider}
                                />

                                <CustomTextArea
                                    className='form-textarea'
                                    name='communication_objective'
                                    id='communication_objective'
                                    label={getTranslation(
                                        'objetivo_de_comunicacion',
                                    )}
                                    required={sendBool}
                                />
                                <CustomCheckboxGroup
                                    className='form-checkbox'
                                    name='formatType'
                                    id='formatType'
                                    label={specificationFields[2]?.name}
                                    data={specificationFields[2]?.subitems}
                                />

                                <CustomCheckboxGroup
                                    className='form-checkbox'
                                    name='formatList'
                                    id='formatList'
                                    label={specificationFields[3]?.name}
                                    data={specificationFields[3]?.subitems}
                                />

                                <CustomCheckboxGroup
                                    className='form-checkbox'
                                    name='objectivePoints'
                                    id='objectivePoints'
                                    visible
                                    label={specificationFields[4]?.name}
                                    data={specificationFields[4]?.subitems}
                                />
                            </div>
                        </div>
                        <div className='card  buttons-footer'>
                            <Row>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Button
                                        type='primary'
                                        size='small'
                                        onClick={onSend}>
                                        {getTranslation(
                                            'convertir_en_propuesta',
                                        )}
                                    </Button>
                                    <Button
                                        style={{ margin: '0 16px' }}
                                        ghost
                                        type='primary'
                                        size='small'
                                        onClick={() => setSendBool(false)}
                                        htmlType='submit'>
                                        {getTranslation('guardar_borrador')}
                                    </Button>
                                    <Button
                                        ghost
                                        type='primary'
                                        size='small'
                                        onClick={handleCancel}>
                                        {getTranslation('cancelar')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Form>
            </Spin>
            <BrandFormModal
                advertiserId={formValue.cliente}
                showModal={brandModal}
                setShowModal={setBrandModal}
                setGlobalValue={setFormValue}
                globalValue={formValue}
            />
            <CompanyNamesFormModal
                advertiserId={formValue.cliente}
                showModal={companyNamesModal}
                setShowModal={setCompanyNamesModal}
                setGlobalValue={setFormValue}
                globalValue={formValue}
            />
        </div>
    )
}

export default withRouter(BriefForm)
