import { Form, Checkbox, Row, Col, Popover } from 'antd'
import { useEffect, useState } from 'react'
import { Language } from '../../../config/Language'

interface IProps {
    id?: string
    label: string
    name: string
    className?: string
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    value?: any
    disabled?: boolean
    onChange?: any
    data?: any[]
    visible?: boolean
    tooltipTitle?: string
}

export const CustomCheckboxGroup = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        requiredMessage = getTranslation('required'),
        visible = false,
        value,

        data,
    } = props

    const [selectedValue, setSelectedValue] = useState<any>(null)

    useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
        return () => {}
    }, [value])

    // const content = (item: any) => {
    //     let content = (
    //         <p>Content, Content, Content, Content, Content, Content ,Content</p>
    //     )
    //     return content
    // }

    return (
        <Form.Item
            id={id}
            name={name}
            className={className}
            label={label}
            rules={[
                {
                    required: required,
                    message: requiredMessage,
                },
            ]}>
            <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                    {data?.map((item: any, index: any) => (
                        <Col key={index}>
                            {/* <Popover
                                content={content(item)}
                                title={item.name}> */}
                            <Checkbox value={item.id}>{item.name}</Checkbox>
                            {/* </Popover> */}
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
        </Form.Item>
    )
}
