import { Language } from '../../../config/Language'
import { IProposal } from '../../../interface/IProposal'
import parse from 'html-react-parser'

interface IProps {
    data: IProposal | null
}

export const AssetsStrategy = ({ ...props }: IProps) => {
    const { data } = props
    const { getTranslation } = Language()

    return (
        <div className='strategy-container'>
            <div className='container'>
                {data?.strategy ? (
                    parse(data?.strategy)
                ) : (
                    <span>{getTranslation('sin_datos')}</span>
                )}
            </div>
        </div>
    )
}
