import { BRIEFS_FORM } from '../../config/Path'
import { Link, useHistory } from 'react-router-dom'
import { Menu, Dropdown, Table, Button, message, Tooltip } from 'antd'
import { SoundOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { useAxios } from '../../hooks/useAxios'
import { ENDPOINTS } from '../../config/EndPoints'
import { Notifications } from '../common/notifications/Notifications'
import { Language } from '../../config/Language'
import { antIcon } from '../common/spin-icon/SpinIcon'

export const Briefs = () => {
    const history = useHistory()
    const { getTranslation, getTranslationObject } = Language()
    const { get } = useAxios()
    const { errorObjectWithData } = Notifications()
    const [briefList, setBriefList] = useState<any>([])
    const [spinner, setSpinner] = useState(false)

    let height = 0
    const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
    )

    height = vh - 290

    useEffect(() => {
        loadBirefList()
        return () => {}
    }, [])

    const loadBirefList = () => {
        setSpinner(true)
        get(ENDPOINTS.GET_BRIEF_LIST, {}, success, error, always)
    }

    const success = useCallback((response: any) => {
        setSpinner(false)
        response.data.data.forEach((element: any) => {
            element.key = element.id
        })
        setBriefList(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        setSpinner(false)
    }

    const handleMenuDelete = (item: any) => {
        message.info(`Click to delete - ${item.id}`)
    }

    const handleMenuEdit = (item: any) => {
        history.push(`${BRIEFS_FORM}/${item.id}`)
    }

    const menu = (item: any) => {
        return (
            <Menu>
                <div style={{ padding: '0 12px', fontWeight: 600 }}>
                    {getTranslation('briefs')}
                </div>
                <Menu.Divider />
                <Menu.Item key='1' onClick={() => handleMenuEdit(item)}>
                    {getTranslation('completar')}
                </Menu.Item>
                <Menu.Item key='2' onClick={() => handleMenuDelete(item)}>
                    {getTranslation('convertir_en_propuesta')}
                </Menu.Item>
            </Menu>
        )
    }

    const columns: any = [
        {
            title: getTranslation('campaign'),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (name: any, item: any) => (
                <Tooltip placement='topLeft' title={name}>
                    <Link to={`${BRIEFS_FORM}/${item.id}`}>{name}</Link>
                </Tooltip>
            ),
        },
        {
            title: getTranslation('organization'),
            dataIndex: 'client',
            key: 'client',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            sorter: {
                compare: (a: any, b: any) => a.age - b.age,
                multiple: 3,
            },
            render: (client: any) => (
                <Tooltip placement='topLeft' title={client}>
                    {client}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('brand'),
            dataIndex: 'brand',
            width: 150,
            key: 'brand',
            ellipsis: {
                showTitle: false,
            },
            render: (brand: any) => (
                <Tooltip placement='topLeft' title={brand}>
                    {brand}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('created'),
            dataIndex: 'created_at',
            width: 150,
            key: 'created_at',
            ellipsis: {
                showTitle: false,
            },
            render: (created_at: any) => (
                <Tooltip placement='topLeft' title={created_at}>
                    {created_at}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('inicio_estimado'),
            dataIndex: 'start_date',
            key: 'start_date',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (start_date: any) => (
                <Tooltip placement='topLeft' title={start_date}>
                    {start_date}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('fin_estimado'),
            dataIndex: 'end_date',
            key: 'end_date',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (end_date: any) => (
                <Tooltip placement='topLeft' title={end_date}>
                    {end_date}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('estado'),
            dataIndex: 'status',
            key: 'status',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (status: any) => {
                let bgColor = ''
                let bordeColor = ''
                let fontColor = ''
                if (status.id === 2) {
                    bgColor = '#E6F7FF'
                    bordeColor = '#91D5FF'
                    fontColor = '#1890FF'
                } else if (status.id === 1) {
                    bgColor = '#FFF7E6'
                    bordeColor = '#FFD591'
                    fontColor = '#FA8C16'
                }
                return (
                    <Tooltip placement='topLeft' title={status.name}>
                        <div
                            className='estado'
                            style={{
                                backgroundColor: bgColor,
                                borderColor: bordeColor,
                                color: fontColor,
                            }}>
                            <span>{status.name}</span>
                        </div>
                    </Tooltip>
                )
            },
        },
        {
            title: getTranslation('ejecutivo'),
            dataIndex: 'user',
            key: 'user',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (user: any) => (
                <Tooltip placement='topLeft' title={user.name}>
                    {user.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('acciones'),
            dataIndex: 'action',
            key: 'action',
            ellipsis: {
                showTitle: false,
            },
            width: '150px',
            render: (_: any, item: any) => (
                <Dropdown trigger={['click']} overlay={() => menu(item)}>
                    <Button className='btn-actions'>
                        {getTranslation('accion')} <DownOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ]

    return (
        <div id='brief-list'>
            <div className='header'>
                <div className='header-title'>{getTranslation('briefs')}</div>
                <div className='header-button'>
                    <Button type='primary' className='btn-big' shape='round'>
                        <Link to={BRIEFS_FORM}>
                            {getTranslation('nueva_campana')} <SoundOutlined />
                        </Link>
                    </Button>
                </div>
            </div>
            <div className='body'>
                <div className='body-title'>
                    {getTranslation('listado_briefs')}
                </div>
                <div className='body-table'>
                    <Table
                        className='brief-table'
                        sticky
                        locale={getTranslationObject('table_trad')}
                        bordered
                        pagination={false}
                        scroll={{ x: 800, y: height }}
                        columns={columns}
                        loading={{ spinning: spinner, indicator: antIcon }}
                        dataSource={briefList}
                    />
                </div>
            </div>
        </div>
    )
}
