import { DownOutlined } from '@ant-design/icons'
import {
    Menu,
    Form,
    Button,
    Tooltip,
    Table,
    Dropdown,
    message,
    Modal,
    Spin,
} from 'antd'
import { useEffect, useState } from 'react'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Functions } from '../../../config/Functions'
import { Language } from '../../../config/Language'
import { useAxios } from '../../../hooks/useAxios'
import useCountryContext from '../../../hooks/useCountryContext'
import { IAccountGateway } from '../../../interface/IAccountGateway'
import { IContacts } from '../../../interface/IContacts'
import { ICountries } from '../../../interface/ICountries'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomSelect } from '../../common/custom-select/CustomSelect'
import { Notifications } from '../../common/notifications/Notifications'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    accountData: IAccountGateway | null
    contactDataSpin: boolean
    setContactDataSpin: (bool: boolean) => void
}

interface FormValue {
    contactName: string
    contactLastName: string
    email: string
    mobile: string
    contactType: number
    countryId: number
    brand_id: number
}

export const ContactDetail = ({ ...props }: IProps) => {
    const { accountData, contactDataSpin, setContactDataSpin } = props
    const { getTranslationObject, getTranslation } = Language()
    const { get, post } = useAxios()
    const [form] = Form.useForm()
    const { country } = useCountryContext()
    const { errorObjectWithData, successObject } = Notifications()
    const [data, setData] = useState<IContacts[]>([])
    const [contactType, setContactType] = useState([])
    const [contactTypeSpin, setContactTypeSpin] = useState(false)
    const [showModal, setShowModal] = useState<any>(false)
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [saveSpin, setSaveSpin] = useState(false)
    const [loadLocalSpin, setLoadLocalSpin] = useState(false)

    useEffect(() => {
        loadContactData()
        loadContactType()
        return () => {}
    }, [accountData])

    const onChange = (values: any) => {
        let newFormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const loadContactType = () => {
        setContactTypeSpin(true)
        get(
            ENDPOINTS.GET_CONTACT_TYPES,
            {},
            successContactType,
            error,
            alwaysContactType,
        )
    }

    const successContactType = (response: any) => {
        setContactTypeSpin(false)
        let result: any = response.data.data
        result = result.filter((item: any) => item.id !== 3)
        let dataResponse = result.reduce(
            (acc: any, item: any) => [
                ...acc,
                {
                    label: item.label || item.name || item.full_name,
                    value: item.id || item.value,
                },
            ],
            [],
        )
        setContactType(dataResponse)
    }

    const alwaysContactType = () => {
        setContactTypeSpin(false)
    }

    const getValues = () => {
        let result: any = {
            name: formValue?.contactName,
            last_name: formValue?.contactLastName,
            email: formValue?.email,
            country_id: formValue?.countryId,
            contact_type_id: formValue?.contactType,
            brand_id: formValue?.brand_id,
        }
        if (formValue?.mobile) result.mobile = formValue?.mobile
        return result
    }

    const loadContactData = (local?: boolean) => {
        if (accountData) {
            local ? setLoadLocalSpin(true) : setContactDataSpin(true)
            get(
                ENDPOINTS.GET_ACCOUNT_CONTACTS.replace(
                    '{account}',
                    accountData?.id.toString(),
                ),
                {},
                successLoad,
                error,
                alwaysLoad,
            )
        }
    }

    const successLoad = (response: any) => {
        setContactDataSpin(false)
        setLoadLocalSpin(false)
        setData(response.data.data)
    }

    const alwaysLoad = () => {
        setLoadLocalSpin(false)
        setContactDataSpin(false)
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()
        accountData?.id &&
            post(
                ENDPOINTS.POST_ACCOUNT_CONTACT.replace(
                    '{account}',
                    accountData?.id.toString(),
                ),
                result,
                successSave,
                error,
                alwaysSave,
            )
    }

    const successSave = (response: any) => {
        setSaveSpin(false)
        setShowModal(false)
        loadContactData(true)
        successObject(response)
    }

    const alwaysSave = () => {
        setSaveSpin(false)
        setFormValue({})
        form.setFieldsValue({
            contactName: '',
            contactLastName: '',
            email: '',
            mobile: '',
            contactType: null,
            countryId: null,
        })
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const handleMenuDelete = (item: any) => {
        message.info(`Click to delete - ${item.id}`)
    }

    const handleMenuEdit = (item: any) => {
        // history.push(`${BRIEFS_FORM}/${item.id}`)
    }

    const menu = (item: any) => {
        return (
            <Menu>
                <div style={{ padding: '0 12px', fontWeight: 600 }}>
                    {getTranslation('advertisers')}
                </div>
                <Menu.Divider />
                <Menu.Item key='1' onClick={() => handleMenuEdit(item)}>
                    {getTranslation('completar')}
                </Menu.Item>
                <Menu.Item key='2' onClick={() => handleMenuDelete(item)}>
                    {getTranslation('convertir_en_propuesta')}
                </Menu.Item>
            </Menu>
        )
    }

    const columns: any = [
        {
            title: getTranslation('name_last_name'),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (name: any, item: IContacts) => (
                <Tooltip
                    placement='topLeft'
                    title={`${item.name} ${item.last_name}`}>
                    {`${item.name} ${item.last_name}`}
                </Tooltip>
            ),
        },

        {
            title: getTranslation('brand'),
            dataIndex: 'brand',
            width: 150,
            key: 'brand',
            ellipsis: {
                showTitle: false,
            },
            render: (brand: any, item: IContacts) => (
                <Tooltip placement='topLeft' title={item.brand.name}>
                    {item.brand.name}
                </Tooltip>
            ),
        },

        {
            title: getTranslation('email'),
            dataIndex: 'email',
            key: 'email',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (email: any) => (
                <Tooltip placement='topLeft' title={email}>
                    {email}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('phone'),
            dataIndex: 'mobile',
            key: 'mobile',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (mobile: any) => (
                <Tooltip placement='topLeft' title={mobile}>
                    {mobile}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('tipo_de_contacto'),
            dataIndex: 'contact_type',
            key: 'contact_type',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (contact_type: any, item: IContacts) => (
                <Tooltip placement='topLeft' title={item.contact_type.name}>
                    {item.contact_type.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('pais'),
            dataIndex: 'country',
            key: 'country',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (country: any, item: any) => (
                <Tooltip placement='topLeft' title={item.country.name}>
                    {item.country.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('acciones'),
            dataIndex: 'action',
            key: 'action',
            ellipsis: {
                showTitle: false,
            },
            width: '150px',
            render: (_: any, item: any) => (
                <Dropdown trigger={['click']} overlay={() => menu(item)}>
                    <Button className='btn-actions'>
                        {getTranslation('accion')} <DownOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ]

    let countriesList: ICountries[] =
        country && Functions.getCountriesByLanguage(country)

    return (
        <>
            <div className='contact box'>
                <div className='header'>
                    {getTranslation('contactos')}
                    <Button
                        size='small'
                        type='primary'
                        onClick={() => setShowModal(true)}>
                        {getTranslation('crear_contacto')}
                    </Button>
                </div>
                <div className='body'>
                    <Table
                        className='advertiser-table'
                        sticky
                        locale={getTranslationObject('table_trad')}
                        bordered
                        pagination={{
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} ${getTranslation(
                                    'of',
                                )} ${total} ${getTranslation('items')}`,
                        }}
                        scroll={{ x: 800, y: 489 }}
                        columns={columns}
                        loading={{
                            spinning: contactDataSpin || saveSpin,
                            indicator: antIcon,
                        }}
                        dataSource={data}
                    />
                </div>
            </div>
            <Modal
                title={getTranslation('crear_contacto')}
                visible={showModal}
                width={800}
                onCancel={() => setShowModal(false)}
                footer={null}>
                <Spin
                    className='spin-local'
                    indicator={antIcon}
                    spinning={saveSpin}>
                    <div id='modal-advertiser'>
                        <div className='body'>
                            <Form
                                className='form-advertiser'
                                form={form}
                                layout='vertical'
                                scrollToFirstError
                                onValuesChange={onChange}
                                size='large'>
                                <div className='form-container'>
                                    <div className='form-body'>
                                        <CustomInput
                                            name='contactName'
                                            id='contactName'
                                            label={getTranslation('nombre')}
                                            required={true}
                                        />
                                        <CustomInput
                                            name='contactLastName'
                                            id='contactLastName'
                                            label={getTranslation('apellido')}
                                            required={true}
                                        />

                                        <CustomSelect
                                            label={getTranslation('brand')}
                                            name='brand_id'
                                            id='brand_id'
                                            endpoint={
                                                accountData
                                                    ? ENDPOINTS.GET_BRAND_BY_CLIENT.replace(
                                                          '{organization}',
                                                          accountData?.organization_id.toString(),
                                                      )
                                                    : ''
                                            }
                                            value={formValue?.brand_id}
                                            required={true}
                                        />

                                        <CustomInput
                                            name='email'
                                            id='email'
                                            type='email'
                                            label={getTranslation('email')}
                                            required={true}
                                        />

                                        <CustomInput
                                            name='mobile'
                                            id='mobile'
                                            label={getTranslation('phone')}
                                        />

                                        <CustomSelect
                                            label={getTranslation(
                                                'tipo_de_contacto',
                                            )}
                                            name='contactType'
                                            id='contactType'
                                            data={contactType}
                                            value={formValue?.contactType}
                                            required={true}
                                        />

                                        <CustomSelect
                                            label={getTranslation('pais')}
                                            name='countryId'
                                            id='countryId'
                                            data={countriesList}
                                            value={formValue?.countryId}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </Form>

                            <div className='footer'>
                                <Button
                                    style={{ margin: '0 8px' }}
                                    ghost
                                    type='primary'
                                    onClick={() => setShowModal(false)}
                                    size='small'>
                                    {getTranslation('cancelar')}
                                </Button>

                                <Button
                                    type='primary'
                                    size='small'
                                    onClick={onSave}>
                                    {getTranslation('guardar')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}
