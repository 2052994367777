import { Button, Form, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { Language } from '../../config/Language'
import {
    ADVERTISERS,
    ADVERTISERS_DETAIL,
    NEW_ADVERTISERS,
} from '../../config/Path'
import { useAxios } from '../../hooks/useAxios'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { CustomInput } from '../common/custom-input/CustomInput'
import { Notifications } from '../common/notifications/Notifications'
import { ShowImage } from '../common/show-img/ShowImage'
import { antIcon } from '../common/spin-icon/SpinIcon'

interface RouteParams {
    id: string
}

export const AdvertiserPreview = () => {
    let { id } = useParams<RouteParams>()
    const [form] = Form.useForm()
    const { getTranslation } = Language()
    const { get, post } = useAxios()
    const history = useHistory()
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { successObject, errorObjectWithData } = Notifications()
    const [advertiserId, setAdvertiserId] = useState<any>(null)
    const [formValue, setFormValue] = useState<any>()
    const [advertiserDataSpin, setAdvertiserDataSpin] = useState(false)
    const [saveSpin, setSaveSpin] = useState(false)
    const [advertiserData, setAdvertiserData] = useState<any>({})

    if (id && id !== advertiserId) {
        setAdvertiserId(id)
    }

    const onChange = (values: any) => {
        let newFormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    useEffect(() => {
        updateBreadCrumb(getTranslation('preview-advertisers'))
        id && loadadvertiserData()
        return () => {}
    }, [])

    const loadadvertiserData = () => {
        setAdvertiserDataSpin(true)
        get(
            ENDPOINTS.GET_ORGANIZATION.replace('{organization}', id),
            {},
            successadvertiserData,
            error,
            alwaysadvertiserData,
        )
    }

    const successadvertiserData = useCallback((response: any) => {
        setAdvertiserDataSpin(false)
        updateBreadCrumb(response.data.data.alias_name)
        setAdvertiserData(response.data.data)
    }, [])

    const alwaysadvertiserData = () => {
        setAdvertiserDataSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result: any = {
            name: advertiserData.alias_name,
            advertiser_id: advertiserId,
        }
        if (formValue.discount) {
            result.discount = formValue?.discount
        }
        post(ENDPOINTS.POST_ACCOUNT, result, successSave, error, alwaysSave)
    }

    const successSave = (response: any) => {
        setSaveSpin(false)
        successObject(response)
        history.push(`${ADVERTISERS_DETAIL}/${advertiserId}`)
    }

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    return (
        <Spin
            className='spin-general'
            indicator={antIcon}
            spinning={advertiserDataSpin || saveSpin}>
            <div id='preview-advertiser'>
                <div className='box-container'>
                    <div className='header'>
                        <div className='title'>
                            {getTranslation('preview-advertisers')}
                        </div>
                    </div>
                    <div className='body'>
                        <div className='logo-name'>
                            <ShowImage
                                url={advertiserData?.file}
                                alt={getTranslation('logo')}
                            />
                            <div className='description'>
                                {advertiserData?.alias_name}
                            </div>
                        </div>
                        <div className='title-description'>
                            <div className='title'>
                                {getTranslation('categoria')}
                            </div>
                            <div className='description'>
                                {advertiserData?.subcategory?.category?.name}
                            </div>
                        </div>
                        <div className='title-description'>
                            <div className='title'>
                                {getTranslation('sub_categoria')}
                            </div>
                            <div className='description'>
                                {advertiserData?.subcategory?.name}
                            </div>
                        </div>
                        <Form
                            className='form-preview-advertiser'
                            form={form}
                            layout='vertical'
                            scrollToFirstError
                            onValuesChange={onChange}
                            onFinish={onSave}
                            size='large'>
                            <div className='form-container'>
                                <div className='form-body'>
                                    <CustomInput
                                        label={getTranslation(
                                            'discount_optional',
                                        )}
                                        name='discount'
                                        id='discount'
                                        type='number'
                                        maxNumber={100}
                                    />
                                </div>
                            </div>
                        </Form>

                        <div className='footer'>
                            <Button
                                style={{ margin: '0 8px' }}
                                ghost
                                type='primary'
                                size='small'>
                                <Link to={NEW_ADVERTISERS}>
                                    {getTranslation('volver')}
                                </Link>
                            </Button>

                            <Button
                                type='primary'
                                size='small'
                                onClick={onSave}>
                                {getTranslation('finish')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}
