import { Form, DatePicker, Button, Input } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Moment } from 'moment'
import { SaveOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Language } from '../../../config/Language'

interface IProps {
    id?: string
    label?: string
    name: string
    type?: any
    className?: string
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
    size?: SizeType
    value?: string
    saveSpin: boolean
    editShow: boolean
    setEditShow: (bool: boolean) => void
    handleCloseEdit: () => void
    disabledDate?: (current: Moment) => boolean
}

export const CustomInputProposal = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        size = 'large',
        value,
        saveSpin,
        editShow,
        setEditShow,
        type,
        handleCloseEdit,
    } = props

    const handleCancel = () => {
        setEditShow(false)
        handleCloseEdit()
    }

    const onFocus = (event: any) => {
        if (event.target.autocomplete) {
            event.target.autocomplete = 'whatever'
        }
    }

    return (
        <div id='custom-component-form-proposal'>
            <div className={`form-field ${editShow ? 'show' : ''}`}>
                <Form.Item
                    id={id}
                    name={name}
                    className={className}
                    label={label}
                    rules={[
                        {
                            required: required,
                            message: requiredMessage,
                        },
                    ]}>
                    <Input
                        type={type}
                        autoComplete='off'
                        onFocus={onFocus}
                        disabled={disabled}
                        placeholder=''
                        size={size}
                    />
                </Form.Item>
            </div>

            <div
                className={`field ${!editShow ? 'show' : ''}`}
                onClick={() => setEditShow(!editShow)}>
                <div>{value}</div>
            </div>

            <div className='actions-buttons show'>
                {editShow ? (
                    <>
                        <Button
                            shape='circle'
                            onClick={handleCancel}
                            style={{ marginRight: '5px' }}
                            icon={<CloseOutlined />}
                        />
                        <Button
                            shape='circle'
                            htmlType='submit'
                            loading={saveSpin}
                            icon={<SaveOutlined />}
                        />
                    </>
                ) : (
                    <Button
                        shape='circle'
                        onClick={() => setEditShow(!editShow)}
                        icon={<EditOutlined />}
                    />
                )}
            </div>
        </div>
    )
}
