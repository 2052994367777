import { Pie } from '@ant-design/charts'
import { Empty } from 'antd'
import { Language } from '../../../config/Language'

interface IProps {
    title: string
    data: any[]
}

export const CustomCircleChart = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const { title, data } = props

    let config: any = {
        appendPadding: 10,
        data: data,
        angleField: 'value',
        colorField: 'name',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            { type: 'element-selected' },
            { type: 'element-active' },
        ],

        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
    }

    return (
        <>
            {data.length > 0 ? (
                <div style={{ height: '400px' }}>
                    <Pie {...config} />
                </div>
            ) : (
                <div className='data-ant-empty'>
                    <Empty description={getTranslation('sin_datos')} />
                </div>
            )}
        </>
    )
}
