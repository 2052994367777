import { useParams } from 'react-router-dom'
import { Spin, Affix } from 'antd'
import { useEffect, useState } from 'react'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { useAxios } from '../../hooks/useAxios'
import { Language } from '../../config/Language'
import { ProposalHeader } from './ProposalHeader'
import { ProposalTabs } from './components/ProposalTabs'
import { ENDPOINTS } from '../../config/EndPoints'
import { ProposalFilter } from './components/ProposalFilter'
import { ProposalHeaderAssets } from './components/ProposalHeaderAssets'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { Notifications } from '../common/notifications/Notifications'
import { IProposal } from '../../interface/IProposal'
import { IFilterDataProposal } from '../../interface/IFilterDataProposal'
import { ProposalTable } from './components/ProposalTable'
import { AssetsComponent } from './components/AssetsComponent'
import { StrategyComponent } from './components/StrategyComponent'
import { CancelTokenSource } from 'axios'
import useAssetsClassificationContext from '../../hooks/useAssetsClassificationContext'

interface RouteParams {
    id: string
}

export const ProposalDetail = () => {
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { get, post } = useAxios()
    const { getTranslation } = Language()
    const { errorObjectWithData, successObject } = Notifications()

    const [params, setParams] = useState<any>({})
    const [page, setPage] = useState<any>({
        page: 1,
        per_page: 10,
    })

    const { assetTypesList, geopathList, materialList } =
        useAssetsClassificationContext()

    const [assetsListMap, setAssetsListMap] = useState<any[]>([])
    const [assetsListMapSpin, setAssetsListMapSpin] = useState<boolean>(false)
    const [generalSpin, setGeneralSpin] = useState(false)
    const [proposalSpin, setProposalSpin] = useState(false)

    const [poisClasificationsSpin, setPoisClasificationsSpin] = useState(false)
    const [poisClasificationsList, setPoisClasificationsList] = useState<any[]>(
        [],
    )
    const [flagFirstReloadFilters, setFlagFirstReloadFilters] = useState(false)

    const [vendorsSpin, setVendorsSpin] = useState(false)
    const [vendorsList, setVendorsList] = useState<any[]>([])

    const [proposalId, setProposalId] = useState<string>('')
    const [data, setData] = useState<any | null>(null)
    const [filterShow, setFilterShow] = useState(true)

    const [tab, setTab] = useState('1')

    const [totalElements, setTotalElements] = useState(0)
    const [filterList, setFilterList] = useState<any[]>([])
    const [assetsList, setAssetsList] = useState<any[]>([])
    const [assetsListSpin, setAssetsListSpin] = useState(false)
    const [selectionList, setSelectionList] = useState<any[]>([])
    const [assetsSelected, setAssetsSelected] = useState<any[]>([])
    const [selectionIdsList, setSelectionIdsList] = useState<any[]>([])
    const [axiosRequestList, setAxiosRequestList] =
        useState<CancelTokenSource | null>(null)
    const [axiosRequestMap, setAxiosRequestMap] =
        useState<CancelTokenSource | null>(null)
    const [filterData, setFilterData] = useState<IFilterDataProposal>({
        vendor_ids: {
            title: null,
            array: null,
            description: null,
        },
        material_ids: {
            title: null,
            array: null,
            description: null,
        },
        geopath_clasification_ids: {
            title: null,
            array: null,
            description: null,
        },
        asset_type_ids: {
            title: null,
            array: null,
            description: null,
        },
        poi_ids: {
            title: null,
            array: null,
            description: null,
        },
        start_date: {
            title: null,
            description: null,
        },
        end_date: {
            title: null,
            description: null,
        },
        ots_min: {
            title: null,
            description: null,
        },
        ots_max: {
            title: null,
            description: null,
        },
    })

    let { id } = useParams<RouteParams>()
    if (id && id !== proposalId) {
        setProposalId(id)
    }

    const loadPage = () => {
        setGeneralSpin(true)
        loadProposal()

        loadVendors()

        loadPoisClasifications()
    }

    useEffect(() => {
        if (proposalId !== '') {
            loadPage()
        } else {
            updateBreadCrumb(getTranslation(''))
        }

        return () => {}
    }, [])

    useEffect(() => {
        if (Object.keys(params).length !== 0) {
            let arryIds: any[] = []
            params.hasOwnProperty('vendor_ids') &&
                arryIds.push(...params?.vendor_ids)
            params.hasOwnProperty('poi_ids') && arryIds.push(...params?.poi_ids)
            params.hasOwnProperty('asset_type_ids') &&
                arryIds.push(...params?.asset_type_ids)
            params.hasOwnProperty('geopath_clasification_ids') &&
                arryIds.push(...params?.geopath_clasification_ids)
            params.hasOwnProperty('material_ids') &&
                arryIds.push(...params?.material_ids)
            loadFilters(params)

            setPage({ page: 1, per_page: page.per_page })

            if (axiosRequestMap) {
                axiosRequestMap.cancel()
                setAxiosRequestMap(null)
            }
            loadAssetsListMap()
        }
        return () => {}
    }, [params])

    useEffect(() => {
        if (Object.keys(params).length !== 0) {
            let arryIds: any[] = []
            params.hasOwnProperty('vendor_ids') &&
                arryIds.push(...params?.vendor_ids)
            params.hasOwnProperty('poi_ids') && arryIds.push(...params?.poi_ids)
            params.hasOwnProperty('asset_type_ids') &&
                arryIds.push(...params?.asset_type_ids)
            params.hasOwnProperty('geopath_clasification_ids') &&
                arryIds.push(...params?.geopath_clasification_ids)
            params.hasOwnProperty('material_ids') &&
                arryIds.push(...params?.material_ids)
            loadFilters(params)

            if (axiosRequestList) {
                axiosRequestList.cancel()
                setAxiosRequestList(null)
            }
            loadAssetsList()
        }

        return () => {}
    }, [page])

    const loadAssetsListMap = () => {
        setAssetsListMapSpin(true)
        setAxiosRequestMap(
            get(
                ENDPOINTS.GET_PORTAL_GEOLOCATIONS,
                { ...params, ...page },
                successAssetsListMap,
                error,
                alwaysAssetsListMap,
            ),
        )
    }

    const successAssetsListMap = (response: any) => {
        setAssetsListMapSpin(false)
        setAssetsListMap(response.data)
    }

    const alwaysAssetsListMap = () => {
        // setAssetsListMapSpin(false)
    }

    const loadAssetsList = () => {
        setAssetsListSpin(true)
        setAxiosRequestList(
            get(
                ENDPOINTS.GET_ELEMENTS_LIST,
                { ...params, ...page, view: 'switch' },
                successAssetsList,
                error,
                alwaysAssetsList,
            ),
        )
    }

    const successAssetsList = (response: any) => {
        setAssetsListSpin(false)
        setTotalElements(response.data.pagination.total)
        setAssetsList(response.data.data)
    }

    const alwaysAssetsList = () => {
        // setAssetsListSpin(false)
    }

    const loadIdsFromDataToFilters = (arr: any) => {
        const association: any = {
            material_ids: [],
            asset_type_ids: [],
        }
        arr?.brief.relationships.specifications?.map((item: any) =>
            item.relationships.subitems.map((aux: any) => {
                if (aux.attributes.optional) {
                    let obj = aux.attributes.optional
                    let key = Object.keys(obj)[0]
                    if (key === 'asset_format_id') {
                        association.material_ids = [
                            ...association.material_ids,
                            obj[key],
                        ]
                    } else {
                        association.asset_type_ids = [
                            ...association.asset_type_ids,
                            obj[key],
                        ]
                    }
                }
            }),
        )
        return association
    }

    const loadFilters = (params?: any) => {
        let material_ids: any = []
        let material_ids_description: any = []
        let poi_ids: any = []
        let poi_ids_description: any = []
        let vendor_ids: any = []
        let vendor_ids_description: any = []
        let asset_type_ids: any = []
        let asset_type_ids_description: any = []
        let geopath_clasification_ids: any = []
        let geopath_clasification_ids_description: any = []

        let result: IFilterDataProposal = {
            vendor_ids: {
                title: null,
                array: null,
                description: null,
            },
            material_ids: {
                title: null,
                array: null,
                description: null,
            },
            geopath_clasification_ids: {
                title: null,
                array: null,
                description: null,
            },
            asset_type_ids: {
                title: null,
                array: null,
                description: null,
            },
            poi_ids: {
                title: null,
                array: null,
                description: null,
            },
            start_date: {
                title: null,
                description: null,
            },
            end_date: {
                title: null,
                description: null,
            },
            ots_min: {
                title: null,
                description: null,
            },
            ots_max: {
                title: null,
                description: null,
            },
        }

        if (params?.hasOwnProperty('vendor_ids')) {
            vendorsList.map((item: any) => {
                const index = params.vendor_ids.indexOf(item.id)
                if (index > -1) {
                    vendor_ids.push(item.id)
                    vendor_ids_description.push(item.alias_name)
                }
            })

            result.vendor_ids.array = vendor_ids
            result.vendor_ids.title = getTranslation('vendors')
            result.vendor_ids.description = vendor_ids_description
        }

        if (params?.hasOwnProperty('poi_ids')) {
            poisClasificationsList.map((item: any) => {
                const index = params.poi_ids.indexOf(item.id)
                if (index > -1) {
                    poi_ids.push(item.id)
                    poi_ids_description.push(item.name)
                }
            })
            result.poi_ids.array = poi_ids
            result.poi_ids.title = getTranslation('puntos_de_interes')
            result.poi_ids.description = poi_ids_description
        }
        if (params?.hasOwnProperty('asset_type_ids')) {
            assetTypesList.map((item: any) => {
                const index = params.asset_type_ids.indexOf(item.id)
                if (index > -1) {
                    asset_type_ids.push(item.id)
                    asset_type_ids_description.push(item.name)
                }
            })
            result.asset_type_ids.array = asset_type_ids
            result.asset_type_ids.title = getTranslation('tipo')
            result.asset_type_ids.description = asset_type_ids_description
        }
        if (params?.hasOwnProperty('geopath_clasification_ids')) {
            geopathList.map((item: any) => {
                const index = params.geopath_clasification_ids.indexOf(item.id)
                if (index > -1) {
                    geopath_clasification_ids.push(item.id)
                    geopath_clasification_ids_description.push(item.name)
                }
            })
            result.geopath_clasification_ids.array = geopath_clasification_ids
            result.geopath_clasification_ids.title = getTranslation('entorno')
            result.geopath_clasification_ids.description =
                geopath_clasification_ids_description
        }
        if (params?.hasOwnProperty('material_ids')) {
            materialList.map((item: any) => {
                const index = params.material_ids.indexOf(item.id)
                if (index > -1) {
                    material_ids.push(item.id)
                    material_ids_description.push(item.name)
                }
            })
            result.material_ids.array = material_ids
            result.material_ids.title = getTranslation('material')
            result.material_ids.description = material_ids_description
        }

        result.start_date.title = getTranslation('inicio')
        result.start_date.description = params?.start_date
        result.end_date.title = getTranslation('fin')
        result.end_date.description = params?.end_date
        result.ots_max.title = getTranslation('ots_max')
        result.ots_max.description = params?.ots_max
        result.ots_min.title = getTranslation('ots_min')
        result.ots_min.description = params?.ots_min

        setFilterData(result)
    }

    useEffect(() => {
        if (
            data &&
            poisClasificationsList.length > 0 &&
            assetTypesList.length > 0 &&
            geopathList.length > 0 &&
            materialList.length > 0
        ) {
            let test: any = loadIdsFromDataToFilters(data)
            loadFilters({ ...params, ...test })
            setFlagFirstReloadFilters(true)
        }
        return () => {}
    }, [
        data,
        poisClasificationsList,
        assetTypesList,
        geopathList,
        materialList,
    ])

    const loadProposal = () => {
        setProposalSpin(true)
        get(
            ENDPOINTS.GET_PROPOSAL.replace('{proposal}', proposalId),
            {},
            success,
            error,
            always,
        )
    }

    const success = (response: any) => {
        setProposalSpin(false)
        let assetsSelected: number[] = []
        let data = response.data.data
        data.element_proposals.map((item: any, idex: any) => {
            assetsSelected.push(item.element_id)
        })
        setAssetsSelected(assetsSelected)
        setData(data)
    }

    const always = () => {
        setProposalSpin(false)
    }

    const loadVendors = () => {
        setVendorsSpin(true)
        get(
            ENDPOINTS.GET_ORGANIZATION_VENDORS,
            {},
            successVendors,
            error,
            alwaysVendors,
        )
    }

    const successVendors = (response: any) => {
        setVendorsSpin(false)
        setVendorsList(response.data.data)
    }

    const alwaysVendors = () => {
        setVendorsSpin(false)
    }

    const loadPoisClasifications = () => {
        setPoisClasificationsSpin(true)
        get(
            ENDPOINTS.GET_BRIEF_POI_CLASIFICATIONS,
            {},
            successPoisClasifications,
            error,
            alwaysPoisClasifications,
            false,
        )
    }

    const successPoisClasifications = (response: any) => {
        setPoisClasificationsSpin(false)
        setPoisClasificationsList(response.data.data)
    }

    const alwaysPoisClasifications = () => {
        setPoisClasificationsSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const addAssetsToProposal = () => {
        setAssetsListSpin(true)
        post(
            ENDPOINTS.POST_ELEMENT_PROPOSALS,
            {
                elements_ids: selectionIdsList,
                proposal_id: data?.id,
                start_date: filterData.start_date.description,
                end_date: filterData.end_date.description,
            },
            successAdd,
            error,
            alwaysAdd,
        )
    }

    const successAdd = (response: any) => {
        setAssetsListSpin(false)
        setAssetsSelected([...assetsSelected, ...selectionIdsList])
        setSelectionIdsList([])
        successObject(response)
    }

    const alwaysAdd = () => {
        setAssetsListSpin(false)
    }

    if (
        generalSpin &&
        !proposalSpin &&
        !poisClasificationsSpin &&
        !vendorsSpin
    ) {
        setGeneralSpin(false)
    }

    if (
        !generalSpin &&
        (proposalSpin || poisClasificationsSpin || vendorsSpin)
    ) {
        setGeneralSpin(true)
    }

    return (
        <Spin
            className='spin-general'
            indicator={antIcon}
            spinning={generalSpin}>
            <div id='proposal-detail'>
                <div className='box'>
                    <ProposalHeader data={data} loadPage={loadPage} />
                </div>
                <ProposalTabs
                    tab={tab}
                    assetsSelected={assetsSelected}
                    setTab={setTab}
                    loadPage={loadPage}
                    data={data}
                />

                <div id='body-container'>
                    {tab === '1' ? (
                        <AssetsComponent
                            classname={
                                tab === '1'
                                    ? 'component-visible'
                                    : 'component-hidden'
                            }
                            loadPage={loadPage}
                            data={data}
                            flagFirstReloadFilters={flagFirstReloadFilters}
                            setFlagFirstReloadFilters={
                                setFlagFirstReloadFilters
                            }
                            filterData={filterData}
                            filterShow={filterShow}
                            filterList={filterList}
                            vendorsList={vendorsList}
                            materialList={materialList}
                            assetTypesList={assetTypesList}
                            geopathList={geopathList}
                            poisClasificationsList={poisClasificationsList}
                            selectionIdsList={selectionIdsList}
                            addAssetsToProposal={addAssetsToProposal}
                            assetsSelected={assetsSelected}
                            setParams={setParams}
                            setFilterShow={setFilterShow}
                            setFilterList={setFilterList}
                            page={page}
                            totalElements={totalElements}
                            setPage={setPage}
                            assetsListSpin={assetsListSpin}
                            assetsList={assetsList}
                            setSelectionIdsList={setSelectionIdsList}
                            assetsListMap={assetsListMap}
                            assetsListMapSpin={assetsListMapSpin}
                        />
                    ) : (
                        <StrategyComponent
                            classname={
                                tab === '2'
                                    ? 'component-visible'
                                    : 'component-hidden'
                            }
                            data={data}
                        />
                    )}
                </div>
            </div>
        </Spin>
    )
}
