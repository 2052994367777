import { Form, Button } from 'antd'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Language } from '../../../config/Language'

interface IProps {
    form: any
    onChange: (values: any) => void
    onSubmit: (values: any) => void
    spin: boolean
    openForgotPassword: (bool: boolean) => void
    openHelp: (bool: boolean) => void
    errorBool: boolean
    error: any
}
export const LoginForm = ({ ...props }: IProps) => {
    const {
        form,
        onChange,
        onSubmit,
        spin,
        openForgotPassword,
        openHelp,
        errorBool,
        error,
    } = props
    const { getTranslation } = Language()

    let text = ''
    if (error.response) {
        if (error.response.status === 401)
            text =
                'Nombre de usuario o contraseña incorrectos, por favor vuelve a intentarlo.'
        if (error.response.status === 500) text = 'Error de servidor'
    }

    return (
        <div className='context'>
            <div className='logo'></div>
            <div className='title'>Inicio de sesión</div>
            <div className='subtitle'>
                Inicia sesión con tu email y contraseña.
            </div>
            {errorBool && (
                <div className='errors'>
                    <div className='icon'>
                        <CloseCircleOutlined />
                    </div>
                    <div className='text'>{text}</div>
                </div>
            )}
            <Form
                layout='vertical'
                form={form}
                onValuesChange={onChange}
                onFinish={onSubmit}
                scrollToFirstError>
                <CustomInput
                    id='email'
                    className='field-email'
                    name='email'
                    type='email'
                    label='Email'
                    required
                />
                <CustomInput
                    id='password'
                    className='field-password'
                    name='password'
                    type='password'
                    label='Contraseña'
                    required
                />
                <Form.Item>
                    <div className='buttons-container'>
                        <div className='buttons-link'>
                            <a
                                className='forgot'
                                onClick={() => openForgotPassword(true)}
                                type='primary'>
                                Olvidé mi contraseña
                            </a>
                            <a
                                className='help'
                                onClick={() => openHelp(true)}
                                type='primary'>
                                Ayuda
                            </a>
                        </div>
                        <Button
                            loading={spin}
                            className='btn-login'
                            htmlType='submit'
                            size='small'
                            type='primary'>
                            Iniciar sesión
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}
