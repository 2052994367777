import { Language } from '../../config/Language'
import { GeneralBoxContainer } from './GeneralBoxContainer'

interface IProps {
    data: {
        total_activities: number
        meetings_rate: number
        conversion_rate: number
        campaigns_executed: number
        proposals_sent: number
    }
}

export const GeneralMetrics = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const { data } = props

    return (
        <div className='general-metrics'>
            <GeneralBoxContainer
                title={getTranslation('propuesta_enviada')}
                textFooter={getTranslation('historical_total')}
                data={data.proposals_sent}
            />
            <GeneralBoxContainer
                title={getTranslation('campana_ejecutadas')}
                textFooter={getTranslation('historical_total')}
                data={data.campaigns_executed}
            />
            <GeneralBoxContainer
                title={getTranslation('tasa_conversion')}
                textFooter={getTranslation('historical_total')}
                data={data.conversion_rate}
                typeData='%'
            />
            <GeneralBoxContainer
                title={getTranslation('activities')}
                textFooter={getTranslation('total_actividades')}
                data={data.total_activities}
            />
            <GeneralBoxContainer
                title={getTranslation('reuniones')}
                textFooter={getTranslation('promedio_semanal')}
                data={data.meetings_rate}
            />
        </div>
    )
}
