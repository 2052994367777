import { Pie } from '@ant-design/charts'
import { Empty } from 'antd'
import { Language } from '../../../config/Language'

export const CustomPieChart = () => {
    const { getTranslation } = Language()
    var data: any[] = [
        // {
        //     name: 'asd asdasd',
        //     value: 27,
        // },
        // {
        //     name: 'qwesdf sdf',
        //     value: 25,
        // },
        // {
        //     name: 'zxcsdfsd sdf sd',
        //     value: 18,
        // },
        // {
        //     name: 'qaze wewe  w',
        //     value: 15,
        // },
        // {
        //     name: 'wsx sdsdf  sdf',
        //     value: 10,
        // },
        // {
        //     name: 'rfv33e e edw  wd  d',
        //     value: 5,
        // },
    ]

    var config = {
        appendPadding: 10,
        data: data,
        angleField: 'value',
        colorField: 'name',
        radius: 1,
        startAngle: Math.PI,
        endAngle: Math.PI * 1.5,
        label: {
            type: 'inner',
            offset: '-8%',
            content: '{name}',
            style: { fontSize: 18 },
        },
        interactions: [{ type: 'element-active' }],
        pieStyle: { lineWidth: 0 },
    }
    return (
        <>
            {data.length > 0 ? (
                <Pie {...config} />
            ) : (
                <div className='data-ant-empty'>
                    <Empty description={getTranslation('sin_datos')} />
                </div>
            )}
        </>
    )
}
