import { Language } from '../../../config/Language'
import { ShowImage } from '../../common/show-img/ShowImage'
import { Form } from 'antd'
import { useAxios } from '../../../hooks/useAxios'
import { useState, useCallback, useEffect } from 'react'
import { Notifications } from '../../common/notifications/Notifications'
import moment from 'moment'
import { IProposal } from '../../../interface/IProposal'
import { Functions } from '../../../config/Functions'
import { SwapRightOutlined } from '@ant-design/icons'
import { CustomDatePickerProposal } from '../../common/custom-date-picker-proposal/CustomDatePickerProposal'
import useCurrencyContext from '../../../hooks/useCurrencyContext'
import { ENDPOINTS } from '../../../config/EndPoints'
import { IElementProposal } from '../../../interface/IElementProposal'
import { CustomInput } from '../../common/custom-input/CustomInput'
import { CustomInputProposal } from '../../common/custom-input-proposal/CustomInputProposal'

interface IProps {
    data: IProposal | null
    formCosts: any
    selectionList: any[]
    costProposalData: any
    formValueCosts: any
    onChangeCosts: (values: any) => void
    loadProposal: () => void
}

interface FormValue {
    expirationDate: any | null
    contact: any | null
}

export const Abstract = ({ ...props }: IProps) => {
    const {
        data,
        formCosts,
        formValueCosts,
        selectionList,
        costProposalData,
        onChangeCosts,
        loadProposal,
    } = props
    const { currency } = useCurrencyContext()
    const { getTranslation, getLanguage } = Language()
    const [form] = Form.useForm()
    const { put } = useAxios()
    const { successObject, errorObjectWithData } = Notifications()
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [saveSpin, setSaveSpin] = useState(false)
    const [saveContactSpin, setSaveContactSpin] = useState(false)
    const [editDatesShow, setEditDatesShow] = useState<any>(false)
    const [contactShow, setContactShow] = useState<any>(false)
    const [costTotal, setCostTotal] = useState(0)

    useEffect(() => {
        if (data) {
            let newFormValue: FormValue | any = {
                expirationDate: moment(new Date(data.expiration_date)).utc(),
                contact: data.email,
            }
            form.setFieldsValue(newFormValue)
            setFormValue(newFormValue)
        }
        return () => {}
    }, [data])

    useEffect(() => {
        calcTotal()
    }, [formValueCosts, costProposalData])

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const onSave = (values: any) => {
        setSaveSpin(true)

        data &&
            put(
                ENDPOINTS.PUT_PROPOSAL_EXPIRATION.replace(
                    '{proposal}',
                    data?.id.toString(),
                ),
                {
                    expiration_date:
                        formValue?.expirationDate.format('YYYY-MM-DD'),
                },
                successSave,
                error,
                alwaysSave,
            )
    }

    const successSave = useCallback((response: any) => {
        setSaveSpin(false)
        successObject(response)
        loadProposal()
        setEditDatesShow(false)
    }, [])

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    const onSaveContact = (values: any) => {
        setSaveContactSpin(true)

        data &&
            put(
                ENDPOINTS.PUT_PROPOSAL_EMAIL.replace(
                    '{proposal}',
                    data?.id.toString(),
                ),
                {
                    email: formValue?.contact,
                },
                successSaveContact,
                error,
                alwaysSaveContact,
            )
    }

    const successSaveContact = useCallback((response: any) => {
        setSaveContactSpin(false)
        successObject(response)
        loadProposal()
        setContactShow(false)
    }, [])

    const alwaysSaveContact = () => {
        setSaveContactSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const handleCloseEditDate = () => {
        let newFormValue: FormValue = formValue
        if (data?.start_date && data?.end_date) {
            newFormValue.expirationDate = moment(
                new Date(data?.expiration_date),
            ).utc()
        }
        form.setFieldsValue(newFormValue)
        setFormValue(newFormValue)
    }

    const handleCloseEditContact = () => {
        let newFormValue: FormValue = formValue
        if (data?.email) {
            newFormValue.contact = data?.email
        }
        form.setFieldsValue(newFormValue)
        setFormValue(newFormValue)
    }

    let budget = getTranslation('presupuesto')
    if (data) {
        if (getLanguage() === 'en') {
            budget = `${data?.brief?.budget_type?.name} ${getTranslation(
                'presupuesto',
            ).toLocaleLowerCase()}`
        } else {
            budget = `${getTranslation(
                'presupuesto',
            )} ${data?.brief?.budget_type?.name.toLocaleLowerCase()}`
        }
    }

    const calcTotal = () => {
        let result =
            costProposalData?.total_price +
            formValueCosts.production_cost +
            formValueCosts.installation_cost

        let part = (formValueCosts.discount / 100) * result
        result -= part
        setCostTotal(result ? result : 0)
    }

    let currencyLabel: any =
        currency &&
        currency.find(
            (item: any, index: any) => item.value === data?.brief.currency_id,
        )

    return (
        <div className='abstract-container'>
            <div className='container'>
                <div className='logo'>
                    <ShowImage
                        url={data?.brand.file}
                        alt={getTranslation('logo')}
                    />
                </div>
                <div className='info'>
                    <div className='info-item'>
                        <div className='title'>{getTranslation('brand')}</div>
                        <div className='text'>{data?.brand.name}</div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>
                            {getTranslation('nombre_campana')}
                        </div>
                        <div className='text'>{data?.brief.name}</div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>{budget}</div>
                        <div className='text'>
                            {data?.brief.budget &&
                                `${
                                    currencyLabel?.symbol
                                        ? currencyLabel?.symbol
                                        : ''
                                } ${Functions.formatNumber(
                                    data?.brief.budget,
                                )} ${currencyLabel?.label}`}
                        </div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>{getTranslation('pais')}</div>
                        <div className='text'>
                            {data?.brief.city.state.country.name}
                        </div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>{getTranslation('ciudad')}</div>
                        <div className='text'>{data?.brief.city.name}</div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>
                            {getTranslation('fecha_estimada')}
                        </div>
                        <div className='text'>
                            {data &&
                                Functions.formatDateDayMonthYear(
                                    data?.start_date,
                                )}
                            <SwapRightOutlined />
                            {data &&
                                Functions.formatDateDayMonthYear(
                                    data?.end_date,
                                )}
                        </div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>
                            {getTranslation('proposal_expiration')}
                        </div>
                        <div className='text'>
                            {data?.expiration_date && (
                                <Form
                                    form={form}
                                    layout='vertical'
                                    scrollToFirstError
                                    onValuesChange={onChange}
                                    onFinish={onSave}
                                    size='small'>
                                    <CustomDatePickerProposal
                                        name='expirationDate'
                                        id='expirationDate'
                                        className='date-picker-proposal'
                                        date={
                                            data?.expiration_date &&
                                            Functions.formatDateDayMonthYear(
                                                data?.expiration_date,
                                            )
                                        }
                                        size='small'
                                        handleCloseEditDate={
                                            handleCloseEditDate
                                        }
                                        editShow={editDatesShow}
                                        setEditShow={setEditDatesShow}
                                        saveSpin={saveSpin}
                                    />
                                </Form>
                            )}
                        </div>
                    </div>
                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>
                            {getTranslation('contactos')}
                        </div>
                        <div className='text'>
                            <Form
                                form={form}
                                layout='vertical'
                                scrollToFirstError
                                onValuesChange={onChange}
                                onFinish={onSaveContact}
                                size='small'>
                                <CustomInputProposal
                                    name='contact'
                                    id='contact'
                                    className='contact-proposal'
                                    value={
                                        data?.email
                                            ? data?.email
                                            : getTranslation('sin_datos')
                                    }
                                    type='email'
                                    size='small'
                                    handleCloseEdit={handleCloseEditContact}
                                    editShow={contactShow}
                                    setEditShow={setContactShow}
                                    saveSpin={saveContactSpin}
                                />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <Form
                form={formCosts}
                layout='vertical'
                scrollToFirstError
                onValuesChange={onChangeCosts}
                size='small'>
                <div className='totals-container'>
                    <div className='totals'>
                        <div className='section'>
                            <div className='section-item'>
                                <div className='title'>
                                    {getTranslation('soportes_totales')}
                                </div>
                                <div className='value'>
                                    {data?.element_proposals?.length}
                                </div>
                            </div>
                            <div className='section-item'>
                                <div className='title'>
                                    {getTranslation('total_OTS')}
                                </div>
                                <div className='value'>
                                    {Functions.formatNumber(
                                        costProposalData?.total_ots
                                            ? costProposalData?.total_ots
                                            : 0,
                                    )}
                                </div>
                            </div>
                            <div className='section-item'>
                                <div className='title'>CPM OTS</div>
                                <div className='value'>{`${
                                    currencyLabel?.symbol
                                        ? currencyLabel?.symbol
                                        : ''
                                } ${Functions.formatNumber(
                                    costProposalData?.cpm
                                        ? costProposalData?.cpm
                                        : 0,
                                )}`}</div>
                            </div>
                            <div className='section-item'>
                                <div className='title-black'>
                                    {getTranslation('subtotal')}
                                </div>
                                <div className='value-black'>{`${
                                    currencyLabel?.symbol
                                        ? currencyLabel?.symbol
                                        : ''
                                } ${Functions.formatNumber(
                                    costProposalData?.total_price
                                        ? costProposalData?.total_price
                                        : 0,
                                )}`}</div>
                            </div>
                        </div>

                        <div className='section'>
                            <div className='section-item'>
                                <div className='title'>
                                    {getTranslation('cost_production')}
                                </div>
                                <div className='input'>
                                    <CustomInput
                                        name='production_cost'
                                        id='production_cost'
                                        type='number'
                                        size={'small'}
                                        label={''}
                                    />
                                </div>
                            </div>
                            <div className='section-item'>
                                <div className='title'>
                                    {getTranslation('installation_cost')}
                                </div>
                                <div className='input'>
                                    <CustomInput
                                        name='installation_cost'
                                        id='installation_cost'
                                        type='number'
                                        size={'small'}
                                        label={''}
                                    />
                                </div>
                            </div>
                            <div className='section-item'>
                                <div className='title'>
                                    {getTranslation('discount_')}
                                </div>
                                <div className='input'>
                                    <CustomInput
                                        name='discount'
                                        id='discount'
                                        type='number'
                                        size={'small'}
                                        label={''}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='section'>
                            <div className='section-item'>
                                <div className='title-black'>
                                    {getTranslation('total')}
                                </div>
                                <div className='value-strong'>{`${
                                    currencyLabel?.symbol
                                        ? currencyLabel?.symbol
                                        : ''
                                } ${Functions.formatNumber(costTotal)}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
