import { createContext } from 'react'
import { useCallback, useState, useMemo } from 'react'

export const BreadCrumbContext = createContext<any>({})

const BreadCrumbContextProvider = ({ children }: any) => {
    const [breadCrumb, setBreadCrumb] = useState<any | null>({
        title: '',
    })

    const updateBreadCrumb = useCallback((breadCrumb: any) => {
        setBreadCrumb({ title: breadCrumb })
    }, [])

    const value: any = useMemo(
        () => ({
            updateBreadCrumb,
            breadCrumb,
        }),
        [breadCrumb, updateBreadCrumb],
    )

    // @ts-ignore
    return (
        <BreadCrumbContext.Provider value={value}>
            {children}
        </BreadCrumbContext.Provider>
    )
}

export default BreadCrumbContextProvider
