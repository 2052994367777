import { Spin, Table, Tooltip } from 'antd'
import Checkbox, { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import { Functions } from '../../../config/Functions'
import { Language } from '../../../config/Language'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    page: any
    totalElements: number
    classname: string
    setPage: ({}) => void
    assetsList: any[]
    assetsListSpin: boolean
    selectionIdsList: any[]
    setSelectionIdsList: (list: any[]) => void
    assetsSelected: any[]
}

export const ProposalTable = ({ ...props }: IProps) => {
    const {
        assetsList,
        page,
        classname,
        totalElements,
        setPage,
        assetsListSpin,
        selectionIdsList,
        setSelectionIdsList,
        assetsSelected,
    } = props
    const { getTranslation, getTranslationObject } = Language()

    const onSelectChange = (selectedRowKeys: any) => {
        setSelectionIdsList(selectedRowKeys)
    }

    const onSelectCheckBoxChange = (e: any, key: any) => {
        if (e.target.checked) {
            setSelectionIdsList([...selectionIdsList, key])
        } else {
            setSelectionIdsList(
                selectionIdsList.filter((item: any) => item !== key),
            )
        }
    }

    const columns: any[] = [
        {
            title: getTranslation('ciudad'),
            dataIndex: 'city',
            key: 'city',
            fixed: 'left',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.city.name, b.city.name),
            render: (city: any, item: any) => (
                <Tooltip placement='topLeft' title={city.name}>
                    {city.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('medio'),
            dataIndex: 'vendor',
            key: 'vendor',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.vendor.name, b.vendor.name),
            render: (vendor: any) => (
                <Tooltip placement='topLeft' title={vendor.name}>
                    {vendor.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('material'),
            dataIndex: 'material',
            key: 'material',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.material.name, b.material.name),
            render: (material: any) => (
                <Tooltip placement='topLeft' title={material.name}>
                    {material.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('entorno'),
            dataIndex: 'geopath_clasification',
            key: 'geopath_clasification',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.geopath_clasification.name,
                    b.geopath_clasification.name,
                ),
            render: (geopath_clasification: any) => (
                <Tooltip placement='topLeft' title={geopath_clasification.name}>
                    {geopath_clasification.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('tipo'),
            dataIndex: 'asset_type',
            key: 'asset_type',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.asset_type.name, b.asset_type.name),
            render: (asset_type: any) => (
                <Tooltip placement='topLeft' title={asset_type.name}>
                    {asset_type.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('nombre_comercial'),
            dataIndex: 'supplier_alias',
            key: 'supplier_alias',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(
                    a.supplier_alias.name,
                    b.supplier_alias.name,
                ),
            render: (supplier_alias: any, item: any) => (
                <Tooltip placement='topLeft' title={supplier_alias.name}>
                    {supplier_alias.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('ubicacion'),
            dataIndex: 'address',
            key: 'address',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) =>
                Functions.sortByText(a.address, b.address),
            render: (address: any, item: any) => (
                <Tooltip placement='topLeft' title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            title: 'OTS',
            dataIndex: 'ots',
            key: 'ots',
            width: 150,
            align: 'right',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) => Functions.sortByText(a.ots, b.ots),
            render: (ots: any, item: any) => (
                <Tooltip placement='topLeft' title={ots}>
                    {ots}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('precio_unitario'),
            dataIndex: 'price',
            key: 'price',
            width: 200,
            align: 'right',
            ellipsis: {
                showTitle: false,
            },
            sorter: (a: any, b: any) => Functions.sortByText(a.price, b.price),
            render: (price: any, item: any) => (
                <Tooltip placement='topLeft' title={price}>
                    {price}
                </Tooltip>
            ),
        },
    ]

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setPage({
            page: pagination.current,
            per_page: pagination.pageSize,
        })
    }

    return (
        <Spin
            className='spin-local'
            indicator={antIcon}
            spinning={assetsListSpin}>
            <div className={`assets-list ${classname}`}>
                <Table
                    sticky
                    id='assets-table'
                    bordered
                    locale={getTranslationObject('table_trad')}
                    pagination={{
                        current: page.page,
                        pageSize: page.per_page,
                        showSizeChanger: true,
                        total: totalElements,
                        showTotal: (total: any, range: any) =>
                            `${range[0]}-${range[1]} ${getTranslation(
                                'of',
                            )} ${total} ${getTranslation('items')}`,
                    }}
                    scroll={{ x: true }}
                    columns={columns}
                    rowClassName={(record) =>
                        assetsSelected.find(
                            (item: any) => item === record.key,
                        ) && 'disabled-row'
                    }
                    dataSource={assetsList}
                    rowSelection={{
                        selectedRowKeys: [...selectionIdsList],
                        onChange: onSelectChange,
                        getCheckboxProps: (record: any) => ({
                            disabled: assetsSelected.find(
                                (item: any) => item === record.key,
                            ),
                        }),
                        renderCell: (value: boolean, record: any) => {
                            let selected = assetsSelected.find(
                                (item: any) => item === record.key,
                            )
                            let selection = selectionIdsList.find(
                                (item: any) => item === record.key,
                            )
                            return (
                                <Tooltip
                                    placement='topLeft'
                                    title={
                                        selected
                                            ? getTranslation('already_added')
                                            : ''
                                    }>
                                    <span
                                        style={{
                                            cursor: selected
                                                ? 'not-allowed'
                                                : 'pointer',
                                        }}>
                                        <Checkbox
                                            className={`${
                                                selected
                                                    ? 'checkbox-disabled'
                                                    : ''
                                            }`}
                                            checked={selection || selected}
                                            disabled={selected}
                                            defaultChecked={selected}
                                            style={{
                                                pointerEvents: selected
                                                    ? 'none'
                                                    : 'auto',
                                            }}
                                            onChange={(e: any) => {
                                                onSelectCheckBoxChange(
                                                    e,
                                                    record.key,
                                                )
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            )
                        },
                    }}
                    onChange={handleTableChange}
                />
            </div>
        </Spin>
    )
}
