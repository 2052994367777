import { Modal, Form, Button, Spin, Empty } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import { NEW_ORGANIZATION } from '../../config/Path'
import { Account } from './Account'
import { CustomSelect } from '../common/custom-select/CustomSelect'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { Language } from '../../config/Language'

export const AccountsList = () => {
    const [form] = Form.useForm()
    const { getTranslation } = Language()
    const history = useHistory()
    const { get } = useAxios()
    const { errorObjectWithData } = Notifications()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [formValue, setFormValue] = useState<any>({})
    const [generalSpin, setGeneralSpin] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        loadAccounts()
        return () => {}
    }, [])

    const loadAccounts = () => {
        setGeneralSpin(true)
        get(ENDPOINTS.GET_ACCOUNTS, {}, successBrand, error, alwaysBrand)
    }

    const successBrand = useCallback((response: any) => {
        setGeneralSpin(false)
        setData(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const alwaysBrand = () => {
        setGeneralSpin(false)
    }

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const onChange = (values: any) => {
        let newFormValue: any = { ...formValue, ...values }

        setFormValue(newFormValue)
    }

    const onSave = () => {
        form.validateFields(['organizationId'])
        if (formValue.organizationId) {
            history.push(`${NEW_ORGANIZATION}/${formValue.organizationId}`)
        }
    }

    return (
        <div id='accounts-list'>
            <div className='header'>
                <div className='header-title'>{getTranslation('accounts')}</div>
                <div className='header-button'>
                    <Button type='primary' size='small' onClick={showModal}>
                        {getTranslation('crear_marca')}
                    </Button>
                    <Button type='primary' size='small'>
                        <Link to={NEW_ORGANIZATION}>
                            {getTranslation('crear_organizacion')}
                        </Link>
                    </Button>
                </div>
            </div>
            <Spin
                className='spin-local'
                indicator={antIcon}
                spinning={generalSpin}>
                {data.length > 0 ? (
                    <div className='body'>
                        {data.map((item: any, index: any) => {
                            return <Account data={item} key={index} />
                        })}
                    </div>
                ) : (
                    <Empty description={getTranslation('sin_datos')} />
                )}
            </Spin>
            <Modal
                title={getTranslation('crear_marca')}
                visible={isModalVisible}
                onOk={onSave}
                onCancel={handleCancel}>
                <p>{getTranslation('modal_nueva_marca_detalle')}</p>
                <Form
                    form={form}
                    layout='vertical'
                    scrollToFirstError
                    onValuesChange={onChange}
                    size='large'>
                    <div className='form-container'>
                        <div className='form-body'>
                            <CustomSelect
                                label={getTranslation('organization')}
                                name='organizationId'
                                id='organizationId'
                                endpoint={ENDPOINTS.GET_ADVERTISERS}
                                value={formValue?.organizationId}
                                required={true}
                            />
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
