import { Button, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { ENDPOINTS } from '../../../config/EndPoints'
import { Language } from '../../../config/Language'
import { useAxios } from '../../../hooks/useAxios'
import { IProposal } from '../../../interface/IProposal'
import { Notifications } from '../../common/notifications/Notifications'
import { antIcon } from '../../common/spin-icon/SpinIcon'

interface IProps {
    data: IProposal | null
    classname: string
}
export const StrategyComponent = ({ ...props }: IProps) => {
    const { data, classname } = props
    const { getLanguage, getTranslation } = Language()
    const { get, put } = useAxios()
    const { successObject, errorObjectWithData } = Notifications()
    const [editorValue, setEditorValue] = useState<string>('')
    const [timeOut, setTimeOut] = useState<any>()
    const [hasChange, setHasChange] = useState<any>(false)
    const [proposalSpin, setProposalSpin] = useState<any>(false)
    let lang: any = 'en'
    if (getLanguage() === 'en' || 'es' || 'pt') {
        lang = getLanguage()
    }

    useEffect(() => {
        loadProposal()
    }, [])

    const loadProposal = () => {
        setProposalSpin(true)
        data &&
            get(
                ENDPOINTS.GET_PROPOSAL.replace(
                    '{proposal}',
                    data?.id.toString(),
                ),
                {},
                successProposal,
                error,
                alwaysProposal,
            )
    }

    const successProposal = (response: any) => {
        setProposalSpin(false)
        setEditorValue(response.data.data.strategy)
    }

    const alwaysProposal = () => {
        setProposalSpin(false)
    }

    const handleOnChangeEditor = (value: any) => {
        setHasChange(true)
        setEditorValue(value)
    }

    useEffect(() => {
        if (hasChange) {
            clearTimeout(timeOut)
            setTimeOut(
                setTimeout(() => {
                    handleSave()
                }, 3000),
            )
        }
    }, [editorValue])

    const handleSave = () => {
        put(
            ENDPOINTS.PUT_PROPOSAL_STRATEGY.replace(
                '{proposal}',
                data?.id ? data?.id.toString() : '',
            ),
            { strategy: editorValue },
            success,
            error,
            always,
        )
    }

    const success = (response: any) => {
        // setArchiveProposalSpin(false)
        successObject(response)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        // setArchiveProposalSpin(false)
    }

    return (
        <div className={`strategy-container ${classname}`}>
            <SunEditor
                lang={lang}
                autoFocus={true}
                disable={proposalSpin}
                onChange={handleOnChangeEditor}
                setContents={editorValue}
                placeholder={getTranslation('write_here')}
                setOptions={{
                    defaultStyle: 'font-family: Arial',
                    height: '350',
                    buttonList: [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        [
                            'bold',
                            'underline',
                            'italic',
                            'strike',
                            'subscript',
                            'superscript',
                        ],
                        ['removeFormat'],
                        '/',
                        ['fontColor', 'hiliteColor'],
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule'],
                    ],
                }}
            />
        </div>
    )
}
