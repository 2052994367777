import { Form, TimePicker } from 'antd'
import { useEffect, useState } from 'react'
import { Language } from '../../../config/Language'

interface IProps {
    id?: string
    label: string
    name: string
    type?: any
    className?: string
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    value?: any
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
    minuteStep?: number
}

export const CustomTimePicker = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        requiredMessage = getTranslation('required'),
        value,
        type,
        minuteStep,
    } = props
    const [selectedValue, setSelectedValue] = useState<any>(null)

    useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
        return () => {}
    }, [value])

    return (
        <Form.Item
            id={id}
            name={name}
            className={className}
            valuePropName='option'
            label={label}
            rules={[
                {
                    type: type,
                    required: required,
                    message: requiredMessage,
                },
            ]}>
            <TimePicker
                value={selectedValue}
                minuteStep={minuteStep}
                use12Hours
                format='h:mm a'
            />
        </Form.Item>
    )
}
