import { Form, Input } from 'antd'
import { useEffect, useState } from 'react'
import { Language } from '../../../config/Language'

interface IProps {
    id?: string
    label: string
    name: string
    className?: string
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    value?: any
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
}

export const CustomTextArea = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        value,
    } = props
    const [selectedValue, setSelectedValue] = useState<any>(null)

    useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
        return () => {}
    }, [value])

    return (
        <Form.Item
            id={id}
            name={name}
            className={className}
            label={label}
            rules={[
                {
                    required: required,
                    message: requiredMessage,
                },
            ]}>
            <Input.TextArea
                disabled={disabled}
                autoSize={{
                    minRows: 4,
                    maxRows: 4,
                }}
            />
        </Form.Item>
    )
}
