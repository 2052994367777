import { Button, Collapse, Empty } from 'antd'
import { useEffect, useState } from 'react'
import { Language } from '../../config/Language'
import { ContactForm } from './ContactForm'

interface IProps {
    contactsList: any[]
    organization: any
    loadOrganizationData: () => void
    hasAccounts?: boolean
}
const { Panel } = Collapse
export const ContactsList = ({ ...props }: IProps) => {
    const {
        contactsList,
        organization,
        loadOrganizationData,
        hasAccounts = true,
    } = props
    const { getTranslation } = Language()
    const [contactsListData, setContactsListData] = useState<any[]>([])
    const [panelId, setPanelId] = useState<string>('new')

    useEffect(() => {
        setContactsListData(contactsList)
        return () => {}
    }, [contactsList])

    const newContact = () => {
        setPanelId('new')
        setContactsListData(['', ...contactsListData])
    }

    const deleteNewContact = () => {
        if (contactsListData[0] === '') {
            let newArray = contactsListData.filter((item: any) => item !== '')
            setContactsListData(newArray)
        }
    }

    const onChange = (value: any) => {
        setPanelId(value)
    }

    const handlePanelId = (id: any) => {
        setPanelId(id)
    }

    const showContactsForm = () => {
        let result: any = []
        contactsListData.map((item: any, index: any) => {
            let name: string = getTranslation('nuevo_contacto')
            if (item.name) {
                name = `${item.name} ${item.last_name}`
            }
            result.push(
                <Panel
                    header={name}
                    key={(item.id && item.id.toString()) || 'new'}>
                    <ContactForm
                        organization={organization}
                        loadOrganizationData={loadOrganizationData}
                        index={index}
                        contact={item}
                        setPanelId={handlePanelId}
                        deleteNewContact={deleteNewContact}
                    />
                </Panel>,
            )
        })
        return result
    }

    return (
        <div id='contact-form'>
            <div className='header'>
                <div className='header-title'>
                    {getTranslation('contactos')}
                </div>
                <div className='header-button'>
                    <Button
                        type='primary'
                        disabled={contactsListData[0] === '' || !hasAccounts}
                        onClick={newContact}
                        size='small'>
                        {getTranslation('crear_contacto')}
                    </Button>
                </div>
            </div>
            {contactsListData.length > 0 ? (
                <Collapse
                    accordion
                    onChange={onChange}
                    className='collapse-contact'
                    activeKey={panelId}>
                    {showContactsForm()}
                </Collapse>
            ) : (
                <Empty
                    style={{ margin: '24px' }}
                    description={
                        <span>{getTranslation('crea_un_contacto')}</span>
                    }></Empty>
            )}
        </div>
    )
}
