import { useHistory } from 'react-router-dom'
import { NEW_ACTIVITY } from '../../config/Path'
import { ShowImage } from '../common/show-img/ShowImage'
import { Dropdown, Menu, Tooltip } from 'antd'
import {
    CalendarOutlined,
    EllipsisOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons'
import { Language } from '../../config/Language'
import { IActivity } from '../../interface/IActivity'
import { useCallback } from 'react'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { ENDPOINTS } from '../../config/EndPoints'
import IconsByActivityType from './IconsByActivityType'

interface IProps {
    data: IActivity
    setDeleteSpin: (bool: boolean) => void
    handleSuccessDelete: (tab: string) => void
    tab: string
}

export const Activity = ({ ...props }: IProps) => {
    const { data, setDeleteSpin, handleSuccessDelete, tab } = props
    const history = useHistory()
    const { getTranslation } = Language()
    const { del } = useAxios()
    const { successObject, errorObjectWithData } = Notifications()

    const handleDelete = () => {
        setDeleteSpin(true)
        del(
            ENDPOINTS.DEL_ACTIVITY.replace('{activity}', data.id.toString()),
            {},
            successDelete,
            error,
            alwaysDelete,
        )
    }
    const successDelete = useCallback((response: any) => {
        setDeleteSpin(false)
        successObject(response)
        handleSuccessDelete(tab)
    }, [])

    const alwaysDelete = () => {
        setDeleteSpin(false)
    }
    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const handleMenuClick = (e: any) => {
        if (e.key === '1') {
            history.push(`${NEW_ACTIVITY}/${data.id}`)
        } else {
            handleDelete()
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.ItemGroup title={getTranslation('activity')}>
                <Menu.Divider />
                <Menu.Item key='1'>{getTranslation('editar')}</Menu.Item>
                <Menu.Item key='2'>{getTranslation('eliminar')}</Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    )

    return (
        <div className='activity-item'>
            <div className='activity-header'>
                <div className='logo'>
                    <ShowImage
                        url={data.brand.file}
                        alt={getTranslation('logo')}
                    />
                </div>
                <div className='title'>
                    {data.brand.organization?.name}
                    <span>{data.brand?.name}</span>
                </div>
                <div className='botton-options'>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <EllipsisOutlined rotate={90} />
                    </Dropdown>
                </div>
            </div>
            <div className='activity-body'>
                <div className='activity'>
                    <div className='type'>
                        <div className='activity-type'>
                            {IconsByActivityType(data.activity_type.id)}
                            <div className='text-ellipsis'>
                                <Tooltip title={data.activity_type.name}>
                                    {data.activity_type.name}
                                </Tooltip>
                            </div>
                        </div>
                        <div className='activity-date'>
                            <div className='date'>
                                <CalendarOutlined />
                                <div className='text'>{data.date}</div>
                            </div>
                            <div className='hour'>
                                <ClockCircleOutlined />
                                <div className='text'>{data.hour}</div>
                            </div>
                        </div>
                    </div>
                    <div className='description'>
                        <div className='label'>
                            {getTranslation('descripcion')}
                        </div>
                        <div>{data.description}</div>
                    </div>

                    <div className='description'>
                        <div className='label'>
                            {getTranslation('contacto')}
                        </div>
                        <div>{data.contact ? data.contact.name : '-'}</div>
                    </div>
                    <div className='worker'>
                        <div className='label'>
                            {getTranslation('ejecutivo_cuenta')}
                        </div>
                        <div className='worker-link'>{data.user.name}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
