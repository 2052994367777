import { SoundOutlined, BookOutlined } from '@ant-design/icons'
import { Table, Button, Tooltip, Progress } from 'antd'
import { Link } from 'react-router-dom'
import { Language } from '../../config/Language'
import { BRIEFS_FORM } from '../../config/Path'

interface IProps {}

const CampaignsTable = ({ ...props }: IProps) => {
    const { getTranslation, getTranslationObject } = Language()
    let media768 = window.matchMedia('(max-width: 768px)').matches
    let media992 = window.matchMedia('(max-width: 992px)').matches
    let space = 503
    let height = 0
    const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
    )
    if (!media768 && !media992) {
        height = vh - space
    } else {
        height = 500
    }

    const columns: any = [
        {
            title: getTranslation('campaign'),
            dataIndex: 'campaign',
            key: 'campaign',
            fixed: 'left',
            // width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (text: any) => (
                <Tooltip placement='topLeft' title={text}>
                    <a>{text}</a>
                </Tooltip>
            ),
        },
        {
            title: getTranslation('cliente'),
            dataIndex: 'client',
            key: 'client',
            // width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: {
                compare: (a: any, b: any) => a.age - b.age,
                multiple: 3,
            },
            render: (address: any) => (
                <Tooltip placement='topLeft' title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('brand'),
            dataIndex: 'marca',
            key: 'marca',
            ellipsis: {
                showTitle: false,
            },
            render: (address: any) => (
                <Tooltip placement='topLeft' title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('progreso'),
            dataIndex: 'progreso',
            key: 'progreso',
            // width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (item: any) => {
                let config: any = ''
                if (item < 50) {
                    config = {
                        status: 'exception',
                    }
                }

                return (
                    <>
                        <Tooltip placement='topLeft' title={item}>
                            <Progress percent={item} {...config} />
                        </Tooltip>
                    </>
                )
            },
        },
        {
            title: getTranslation('etapa'),
            dataIndex: 'etapa',
            key: 'etapa',
            ellipsis: {
                showTitle: false,
            },
            render: (address: any) => (
                <Tooltip placement='topLeft' title={address}>
                    <div className='etapa'>
                        <span>{address}</span>
                        <BookOutlined />
                    </div>
                </Tooltip>
            ),
        },
        {
            title: getTranslation('finalizacion'),
            dataIndex: 'finalizacion',
            key: 'finalizacion',
            ellipsis: {
                showTitle: false,
            },
            render: (address: any) => (
                <Tooltip placement='topLeft' title={address}>
                    {address}
                </Tooltip>
            ),
        },
    ]

    const data = [
        {
            key: '1',
            campaign: 'John Brown',
            client: 'Nike',
            marca: 'Nike',
            progreso: 10,
            etapa: 'Brief',
            finalizacion: '30-06-2021',
        },
        {
            key: '2',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 59,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '3',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 2,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '4',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 90,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '5',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 45,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '6',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 100,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '7',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 70,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '8',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 29,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '9',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 29,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '10',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 29,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '11',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 29,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
        {
            key: '12',
            campaign: 'John Brown',
            client: 'Playstation',
            marca: 'Play',
            progreso: 30,
            etapa: 'Brief',
            finalizacion: '31-07-2021',
        },
    ]

    return (
        <div className='campaigns-table card'>
            <div className='header'>
                <div className='header-title'>
                    {getTranslation('campaigns')}
                </div>
                <div className='header-button'>
                    <Button type='primary' className='btn-big' shape='round'>
                        <Link to={BRIEFS_FORM}>
                            {getTranslation('nueva_campana')} <SoundOutlined />
                        </Link>
                    </Button>
                </div>
            </div>
            <div className='body'>
                <Table
                    className='campaign-table'
                    sticky
                    locale={getTranslationObject('table_trad')}
                    bordered
                    pagination={false}
                    scroll={{ x: 800, y: height }}
                    columns={columns}
                    dataSource={data}
                />
            </div>
        </div>
    )
}

export default CampaignsTable
