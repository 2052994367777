import { Language } from '../../config/Language'
import { ShowImage } from '../common/show-img/ShowImage'
import { Button, Form } from 'antd'
import { useAxios } from '../../hooks/useAxios'
import { UpOutlined, SwapRightOutlined } from '@ant-design/icons'
import { useState, useCallback, useEffect } from 'react'
import { ENDPOINTS } from '../../config/EndPoints'
import { Notifications } from '../common/notifications/Notifications'
import moment from 'moment'
import { CustomRangePickerProposal } from '../common/custom-range-picker-proposal/CustomRangePickerProposal'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { IProposal } from '../../interface/IProposal'
import { Functions } from '../../config/Functions'
import useCurrencyContext from '../../hooks/useCurrencyContext'

interface IProps {
    data: any | null
    loadPage: () => void
}

interface FormValue {
    rangesDates: any | null
}

export const ProposalHeader = ({ ...props }: IProps) => {
    const { data, loadPage } = props
    const { getTranslation, getLanguage } = Language()
    const [form] = Form.useForm()
    const { currency } = useCurrencyContext()
    const { put } = useAxios()
    const { successObject, errorObjectWithData } = Notifications()
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const { updateBreadCrumb } = useBreadCrumbContext()
    const [saveSpin, setSaveSpin] = useState(false)
    const [showCollapse, setShowCollapse] = useState(false)
    const [editDatesShow, setEditDatesShow] = useState<any>(false)

    useEffect(() => {
        if (data) {
            let newFormValue: FormValue | any = {
                rangesDates: [
                    moment(new Date(data.start_date)).utc(),
                    moment(new Date(data.end_date)).utc(),
                ],
            }
            form.setFieldsValue(newFormValue)
            updateBreadCrumb(data.name)
            setFormValue(newFormValue)
        }
        return () => {}
    }, [data])

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            start_date: formValue?.rangesDates[0].format('YYYY-MM-DD'),
            end_date: formValue?.rangesDates[1].format('YYYY-MM-DD'),
        }

        return result
    }

    const onSave = (values: any) => {
        setSaveSpin(true)
        let result = getValues()
        data &&
            put(
                ENDPOINTS.PUT_PROPOSAL_DATES.replace(
                    '{proposal}',
                    data?.id.toString(),
                ),
                result,
                successSave,
                error,
                alwaysSave,
            )
    }

    const successSave = useCallback((response: any) => {
        setSaveSpin(false)
        successObject(response)
        loadPage()
        setEditDatesShow(false)
    }, [])

    const alwaysSave = () => {
        setSaveSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const handleCloseEditDate = () => {
        let newFormValue: FormValue = formValue
        if (data?.start_date && data?.end_date) {
            newFormValue.rangesDates = [
                moment(new Date(data?.start_date)).utc(),
                moment(new Date(data?.end_date)).utc(),
            ]
        }
        form.setFieldsValue(newFormValue)
        setFormValue(newFormValue)
    }

    let budget = getTranslation('presupuesto')
    if (data) {
        if (getLanguage() === 'en') {
            budget = `${
                data?.brief?.relationships.budgetType.attributes.name
            } ${getTranslation('presupuesto').toLocaleLowerCase()}`
        } else {
            budget = `${getTranslation(
                'presupuesto',
            )} ${data?.brief?.relationships.budgetType.attributes.name.toLocaleLowerCase()}`
        }
    }

    let currencyLabel: any =
        currency &&
        currency.find(
            (item: any, index: any) =>
                item.value === data?.brief.attributes.currency_id,
        )

    const renderSubItems = (arr: any[]) => {
        const result = arr.map((item: any) => item.attributes.name)
        return result.join(', ')
    }
    return (
        <>
            <div className='container'>
                <div className='logo'>
                    <ShowImage
                        url={data?.brand.file}
                        alt={getTranslation('logo')}
                    />
                </div>
                <div className='info'>
                    <div className='info-item'>
                        <div className='title'>{getTranslation('brand')}</div>
                        <div className='text'>{data?.brand.name}</div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>
                            {getTranslation('nombre_campana')}
                        </div>
                        <div className='text'>
                            {data?.brief.attributes.name}
                        </div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>{budget}</div>
                        <div className='text'>
                            {data?.brief.attributes.budget &&
                                `${
                                    currencyLabel?.symbol
                                } ${Functions.formatNumber(
                                    data?.brief.attributes.budget,
                                )} ${currencyLabel?.label}`}
                        </div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>{getTranslation('pais')}</div>
                        <div className='text'>
                            {
                                data?.brief.relationships.city.relationships
                                    .state.relationships.country.attributes.name
                            }
                        </div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>{getTranslation('ciudad')}</div>
                        <div className='text'>
                            {data?.brief.relationships.city.attributes.name}
                        </div>
                    </div>

                    <div className='info-item'>
                        <div className='divider'></div>
                        <div className='title'>
                            {getTranslation('fecha_estimada')}
                        </div>
                        <div className='text'>
                            {data?.start_date && (
                                <Form
                                    form={form}
                                    layout='vertical'
                                    scrollToFirstError
                                    onValuesChange={onChange}
                                    onFinish={onSave}
                                    size='small'>
                                    <CustomRangePickerProposal
                                        name='rangesDates'
                                        id='rangesDates'
                                        className='range-picker-proposal'
                                        start_date={
                                            data?.start_date &&
                                            Functions.formatDateDayMonthYear(
                                                data?.start_date,
                                            )
                                        }
                                        end_date={
                                            data?.end_date &&
                                            Functions.formatDateDayMonthYear(
                                                data?.end_date,
                                            )
                                        }
                                        size='small'
                                        handleCloseEditDate={
                                            handleCloseEditDate
                                        }
                                        type={'array' as const}
                                        editShow={editDatesShow}
                                        setEditShow={setEditDatesShow}
                                        saveSpin={saveSpin}
                                    />
                                </Form>
                            )}
                        </div>
                    </div>
                </div>
                <div className='button-collapse'>
                    <Button
                        shape='circle'
                        size='small'
                        onClick={() => setShowCollapse(!showCollapse)}
                        icon={
                            <UpOutlined
                                className={showCollapse ? 'open' : ''}
                            />
                        }
                    />
                </div>
            </div>
            <div
                className={`container-collapse ${
                    showCollapse ? 'show' : 'hidden'
                }`}>
                <div className='public-objetives'>
                    <div className='title'>
                        {getTranslation('publico_objetivo')}
                    </div>

                    <div className='info'>
                        {data?.brief?.relationships?.objectives?.map(
                            (item: any, index: any) => (
                                <div className='info-item' key={index}>
                                    <div className='divider'></div>
                                    <div className='title'>
                                        {item.attributes.name}
                                    </div>
                                    <div className='text'>
                                        {renderSubItems(
                                            item.relationships.subitems,
                                        )}
                                    </div>
                                </div>
                            ),
                        )}
                        <div className='info-item'>
                            <div className='divider'></div>
                            <div className='title'>
                                {getTranslation('perfil_psicografico')}
                            </div>
                            <div className='text'>
                                {data?.brief.attributes.psychographic_profile}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='campaign-specifications'>
                    <div className='title'>
                        {getTranslation('especificaciones_de_campana')}
                    </div>
                    <div className='info'>
                        {data?.brief?.relationships?.specifications?.map(
                            (item: any, index: any) => (
                                <div className='info-item' key={index}>
                                    <div className='divider'></div>
                                    <div className='title'>
                                        {item.attributes.name}
                                    </div>
                                    <div className='text'>
                                        {renderSubItems(
                                            item.relationships.subitems,
                                        )}
                                    </div>
                                </div>
                            ),
                        )}
                        <div className='info-item'>
                            <div className='divider'></div>
                            <div className='title'>
                                {getTranslation('objetivo_de_comunicacion')}
                            </div>
                            <div className='text'>
                                {data?.brief.attributes.communication_objective}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
