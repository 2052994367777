import { Form, Input, InputNumber } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Language } from '../../../config/Language'
import { Link } from 'react-router-dom'

interface IProps {
    id?: string | any
    label: string
    name: string
    className?: string | any
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    value?: any
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
    type?: string | any
    help?: string
    size?: string | any
    maxNumber?: number | any
}

export const CustomInput = ({ ...props }: IProps) => {
    const { getTranslation } = Language()
    const {
        id,
        label,
        name,
        className,
        required = false,
        disabled = false,
        requiredMessage = getTranslation('required'),
        value,
        tooltipTitle,
        showTooltips,
        help,
        type = 'text',
        size = 'large',
        maxNumber = '',
    } = props

    const [selectedValue, setSelectedValue] = useState<any>(null)

    useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
        return () => {}
    }, [value])

    const configTooltips = showTooltips
        ? {
              title: tooltipTitle,
              icon: <InfoCircleOutlined />,
          }
        : false

    const onFocus = (event: any) => {
        if (event.target.autocomplete) {
            event.target.autocomplete = 'whatever'
        }
    }

    let labelContainer = (
        <div className='label-container-help'>
            {label} {help && <Link to={help}>{getTranslation('help')}</Link>}
        </div>
    )

    return (
        <Form.Item
            id={id}
            name={name}
            className={className}
            label={labelContainer}
            rules={[
                {
                    required: required,
                    message: requiredMessage,
                },
            ]}
            tooltip={configTooltips}>
            {type === 'password' ? (
                <Input.Password
                    type={type}
                    disabled={disabled}
                    placeholder=''
                    size={size}
                />
            ) : type === 'number' ? (
                <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    max={maxNumber}
                    disabled={disabled}
                    placeholder=''
                    size={size}
                />
            ) : (
                <Input
                    type={type}
                    autoComplete='off'
                    onFocus={onFocus}
                    disabled={disabled}
                    placeholder=''
                    size={size}
                />
            )}
        </Form.Item>
    )
}
