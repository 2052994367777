import { Divider, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { ENDPOINTS } from '../../config/EndPoints'
import { Language } from '../../config/Language'
import { useAxios } from '../../hooks/useAxios'
import { CustomBarChart } from '../common/custom-bar-chart/CustomBarChart'
import { CustomCircleChart } from '../common/custom-circle-chart/CustomCircleChart'
import { CustomColumnChart } from '../common/custom-column-chart/CustomColumnChart'
import { CustomLineChart } from '../common/custom-line-chart/CustomLineChart'
import { CustomPieChart } from '../common/custom-pie-chart/CustomPieChart'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { FilterMetrics } from './FilterMetrics'
import { GeneralMetrics } from './GeneralMetrics'

export const Metrics = () => {
    const { get } = useAxios()
    const { getTranslation } = Language()
    const { errorObjectWithData } = Notifications()
    const [params, setParams] = useState({})
    const [generalSpin, setGeneralSpin] = useState(false)

    const [investmentHistoryData, setInvestmentHistoryData] = useState([])
    const [investmentHistorySpin, setInvestmentHistorySpin] = useState(false)

    const [activitiesKpisData, setActivitiesKpisData] = useState<any>({})
    const [activitiesKpisSpin, setActivitiesKpisSpin] = useState(false)

    const [activitiesWorkersData, setActivitiesWorkersData] = useState([])
    const [activitiesWorkersSpin, setActivitiesWorkersSpin] = useState(false)

    const [activitiesAccountData, setActivitiesAccountData] = useState([])
    const [activitiesAccountSpin, setActivitiesAccountSpin] = useState(false)

    const [activityByMonthsData, setActivityByMonthsData] = useState([])
    const [activityByMonthsSpin, setActivityByMonthsSpin] = useState(false)

    const [activityByTypeData, setActivityByTypeData] = useState([])
    const [activityByTypeSpin, setActivityByTypeSpin] = useState(false)

    useEffect(() => {
        setGeneralSpin(true)
        loadActivityByMonths()
        loadActivityByType()
        loadActivitiesWorkers()
        loadActivitiesAccount()
        loadActivitiesKpis()
        return () => {}
    }, [params])

    // ------------------------------------------
    const loadActivityByMonths = () => {
        setActivityByMonthsSpin(true)
        get(
            ENDPOINTS.GET_ACTIVITY_CHART_BY_MONTHS,
            params,
            successActivityByMonths,
            error,
            alwaysActivityByMonths,
        )
    }

    const successActivityByMonths = useCallback((response: any) => {
        setActivityByMonthsSpin(false)
        setActivityByMonthsData(response.data.data)
    }, [])

    const alwaysActivityByMonths = () => {
        setActivityByMonthsSpin(false)
    }

    // ------------------------------------------
    const loadActivitiesKpis = () => {
        setActivitiesKpisSpin(true)
        get(
            ENDPOINTS.GET_ACTIVITY_KPIS,
            params,
            successActivitiesKpis,
            error,
            alwaysActivitiesKpis,
        )
    }

    const successActivitiesKpis = useCallback((response: any) => {
        setActivitiesKpisSpin(false)
        setActivitiesKpisData(response.data.data)
    }, [])

    const alwaysActivitiesKpis = () => {
        setActivitiesKpisSpin(false)
    }
    // ------------------------------------------
    const loadActivitiesAccount = () => {
        setActivitiesAccountSpin(true)
        get(
            ENDPOINTS.GET_ACTIVITY_CHART_BY_ACCOUNTS,
            params,
            successActivitiesAccount,
            error,
            alwaysActivitiesAccount,
        )
    }

    const successActivitiesAccount = useCallback((response: any) => {
        setActivitiesAccountSpin(false)
        setActivitiesAccountData(response.data.data)
    }, [])

    const alwaysActivitiesAccount = () => {
        setActivitiesAccountSpin(false)
    }

    // ------------------------------------------
    const loadActivityByType = () => {
        setActivityByTypeSpin(true)
        get(
            ENDPOINTS.GET_ACTIVITY_CHART_BY_TYPES,
            params,
            successActivityByType,
            error,
            alwaysActivityByType,
        )
    }

    const successActivityByType = useCallback((response: any) => {
        setActivityByTypeSpin(false)
        setActivityByTypeData(response.data.data)
    }, [])

    const alwaysActivityByType = () => {
        setActivityByTypeSpin(false)
    }
    // ------------------------------------------

    const loadActivitiesWorkers = () => {
        setActivitiesWorkersSpin(true)
        get(
            ENDPOINTS.GET_ACTIVITY_CHART_BY_WORKERS,
            params,
            successActivitiesWorkers,
            error,
            alwaysActivitiesWorkers,
        )
    }

    const successActivitiesWorkers = useCallback((response: any) => {
        setActivitiesWorkersSpin(false)
        setActivitiesWorkersData(response.data.data)
    }, [])

    const alwaysActivitiesWorkers = () => {
        setActivitiesWorkersSpin(false)
    }

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }
    let dataTest = {
        campaignData: null,
        date: null,
    }

    if (
        generalSpin &&
        !activityByTypeSpin &&
        !activityByMonthsSpin &&
        !investmentHistorySpin &&
        !activitiesWorkersSpin &&
        !activitiesAccountSpin &&
        !activitiesKpisSpin
    ) {
        setGeneralSpin(false)
    }

    return (
        <Spin
            className='spin-general'
            indicator={antIcon}
            spinning={generalSpin}>
            <div id='metrics-page'>
                <div className='box'>
                    <div className='header'>
                        <div className='title'>{getTranslation('metrics')}</div>
                        <div className='campaign-box'>
                            <div className='last-campaign'>
                                <div className='campaign-title'>
                                    {getTranslation('ultima_campana')}
                                </div>
                                <div className='campaign-data'>
                                    {dataTest.campaignData ? (
                                        <span>{dataTest.campaignData}</span>
                                    ) : (
                                        <span className='data-empty'>
                                            {getTranslation('sin_datos')}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <Divider
                                type='vertical'
                                style={{ height: '100%' }}
                            />
                            <div className='last-campaig-date'>
                                <div className='campaign-title'>
                                    {getTranslation('fecha_ultima_campana')}
                                </div>
                                <div className='campaign-data'>
                                    {dataTest.date ? (
                                        <span>{dataTest.date}</span>
                                    ) : (
                                        <span className='data-empty'>
                                            {getTranslation('sin_datos')}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='box'>
                    <FilterMetrics setParams={setParams} />
                </div>
                <div className='box'>
                    <GeneralMetrics data={activitiesKpisData} />
                </div>
                <div className='box'>
                    <div className='title'>
                        {getTranslation('metricas_campanas')}
                    </div>
                </div>
                <div className='container-charts'>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('historial_propuestas')}
                        </div>
                        <div className='body'>
                            <CustomPieChart />
                        </div>
                    </div>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('historial_inversion_campana')}
                        </div>
                        <div className='body'>
                            <CustomBarChart />
                        </div>
                    </div>
                </div>
                <div className='container-charts'>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('historial_inversion_mensual')}
                        </div>
                        <div className='body'>
                            <CustomLineChart data={investmentHistoryData} />
                        </div>
                    </div>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('historial_inversion_medio')}
                        </div>
                        <div className='body'>
                            <CustomBarChart />
                        </div>
                    </div>
                </div>
                <div className='container-charts'>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('prevision_inversion_campaña')}
                        </div>
                        <div className='body'>
                            <CustomBarChart />
                        </div>
                    </div>
                </div>
                <div className='box'>
                    <div className='title'>
                        {getTranslation('metricas_actividades')}
                    </div>
                </div>
                <div className='container-charts'>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('por_tipo_actividad')}
                        </div>
                        <div className='body'>
                            <CustomCircleChart
                                data={activityByTypeData}
                                title={getTranslation('activities')}
                            />
                        </div>
                    </div>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('evolucion_actividades')}
                        </div>
                        <div className='body'>
                            <CustomLineChart data={activityByMonthsData} />
                        </div>
                    </div>
                </div>
                <div className='container-charts'>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('actividades_ejecutivo')}
                        </div>
                        <div className='body'>
                            <CustomCircleChart
                                data={activitiesWorkersData}
                                title={getTranslation('ejecutivo_cuenta')}
                            />
                        </div>
                    </div>
                    <div className='box-chart'>
                        <div className='title'>
                            {getTranslation('actividades_por_cuenta')}
                        </div>
                        <div className='body'>
                            <CustomColumnChart data={activitiesAccountData} />
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}
