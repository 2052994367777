import { Button, Steps } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Language } from '../../config/Language'
import { HELP } from '../../config/Path'
import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { AdvertiserForm } from './components/AdvertiserForm'
import { BoxComponents } from './components/BoxComponents'
import { BrandForm } from './components/BrandForm'
import { CompanyNamesForm } from './components/CompanyNamesForm'
import { ContactForm } from './components/ContactForm'

let { Step } = Steps

export const AddAdvertiser = () => {
    const { getTranslation } = Language()
    const { updateBreadCrumb } = useBreadCrumbContext()
    const [currentStep, setCurrentStep] = useState(0)
    const [globalData, setGlobalData] = useState<any>({})
    const [currentErrors, setCurrentErrors] = useState<number[]>([])
    useEffect(() => {
        updateBreadCrumb(getTranslation('add_an_advertiser'))
        return () => {}
    }, [])

    const next = () => {
        setCurrentStep(currentStep + 1)
    }

    const prev = () => {
        if (currentStep !== 0) {
            setCurrentStep(currentStep - 1)
        }
    }

    const skip = () => {
        setCurrentStep(currentStep + 1)
        setCurrentErrors([...currentErrors, currentStep])
    }

    const handleStatus = (current: number) => {
        if (currentErrors.find((item: any) => item === current)) return 'error'
        else if (current < currentStep) return 'finish'
        else if (current === currentStep) return 'process'
        else return 'wait'
    }

    return (
        <div id='add-advertiser'>
            <div className='left-container'>
                <Steps direction='vertical' current={currentStep}>
                    <Step
                        title={getTranslation('advertiser')}
                        status={handleStatus(0)}
                        description={
                            globalData.advertiserName &&
                            globalData.advertiserName
                        }
                    />
                    <Step
                        title={getTranslation('brand')}
                        status={handleStatus(1)}
                        description={
                            globalData.brandName && globalData.brandName
                        }
                    />
                    <Step
                        title={getTranslation('razon_social')}
                        description={
                            globalData.companyNames
                                ? globalData.companyNames
                                : getTranslation('you_can_skip')
                        }
                        status={handleStatus(2)}
                    />
                    <Step
                        title={getTranslation('contactos')}
                        description={getTranslation('you_can_skip')}
                        status={handleStatus(3)}
                    />
                </Steps>
            </div>
            <div className='right-container'>
                <BoxComponents
                    title={getTranslation('create_an_advertiser')}
                    id={0}
                    currentStep={currentStep}
                    help={HELP}>
                    <AdvertiserForm
                        globalData={globalData}
                        setGlobalData={setGlobalData}
                        next={next}
                    />
                </BoxComponents>
                <BoxComponents
                    title={getTranslation('crear_marca')}
                    id={1}
                    currentStep={currentStep}
                    help={HELP}>
                    <BrandForm
                        globalData={globalData}
                        setGlobalData={setGlobalData}
                        next={next}
                    />
                </BoxComponents>

                <BoxComponents
                    title={getTranslation('create_company_name')}
                    id={2}
                    currentStep={currentStep}
                    help={HELP}>
                    <CompanyNamesForm
                        globalData={globalData}
                        setGlobalData={setGlobalData}
                        next={next}
                        skip={skip}
                    />
                </BoxComponents>
                <BoxComponents
                    title={getTranslation('create_an_advertiser')}
                    id={3}
                    currentStep={currentStep}
                    help={HELP}>
                    <ContactForm
                        globalData={globalData}
                        setGlobalData={setGlobalData}
                        next={next}
                    />
                </BoxComponents>
            </div>
        </div>
    )
}
