import { Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ENDPOINTS } from '../../config/EndPoints'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { antIcon } from '../common/spin-icon/SpinIcon'

import useBreadCrumbContext from '../../hooks/useBreadCrumbContext'
import { BrandList } from './BrandList'
import { OrganizationForm } from './OrganizationForm'
import { ContactsList } from './ContactsList'
import { Language } from '../../config/Language'

interface RouteParams {
    id: string
}

export const Organization = () => {
    const { updateBreadCrumb } = useBreadCrumbContext()
    const { get } = useAxios()
    const { errorObjectWithData } = Notifications()
    const { getTranslation } = Language()
    const [generalSpin, setGeneralSpin] = useState(false)
    const [organizationSpin, setOrganizationSpin] = useState(false)
    const [organizationId, setOrganizationId] = useState<number | any>('')
    const [organization, setOrganization] = useState<any>(null)
    const [brands, setBrands] = useState<any>([])
    const [contactsList, setContactsList] = useState<any>([])

    let { id } = useParams<RouteParams>()
    if (id && id !== organizationId) {
        setOrganizationId(id)
    }

    const loadOrganizationData = () => {
        updateBreadCrumb(getTranslation(''))
        setOrganizationSpin(true)
        setGeneralSpin(true)
        get(
            ENDPOINTS.GET_ORGANIZATION.replace(
                '{organization}',
                organizationId,
            ),
            {},
            success,
            error,
            always,
        )
    }

    useEffect(() => {
        if (organizationId !== '') {
            loadOrganizationData()
        } else {
            updateBreadCrumb(getTranslation('nueva_organizacion'))
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (organizationId !== '' && organizationId !== null) {
            loadOrganizationData()
        } else {
            updateBreadCrumb(getTranslation('nueva_organizacion'))
            setOrganization(null)
        }
        return () => {}
    }, [organizationId])

    const success = useCallback((response: any) => {
        setOrganizationSpin(false)
        let organization = response.data.organization
        let brandList = response.data.accounts || []
        handleContacts(brandList)
        setOrganization(organization)
        setBrands(brandList)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        setOrganizationSpin(false)
    }

    const handleContacts = (accounts: any[]) => {
        let contacts: any[] = []
        accounts.map((item) => {
            item.contacts.map((item: any) => contacts.push(item))
        })
        setContactsList(contacts)
    }

    const handleOrganizationId = (id: number | null) => {
        if (id) {
            setOrganizationId(id)
        } else {
            setOrganizationId(null)
        }
    }

    if (!organizationSpin && generalSpin) {
        setGeneralSpin(false)
    }

    return (
        <>
            <Spin
                className='spin-general'
                indicator={antIcon}
                spinning={generalSpin}>
                <OrganizationForm
                    setOrganizationId={handleOrganizationId}
                    organization={organization}
                    hasAccounts={brands.length > 0}
                />
                {organization && (
                    <BrandList
                        brandList={brands}
                        organization={organization}
                        loadOrganizationData={loadOrganizationData}
                    />
                )}

                {organization && (
                    <ContactsList
                        contactsList={contactsList}
                        organization={organization}
                        hasAccounts={brands.length > 0}
                        loadOrganizationData={loadOrganizationData}
                    />
                )}
            </Spin>
        </>
    )
}
