import {
    ADVERTISERS_DETAIL,
    BRIEFS_FORM,
    NEW_ADVERTISERS,
} from '../../config/Path'
import { Link, useHistory } from 'react-router-dom'
import { Menu, Dropdown, Table, Button, message, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { useAxios } from '../../hooks/useAxios'
import { ENDPOINTS } from '../../config/EndPoints'
import { Notifications } from '../common/notifications/Notifications'
import { Language } from '../../config/Language'
import { antIcon } from '../common/spin-icon/SpinIcon'
import { ShowImage } from '../common/show-img/ShowImage'

export const AdvertisersList = () => {
    const history = useHistory()
    const { getTranslation, getTranslationObject } = Language()
    const { get } = useAxios()
    const { errorObjectWithData } = Notifications()
    const [advertiserList, setAdvertiserList] = useState<any>([])
    const [spinner, setSpinner] = useState(false)

    let height = 0
    const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
    )

    height = vh - 290

    useEffect(() => {
        loadAdvertiserList()
        return () => {}
    }, [])

    const loadAdvertiserList = () => {
        setSpinner(true)
        get(ENDPOINTS.GET_ACCOUNTS, {}, success, error, always)
    }

    const success = useCallback((response: any) => {
        setSpinner(false)
        response.data.data.forEach((element: any) => {
            element.key = element.id
        })
        setAdvertiserList(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        setSpinner(false)
    }

    const handleMenuDelete = (item: any) => {
        message.info(`Click to delete - ${item.id}`)
    }

    const handleMenuEdit = (item: any) => {
        history.push(`${BRIEFS_FORM}/${item.id}`)
    }

    const menu = (item: any) => {
        return (
            <Menu>
                <div style={{ padding: '0 12px', fontWeight: 600 }}>
                    {getTranslation('advertisers')}
                </div>
                <Menu.Divider />
                <Menu.Item key='2' onClick={() => handleMenuDelete(item)}>
                    {getTranslation('to_disable')}
                </Menu.Item>
            </Menu>
        )
    }

    const columns: any = [
        {
            title: getTranslation('logo'),
            dataIndex: 'organization_logo',
            key: 'organization_logo',
            fixed: 'left',
            align: 'center',
            width: 100,
            ellipsis: {
                showTitle: false,
            },

            render: (organization_logo: any, item: any) => (
                <Tooltip placement='topLeft' title={item.organization_logo}>
                    <ShowImage url={organization_logo} />
                </Tooltip>
            ),
        },
        {
            title: getTranslation('advertiser'),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (name: any, item: any) => (
                <Tooltip placement='topLeft' title={name}>
                    <Link to={`${ADVERTISERS_DETAIL}/${item.id}`}>{name}</Link>
                </Tooltip>
            ),
        },
        {
            title: getTranslation('categoria'),
            dataIndex: 'categoria',
            key: 'categoria',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: {
                compare: (a: any, b: any) => a.age - b.age,
                multiple: 3,
            },
            render: (categoria: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={item.subcategory.category.name}>
                    {item.subcategory.category.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('sub_categoria'),
            dataIndex: 'sub_categoria',
            width: 150,
            key: 'sub_categoria',
            ellipsis: {
                showTitle: false,
            },
            render: (sub_categoria: any, item: any) => (
                <Tooltip placement='topLeft' title={item.subcategory.name}>
                    {item.subcategory.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('brands'),
            dataIndex: 'brands',
            key: 'brands',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (brands: any) => {
                let names: string[] = []
                brands.map((item: any) => names.push(item.name))
                return (
                    <Tooltip placement='topLeft' title={names.join(', ')}>
                        {names.join(', ')}
                    </Tooltip>
                )
            },
        },
        {
            title: getTranslation('discount'),
            dataIndex: 'discount',
            key: 'discount',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (discount: any) => (
                <Tooltip placement='topLeft' title={discount}>
                    {`${discount} %`}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('created_by'),
            dataIndex: 'created_by',
            key: 'created_by',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (created_by: any, item: any) => (
                <Tooltip placement='topLeft' title={item?.user?.name}>
                    {item?.user?.name}
                </Tooltip>
            ),
        },
        {
            title: getTranslation('acciones'),
            dataIndex: 'action',
            key: 'action',
            ellipsis: {
                showTitle: false,
            },
            width: '150px',
            render: (_: any, item: any) => (
                <Dropdown trigger={['click']} overlay={() => menu(item)}>
                    <Button className='btn-actions'>
                        {getTranslation('accion')} <DownOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ]

    return (
        <div id='advertiser-list'>
            <div className='header'>
                <div className='header-title'>
                    {getTranslation('advertisers')}
                </div>
                <div className='header-button'>
                    <Button size='small' type='primary'>
                        <Link to={NEW_ADVERTISERS}>
                            {getTranslation('add_advertiser')}
                        </Link>
                    </Button>
                </div>
            </div>
            <div className='body'>
                <div className='body-title'>
                    {getTranslation('advertiser_list')}
                </div>
                <div className='body-table'>
                    <Table
                        className='advertiser-table'
                        sticky
                        locale={getTranslationObject('table_trad')}
                        bordered
                        pagination={false}
                        scroll={{ x: 800, y: height }}
                        columns={columns}
                        loading={{ spinning: spinner, indicator: antIcon }}
                        dataSource={advertiserList}
                    />
                </div>
            </div>
        </div>
    )
}
